import axios from 'axios'
import { db, messaging } from '../api/firebaseConfig'
import { signOut, getAuth, updateEmail } from 'firebase/auth'
import {
  query,
  collection,
  limit,
  orderBy,
  getDocs,
  DocumentData,
  where,
  QueryConstraint,
  setDoc,
  updateDoc,
  doc,
  getDoc,
} from 'firebase/firestore'
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions'
import { getApp } from 'firebase/app'
import {
  ICustomerReview,
  IPdfInvoiceParams,
  IContactUsRequest,
} from '../components/utils/interfaces'
import { getToken } from 'firebase/messaging'

const BASE_URL = process.env.REACT_APP_BASE_URL
const functions = getFunctions(getApp())
const vapidKey = process.env.REACT_APP_VAPID_KEY
// connectFunctionsEmulator(functions, "localhost", 5001);
// Comment this before PR

export default class FirebaseAPI {
  static async fetchItem(table: string, id: string, raw?: boolean) {
    const req = doc(db, table, id)
    const docSnap = await getDoc(req)
    return docSnap.data()
  }
  static async setReview(id: string, data: ICustomerReview) {
    const docRef = doc(db, 'customer_rating', id)
    const reviewData = data.review.trim() || ''
    const editedData = { ...data, review: reviewData }
    return await setDoc(docRef, data)
  }

  static async sendMessage(request: IContactUsRequest) {
    return await axios.post(BASE_URL + 'sendUserMessage', request)
  }
  static async contactUsEmail(data: { to: string }) {
    return await axios.post(BASE_URL + 'contactUsMail', data)
  }
  static async fetchFixer(id: string, raw?: boolean) {
    return await this.fetchItem('tradesman', id, raw)
  }
  static async fetchCustomer(id: string, raw?: boolean) {
    return await this.fetchItem('customers', id, raw)
  }
  static async getBlogPosts(
    articlesPerPage?: number,
    sortingAsc?: boolean,
    type?: string
  ) {
    const constraints: QueryConstraint[] = [
      orderBy('date', sortingAsc ? 'asc' : 'desc'),
    ]
    if (type) constraints.push(where('articleType', '==', type))
    if (articlesPerPage) constraints.push(limit(articlesPerPage))
    let articlesQuery = query(collection(db, 'fl_content'), ...constraints)
    const snapshot = await getDocs(articlesQuery)
    const data = snapshot.docs.map((doc) => {
      return doc.data()
    })
    return data
  }
  static async getInvoices() {
    const getInvoicesById = httpsCallable(functions, 'getInvoicesById')
    return await getInvoicesById()
  }
  static async customerSignupEmail(to: string) {
    return await fetch(BASE_URL + 'customerSignupMail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ to }),
    })
  }
  static async getReview(id: string) {
    const idQuery = query(
      collection(db, 'customer_rating'),
      where('booking_id', '==', id)
    )
    const idSnapshot = await getDocs(idQuery)
    const idData = idSnapshot.docs.map((doc) => {
      return doc.data()
    })
    return idData[0]
  }
  static async getPdfInvoice(params: IPdfInvoiceParams) {
    const response = await axios.post(BASE_URL + 'getPdfInvoice', params, {
      responseType: 'arraybuffer',
    })
    var binaryData = []
    binaryData.push(response.data)
    const href = URL.createObjectURL(
      new Blob(binaryData, { type: 'application/zip' })
    )
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', 'invoice.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
  static async doesUserExistInPath(uid: string, path: string) {
    const docRef = doc(db, path, uid)
    const docSnap = await getDoc(docRef)
    return docSnap.exists()
  }
  static async updateEmail(email: string) {
    const auth = getAuth()
    if (!auth.currentUser) {
      throw new Error('No user logged in')
    }
    await updateEmail(auth.currentUser, email)
    const dbPath =
      window.localStorage.getItem('userType') === 'customer'
        ? 'customers'
        : 'tradesman'
    await updateDoc(doc(db, dbPath, auth.currentUser.uid), { email })
  }
  static async getBlogPost(id: string) {
    const articlesQuery = query(
      collection(db, 'fl_content'),
      where('id', '==', id)
    )
    const documentSnapshots = await getDocs(articlesQuery)
    const rawData = documentSnapshots.docs.map((doc) => {
      const data = doc.data()
      return data
    })
    return rawData[0]
  }
  static async sendSupportEmail(data: {
    link?: string
    email?: string
    completed?: boolean
    pText?: string
    to?: string
    title?: string
  }) {
    return await axios.post(BASE_URL + 'supportMail', data)
  }
  static async stripeCreateFixerAccount(
    business_type: string,
    country: string,
    email: string,
    name: string,
    phone: string,
    url = '',
    vat_id = ''
  ) {
    return await axios.post(BASE_URL + 'stripeCreateFixerAccount', {
      business_type,
      country,
      email,
      name,
      phone,
      url,
      vat_id,
    })
  }
  static async sendMail(
    title: string,
    title2: string,
    paragraph: string,
    paragraph2: string,
    secondParagraph: string,
    secondParagraph2: string,
    ctaButton: string,
    to: string
  ) {
    await axios.post(BASE_URL + 'sendMail', {
      title,
      title2,
      paragraph,
      paragraph2,
      secondParagraph,
      secondParagraph2,
      ctaButton,
      to,
    })
  }
}
