import React from 'react'
import styled from 'styled-components'
import { convertToHex } from '../utils/convertToHex'

interface Props {
  data: {
    name: string
    image: string
    backgroundColor: number
  }
}

const ServiceCard: React.FC<Props> = ({ data }) => {
  const color = convertToHex(data.backgroundColor)

  return (
    <Container background={color}>
      <a href="/working-on-it">
        {/* TODO add routing */}
        <div className="img-container">
          <img src={data.image} alt={data.name} />
        </div>
        <div className="paragraph-container">
          <p>{data.name}</p>
        </div>
      </a>
    </Container>
  )
}

export default ServiceCard

interface cardProps {
  background?: string | number
}

const Container = styled.div<cardProps>`
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  min-width: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  background: ${(props) => props.background};
  .img-container {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: cover;
      width: 40%;
    }
  }
  .paragraph-container {
    white-space: nowrap;
    font-size: 15px;
    text-align: center;
    color: #000000;
    font-weight: 500;
  }
  a {
    text-decoration: none;
  }
`
