import { createSlice } from '@reduxjs/toolkit'

/* Each page have an individual boolean state, functionallity to only have one state set to true at any given time */
/* To add page to state, add object with following properties type, status, translation as shown below. */
const initialState = {
  currentDot: 'noDot',
  dots: [
    {
      type: 'my-account',
      status: false,
      translation: 'my-account',
    },
    {
      type: 'About',
      status: false,
      translation: 'about',
    },
    {
      type: 'Howitworks',
      status: false,
      translation: 'Howitworks',
    },
    {
      type: 'Services',
      status: false,
      translation: 'services',
    },
    {
      type: 'noDot',
      status: false,
      translation: 'noDot',
    },
  ],
}

const dotSlice = createSlice({
  name: 'dots',
  initialState,
  reducers: {
    setCurrentDot(state, action) {
      state.dots.map((page, index) => {
        if (page.status === true) {
          state.dots[index].status = false
        }
        if (page.type === action.payload) {
          state.currentDot = page.type
          return { ...page, status: true }
        }
        return ''
      })
    },
  },
})

export const { setCurrentDot } = dotSlice.actions
export default dotSlice.reducer
