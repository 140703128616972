import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fixerInfoNav: 'requirements',
  navs: [
    {
      type: 'requirements',
      status: false,
    },
    {
      type: 'earnings',
      status: false,
    },
    {
      type: 'the-basics',
      status: false,
    },
    {
      type: 'take-control',
      status: false,
    },
    {
      type: 'contact',
      status: false,
    },
  ],
}

const fixerInfoSlice = createSlice({
  name: 'navs',
  initialState,
  reducers: {
    setFixerInfoNav(state, action) {
      state.navs.map((page, index) => {
        if (page.status === true) {
          state.navs[index].status = false
        }
        if (page.type === action.payload) {
          state.fixerInfoNav = page.type
          return { ...page, status: true }
        }
        return ''
      })
    },
  },
})

export const { setFixerInfoNav } = fixerInfoSlice.actions
export default fixerInfoSlice.reducer
