import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import mya from '../../assets/global/blobs/mya.png'
import { useTranslation } from 'react-i18next'
import circle from '../../assets/global/icons/green-circle.svg'
import check from '../../assets/global/icons/white-check.svg'
import Modal from './Modal'
import { useSelector, useDispatch } from 'react-redux'
import { setIsClosed } from '../../store/slice/ModalSlice'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store/rootstate'
import DownloadAppModal from './DownloadAppModal'

const CustomerContactUsSent: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)
  const { currentModal } = useSelector((state: RootState) => state.modalReducer)
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  const autoCloseModal = () => {
    setTimeout(() => {
      dispatch(setIsClosed('customerContactUsSent'))
      // useEffect(() => {
      navigate('/', { replace: true })
      // })
    }, 5000)
  }
  useEffect(() => {
    autoCloseModal()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <Modal option={currentModal === 'customerContactUsSent' ? true : false}>
      {isDesktop ? (
        <DesktopModal>
          <img src={mya} alt="mya blob" />
          <h1>{t('customer-contact-us.desktop.modal.message-1')}</h1>
          <h3>{t('customer-contact-us.desktop.modal.message-2')}</h3>
        </DesktopModal>
      ) : (
        <MobileModal>
          <p>{t('customer-contact-us.mobile.modal-message')}</p>
          <div className="check-icon">
            <img
              className="circle"
              src={circle}
              alt="background circle decoration"
            />
            <img
              className="check"
              src={check}
              alt="background circle decoration"
            />
          </div>
        </MobileModal>
      )}
    </Modal>
  )
}

export default CustomerContactUsSent

const DesktopModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Avenir-light;
  font-weight: 100;
  img {
    height: 233px;
    margin: 30px;
  }
  h1 {
    font-size: 52px;
    font-family: Avenir-light;
    font-weight: 100;
  }
  h3 {
    font-size: 28px;
    font-family: Avenir-light;
    font-weight: 100;
  }
`

const MobileModal = styled.div`
  p {
    width: 259px;
    height: 60px;
    margin: 93px auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #000000;
  }

  .check-icon {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  .circle {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .check {
    position: absolute;
    z-index: 2;
    margin: 28.5px 22.5px;
  }
`
