export const organizationListEN = [
  'NAPIT',
  'APHC',
  'BESCA',
  'Blue Flame Certification',
  'NICEIC',
  'OFTEC',
  'Registered Competent Person',
  'ECA',
  'ECS',
  'Watersafe',
  'CIPHE',
  'SNIPEF',
  'Fairtrades',
  'Gas safe register',
  'Other',
]
export const organizationListSE = ['Elsäkerhetsverket', 'Säkervatten', 'Annat']