import { DefaultTheme } from 'styled-components'

export const variables: DefaultTheme = {
  text: {
    paragraph: {
      fontSize: '16px',
    },
    heading1: {
      responsiveSize: '4rem',
    },
    heading2: {
      responsiveSize: '3rem',
    },
    heading3: {
      footer: {
        fontSize: '25px',
        fontColor: '#ffffff',
      },
    },
    link: {
      footer: {
        fontColor: '#ffffff',
      },
    },
  },

  button: {
    orange: '#F27649',
    green: '#0F7173',
    fontSize: '20px',
    hover: 'brightness(1.15)',
    active: 'brightness(1)',
  },

  mixins: {
    // align and justify => Center using column.
    flexcolumn:
      'display:flex; flex-direction: column; justify-content: center; align-items: center;',
  },
  contentWidth: {
    max: '1200px',
    width: '100%',
  },
}
