import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import About from '../pages/About'
import FAQPage from '../pages/FAQPage'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import Careers from '../pages/Careers'
import CareerDetails from '../pages/CareerDetails'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import TermsConditionsPage from '../pages/TermsConditionsPage'
import HowItWorks from '../pages/HowItWorks'
import FixerHowItWorks from '../pages/FixerHowItWorks'
import FixerInfoRequirements from '../pages/FixerInfoRequirements'
import FixerInfoEarnings from '../pages/FixerInfoEarnings'
import CustomerContactUs from '../pages/CustomerContactUs'
import FixerTermsConditions from '../pages/FixerTermsConditions'
import WereWorkingOnIt from '../pages/WereWorkingOnIt'
import PageNotFound from '../pages/PageNotFound'
import { WhatIsROT } from '../pages/WhatIsROT'
import MeetTheTeam from '../pages/MeetTheTeam'
import SignUpPage from '../pages/SignUp'
import SuccessResetPasswordMailSend from '../pages/SuccessResetPasswordSent'
import SuccessNewPassword from '../pages/SuccessNewPassword'
import Blog from '../pages/Blog/Blog'
import { useTranslation } from 'react-i18next'
import MyAccount from '../pages/Account/MyAccount/MyAccount'

import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string)

import FixerInfoBasics from '../pages/FixerInfoBasics'
import FixerInfoContact from '../pages/FixerInfoContact'
import FixerInfoTakeControl from '../pages/FixerInfoTakeControl'
import HomePage from '../pages/HomePage'
import BlogExpanded from '../pages/Blog/BlogExpanded'
import SuccessPage from '../pages/Account/SuccessPage'
import ServiceTerms from '../pages/ServiceTerms'
import DownloadApp from '../pages/DownloadApp/DownloadApp'
import SignInWrapper from '../components/modals/LogIn/SignInWrapper'
import CompanyDetails from '../pages/Account/FixerAccount/CompanyDetails'
import Account from '../pages/Account/CustomerAccount/Account'
import Invoices from '../pages/Account/Invoices/Invoices'
import MyBookings from '../pages/Account/MyBookings/MyBookings'
import BookingDetails from '../pages/Account/BookingDetails/BookingDetails'
import Settings from '../pages/Account/Settings/Settings'
import FixerSettings from '../pages/Account/FixerSettings/FixerSettings'
const Routes: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  let element = useRoutes([
    {
      element: <HowItWorks />,
      path: '/',
    },
    {
      element: <HomePage />,
      path: '/home',
    },
    {
      element: <About />,
      path: '/about',
    },
    {
      element: <PrivacyPolicyPage />,
      path: '/privacy-policy',
    },
    {
      element: <Careers />,
      path: '/careers',
    },
    {
      element: <CareerDetails />,
      path: '/career-details',
    },
    {
      element: <CustomerContactUs />,
      path: '/contact-us',
    },
    {
      element: <CustomerContactUs />,
      path: '/contact-us/:id',
    },
    {
      element: <TermsConditionsPage />,
      path: '/terms-conditions',
    },
    {
      element: <FAQPage />,
      path: '/faqs',
    },
    {
      element: <ResetPasswordPage />,
      path: '/reset-password',
    },
    {
      element: <ResetPasswordPage />,
      path: '/reset-Password',
    },
    {
      element: <FixerInfoRequirements />,
      path: '/fixer-information',
    },
    {
      element: <FixerInfoRequirements />,
      path: '/fixer-information/requirements',
    },
    {
      element: <FixerInfoEarnings />,
      path: '/fixer-information/earnings',
    },
    {
      element: <FixerInfoBasics />,
      path: '/fixer-information/the-basics',
    },
    {
      element: <FixerInfoTakeControl />,
      path: '/fixer-information/take-control',
    },
    {
      element: <FixerInfoContact />,
      path: '/fixer-information/contact',
    },
    {
      element: <HowItWorks />,
      path: '/how-it-works',
    },
    {
      element: <FixerHowItWorks />,
      path: '/fixer-how-it-works',
    },
    {
      element: <FixerTermsConditions />,
      path: '/fixer-terms-conditions',
    },
    {
      element: <WereWorkingOnIt />,
      path: '/working-on-it',
    },
    {
      element: <CustomerContactUs />,
      path: '/message-sent',
    },
    {
      element: <WhatIsROT />,
      path: '/what-is-rot',
    },
    {
      element: <MeetTheTeam />,
      path: '/meet-the-team',
    },
    {
      path: '/sign-up',
      element: <SignUpPage />,
    },
    {
      path: '/success-reset-password',
      element: <SuccessNewPassword />,
    },
    {
      path: '/success-mail-sent',
      element: <SuccessResetPasswordMailSend />,
    },
    {
      path: '/blog',
      element: <Blog />,
    },
    { path: '/news', element: <Blog initialCategory="News" /> },
    { path: '/video', element: <Blog initialCategory="Video" /> },
    {
      path: '/blog/:id',
      element: <BlogExpanded />,
    },
    {
      path: '/account',
      element: <CompanyDetails />,
    },
    {
      path: '/account/company-details',
      element: <CompanyDetails />,
    },
    {
      path: '/email-success',
      element: (
        <SuccessPage
          title={t('success-page.title')}
          paragraph={t('success-page.paragraph-changed-email')}
        />
      ),
    },
    {
      path: '/details-success',
      element: (
        <SuccessPage
          title={t('success-page.title')}
          paragraph={t('success-page.paragraph-changed-details')}
        />
      ),
    },
    {
      path: '/deactivate-success',
      element: (
        <SuccessPage
          title={t('success-page.title')}
          paragraph={t('success-page.paragraph-deactivate')}
        />
      ),
    },
    {
      path: '/service-terms',
      element: <ServiceTerms />,
    },
    {
      path: '/download-fixer-app',
      element: <DownloadApp />,
    },
    {
      path: '/download-customer-app',
      element: <DownloadApp isCustomer={true} />,
    },
    {
      path: '/sign-in',
      element: <SignInWrapper />,
    },
    {
      path: '/customer-account',
      element: <MyAccount />,
    },
    {
      path: '/invoices',
      element: <Invoices />,
    },
    {
      path: '/bookings',
      element: <MyBookings />,
    },
    {
      path: '/bookings/:id',
      element: <BookingDetails />,
    },
    {
      path: '/customer-account/settings',
      element: <Settings />,
    },
    { path: '/account/settings', element: <FixerSettings /> },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ])

  return element
}

export default Routes
