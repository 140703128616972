import styled from 'styled-components'
export const Button = styled.button`
  font-size: 16px;
  &:disabled {
    opacity: 0.7;
  }
  &.orange {
    background-color: #f27649;
  }
  outline: none;
  border: none;
  padding: 14px 38px;
  background-color: #34344a;
  border-radius: 4px;
  font-weight: 900;
  color: white;
  font-family: 'Avenir', Arial, sans-serif;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
  }
`