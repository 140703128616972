import styled from 'styled-components'

export const Input = styled.input`
  background-color: #efecef;
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #727272;
  font-weight: 900;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`



export const MobileInput = styled.div`
  width: 100%;
  background-color: #efecef;
  & img {
    margin-bottom: 3px;
  }
  display: flex;
  font-weight: 900;
  color: #727272;
  font-family: 'Avenir', Arial, sans-serif;
  position: relative;
  & > .PhoneInput {
    width: 100%;
    margin-top: 0;
  }
  & > .PhoneInput > .PhoneInputCountry {
    padding-right: 9px;
    opacity: 0;
    position: absolute;
  }
  & > .PhoneInput > .PhoneInputCountryIcon {
    display: none;
  }
  & > .PhoneInput > .PhoneInputCountrySelect, & > .PhoneInput > div {
    height: 100%;
    left: 0;
    width: 60px;
   }

   & > .PhoneInput > .PhoneInputCountry > select {
    width: 60px;
    }

    & > .PhoneInput> input {
      background: transparent;
      border: none;
      outline: none;
      }
      
  & > .PhoneInput .PhoneInputInput {
    padding-right: 5px;
    padding-left: 5px;
    font-weight: 900;
    color: #727272;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  & > .mobile-input-code {
    display: flex;
    gap: 5px;
    align-items: center;
    top: 16px;
    left: 10px;
  }
`