import { useTranslation } from "react-i18next";

function useInvoicesData() {
  const { t } = useTranslation();
  return [
    {
      value: 'all',
      label: t('select.all'),
    },
    {
      value: 'lastMonth',
      label: t('select.last-month'),
    },
    {
      value: 'last6Months',
      label: t('select.last-6-months'),
    },
    {
      value: 'lastYear',
      label: t('select.last-12-months'),
    }
  ]
}

export default useInvoicesData;