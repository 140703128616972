import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LegalTerms = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <div className="containers">
        <div className="rights-reserved">
          <b>{t('terms-and-conditions.copyright')}</b>
        </div>
        <div className="terms-policy">
          <Link to="/terms-conditions">
            <b>{t('terms-and-conditions.part-2-user')}</b>
          </Link>
          <Link to="/privacy-policy">
            <b>{t('terms-and-conditions.part-4')}</b>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default LegalTerms

const Container = styled.section`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;
  .containers {
    display: flex;
    margin: 0;
    max-width: 1250px;
    width: 100%;
  }
  b {
    font-size: 0.8rem;
  }
  .terms-policy {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    a {
      text-decoration: none;
      color: #000000;
    }
  }
  .rights-reserved {
    width: 100%;
    display: none;

    @media screen and (min-width: 499px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
