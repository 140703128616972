import React from 'react'
import styles from './ProgressBar.module.css'

function ProgressBar(props: {
  className: string
  progress: number
  containerClass?: string
  titleClass?: string
  barClass?: string
}) {
  return (
    <div
      className={`${props.containerClass} ${props.className} ${styles.container}`}
    >
      <span className={`${props.titleClass} ${styles.title}`}>
        {props.progress}% complete
      </span>
      <div className={styles.barWrapper}>
        <div
          className={styles.bar}
          style={{ width: `${props.progress}%` }}
        ></div>
      </div>
    </div>
  )
}

export default ProgressBar
