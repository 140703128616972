import { A } from '../ui'

function InsuranceButton(isFromUk: boolean, name: any) {
  // console.log(isFromUk)
  if (isFromUk) {
    return (
      <A target="_blank" href="https://bit.ly/Bokafix-Towergate">
        {name}
      </A>
    )
  }
  return
}

export default InsuranceButton
