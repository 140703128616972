import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import styles from './LogInModal.module.css'
import MainButton from '../../common/MainButton'
import { ErrorItem } from '../../ui/ModalUI'
import { validateEmail, validatePassword } from './validation'
import backgroundpassword from '../../../assets/global/modals/auth-mobile-background.png'
import MyLogo from '../../../assets/global/modals/bokafix-fixer-logo.svg'
import inputlogo from '../../../assets/global/modals/user-logo-black.svg'
import passlogo from '../../../assets/global/modals/lock.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../../../store/slice/ModalSlice'
import Switch from './../../ui/Switch'
import PasswordField from './../../ui/PasswordField'
import {
  MobileElement,
  CardMobile,
  OptionsContainer,
  RememberContainer,
  OrangeText,
  RegisterLink,
  WhiteBoldSpan,
} from './styled-components'
import SelectType from './SelectType'

interface IProps {
  isEmailValid: boolean | null
  isPassValid: boolean | null
  setIsEmailValid: (value: boolean) => void
  setIsPassValid: (value: boolean) => void
  handleSubmit: () => void
  handleMail: (text: string) => void
  handlePassword: (pass: string) => void
  handleRemember: () => void
}

function LogInModalMobile(props: IProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  function goToForgotPassword() {
    dispatch(setIsOpen('forgotPasswordModal'))
  }

  const handlePassword = (text: string) => {
    props.handlePassword(text)
    props.setIsPassValid(validatePassword(text))
  }

  const handleEmail = (text: string) => {
    props.handleMail(text)
    props.setIsEmailValid(validateEmail(text))
  }

  const submitPassword = () => {
    if (props.isPassValid === true && props.isEmailValid === true) {
      props.handleSubmit()
    } else {
      props.setIsPassValid(false)
      props.setIsEmailValid(false)
    }
  }

  const [step, setStep] = useState('selectType')

  return (
    <MobileElement>
      <CardMobile style={{ backgroundImage: `url(${backgroundpassword})` }}>
        <div className="wrap-container-forgotm">
          <div className="logo-container-forgotm">
            <img src={MyLogo} alt="logo" />
          </div>
          {step === 'selectType' ? (
            <SelectType setStep={setStep} />
          ) : (
            <>
              <div className="input-container-forgotm">
                <label style={{ position: 'relative' }} htmlFor="">
                  <div className="input-label-forgot">
                    <img style={{ opacity: '.5' }} src={inputlogo} alt="logo" />
                  </div>
                  <input
                    onChange={(e) => handleEmail(e.target.value)}
                    type="email"
                    placeholder={t('form.email')}
                  />
                  {props.isEmailValid || props.isEmailValid === null ? null : (
                    <ErrorItem>{t('errors.invalid-email')}</ErrorItem>
                  )}
                </label>
                <label style={{ position: 'relative' }} htmlFor="">
                  <div style={{ zIndex: 1 }} className="input-label-forgot">
                    <img src={passlogo} alt="logo" />
                  </div>
                  <PasswordField
                    className={styles['password-field-mobile']}
                    placeholder={t('form.password')}
                    onChange={(e) => handlePassword(e.target.value)}
                  />
                  {props.isPassValid || props.isPassValid === null ? null : (
                    <ErrorItem>{t('errors.invalid-password')}</ErrorItem>
                  )}
                </label>
              </div>
              <OptionsContainer>
                <RememberContainer>
                  <Switch onChange={props.handleRemember} />
                  <OrangeText>{t('form.remember-me')}</OrangeText>
                </RememberContainer>
                <OrangeText onClick={goToForgotPassword}>
                  {t('modals.forgot-password.mobile-heading')}
                </OrangeText>
              </OptionsContainer>
              <div className="btn-container-forgotm">
                <MainButton
                  handleClick={submitPassword}
                  isLarge={true}
                  isLight={true}
                  buttonName={t('button.login')}
                />
              </div>
            </>
          )}
        </div>
        <div className="link-container-forgotm">
          <RegisterLink href="/sign-up">
            {t('login.do-not-have-an-account')}
            <WhiteBoldSpan>{t('login.register-now')}</WhiteBoldSpan>
          </RegisterLink>
        </div>
      </CardMobile>
    </MobileElement>
  )
}

export default LogInModalMobile
