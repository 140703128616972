import { getAuth, signOut } from 'firebase/auth'
import { setIsClosed, setIsOpen } from '../store/slice/ModalSlice';
import { useDispatch } from 'react-redux';

function useHandleUserNotFound() {
  const dispatch = useDispatch();
  return () => {
    dispatch(setIsClosed('logInModal'))
    const auth = getAuth();
    dispatch(setIsOpen('missingAccountModal'))
    return signOut(auth);
  }
}

export default useHandleUserNotFound