import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setUser } from '../store/slice/AuthSlice'

export const useFetchAuth = (
) => {
  const dispatch = useDispatch();
  const auth = getAuth()
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        dispatch(setUser(null));
        return;
      }
      const userData = user.providerData[0];
      const uid = user.uid;
      dispatch(setUser({
        ...userData,
        uid
      }));
    })
    return unsubscribe;
  }, [])
}
