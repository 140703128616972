import styled from 'styled-components';
export const Container = styled.div`
  height: 70%;
  width: 80vw;
  padding: 40px 90px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Avenir-light;
  font-weight: 100;
  background: white;
  text-align: center;
  * {
    font-family: Avenir-light;
    font-weight: 100;
  }

  .blob {
    width: 100px;
  }
  h1 {
    padding: 0 8%;
    font-size: 40px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 10px;
  }
  .links {
    * {
      margin: 0 5px;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100vw;
    height: 100vh;
    justify-content: space-between;
    padding: 20% 10% 30%;
    * {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
    }
    .main {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    h1,
    h3 {
      font-size: 24px;
    }
    h1 {
      margin: 0px 10% 10%;
    }
    p {
      margin-top: 40%;
    }
    .links {
      display: flex;
    }
    .blob {
      margin-top: 20%;
    }
  }
`
