import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { getAuth, onAuthStateChanged, signOut, updateEmail } from 'firebase/auth'

interface IProps {
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}

async function deactivateAccount() {
  const user = await getAuth().currentUser
  if (user) {
    axios
      .post(
        'https://us-central1-bokafix-4371c.cloudfunctions.net/changeAccountDisabledStatus',
        {
          isDisabled: true,
          uid: user?.uid,
        }
      )
      .then(() => {
        signOut(getAuth())
        window.location.href = '/deactivate-success'
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function DeleteUserModal(props: IProps) {
  return (
    <ModalWrapper>
      <ModalContainer>
        <ModalTitleWrapper>
          <ModalTitle>Are you sure you want to delete your account?</ModalTitle>
        </ModalTitleWrapper>
        <ModalButtons>
          <Button onClick={deactivateAccount} type="button" className="dark">
            Yes
          </Button>
          <Button
            type="button"
            onClick={() => props.setDeleteModal(false)}
            className="orange"
          >
            No
          </Button>
        </ModalButtons>
      </ModalContainer>
    </ModalWrapper>
  )
}

const ModalTitleWrapper = styled.div`
  @media (max-width: 900px) {
    background-color: #34344a;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
    border-radius: 8px;
    margin-bottom: 22px;
  }
`

const ModalTitle = styled.h2`
  letter-spacing: 1.56px;
  color: #3d3d3d;
  opacity: 1;
  text-align: center;
  max-width: 620px;
  font: normal normal 100 52px/60px Avenir;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    color: white;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    padding: 18px 43px;
  }
`

const ModalWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  transition: 2s;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media (max-width: 900px) {
    box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
    backdrop-filter: blur(13.5914px);
  }
`

const ModalContainer = styled.div`
  background: white;
  box-shadow: 5px 5px 25px #0000001a;
  border-radius: 4px;
  padding: 160px 140px;
  @media (max-width: 900px) {
    padding: 0;
  }
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 19px;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0px 20px 20px 20px;
  }
`
const Button = styled.button`
  border-radius: 4px;
  color: white;
  border: none;
  outline: none;
  padding: 14px 68px;
  transition: 0.3s;
  font-weight: 900;
  &:hover {
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.7;
  }
  &.dark {
    background-color: #34344a;
    @media (max-width: 900px) {
      background-color: #f27649;
    }
  }
  &.orange {
    background-color: #f27649;
  }
`

export default DeleteUserModal
