import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'

export const WhatIsROT = () => {
  const { t } = useTranslation()
  usePageParams('What is ROT/RUT avdrag?', 'customerPage', '')
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      <Container>
        <h1>{t('rot.heading')}</h1>
        <main>
          <section className="terms">
            <h2>{t('rot.terms.title')}</h2>
            <p>{t('rot.terms.first')}</p>
            <ul>
              <li>{t('rot.terms.list.first')}</li>
              <li>{t('rot.terms.list.second')}</li>
              <li>{t('rot.terms.list.third')}</li>
            </ul>
            <p>
              {t('rot.terms.second')}
              <a target="blank" href="https://www.skatteverket.se/">
                {t('rot.terms.link')}
              </a>
              {t('rot.terms.finish')}
            </p>
          </section>
          <section className="what1">
            <h2>{t('rot.what-is-rot-work.title')}</h2>
            <p>{t('rot.what-is-rot-work.text')}</p>
          </section>
          <section className="what2">
            <h2>{t('rot.what-is-rut-work.title')}</h2>
            <p>{t('rot.what-is-rut-work.first')}</p>
            <ul>
              <li>{t('rot.what-is-rut-work.list.first')}</li>
              <li>{t('rot.what-is-rut-work.list.second')}</li>
              <li>{t('rot.what-is-rut-work.list.third')}</li>
              <li>{t('rot.what-is-rut-work.list.fourth')}</li>
              <li>{t('rot.what-is-rut-work.list.fifth')}</li>
            </ul>
          </section>
          <section className="size">
            <h2>{t('rot.size.title')}</h2>
            <a
              target="blank"
              href="https://www.skatteverket.se/foretag/skatterochavdrag/rotochrut.4.2ef18e6a125660db8b080002674.html?q=rot"
            >
              {t('rot.size.first')}
            </a>
            <p>{t('rot.size.second')}</p>
            <ul>
              <li>
                <a
                  target="blank"
                  href="https://www.skatteverket.se/foretag/skatterochavdrag/rotochrut/gerarbetetratttillrotavdrag.4.5c1163881590be297b5173bf.html"
                >
                  {t('rot.size.list.first')}
                </a>
              </li>
              <li>
                <a
                  target="blank"
                  href="https://www.skatteverket.se/foretag/skatterochavdrag/rotochrut/gerarbetetratttillrutavdrag.4.2ef18e6a125660db8b080001531.html"
                >
                  {t('rot.size.list.second')}
                </a>
              </li>
            </ul>
            <p>{t('rot.size.third')}</p>
          </section>
        </main>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 100px 150px;
  font-family: Avenir;
  h1 {
    font-family: Avenir-heavy;
    font-size: 53px;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  h2 {
    font-family: Avenir-heavy;
    font-size: 16px;
    margin: 30px 0;
  }
  a,
  p,
  li {
    line-height: 24px;
    white-space: pre-line;
  }
  li {
    line-height: 30px;
  }
  ul {
    margin: 20px 0;
    padding-left: 15px;
  }
  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  @media only screen and (max-width: 900px) {
    padding: 0 10px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    h2 {
      font-family: Poppins;
      font-weight: 500;
      font-size: 13px;
      margin: 20px 0;
    }
    h1 {
      display: none;
    }
    li {
      line-height: 24px;
    }
    ul {
      padding-left: 20px;
    }
  }
`
