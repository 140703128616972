import { createSlice } from '@reduxjs/toolkit'

/* Each modal have an individual boolean state, functionallity to only have one state set to true at any given time */
/* To add modal to state, add object with following properties type, status, translation as shown below. */
const initialState = {
  isModal: false,
  currentModal: '',
  modals: [
    {
      type: 'registration-modal',
      status: false,
      translation: 'registration-modal',
      title: 'Sign up',
    },
    {
      type: 'forgotPasswordModal',
      status: false,
      translation: 'forgotPasswordModal',
    },
    {
      type: 'customerContactUsSent',
      status: false,
      translation: 'customerContactUsSent',
    },
    {
      type: 'registrationFixerModal',
      status: false,
      translation: 'registrationFixerModal',
      title: 'Sign up',
    },
    {
      type: 'registrationFixerSent',
      status: false,
      translation: 'registrationFixerSent',
    },
    {
      type: 'downloadAppModal',
      status: false,
      translation: 'downloadAppModal',
    },
    {
      type: 'logInModal',
      status: false,
      translation: 'logInModal',
    },
    {
      type: 'reactivationModal',
      status: false,
      translation: 'reactivationModal',
    },
    {
      type: 'registrationCustomerModal',
      status: false,
      translation: 'registrationCustomerModal',
    },
    {
      type: 'selectUserTypeModal',
      status: false,
      translation: 'selectUserTypeModal',
    },
    {
      type: 'missingAccountModal',
      status: false,
      translation: 'missingAccountModal',
    },
    {
      type: 'changePasswordModal',
      status: false,
      translation: 'changePasswordModal',
    },
  ],
}

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setIsOpen(state, action) {
      state.modals.map((modal, index) => {
        /* To check if there is more then one modal status true */
        if (modal.status === true) {
          state.isModal = false
          state.modals[index].status = false
        }
        /* Set modal to true, recive type from payload */
        if (modal.type === action.payload) {
          state.isModal = true
          state.currentModal = modal.type
          return { ...modal, status: true }
        }
        return ''
      })
    },
    setIsClosed(state, action) {
      state.modals.map((modal) => {
        if (modal.type === action.payload) {
          state.isModal = false
          state.currentModal = ''
          return { ...modal, status: false }
        }
        return ''
      })
    },
  },
})

export const { setIsOpen, setIsClosed } = modalSlice.actions
export default modalSlice.reducer
