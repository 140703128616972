export function getRegistrationProgress(initialValues: any, values: any[]) {
  const maxValue = initialValues.length
  const numberOfOptionalFields = 1
  const value = values.reduce((acc, curr) => {
    if (curr.length > 0 && !!curr) {
      acc += 1
    }
    return acc
  }, 0)

  const val = Math.round((value / (maxValue - numberOfOptionalFields)) * 100);
  return val
}