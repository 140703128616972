interface InputProps {
  id: string;
  onChange: (e: any) => void;
  placeholder: string;
  className: string;
  background: 'white' | 'grey'| 'black';
  textColor: 'black' | 'grey';
}

const TextInput = (props: InputProps): JSX.Element => {
  let background: string;
  let textColor: string;

  switch (props.background) {
    case 'white':
      background = '#FFFFFF';
      break;
    case 'grey':
      background = '#f5f5f5';
      break;
  }
  switch (props.textColor) {
    case 'black':
      textColor = '#000000';
      break;
    case 'grey':
      textColor = '#7b7b7b';
      break;
  }
  const StyleObject: Record<string, boolean | string | undefined> = {
    height: '50px',
    border: 'none',
    borderRadius: '6px',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    background: props.background,
    color: props.textColor
  };
  return (
    <input
      className={props.className}
      id={props.id}
      type="text"
      onChange={props.onChange}
      placeholder={props.placeholder}
      style={StyleObject}
    />
  );
};

export default TextInput;
