import { AnyAction } from '@reduxjs/toolkit'
import { DocumentData } from 'firebase/firestore'
import { E164Number } from 'libphonenumber-js/types'
import { Dispatch } from 'react'

type StateHandle = (value: any, actionType?: string) => AnyAction

export default class DetailsHandler {
  static handleFirstNameChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ first_name: e.target.value }))
  }
  static handleLastNameChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ last_name: e.target.value }))
  }
  static handleCompanyNameChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ company_name: e.target.value }))
  }
  static handleCompanyRegChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ company_reg_no: e.target.value }))
  }
  static handlePhoneChange(
    e: E164Number | undefined,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    if (e) {
      dispatch(setState({ phone_no: e }))
    }
  }
  static handleEmailChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ email: e.target.value }))
  }
  static handleAccrNoChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ accreditation_no: e.target.value }))
  }
  static handleAccrOrgChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ accreditation_org: e.target.value }))
  }
  static handleCountryChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ country: e.target.value }))
  }
  static handleAddressChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ address: e.target.value }))
  }
  static handleCityChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ city: e.target.value }))
  }
  static handlePostcodeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ postcode: e.target.value }))
  }
  static handleBusinessTypeChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ business_type: e.target.value }))
  }
  static handleEcoFriendlyChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ eco_friendly_vehicle: e.target.checked }))
  }
  static handleCompanyLogoChange(
    e: string,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ company_logo: e }))
  }
  static handleCertificateChange(
    e: string,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    dispatch(setState({ certificate: e }))
  }
  static handleTradesChange(
    id: string,
    setState: StateHandle,
    dispatch: Dispatch<AnyAction>
  ) {
    if (id) {
      dispatch(setState(id))
    }
  }
}
