type stupidTimestamp = {
  _seconds: number
  _nanoseconds: number
  seconds: number
  nanoseconds: number
}

export default (timestamp: Partial<stupidTimestamp>) => {
  if (typeof timestamp !== 'object') {
    return null
  }
  if (
    Object.hasOwn(timestamp, 'seconds') &&
    Object.hasOwn(timestamp, 'nanoseconds') &&
    timestamp.seconds &&
    timestamp.nanoseconds
  ) {
    return new Date(timestamp.seconds * 1000)
  } else if (
    Object.hasOwn(timestamp, '_seconds') &&
    Object.hasOwn(timestamp, '_nanoseconds') &&
    timestamp._seconds &&
    timestamp._nanoseconds
  ) {
    return new Date(
      timestamp._seconds * 1000
    )
  }
}
