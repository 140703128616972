import React from 'react'
import styled from 'styled-components'
import { ModalInput } from './ModalUI'
import passwordEye from '../../assets/global/icons/eye.svg'
import hiddenPass from '../../assets/global/modals/crossed-eye-logo-grey.png'

function PasswordField(props: {
  placeholder?: string
  style?: object
  className?: string
  label?: string
  name?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  error?: string
  touched?: boolean
}) {
  const [type, setType] = React.useState('password')

  function toggleType() {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }
  }

  return (
    <div className={props.className} style={{ position: 'relative' }}>
      <ModalInput
        onBlur={props.onBlur}
        onChange={props.onChange}
        required
        type={type}
        style={{ ...props.style, paddingRight: '50px' }}
        placeholder={props.placeholder}
        name=""
        id=""
      />
      <RevealBtn type="button" onClick={toggleType}>
        <Icon
          src={type === 'password' ? passwordEye : hiddenPass}
          alt="Reveal password"
        />
      </RevealBtn>
    </div>
  )
}

const RevealBtn = styled.button`
  outline: none;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`

const Icon = styled.img`
  width: 32px;
`

export default PasswordField
