import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import Modal from '../Modal'
import { useSelector } from 'react-redux'
import SelectUserTypeDesktop from './SelectUserTypeDesktop'
import SelectUserTypeMobile from './SelectUserTypeMobile'
import { useDispatch } from 'react-redux'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'

function SelectUserType() {
  const isDesktop = useMediaQuery(900)
  const dispatch = useDispatch()
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const [userType, setUserType] = React.useState<string>('customer')
  const proceedToRegistration = () => {
    dispatch(setIsClosed('selectUserTypeModal'))
    if (userType === 'customer') {
      dispatch(setIsOpen('registrationCustomerModal'))
    } else {
      dispatch(setIsOpen('registrationFixerModal'))
    }
  }
  const openLogin = () => {
    dispatch(setIsClosed('selectUserTypeModal'))
    dispatch(setIsOpen('logInModal'))
  }
  const props = {
    setUserType,
    proceedToRegistration,
    userType,
    openLogin,
  }
  return (
    <div style={{ position: 'relative' }}>
      <Modal
        style={{ marginBottom: '0px', paddingBottom: '200px' }}
        option={currentModal === 'selectUserTypeModal' ? true : false}
      >
        {isDesktop ? (
          <SelectUserTypeDesktop {...props} />
        ) : (
          <SelectUserTypeMobile {...props} />
        )}
      </Modal>
    </div>
  )
}

export default SelectUserType
