import { createSlice } from '@reduxjs/toolkit'

/* Each page have an individual boolean state, functionallity to only have one state set to true at any given time */
/* To add page to state, add object with following properties type, status, translation as shown below. */
const initialState = {
  currentNav: '',
  navs: [
    {
      type: 'homePage',
      status: false,
      translation: 'homePage',
    },
    {
      type: 'customerPage',
      status: false,
      translation: 'customerPage',
    },
    {
      type: 'fixerPage',
      status: false,
      translation: 'fixerPage',
    },
  ],
}

const navSlice = createSlice({
  name: 'navs',
  initialState,
  reducers: {
    setCurrentNav(state, action) {
      state.navs.map((page, index) => {
        if (page.status === true) {
          state.navs[index].status = false
        }
        if (page.type === action.payload) {
          state.currentNav = page.type
          return { ...page, status: true }
        }
        return ''
      })
    },
  },
})

export const { setCurrentNav } = navSlice.actions
export default navSlice.reducer
