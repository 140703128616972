import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { setIsOpen } from '../../../store/slice/ModalSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  BannerWrapper,
  BannerContent,
  BannerText,
  BannerButton,
} from './components'

function BecomeFixerBanner() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function openFixerRegistrationModal() {
    navigate('/sign-up', { replace: true })
    dispatch(setIsOpen('registrationFixerModal'))
  }

  return isDesktop ? (
    <BannerWrapper>
      <BannerContent>
        <BannerText>{t('banners.join-fixers')}</BannerText>
        <BannerButton onClick={openFixerRegistrationModal}>
          {t('banners.become-a-fixer')}
        </BannerButton>
      </BannerContent>
    </BannerWrapper>
  ) : null
}

export default BecomeFixerBanner
