import React from 'react'
import styled from 'styled-components'

type Link = {
  name: string
  path: string
}

interface LinkProps {
  link: Link
}

const LinkItem: React.FC<LinkProps> = ({ link }): JSX.Element => {
  return (
    <List>
      <a
        id={link.path !== '' || link.path ? '' : 'notActive'}
        href={
          link.path !== '' || link.path
            ? link.path.includes('Plumber') ||
              link.path.includes('Decorator') ||
              link.path.includes('Carpenter') ||
              link.path.includes('Electrician') ||
              link.path.includes('Gas & Boiler')
              ? '/working-on-it'
              : link.path
            : '#'
        }
      >
        {/* TODO do better above */}
        {link.name}
      </a>
    </List>
  )
}

export default LinkItem

const List = styled.li`
  #notActive {
    display: none;
  }
`
