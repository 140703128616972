import { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setIsOpen, setIsClosed } from '../../../store/slice/ModalSlice'
import { DocumentData } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, updateEmail } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { updateCategories } from '../../../store/slice/UserDataSlice'
import CompanyDetailsDesktop from './CompanyDetailsDesktop'
import { db } from '../../../api/firebaseConfig'
import { doc, setDoc } from 'firebase/firestore'
import CompanyDetailsMobile from './CompanyDetailsMobile'
import DetailsHandler from '../statesHandler'
import { useFetchData } from '../fetchUserData'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'

interface ITradesSelected {
  electrician: boolean
  plumber: boolean
  handyman: boolean
  gasBoiler: boolean
  decorator: boolean
  carpenter: boolean
}

let isCertificateSet = false
function CompanyDetails() {
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const dispatch = useDispatch()
  const auth = getAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (window.localStorage.getItem('userType') !== 'fixer') {
      navigate('/')
    }
  }, [userData])

  const userInfo = useFetchData()

  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 900)
  const [initialCertificate, setInitialCertificate] = useState<string>('')

  const handleSubmit = async () => {
    let updatedData = userData
    if (userData.certificate !== initialCertificate) {
      updatedData = { ...userData }
      await FirebaseAPI.sendSupportEmail({
        completed: false,
        pText: `${userData.email} has updated their certificate!`,
      })
    }
    await setDoc(doc(db, 'tradesman', user?.uid), updatedData)
  }

  function changeSelectedTrades(id: string) {
    DetailsHandler.handleTradesChange(id, updateCategories, dispatch)
  }

  // Set initial certificate
  useEffect(() => {
    if (!isCertificateSet && userData.certificate) {
      setInitialCertificate(userData.certificate)
      isCertificateSet = true
    }
  }, [userData])

  useEffect(() => {
    if (!user || !user.uid) {
      navigate('/')
      dispatch(setIsOpen('logInModal'))
    }
    if (window.localStorage.getItem('userType') !== 'fixer') {
      navigate('/')
    }
  }, [user])

  async function updateUserMail(value: string) {
    if (auth && auth.currentUser && userInfo?.uid) {
      try {
        await updateEmail(auth.currentUser, value)
        await setDoc(doc(db, 'tradesman', userInfo?.uid), {
          ...userData,
          email: value,
        })
        window.location.href = '/email-success'
      } catch (error) {
        console.log(error)
      }
    } else {
      window.location.href = '/'
    }
  }

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return Object.values(userData).length === 0 ? (
    <div>Loading</div>
  ) : isDesktop ? (
    <CompanyDetailsDesktop
      changeSelectedTrades={changeSelectedTrades}
      updateEmail={updateUserMail}
      handleSubmit={handleSubmit}
    />
  ) : (
    <CompanyDetailsMobile
      changeSelectedTrades={changeSelectedTrades}
      updateEmail={updateUserMail}
      handleSubmit={handleSubmit}
    />
  )
}

export default CompanyDetails
