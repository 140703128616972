import styled from 'styled-components'

export const RegisterLink = styled.a`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: white;
  white-space: nowrap;
`

export const WhiteBoldSpan = styled.span`
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: white;
`

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const OptionsContainer = styled.div`
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
`

export const ForgotLink = styled.a`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.1125px;

  color: #f27649;
`

export const MobileElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
`
export const DesktopElement = styled.div`
  width: 100%;
  display: none;
  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: center;
  }
`

export const OrangeText = styled.span`
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #f27649;
`

export const CardDesktop = styled.div`
  max-width: 895px;
  position: relative;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #ffffff;
  padding: 3rem 5rem 2rem 5rem;
  border-radius: 5px;
  .heading-forgotd {
    margin-bottom: 30px;
    h1 {
      color: #3d3d3d;
      font-weight: 300;
      text-align: start;
      font-size: 35px;
      letter-spacing: 1.5px;
    }
  }
  .image-container-forgotd {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .input-container-forgotd {
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    margin-bottom: 20px;
    input {
      width: 100%;
      padding: 10px;
      height: 48px;
      border: none;
      background: #efecef;
      border-radius: 2.5px;
    }
  }
  .btn-container-forgotd {
    margin-bottom: 30px;
    button {
      height: 48px;
    }
  }
  .link-container-forgotd {
    text-align: center;
    a {
      color: #727272;
      letter-spacing: 1px;
    }
  }
`

export const CardMobile = styled.div`
  padding: 15px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: calc(100vh + 92px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: -92px;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 350px) {
    min-height: 1600px;
  }
  .wrap-container-forgotm {
    margin-top: 8rem;
  }
  .logo-container-forgotm {
    display: flex;
    justify-content: center;
    margin-bottom: 105px;
  }
  .heading-container-forgotm {
    margin-bottom: 65px;
    text-align: center;
    p {
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .input-container-forgotm {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    input {
      height: 48px;
      width: 100%;
      border: none;
      border-radius: 2.5px;
      padding: 10px 3.5rem;
    }
    .input-label-forgot {
      position: absolute;
      top: 4px;
      left: 5px;
      border-radius: 2.5px;
      width: 40px;
      height: 40px;
      background: #f7f6f6;
      img {
        margin: 11px;
      }
    }
  }
  .btn-container-forgotm {
    button {
      height: 48px;
    }
  }
  .link-container-forgotm {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    a {
      text-decoration: none;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 300;
      font-size: 13px;
    }
  }
`

export const CloseContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #efecef;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
  }
`
