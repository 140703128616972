import './App.css'
import Layout from './components/layout/Layout'
import Routes from './routes/Routes'
import FirebaseTranslationContextProvider from './context/FirebaseTranslationContext'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFetchData } from './pages/Account/fetchUserData'
import { useFetchAuth } from './hooks/useFetchAuth'

function App() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useFetchAuth()

  useFetchData()

  return (
    <FirebaseTranslationContextProvider>
      <Layout>
        <Routes />
      </Layout>
      {/* <FixerRegistration />
      <ForgotPassword /> */}
    </FirebaseTranslationContextProvider>
  )
}

export default App
