import React from 'react'
import { CloseContainerDesktop } from '../styled-components'
import closeButton from '../../../assets/global/modals/close-button.svg'
import { useDispatch } from 'react-redux'
import { setIsClosed } from '../../../store/slice/ModalSlice'
import { FormState } from 'final-form'
function CloseRegistrationBtn(props: {
  goToStep: (
    form: FormState<Record<string, any>, Partial<Record<string, any>>> | number,
    step?: number | undefined
  ) => void
}) {
  const dispatch = useDispatch()
  const closeModal = () => {
    props.goToStep(1)
    dispatch(setIsClosed('registrationFixerModal'))
  }
  return (
    <CloseContainerDesktop>
      <button onClick={closeModal}>
        <img src={closeButton} alt="close-button" />
      </button>
    </CloseContainerDesktop>
  )
}

export default CloseRegistrationBtn
