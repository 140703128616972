import {
  query,
  startAt,
  doc,
  getDoc,
  orderBy,
  collection,
  limit,
  where,
  DocumentData,
  getDocs,
} from 'firebase/firestore'
import { trimString } from './utils'
import * as DOMPurify from 'dompurify'
import arrow from './arrow.svg'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../api/firebaseConfig'
import {
  HowItWorksBackgroundDesktop,
  HowItWorksBackgroundMobile,
} from '../../components/Background'
import { setIsOpen } from '../../store/slice/ModalSlice'
import {
  BlogWrapper,
  BlogSummary,
  BlogContent,
  BlogTitle,
  ArticleMetaData,
  ArticleMetaDataItem,
  PreviewImg,
  Nav,
  NavTop,
  NavIcon,
  NavText,
  NavTitle,
  NavItem,
} from './blog-expanded-components'
import { IArticle, IBlogIds } from './interfaces'
import usePageParams from '../../utils/usePageParams'
import { useTranslation } from 'react-i18next'
import FirebaseAPI from '../../firebase-api/FirebaseAPI'
import BlogMetaTags from './BlogMetaTags'
import BecomeFixerBanner from '../../components/common/BecomeFixerBanner/BecomeFixerBanner'

function BlogExpanded() {
  const { t } = useTranslation()
  usePageParams('Blog', 'customerPage', '', '', true)
  const { id } = useParams<{ id: string }>()
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)
  const [article, setArticle] = useState<DocumentData>()
  const [articles, setArticles] = useState<DocumentData[]>([])
  const [blogIds, setBlogIds] = useState<IBlogIds>({
    prevId: '',
    nextId: '',
  })
  const [currentIndex, setCurrentIndex] = useState(0)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function openFixerRegistrationModal() {
    navigate('/sign-up', { replace: true })
    dispatch(setIsOpen('registrationFixerModal'))
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  useEffect(() => {
    if (id) {
      FirebaseAPI.getBlogPost(id).then((article) => setArticle(article))
    }
    FirebaseAPI.getBlogPosts().then((articles) => {
      setArticles(articles)
    })
  }, [])

  useEffect(() => {
    const datesOnly = articles.map((article) => article.date)
    const currentIndex = datesOnly.indexOf(article?.date)
    setCurrentIndex(currentIndex)
    if (articles) {
      setBlogIds({
        prevId: articles[currentIndex - 1]?.id,
        nextId: articles[currentIndex + 1]?.id,
      })
    }
  }, [articles])

  return (
    <>
      <BlogMetaTags
        title={article?.title}
        description={article?.summary}
        imgURL={article?.articlePreviewImage}
      />
      {isDesktop ? (
        <HowItWorksBackgroundDesktop />
      ) : (
        <HowItWorksBackgroundMobile />
      )}
      <BlogWrapper>
        <BlogTitle>{article?.title}</BlogTitle>
        <ArticleMetaData>
          <ArticleMetaDataItem>{article?.articleType}</ArticleMetaDataItem>{' '}
          {'|'}
          <ArticleMetaDataItem>
            {article?.date &&
              new Date(article.date).toLocaleDateString('en-GB')}
          </ArticleMetaDataItem>
        </ArticleMetaData>
        <BlogSummary>{article?.summary}</BlogSummary>
        {article?.content ? (
          <BlogContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(article.content) || '',
            }}
          />
        ) : (
          ''
        )}
        <Nav>
          <NavItem
            className={blogIds.prevId === undefined ? 'disabled' : ''}
            href={
              blogIds.prevId === undefined ? '#' : '/blog/' + blogIds.prevId
            }
          >
            <NavTop className="left">
              <NavIcon className="rotated" src={arrow} />
              <NavText>{t('button.previous')}</NavText>
            </NavTop>
            <NavTitle>
              {trimString(articles[currentIndex - 1]?.title, 20) || 'Previous'}
            </NavTitle>
          </NavItem>
          <NavItem
            className={
              blogIds.nextId === id || blogIds.nextId === undefined
                ? 'disabled'
                : ''
            }
            href={
              blogIds.nextId === undefined ? '#' : '/blog/' + blogIds.nextId
            }
          >
            <NavTop>
              <NavIcon src={arrow} />
              <NavText>{t('button.next')}</NavText>
            </NavTop>
            <NavTitle>
              {trimString(articles[currentIndex + 1]?.title, 20) || 'Next'}
            </NavTitle>
          </NavItem>
        </Nav>
      </BlogWrapper>
      <BecomeFixerBanner />
    </>
  )
}

export default BlogExpanded
