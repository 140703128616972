import { userType } from "../components/utils/interfaces";

const setUserType = (type: userType) => {
  localStorage.setItem('userType', type);
}

export const setCustomerType = () => {
  setUserType('customer');
}

export const setFixerType = () => {
  setUserType('fixer');
}

export default setUserType