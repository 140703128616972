import facebook from '../assets/global/non-bokafix-logos/facebook.png'
import instagram from '../assets/global/non-bokafix-logos/instagram.png'
import twitter from '../assets/global/non-bokafix-logos/twitter.png'
import linkedin from '../assets/global/non-bokafix-logos/linkedin.png'
import appstore from '../assets/global/non-bokafix-logos/appstore.png'
import googleplay from '../assets/global/non-bokafix-logos/googleplay.png'

export const IconLinkList = [
  {
    icon: facebook,
    iconAlt: 'facebook icon',
    path: 'https://www.facebook.com/bokafixab',
  },
  {
    icon: twitter,
    iconAlt: 'twitter icon',
    path: 'https://twitter.com/bokafix',
  },
  {
    icon: instagram,
    iconAlt: 'instagram icon',
    path: 'https://www.instagram.com/bokafixofficial',
  },
  {
    icon: linkedin,
    iconAlt: 'linkedin icon',
    path: 'https://www.linkedin.com/company/76895360',
  },
  {
    icon: appstore,
    iconAlt: 'appstore icon',
    path: 'https://apps.apple.com/se/app/bokafix/id1606480773',
    space: true,
  },
  {
    icon: googleplay,
    iconAlt: 'googleplay icon',
    path: 'https://play.google.com/store/apps/details?id=com.bokafix.customer&pli=1',
  },
]
