import React, { useLayoutEffect } from 'react'
import { TextInput, Button } from '../../component-library/index'
import GreenLeaf from '../../../assets/global/green-leaf.svg'
import PhoneInput from 'react-phone-number-input'
import { IFileInputs, IRegistrationFixerProps } from '../../utils/interfaces'
import { useTranslation } from 'react-i18next'
import { MobileModalContainer } from '../styled-components'
import PasswordField from '../../ui/PasswordField'
import {
  organizationListEN,
  organizationListSE,
} from '../../../constants/organizationsList'
import { CircularProgress } from '@mui/material'
import createOptionsFromList from '../../utils/createOptionsFromList'
import TradesSelector from './TradesSelector'
import { Field } from 'react-final-form'
import validationRules from '../../utils/validation-rules'
import Options from '../../common/Options'
import styles from './FixerRegistration.module.css'
import { SignUpBackgroundMobile } from '../../Background'
import InsuranceWindow from './InsuranceWindow/InsuranceWindow'
import FileUpload from '../../../pages/Account/FileUpload'
import ConfirmedSignup from './ConfirmedSignup/ConfirmedSignup'
import useMinHeightAdjustment from '../../../hooks/useMinHeightAdjustment'

function FixerRegistrationMobile({
  step,
  registerUser,
  rolesSelected,
  fileInputs,
  setFileInputs,
  miscFlags,
  formState,
  registerInitially,
  formHasErrors,
  goToStep,
}: IRegistrationFixerProps) {
  const { t } = useTranslation()

  const accreditingOrganisation =
    formState.values.country === 'Sweden'
      ? createOptionsFromList(
          organizationListSE,
          t('form.accreditation-organisation')
        )
      : createOptionsFromList(
          organizationListEN,
          t('form.accreditation-organisation')
        )

  const countriesList = [
    { label: t('registration.select-country'), value: '', disabled: true },
    { value: 'Sweden', label: t('country.sweden') },
    { value: 'United Kingdom', label: t('country.united-kingdom') },
    { label: 'Ireland', value: t('country.ireland') },
  ]

  const companiesList = [
    { label: t('form.company.type'), value: '', disabled: true },
    {
      label: t('form.company.sole-trader'),
      value: t('form.company.sole-trader'),
    },
    {
      label: t('form.company.partnership'),
      value: t('form.company.partnership'),
    },
    { label: t('form.company.limited'), value: t('form.company.limited') },
  ]

  const ref = useMinHeightAdjustment()

  return (
    <>
      <MobileModalContainer
        ref={ref}
        style={step === 3 ? { justifyContent: 'center' } : {}}
      >
        {step === 1 && (
          <div
            className="row"
            style={{
              alignItems: 'flex-start',
            }}
          >
            <div className="column1">
              <div
                className="row"
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <div className="column1">
                  <h3 className={styles.fieldTitle}>
                    {t('registration.select-trade')}
                  </h3>
                  <TradesSelector />
                  <div>
                    <h4
                      style={{ marginTop: '5px' }}
                      className={styles.fieldTitle}
                    >
                      {t('registration.select-country')}
                    </h4>
                    <Field
                      validate={validationRules.required.bind('Select country')}
                      name="country"
                      className="select-country"
                      options={countriesList}
                    >
                      {({ input, options }) => {
                        return (
                          <div className="select-container" id="country-field">
                            <select
                              className="select-country"
                              name="country"
                              defaultValue={''}
                              onChange={(value) => input.onChange(value)}
                            >
                              <Options options={options} />
                            </select>
                          </div>
                        )
                      }}
                    </Field>
                    <h4
                      className={`${styles.fieldTitle} ${styles.contactInfoTitle}`}
                    >
                      {t('registration.contact-information')}
                    </h4>
                    <Field
                      validate={validationRules.required.bind(
                        'First name is required'
                      )}
                      name="first_name"
                    >
                      {({ input }) => {
                        return (
                          <TextInput
                            id="first_name"
                            {...input}
                            placeholder={t('form.first-name')}
                            className={'inputs step1inputs'}
                            background={'grey'}
                            textColor={'black'}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      validate={validationRules.required.bind(
                        'Last name is required'
                      )}
                      name="last_name"
                    >
                      {({ input }) => (
                        <TextInput
                          id="last_name"
                          {...input}
                          placeholder={t('form.last-name')}
                          className={'inputs step1inputs'}
                          background={'black'}
                          textColor={'black'}
                        />
                      )}
                    </Field>
                    <Field
                      validate={validationRules.requiredEmail}
                      name="email"
                    >
                      {({ input }) => (
                        <TextInput
                          id="email"
                          {...input}
                          placeholder={t('form.email')}
                          className={'inputs step1inputs'}
                          background={'black'}
                          textColor={'black'}
                        />
                      )}
                    </Field>
                    <Field
                      validate={validationRules.requiredPassword}
                      name="password"
                    >
                      {({ input }) => (
                        <PasswordField
                          {...input}
                          className={'inputs step1inputs inputs-pass'}
                          style={{ width: '100%' }}
                          placeholder={t('form.password')}
                        />
                      )}
                    </Field>
                    {miscFlags.isLoaderDisplayed && (
                      <div className={styles.loading}>
                        <CircularProgress color="primary" />
                      </div>
                    )}
                    <div className={styles.continueButtonWrapper}>
                      <Button
                        fullSize={true}
                        name={t('button.continue')}
                        handleClick={() => registerInitially(formState)}
                      />
                    </div>
                  </div>
                </div>
                <div className="column2">
                  <h4 style={{ color: 'red' }}>
                    {miscFlags.issueDisplayed && miscFlags.issueDetected}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 2 && <ConfirmedSignup goToStep={goToStep} />}
        {step > 2 && (
          <div
            className={`row ${step === 4 ? styles.hidden : ''}`}
            style={{
              alignItems: 'flex-start',
            }}
          >
            <div className="column1">
              <div
                className="row"
                style={{
                  alignItems: 'flex-start',
                }}
              >
                <div className="column1">
                  <p>{t('registration.select-trade')}</p>
                  <TradesSelector />
                  <div
                    className="first-column-inputs"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div className="two-columns-field"></div>
                    <Field
                      validate={validationRules.required.bind(
                        'Select company type'
                      )}
                      name="company_type"
                      className="select-country"
                      options={companiesList}
                    >
                      {({ input, options }) => {
                        return (
                          <div className="select-container">
                            <select
                              id="business"
                              className="select-business"
                              name="company_type"
                              defaultValue={''}
                              onChange={(value) => input.onChange(value)}
                            >
                              <Options options={options} />
                            </select>
                          </div>
                        )
                      }}
                    </Field>
                    <Field
                      name="company_name"
                      validate={validationRules.required.bind(
                        'Company name is required'
                      )}
                    >
                      {({ input }) => {
                        return (
                          <TextInput
                            {...input}
                            id="company_name"
                            placeholder={t('form.company.name')}
                            className="aside-select-field"
                            background={'black'}
                            textColor={'black'}
                            onChange={(value) => input.onChange(value)}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name="company_reg_no"
                      validate={
                        formState.values.company_type !== 'Sole trader'
                          ? validationRules.required.bind(
                              'Registration number is required'
                            )
                          : undefined
                      }
                    >
                      {({ input }) => {
                        return (
                          <TextInput
                            {...input}
                            id="company_reg_no"
                            placeholder={t('form.company.registration')}
                            className={'inputs'}
                            background={'black'}
                            textColor={'black'}
                            onChange={(value) => input.onChange(value)}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </div>
                <div className="column2">
                  <div
                    className="column2-container"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div id="column2-spacer"></div>

                    <Field
                      name="address"
                      validate={validationRules.required.bind(
                        'Address is required'
                      )}
                    >
                      {({ input }) => {
                        return (
                          <TextInput
                            id="address"
                            {...input}
                            placeholder={t('form.address')}
                            className={'inputs'}
                            background={'black'}
                            textColor={'black'}
                            onChange={(value) => input.onChange(value)}
                          />
                        )
                      }}
                    </Field>
                    <Field
                      name="postcode"
                      validate={validationRules.required.bind(
                        'Postcode is required'
                      )}
                    >
                      {({ input }) => {
                        return (
                          <TextInput
                            id="postcode"
                            {...input}
                            placeholder={t('form.postcode')}
                            className={'aside-select-field'}
                            background={'black'}
                            textColor={'black'}
                            onChange={(value) => input.onChange(value)}
                          />
                        )
                      }}
                    </Field>
                    <div
                      style={{ paddingBottom: '15px' }}
                      id="phone-input-container"
                    >
                      <Field
                        validate={validationRules.phoneRequired.bind(
                          'Phone number is required'
                        )}
                        name="phone_no"
                      >
                        {({ input }) => {
                          return (
                            <PhoneInput
                              placeholder={t('form.phone')}
                              {...input}
                              onChange={(value) => input.onChange(value)}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    {((formState.values.country !== 'Sweden' &&
                      rolesSelected(
                        formState.values.category_id,
                        '1DZKa0LuOuzD7Xh4UTD8',
                        'bQTIPjF7YRvrA9cll5WI',
                        'kH4yOjzRbgrZ4a0nazdi'
                      )) ||
                      (step === 5 &&
                        formState.values.country === 'Sweden')) && (
                      <Field
                        required={true}
                        name="accreditation_no"
                        validate={validationRules.required.bind(
                          'Accreditation number is required'
                        )}
                      >
                        {({ input }) => {
                          return (
                            <TextInput
                              id="accreditation_no"
                              {...input}
                              placeholder={
                                formState.values.country === 'Sweden'
                                  ? t('form.personnumber')
                                  : t('form.accreditation-number')
                              }
                              className={`aside-select-field ${styles.input}`}
                              background={'black'}
                              textColor={'black'}
                              onChange={(value) => input.onChange(value)}
                            />
                          )
                        }}
                      </Field>
                    )}
                    {rolesSelected(
                      formState.values.category_id,
                      '1DZKa0LuOuzD7Xh4UTD8',
                      'bQTIPjF7YRvrA9cll5WI',
                      'kH4yOjzRbgrZ4a0nazdi'
                    ) &&
                      (step === 5 || formState.values.country !== 'Sweden') && (
                        <div className={`select-container ${styles.input}`}>
                          <Field
                            validate={validationRules.required.bind(
                              'Select organisation'
                            )}
                            name="accreditation_org"
                            className={`select-country ${styles.input}`}
                            initialValue=""
                            options={accreditingOrganisation}
                          >
                            {({ input, meta, options }) => {
                              return (
                                <select
                                  id="accreditation_org"
                                  className="select-accrediting-organisation"
                                  defaultValue={''}
                                  onChange={(value) => input.onChange(value)}
                                >
                                  <Options options={options} />
                                </select>
                              )
                            }}
                          </Field>
                        </div>
                      )}

                    <div className="margin">
                      <FileUpload
                        handleState={(url: string) => {
                          setFileInputs((prev: IFileInputs) => ({
                            ...prev,
                            companyLogoURL: url,
                          }))
                        }}
                        imageUrl={fileInputs.companyLogoURL}
                        folderName="company-logo"
                        fileName={t('form.company.logo')}
                        id="company-logo-details-1"
                      />
                    </div>
                    {step === 5 && (
                      <div className="margin">
                        <FileUpload
                          handleState={(url: string) => {
                            setFileInputs((prev: IFileInputs) => ({
                              ...prev,
                              insuranceCertificateURL: url,
                            }))
                          }}
                          imageUrl={fileInputs.insuranceCertificateURL}
                          folderName="certificate"
                          fileName={t('form.certificate')}
                          id="certificate-details-1"
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{ display: 'flex' }}
                    className="checkbox-container"
                  >
                    <>
                      <label className="eco-friendly">
                        <Field
                          id="eco_friendly_vehicle"
                          type="checkbox"
                          component="input"
                          name="eco_friendly_vehicle"
                          initialValue={false}
                        />
                        <p>{t('registration.eco-friendly')}</p>
                        <img src={GreenLeaf} alt="green leaf" />
                      </label>
                      {/* This is just a placeholder for now. Natascha said that it should not affect anything at the moment */}
                      <label className={`${styles.checkbox}`}>
                        <input type="checkbox" name="issurance" />
                        <p>{t('registration.have-insurance')}</p>
                      </label>
                      <label>
                        <Field
                          id="is_team_lead"
                          type="checkbox"
                          component="input"
                          name="is_team_lead"
                          initialValue={false}
                        />
                        <p>{t('registration.team-lead')}</p>
                      </label>
                      <label className="terms">
                        <Field
                          id="terms_and_conditions"
                          type="checkbox"
                          component="input"
                          name="terms_and_conditions"
                          initialValue={false}
                          validate={validationRules.requiredToBeChecked.bind(
                            'Please, accept the terms and conditions to continue'
                          )}
                        />
                        <p>
                          {t('terms-and-conditions.part-1-user') + ' '}
                          <a
                            href="/fixer-terms-conditions"
                            target="_blank"
                            title="terms and conditions Fixer"
                          >
                            {t('terms-and-conditions.part-2-user')}
                          </a>
                          {' ' + t('terms-and-conditions.part-3') + ' '}
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            title="privacy policy"
                          >
                            {t('terms-and-conditions.part-4')}
                          </a>
                        </p>
                      </label>
                    </>
                    <div className="spacer"></div>
                    <>
                      {step === 3 && (
                        <div className={styles.continueBtnWrapper}>
                          <Button
                            color="green"
                            fullSize={true}
                            name={t('button.continue')}
                            handleClick={() => {
                              registerUser(formState)
                              goToStep(formState)
                            }}
                          />
                        </div>
                      )}
                      {step === 5 && (
                        <Button
                          fullSize={true}
                          name={formHasErrors(formState) ? 'Verify' : 'Save'}
                          handleClick={() => {
                            registerUser(formState)
                          }}
                        />
                      )}
                      {miscFlags.isLoaderDisplayed && (
                        <div className={styles.loading}>
                          <CircularProgress color="primary" />
                        </div>
                      )}
                    </>
                    <h4 style={{ marginTop: '10px', color: 'red' }}>
                      {miscFlags.issueDisplayed && miscFlags.issueDetected}
                      <span>&nbsp;&nbsp;</span>
                    </h4>
                    <div className="spacer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 4 && <InsuranceWindow continue={() => goToStep(5)} />}
      </MobileModalContainer>
      {step !== 3 && <SignUpBackgroundMobile />}
    </>
  )
}

export default FixerRegistrationMobile
