import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import FixerInfoNav from '../components/FixerInfoNav'
import MainButton from '../components/common/MainButton'
import CustomerContactUsSent from '../components/modals/CustomerContactUsSent'
import { AboutBackgroundDesktop } from '../components/Background'
import { FixerInfoMobile } from '../components/Background'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../store/slice/ModalSlice'
import axios from 'axios'

const FixerInfoContact: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  usePageParams('Fixer information', 'customerPage', 'Fixer information')
  const [formInfo, setFormInfo] = useState({
    subject: '',
    name: '',
    email: '',
    message: '',
  })
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)
  const [messageSent, setMessageSent] = useState(false)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function onChange(e: any) {
    setFormInfo((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }))
    return
  }

  const onSubmit = () => {
    const meetsRequirements: Array<string> = []
    const requiredFields = [
      {
        error: 'subject-error',
        id: 'subject',
        input: formInfo.subject,
      },
      {
        error: 'customer-error',
        id: 'name',
        input: formInfo.name,
      },
      {
        error: 'email-error',
        id: 'email',
        input: formInfo.email,
      },
      {
        error: 'message-error',
        id: 'message',
        input: formInfo.message,
      },
    ]

    requiredFields.filter((each) => {
      if (each.input.length <= 1) {
        meetsRequirements.push(each.error)
      }
      if (each.error === 'email-error' && !each.input.includes('@' && '.')) {
        meetsRequirements.push(each.error)
      }
    })

    if (meetsRequirements.length === 0) {
      setMessageSent((prev) => !prev)
      dispatch(setIsOpen('customerContactUsSent'))
      axios
        .post(
          'https://us-central1-bokafix-4371c.cloudfunctions.net/sendUserMessage',
          {
            to: 'support@bokafix.com',
            subject: formInfo.subject,
            name: formInfo.name,
            email: formInfo.email,
            message: formInfo.message,
          }
        )
        .then(function (response) {
          // console.log(response)
        })
        .catch(function (error) {
          // console.log(error)
        })
    } else {
      for (let each of requiredFields) {
        if (each.input.length <= 1) {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'block'
        } else if (
          each.error === 'email-error' &&
          !each.input.includes('@' && '.')
        ) {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'block'
        } else {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'none'
        }
      }
    }
  }
  const dropdownOptions = Array(4)
    .fill(null)
    .map((_, index: number) => {
      return (
        <React.Fragment key={index}>
          <option
            key={index}
            value={t(`fixer-information.contact.subjects.${index}`)}
          >
            {t(`fixer-information.contact.subjects.${index}`)}
          </option>
        </React.Fragment>
      )
    })

  return messageSent ? (
    <CustomerContactUsSent />
  ) : (
    <div>
      {isDesktop ? <AboutBackgroundDesktop /> : <FixerInfoMobile />}
      <FixerInfoNav />
      <Container>
        {isDesktop ? null : <h1>{t('fixer-information.nav.title')}</h1>}
        <h2>{t('fixer-information.contact.title')}</h2>
        <p>{t('fixer-information.contact.subtitle')}</p>
        <section className="form">
          <div className="three-inputs">
            <p id="subject-error" className="error subject-error">
              {t('customer-contact-us.error')}
            </p>
            <div className="select-container">
              <select
                id="subject"
                className="select"
                defaultValue={'default'}
                onChange={onChange}
              >
                <option value="default" disabled>
                  {t('fixer-information.contact.placeholders.subject')}
                </option>
                {dropdownOptions}
              </select>
            </div>
            <p id="customer-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <input
              type="text"
              className="text-input small-input"
              id="name"
              onChange={onChange}
              placeholder={t('fixer-information.contact.placeholders.name')}
            />
            <p id="email-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <input
              type="text"
              className="text-input small-input"
              id="email"
              onChange={onChange}
              placeholder={t('fixer-information.contact.placeholders.email')}
            />
            {isDesktop ? (
              <div className="button-container">
                <MainButton
                  handleClick={onSubmit}
                  isLight={true}
                  isLarge={true}
                  buttonName={t('fixer-information.button')}
                />
              </div>
            ) : null}
          </div>

          <div className="message-container">
            <p id="message-error" className="error message-error">
              {t('customer-contact-us.error')}
            </p>
            <textarea
              className="message"
              id="message"
              onChange={onChange}
              placeholder={t('fixer-information.contact.placeholders.message')}
            />
          </div>
        </section>
        {isDesktop ? null : (
          <MainButton
            handleClick={onSubmit}
            isLight={true}
            isLarge={true}
            buttonName={t('fixer-information.button')}
          />
        )}
      </Container>
      <FixerArmy />
    </div>
  )
}

export default FixerInfoContact

const Container = styled.div`
  width: 100%;
  .error {
    display: none;
    font-size: 12px;
    color: red;
  }

  @media only screen and (min-width: 900px) {
    height: 650px;
    padding: 0 5%;
    margin-top: 50px;
    .form {
      margin-top: 30px;
      height: 270px;
      display: flex;
      justify-content: space-between;
      .three-inputs {
        height: 100%;
        width: 35%;
        display: flex;
        flex-direction: column;
        .select-container {
          background: white;
          padding: 0 10px;
          height: 50px;
          border: none;
          border-radius: 6px;
          margin-bottom: 10px;
        }
        .select {
          width: 100%;
          height: 100%;
          font-family: Avenir-heavy;
          border: none;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
        }
        .small-input {
          background: white;
          padding-left: 10px;
          height: 50px;
          border: none;
          border-radius: 6px;
          font-family: Avenir-heavy;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
      *::placeholder {
        color: black;
        padding-left: 5px;
      }
      *:focus {
        outline: none;
      }

      .message-container {
        width: 60%;
        height: 100%;
        line-height: 20px;

        .message {
          width: 100%;
          height: 250px;
          padding-left: 10px;
          padding-top: 15px;
          border: none;
          border-radius: 6px;
          font-family: Avenir-heavy;
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          .message::placeholder {
            vertical-align: top;
          }
        }
      }
    }

    .button-container {
      margin-top: 30px;
    }
    .error {
      height: 15px;
    }
  }
  @media only screen and (max-width: 900px) {
    height: 700px;
    padding: 15px;
    font-family: poppins;
    font-size: 13px;
    h1 {
      margin-top: 50px;
      font-size: 22px;
      font-weight: 500;
      text-align: right;
    }
    h2 {
      font-size: 14px;
      font-weight: 600;
      margin-top: 40px;
    }
    p {
      font-weight: 500;
    }
    .form {
      margin-top: 20px;
      .select-container,
      input,
      textarea {
        margin: 5px 0;
      }
    }
    .three-inputs {
      display: flex;
      flex-direction: column;
      *::placeholder {
        color: black;
        padding-left: 10px;
      }
      .small-input {
        padding-left: 5px;
        height: 50px;
        border: none;
        border-radius: 6px;
        background: #f5f5f5;

        font-family: Poppins;
        font-size: 12px;
        line-height: 18px;
      }
      .select-container {
        padding-left: 10px;
        padding-right: 10px;
        height: 50px;
        border: none;
        border-radius: 6px;
        background: #f5f5f5;
      }
      .select {
        border: none;
        background: #f5f5f5;
        height: 100%;
        width: 100%;
        font-family: Poppins;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .message-container {
      margin: 5px 0 50px;
      width: 100%;
      height: 100px;
      .message {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        padding-left: 5px;
        padding-top: 10px;
        border: none;
        border-radius: 6px;
        font-family: Poppins;
        font-size: 12px;
        line-height: 18px;
      }
      .message::placeholder {
        color: black;
        padding-left: 10px;
      }
    }
    .error {
      height: 12px;
    }
  }
`
