import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import FixerInfoNav from '../components/FixerInfoNav'
import Mya from '../assets/global/blobs/mya-happy-money.png'
import Fred from '../assets/global/blobs/fred-happy-money.png'
import { AboutBackgroundDesktop } from '../components/Background'
import { FixerInfoMobile } from '../components/Background'

const FixerInfoEarnings: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('Fixer information', 'customerPage', 'Fixer information')

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <div>
      {isDesktop ? <AboutBackgroundDesktop /> : <FixerInfoMobile />}
      <FixerInfoNav />
      <Container>
        <section className="text">
          <h2>{t('fixer-information.earnings.subtitle')}</h2>
          <p>{t('fixer-information.earnings.article-1')}</p>
          <p>{t('fixer-information.earnings.article-2')}</p>
        </section>
        {isDesktop ? null : <h1>{t('fixer-information.nav.title')}</h1>}
        <section className="blobs">
          <img className="mya" src={Mya} alt="mya" />
          <img className="fred" src={Fred} alt="fred" />
        </section>
      </Container>
      <FixerArmy />
    </div>
  )
}

export default FixerInfoEarnings

const Container = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (min-width: 900px) {
    height: 550px;
    padding: 0 5%;
    font-family: Avenir;

    align-items: center;
    p {
      line-height: 28px;
    }
    h2 {
      font-size: 20px;
    }
    .text {
      width: 50%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      * {
        margin: 15px 0;
      }
    }
    .blobs {
      width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        object-fit: none;
        flex: 0 2 auto;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 15px;
    flex-direction: column-reverse;
    font-family: poppins;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 13px;
    h1 {
      font-size: 22px;
      font-weight: 500;
    }
    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 15px 0;
    }
    p {
      font-weight: 500;
      margin: 20px 0;
    }
    .blobs {
      height: 250px;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      margin-bottom: 20px;
      img {
        width: 40%;
      }
    }
  }
`
