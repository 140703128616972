import { DocumentData } from 'firebase/firestore'

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export interface DetailsProps {
  handleSubmit: () => Promise<void>
  updateEmail(value: string): void
  changeSelectedTrades: (event: string) => void
}

export const mustHaveAccrJobs = [
  'kH4yOjzRbgrZ4a0nazdi', // gasBoiler
  '1DZKa0LuOuzD7Xh4UTD8', // plumber
  'bQTIPjF7YRvrA9cll5WI', // electrician
]
