import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import FixerInfoNav from '../components/FixerInfoNav'
import Mya from '../assets/global/blobs/mya-clipboard.png'
import Paula from '../assets/global/blobs/paula-money.png'
import phones from '../assets/global/fixer-information/two-phones.png'
import phonesTablet from '../assets/global/fixer-information/two-phones-and-tablet.png'
import phone1 from '../assets/global/fixer-information/mobile-phone-1.png'
import phone2 from '../assets/global/fixer-information/mobile-phone-2.png'
import computer from '../assets/global/fixer-information/laptop.png'
import appstore from '../assets/global/non-bokafix-logos/app-store-button2.png'
import gplay from '../assets/global/non-bokafix-logos/google-play-button2.png'

import { AboutBackgroundDesktop } from '../components/Background'
import { FixerInfoMobile } from '../components/Background'

const FixerInfoTakeControl: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('Fixer information', 'customerPage', 'Fixer information')

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      {isDesktop ? <AboutBackgroundDesktop /> : <FixerInfoMobile />}
      <FixerInfoNav />
      <Container>
        <section className="section-0">
          <div className={` text text-0`}>
            <h2>{t(`fixer-information.take-control.subtitles.0`)}</h2>
            <p>{t(`fixer-information.take-control.texts.0`)}</p>
          </div>
          {!isDesktop && <h1>{t('fixer-information.nav.title2')}</h1>}
          <div className="image">
            <img className={`img-0`} src={phones} alt={phones} />
          </div>
        </section>

        <section className="section-1">
          <div className={` text text-1`}>
            <h2>{t(`fixer-information.take-control.subtitles.1`)}</h2>
            <p>{t(`fixer-information.take-control.texts.1`)}</p>
          </div>
          <div className="image">
            <img className={`img-1`} src={Mya} alt={Mya} />
          </div>
        </section>
        {isDesktop && (
          <div className="container-app-link">
            <button className="app-link">
              <a
                href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
                target="_blank"
              >
                <img src={gplay} />
              </a>
            </button>
            <button className={'app-link'}>
              <a
                href="https://apps.apple.com/se/app/bokafix-fixer/id1606489189"
                target="_blank"
              >
                <img src={appstore} />
              </a>
            </button>
          </div>
        )}
        <section className="section-2">
          <div className={` text text-2`}>
            <h2>{t(`fixer-information.take-control.subtitles.2`)}</h2>
            <p>{t(`fixer-information.take-control.texts.2`)}</p>
          </div>
          <div className="image">
            <img className={`img-2`} src={Paula} alt={Paula} />
          </div>
        </section>

        <section className="section-3">
          {isDesktop ? (
            <div className="three-texts">
              <h1>{t('fixer-information.take-control.title')}</h1>
              <div className={` text text-3`}>
                <h2>{t(`fixer-information.take-control.subtitles.3`)}</h2>
                <p>{t(`fixer-information.take-control.texts.3`)}</p>
              </div>
              <div className={` text text-4`}>
                <h2>{t(`fixer-information.take-control.subtitles.4`)}</h2>
                <p>{t(`fixer-information.take-control.texts.4`)}</p>
              </div>
              <div className={` text text-5`}>
                <h2>{t(`fixer-information.take-control.subtitles.5`)}</h2>
                <p>{t(`fixer-information.take-control.texts.5`)}</p>
              </div>
            </div>
          ) : null}
          {isDesktop && (
            <div className="image">
              <img className={`img-3`} src={phonesTablet} alt={phonesTablet} />
            </div>
          )}
        </section>
        {isDesktop && (
          <div className="container-app-link">
            <button className="app-link">
              <a
                href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
                target="_blank"
              >
                <img src={gplay} />
              </a>
            </button>
            <button className={'app-link'}>
              <a
                href="https://apps.apple.com/se/app/bokafix-fixer/id1606489189"
                target="_blank"
              >
                <img src={appstore} />
              </a>
            </button>
          </div>
        )}
        {isDesktop ? null : (
          <section className="section-3">
            <h1>{t('fixer-information.take-control.title')}</h1>
            <div className="image">
              <img className={`mobile-img-0`} src={phone1} alt={phone1} />
            </div>
            <div className={` text text-3`}>
              <h2>{t(`fixer-information.take-control.subtitles.3`)}</h2>
              <p>{t(`fixer-information.take-control.texts.3`)}</p>
            </div>
            <div className="image">
              <img className={`mobile-img-1`} src={phone2} alt={phone2} />
            </div>
            <div className={` text text-4`}>
              <h2>{t(`fixer-information.take-control.subtitles.4`)}</h2>
              <p>{t(`fixer-information.take-control.texts.4`)}</p>
            </div>
            <div className="image">
              <img className={`mobile-img-2`} src={computer} alt={computer} />
            </div>
            <div className={` text text-5`}>
              <h2>{t(`fixer-information.take-control.subtitles.5`)}</h2>
              <p>{t(`fixer-information.take-control.texts.5`)}</p>
            </div>
          </section>
        )}
      </Container>
      <FixerArmy />
    </div>
  )
}

export default FixerInfoTakeControl

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  * {
    white-space: pre-line;
  }
  @media only screen and (min-width: 900px) {
    .container-app-link {
      display: flex;
      column-gap: 50px;
      margin-top: 30px;
    }
    .app-link {
      border: none;
      padding: 0;
      background: none;
    }
    .app-link:hover {
      cursor: pointer;
    }
    padding: 0 5%;
    align-items: center;
    h1 {
      font-weight: 200;
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      line-height: 28px;
    }

    section {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .text {
      width: 60%;
      display: flex;
      flex-direction: column;
      * {
        margin: 10px 0;
      }
    }
    .image {
      width: 40%;
    }

    .section-0 {
      align-items: flex-end;
      margin-top: -50px;
      .text {
        padding-right: 5%;
      }
      img {
        width: 250px;
        margin-left: 10%;
      }
    }
    .section-1 {
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 50px;
      img {
        width: 200px;
        margin-left: 25%;
      }
      .text {
        text-align: right;
      }
    }
    .section-2 {
      align-items: center;
      margin-top: 50px;
      .text {
        padding-right: 5%;
      }
      img {
        width: 180px;
        margin-left: 25%;
      }
    }
    .section-3 {
      flex-direction: row-reverse;
      align-items: center;
      img {
        width: 600px;
        margin-left: -170px;
        margin-top: 200px;
      }

      .three-texts {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        text-align: right;
        * {
          width: 100%;
        }
        h1 {
          width: 100%;
          margin-bottom: 20px;
        }
        h2 {
          margin-top: 20px;
        }
        p {
          padding-left: 20%;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 15px;
    font-family: poppins;
    margin-bottom: 20px;
    font-size: 13px;
    h1 {
      font-size: 22px;
      font-weight: 500;
    }
    h2 {
      font-size: 14px;
      font-weight: 600;
      margin: 15px 0;
    }
    p {
      font-weight: 500;
      margin: 20px 0;
    }
    section {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
    .section-0 {
      margin-top: 30px;
      .image {
        display: flex;
        justify-content: center;
        img {
          width: 50%;
        }
      }
      h1 {
        width: 60%;
      }
    }
    .section-1,
    .section-2 {
      .image {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 40%;
        }
      }
      .text {
        margin-top: 20px;
        text-align: center;
        p {
          padding: 0 10%;
        }
      }
    }

    .section-3 {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 20px;
      h1 {
        margin-top: 50px;
      }
      img {
        margin: 20px 0 10px;
      }
      .mobile-img-2 {
        margin: -20px 0 -30px;
      }
    }
  }
`
