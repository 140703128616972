import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Star from '../Star'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentData } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, updateEmail } from 'firebase/auth'
import userIcon from '../../../assets/global/icons/user-icon.png'

function AccountNav(props = { currentPage: 'string' }) {
  const { t } = useTranslation()
  const [user, setUser] = useState<DocumentData | null>()
  const { userData } = useSelector((state: any) => state.userDataReducer)

  useEffect(() => {
    const auth = getAuth()
    setUser(auth.currentUser)
  }, [])

  function logOut() {
    const auth = getAuth()
    auth.signOut()
    window.location.href = '/'
  }

  const navLinks = [
    {
      name: t('account.nav.my-account'),
      link: '/account',
    },
    {
      name: t('account.nav.my-jobs'),
      link: '/account/my-jobs',
    },
    {
      name: t('account.nav.invoices'),
      link: '/account/invoices',
    },
    {
      name: t('account.nav.messages'),
      link: '/account/messages',
    },
    {
      name: t('account.nav.company-details'),
      link: '/account/company-details',
    },
    {
      name: t('account.nav.settings'),
      link: '/account/settings',
    },
  ]

  return (
    <NavWrapper>
      <img className="user-icon" src={user?.photoURL || userIcon} alt="Name" />
      <h4 className="user-name">
        {userData.first_name + ' ' + userData.last_name}
      </h4>
      <button onClick={logOut} className="nav-logout">
        Log out
      </button>
      <div>
        {[...Array(5)].map((star, index) => {
          return <Star key={index} color={index < 0 ? '#FFCC00' : '#EFECEF'} />
        })}
      </div>
      <span className="nav-reviews">0 {t('account.reviews')}</span>
      <div className="nav-links">
        {navLinks.map((item, index) => {
          return (
            <a
              href={item.link}
              className={`nav-link${
                props.currentPage === item.link ? ' nav-link-active' : ''
              }`}
              key={item.link}
            >
              {item.name}
            </a>
          )
        })}
      </div>
    </NavWrapper>
  )
}

const NavWrapper = styled.div`
  border-radius: 4px;
  align-self: start;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 25px 25px 25px;
  font-family: 'Avenir', Arial, sans-serif;

  .nav-reviews {
    color: #00000080;
    font-weight: 300;
    font-size: 10px;
    margin-bottom: 20px;
  }

  .user-icon {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
  }
  .user-name {
    font-size: 25px;
    color: #3d3d3d;
    font-weight: 400;
  }
  .nav-logout {
    cursor: pointer;
    font-size: 15px;
    color: #00000080;
    background: none;
    outline: none;
    border: none;
    margin-bottom: 10px;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
    color: #3d3d3d;
    padding: 12px;
    font-size: 20px;
    font-weight: 100;
    min-width: 160px;
  }
  .nav-link-active {
    border: 1px solid #f27649;
    border-radius: 4px;
  }
`

export default AccountNav
