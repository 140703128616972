import { AdvancedMatching } from "react-facebook-pixel"
import ReactPixel from 'react-facebook-pixel'

export function initializePixel(config: ReactPixel.Options | undefined) {
  ReactPixel.init(
    process.env.REACT_APP_FACEBOOK_PIXEL as string,
    {} as AdvancedMatching,
    config
  )
  ReactPixel.revokeConsent()
}