import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import man from '../assets/global/icons/user-logo-grey.svg'
import link from '../assets/global/icons/link.svg'
import mail from '../assets/global/icons/mail.svg'
import linkedin from '../assets/global/non-bokafix-logos/linkedin2.svg'
import upload from '../assets/global/icons/upload.svg'
import { TextInput, Button } from '../components/component-library/index'
import closeModalIcon from '../assets/global/icons/close-modal.svg'
import FixerArmy from '../components/layout/FixerArmy'
import {
  FAQBackgroundDesktop,
  FAQBackgroundMobile,
} from '../components/Background'
import usePageParams from '../utils/usePageParams'

interface Props {
  className?: string
}

const CareerDetails: React.FC<Props> = () => {
  const { t } = useTranslation()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const location: any = useLocation()
  const state: Record<string, string> = location.state
  const [formInfo, setFormInfo]: any = useState({
    name: '',
    email: '',
    linkedin: '',
    portfolio: '',
    upload: '',
  })
  const [isFieldInvalid, setIsFieldInvalid] = useState({
    name: false,
    email: false,
    linkedin: false,
    portfolio: false,
    upload: false,
  })

  const [fileUploaded, setFileUploaded] = useState(false)
  const uploadedFile = () => {
    let file = formInfo.upload
    return file.replace(/^.*[\\\/]/, '')
  }
  usePageParams('Careers', 'customerPage', '')

  /* ---------------- ---------------- ---------------- */

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  /* ---------------- ---------------- ---------------- */

  function onChange(e: any) {
    if (e.target.id === 'upload') {
      setFileUploaded(true)
    }
    setFormInfo((prev: any) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }))

    return
  }
  const required = i18next.t('career-details.form.error.required')
  const format = i18next.t('career-details.form.error.format')
  const shortener = i18next.t('career-details.form.error.shortener')

  const fieldValidation = (key: string | undefined) => {
    let errorMessage
    if (isFieldInvalid[key as keyof typeof isFieldInvalid] == false) {
      return null
    } else if (key === 'upload' && isFieldInvalid[key]) {
      if (formInfo[key].length < 6) {
        errorMessage = required
      } else if (!formInfo[key].includes('.pdf' || '.jpeg' || '.jpg')) {
        errorMessage = format
      }
      return <p className="error">{errorMessage}</p>
    } else if (key === 'portfolio' && isFieldInvalid[key]) {
      errorMessage = shortener
      return <p className="error">{errorMessage}</p>
    } else {
      errorMessage = required
      return <p className="error">{errorMessage}</p>
    }
  }

  function applyNow() {
    function isFieldInvalid(key: any) {
      switch (key) {
        case 'name':
          if (!formInfo[key].match('^[A-Za-z]')) {
            return true
          }
          return false

        case 'email':
          if (formInfo[key].length < 6 || !formInfo[key].includes('@')) {
            return true
          }
          return false

        case 'linkedin':
          if (!formInfo[key].includes('www.linkedin.com')) {
            return true
          }
          return false
        case 'portfolio':
          if (formInfo[key].match('^http[s]?://(www.)?(.*)?/?(.)*')) {
            return true
          }
          return false
        case 'upload':
          if (
            formInfo[key].length < 6 ||
            !formInfo[key].includes('.pdf' || '.jpeg' || '.jpg')
          ) {
            return true
          }
          return false

        default:
          return false
      }
    }

    for (const key in formInfo) {
      setIsFieldInvalid((prev: any) => ({
        ...prev,
        [key]: isFieldInvalid(key),
      }))
      fieldValidation(key)
    }
  }

  return (
    <div>
      {isDesktop ? <FAQBackgroundDesktop /> : <FAQBackgroundMobile />}
      <Container>
        <h1 className="title">{state.title}</h1>
        <p className="description">{state.description}</p>

        {/************ THIS IS TO USE A FORM FOR APPLICANTS ****************/}

        {/* <form>
          {fieldValidation('name')}

          <label className="label">
            <div className="icon">
              <img src={man} alt="user" />
            </div>
            <TextInput
              background={isDesktop ? 'white' : 'grey'}
              textColor={isDesktop ? 'black' : 'grey'}
              className="name input"
              id="name"
              onChange={onChange}
              placeholder={i18next.t('career-details.form.name')}
            />
          </label>
          {fieldValidation('email')}

          <label className="label">
            <div className="icon">
              <img src={mail} alt="email" />
            </div>
            <TextInput
              background={isDesktop ? 'white' : 'grey'}
              textColor={isDesktop ? 'black' : 'grey'}
              className="email input"
              id="email"
              onChange={onChange}
              placeholder={i18next.t('career-details.form.desktop-email')}
            />
          </label>
          {fieldValidation('linkedin')}

          <label className="label">
            <div className="icon">
              <img src={linkedin} alt="linkedin logo" />
            </div>
            <TextInput
              background={isDesktop ? 'white' : 'grey'}
              textColor={isDesktop ? 'black' : 'grey'}
              className="linkedin input"
              id="linkedin"
              onChange={onChange}
              placeholder={t('career-details.form.linkedin')}
            />
          </label>

          {fieldValidation('portfolio')}

          <label className="label">
            <div className="icon">
              <img src={link} alt="logo" />
            </div>
            <TextInput
              background={isDesktop ? 'white' : 'grey'}
              textColor={isDesktop ? 'black' : 'grey'}
              className="portfolio input"
              id="portfolio"
              onChange={onChange}
              placeholder={t('career-details.form.portfolio')}
            />
          </label>
          {fieldValidation('upload')}

          <label className="label upload-container">
            <div className="browse last">{t('career-details.form.browse')}</div>
            <input
              className="upload-input"
              id="upload"
              type="file"
              accept=".pdf, .jpeg, jpg"
              onChange={onChange}
            />
            <p className="upload-center">
              {fileUploaded
                ? uploadedFile()
                : isDesktop
                ? `${t('career-details.form.desktop-file')}`
                : `${t('career-details.form.file')}`}
            </p>
            <p className="choose">
              {t('career-details.form.choose-desktop-file')}
            </p>
            <div className="upload last">
              <img src={upload} alt="upload" />
            </div>
          </label>
          <div className="fine-print">
            <p>{t('career-details.form.formats')}</p>
            <p>{t('career-details.form.size')}</p>
          </div>
        </form> */}
        <div className="button-container">
          <a target="_blank" href={state.linkedin}>
            <Button
              fullSize={isDesktop ? false : true}
              name={t('career-details.apply-button')}
              handleClick={applyNow}
            />
          </a>
        </div>
      </Container>
      <FixerArmy />
    </div>
  )
}

export default CareerDetails

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 60vh;
  padding: 35px 20px 43px;
  .title {
    font-size: 20px;
  }
  .description {
    font-size: 13px;
    margin: 28px 10px 30px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
  .label {
    display: flex;
    height: 50px;
    width: 345px;
    margin: 5px auto;
    background: #f5f5f5;
    border-radius: 4px;
  }
  .icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    background: #e8e8e8;
    border-radius: 6px;
  }
  .upload-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .input {
    height: 100%;
    width: 70%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: #7b7b7b;
  }
  .input:focus {
    outline: none;
  }
  .upload-center {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background: #f5f5f5;
    border: none;
    text-align: center;
    color: #737373;
  }
  .browse {
    width: 74px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #a5a5a5;
  }
  .upload {
    width: 50px;
    height: 100%;
  }
  .last {
    height: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-container {
    width: 345px;
    margin-top: 18px;
  }
  .choose {
    display: none;
  }
  .fine-print {
    display: none;
  }
  .error {
    width: 345px;
    text-align: left;
    color: red;
    font-size: 8px;
    margin: 10px auto 0;
  }
  [type='file'] {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
  }
  @media only screen and (min-width: 900px) {
    font-family: Avenir-heavy;
    padding: 100px;
    .title {
      font-size: 63px;
      margin: 50px 0 30px;
    }
    .description {
      font-size: 16px;
      margin: 20px 10% 50px;
    }
    .icon {
      display: none;
    }
    form {
      max-width: 853px;
    }
    .label {
      height: 44px;
      width: 100%;
      background: #ffffff;
      margin: 7px 0;
      border-radius: 4px;
    }
    .input {
      box-shadow: 0 3px 6px #707070;
      padding-left: 15px;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      font-family: Avenir-heavy;
      font-size: 16px;
    }
    .input::placeholder {
      color: #000000;
      height: 100%;
      width: 100%;
    }
    .last {
      display: none;
    }
    .upload-center {
      font-family: Avenir-heavy;
      font-size: 14px;
      width: 100%;
      background-color: #ffffff;
      box-shadow: none;
      cursor: pointer;
    }
    #upload {
      cursor: pointer;
    }
    .upload-container::before {
      content: '';
      height: 100%;
      width: 50px;
      left: 260px;
      position: absolute;
      z-index: 1;
      background: url(${closeModalIcon});
      transform: rotate(45deg);
      background-repeat: no-repeat;
    }
    .upload-container {
      position: relative;
      overflow: hidden;
      height: 44px;
      box-shadow: none;
      padding-right: 60px;
      border: 2px dashed #737373;
      cursor: pointer;
    }
    Button {
      min-width: 296px;
      margin-bottom: 50px;
    }
    .choose {
      cursor: pointer;
      font-size: 16px;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      right: 280px;
      z-index: 2;
      color: #7cb1b2;
      text-decoration: underline;
    }
    .fine-print {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #737373;
    }
    .error {
      width: 100%;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: #000000;
    }
  }
`
