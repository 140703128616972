import { useState, useEffect } from 'react'

export default function useMediaQuery(query: number) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > query)
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > query)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return isDesktop;
}