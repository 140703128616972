import React from 'react'
import './Switch.css';

function Switch(props: {onChange: () => void}) {
  return (
    <label className="switch">
    <input onChange={props.onChange} type="checkbox"/>
    <span className="slider round"></span>
  </label>
  )
}

export default Switch