import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FooterLinkColumn from './FooterLinkColumn'
import { IconLinkList } from '../../constants/LinkList'
import FooterIconLink from './FooterIconLink'
import { FireBaseContext } from '../../context/FirebaseTranslationContext'

const Footerlinks: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { footerLinks } = useContext(FireBaseContext)

  type Link = {
    name: string
    path: string
  }

  type LinkObject = {
    title: string
    links: Link[]
  }

  const LinkColumns: LinkObject[] = [
    {
      title: t('footer-links.navigation.title'),
      links: [
        {
          name: t('footer-links.navigation.how-it-works'),
          path: '/how-it-works',
        },
        {
          name: t('footer-links.navigation.about'),
          path: '/about',
        },
        {
          name: t('footer-links.navigation.contact-us'),
          path: '/contact-us',
        },
        {
          name: t('footer-links.navigation.careers'),
          path: '/careers',
        },
      ],
    },
    {
      title: t('footer-links.services.title'),
      links: footerLinks && [...footerLinks],
    },
    {
      title: t('footer-links.resources.title'),
      links: [
        {
          name: t('footer-links.resources.fixer-toc'),
          path: '/fixer-terms-conditions',
        },
        {
          name: t('footer-links.resources.what-is-rot'),
          path: '/what-is-rot',
        },
        {
          name: t('footer-links.resources.fixer-info'),
          path: '/fixer-information',
        },
        {
          name: t('footer-links.resources.news'),
          path: '/news',
        },
        {
          name: t('footer-links.resources.blog'),
          path: '/blog',
        },
        {
          name: t('footer-links.resources.videos'),
          path: '/video',
        },
        {
          name: t('footer-links.resources.faqs'),
          path: '/faqs',
        },
      ],
    },
  ]

  return (
    <Container>
      {footerLinks &&
        LinkColumns.map((column: LinkObject, index: number) => {
          return <FooterLinkColumn key={index} linkList={column} />
        })}
      <div className="social-media-links">
        <ul>
          {IconLinkList.map(
            (
              icon:
                | {
                    icon: string
                    iconAlt: string
                    path: string
                    space?: undefined
                  }
                | {
                    icon: string
                    iconAlt: string
                    path: string
                    space: boolean
                  },
              index: number
            ) => {
              if (index < 4) {
                return <FooterIconLink key={index} links={icon} />
              } else {
                return null
              }
            }
          )}
        </ul>
      </div>
    </Container>
  )
}

export default Footerlinks

const Container = styled.section`
  margin-top: 0.5rem;
  @media screen and (min-width: 500px) {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  @media screen and (min-width: 750px) {
    margin-top: 0;
  }
  .social-media-links {
    margin-top: 2rem;
    @media screen and (min-width: 499px) {
      display: none;
    }
    ul {
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: space-between;
      li {
        margin: 5px;
        img {
          max-width: 45px;
        }
      }
    }
  }
`
