import { getAuth, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
const useLogOut = (redirect?: boolean) => {
const auth = getAuth()
const navigate = useNavigate()
return () => {
  signOut(auth).then(() => {
    if (redirect) {
      navigate('/')
    }
  })
}
}
export default useLogOut