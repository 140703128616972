import React from 'react'
import styles from './Background.module.css'
import flagBlob from '../../assets/global/blobs/purple-guy-w-flag.png'
import binocularBlob from '../../assets/global/blobs/binocular-blob.png'
import yellowBlob from '../../assets/global/blobs/sanjay-w-note.png'
import phoneBlob from '../../assets/global/blobs/sanja-w-phone.png'

function Background(props: { isCustomer?: boolean }) {
  return (
    <div className={styles['background']}>
      <div className={styles['big-green-circle']} />
      <img
        src={props.isCustomer ? binocularBlob : flagBlob}
        className={styles['flag-blob']}
        alt="Purple cartoon character waving a flag"
      />
      <div className={styles['small-light-circle']} />

      <div className={styles['big-light-circle']} />
      <img
        src={props.isCustomer ? phoneBlob : yellowBlob}
        className={styles['notes-blob']}
        alt="yellow blob with a notepad in his hands"
      />
      <div className={styles['small-green-circle']} />
    </div>
  )
}

export default Background
