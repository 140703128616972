import { IOptions } from "./interfaces"

function createOptionsFromList(
  list: string[],
  placeholder: string
): IOptions[] {
  const options = list.map((element: string) => {
    return { label: element, value: element, disabled: false }
  })
  if (placeholder !== undefined) {
    const placeholderOption: IOptions = {
      label: placeholder,
      value: '',
      disabled: true,
    }
    return [placeholderOption, ...options]
  }
  return options
}

export default createOptionsFromList;