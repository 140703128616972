import React, { useEffect, useState } from 'react'
import { IRequest } from '../../../components/utils/interfaces'
import { SectionHeader } from '../ui'
import { useTranslation } from 'react-i18next'
import styles from './Invoices.module.css'
import InvoicesSelect from './InvoicesSelect'
import InvoicesTable from './InvoicesTable'
import useMediaQuery from '../../../hooks/useMediaQuery'

interface IProps {
  invoices: IRequest[] | null
  fetchPdf: (request: IRequest) => void
  value: string
  setValue: (value: string) => void
}

function InvoicesLayout(props: IProps) {
  const { t } = useTranslation()
  if (!props.invoices) {
    return <div>Loading</div>
  }
  return (
    <div>
      <SectionHeader>{t('account.nav.invoices')}</SectionHeader>
      <div className={styles.wrapper}>
        <InvoicesSelect value={props.value} setValue={props.setValue} />
        <InvoicesTable fetchPdf={props.fetchPdf} invoices={props.invoices} />
      </div>
    </div>
  )
}

export default InvoicesLayout
