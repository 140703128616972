import styled from 'styled-components';

export const BlogWrapper = styled.div`
max-width: 1088px;
margin: 0 auto;
margin-top: 198px;

@media (max-width: 900px) {
  margin-top: 27px;
  padding: 0 20px;
}
`;
export const BlogTitle = styled.h1`
color: black;
text-align: center;
font: normal normal 900 63px/64px Avenir;
margin-bottom: 72px;

@media (max-width: 900px) {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
text-align: center;

color: #000000;
}
`;
export const BlogSummary = styled.h3`
font: normal normal 900 40px/50px Avenir;
color: #3D3D3D;
margin-bottom: 50px;

@media (max-width: 900px) {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 20px;
letter-spacing: -0.13125px;

color: #444444;
}
`;
export const BlogContent = styled.div`

& > figure > img {
  height: unset !important;
  width: unset !important;
}

a {
  color: #737373;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

font: normal normal 100 16px/26px Avenir;
letter-spacing: 0px;
color: #737373;
opacity: 1;

img {
 max-width: 100%;
 object-fit: contain;
}

@media (max-width: 900px) {
  
}
`;

export const ArticleMetaDataItem = styled.span`


@media (max-width: 900px) {
  
}
`;
export const ArticleMetaData = styled.div`
margin-bottom: 18px;
display: flex;
gap: 5px;

@media (max-width: 900px) {
  
}
`;

export const PreviewImg = styled.img`
width: 100%;
aspect-ratio: 3/1;
object-fit: cover;
margin-bottom: 40px;
@media (max-width: 900px) {
margin-bottom: 9px;
}
`;

export const Nav = styled.div`
display: flex;
margin-top: 262px;
margin-bottom: 104px;
justify-content: space-between;
@media (max-width: 900px) {
  margin-top: 28px;
  margin-bottom: 50px;
}
`;

export const NavItem = styled.a`
text-decoration: none;
color: black;
transition: .3s;
&:hover {
  opacity: .7;
  transition: .3s;
}
&.disabled {
  opacity: .5;
  cursor: default;
}
`;

export const NavTop = styled.div`
display: flex;
align-items: center;
gap: 16px;
justify-content: flex-end;
&.left {
  justify-content: flex-start;
}
`;

export const NavIcon = styled.img`
order: 1;
&.rotated {
  order: 0;
  transform: rotate(180deg);
}
`;

export const NavTitle = styled.h4`
margin-top: 17px;
font: normal normal 900 24px/34px Avenir;
letter-spacing: 0px;
color: #3D3D3D;
opacity: 1;

@media (max-width: 900px) {
  margin-top: 8px;
  font-size: 16px;
}
`;

export const NavText = styled.span``;

