import React, { useEffect, useReducer, useState } from 'react'
import styles from './ChangePasswordModal.module.css'
import Modal from '../Modal'
import { useDispatch, useSelector } from 'react-redux'
import MobileModalWrapper from '../MobileModalWrapper/MobileModalWrapper'
import { setIsClosed } from '../../../store/slice/ModalSlice'
import PasswordField from '../../ui/PasswordField'
import { useTranslation } from 'react-i18next'
import { Button } from '../../component-library/index'
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth'
import errorReducer from '../../../reducers/errorReducer'
import { ErrorSpan } from '../../../pages/Account/ui'

function ChangePasswordModal() {
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [state, errorDispatch] = useReducer(errorReducer, { message: '' })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(setIsClosed('changePasswordModal'))
  }

  useEffect(() => {
    if (currentModal === 'changePasswordModal') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [currentModal])

  const submitPassword = async () => {
    try {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        errorDispatch({ type: 'AUTH_ERROR', payload: '' })
        return
      }
      if (newPassword.length < 8) {
        errorDispatch({ type: 'LENGTH_ERROR', payload: '' })
        return
      }
      if (user && user.email) {
        await signInWithEmailAndPassword(auth, user.email, oldPassword)
        await updatePassword(user, newPassword)
        alert('Password updated')
        dispatch(setIsClosed('changePasswordModal'))
      }
    } catch (error: any) {
      console.log(error)
      dispatch({ type: 'CUSTOM_ERROR', payload: error.toString() })
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <Modal option={currentModal === 'changePasswordModal' ? true : false}>
        <MobileModalWrapper
          title={t('settings.change-password')}
          closeModal={closeModal}
        >
          <div className={styles.passwords}>
            <PasswordField
              value={oldPassword}
              className={styles.password}
              placeholder={t('form.password')}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            {state.message && <ErrorSpan>{state.message}</ErrorSpan>}
            <PasswordField
              className={styles.password}
              value={newPassword}
              placeholder={t('form.reset-password')}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              fullSize={true}
              name={t('button.save')}
              handleClick={submitPassword}
            />
          </div>
        </MobileModalWrapper>
      </Modal>
    </div>
  )
}

export default ChangePasswordModal
