import React from 'react'
import styled from 'styled-components'

const dark = '#0f7173'
const light = '#dff2d8'

const makeCircles = (number: number) => {
  let array = []
  for (let i = 1; i <= number; i++) {
    array.push(`circle-${i}`)
  }
  return array.map((className: string, index: number) => (
    <div key={index} className={className}></div>
  ))
}

const standardStyles: Record<string, string> = {
  height: '100%',
  width: '100%',
  overflow: 'clip',
  position: 'absolute',
  zIndex: '-5',
}
const makeSmartCircles = (
  circles: Array<Record<string, string | number | undefined>>
) => {
  return circles.map(
    (circle: Record<string, string | number | undefined>, index: number) => (
      <div
        key={index}
        className={`circle-${index}`}
        style={{
          position: 'absolute',
          borderRadius: '100%',
          zIndex: circle.zIndex,
          left: circle.left,
          right: circle.right,
          top: circle.top,
          bottom: circle.bottom,
          background: circle.background,
          height: `${circle.size}px`,
          width: `${circle.size}px`,
        }}
      ></div>
    )
  )
}

export const HomePageBackground: React.FC = (): JSX.Element => {
  return (
    <HomePageBackgroundContainer>{makeCircles(5)}</HomePageBackgroundContainer>
  )
}
export const HomePageBackgroundContainer = styled.div`
  overflow: clip;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -5;
  * {
    position: absolute;
    border-radius: 100%;
  }
  .circle-1 {
    right: -25px;
    top: 280px;
    background: #0f7173;
    height: 100px;
    width: 100px;
    @media only screen and (min-width: 1200px) {
      left: 70px;
      top: 30px;
      height: 70px;
      width: 70px;
    }
  }
  .circle-2 {
    left: -160px;
    top: 420px;
    background: #0f7173;
    height: 200px;
    width: 200px;
    @media only screen and (min-width: 1200px) {
      left: 450px;
      top: 600px;
      height: 180px;
      width: 180px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .circle-4 {
      right: -800px;
      top: 100px;
      background: #0f7173;
      height: 950px;
      width: 950px;
    }
    .circle-5 {
      right: -300px;
      top: 1300px;
      background: #0f7173;
      height: 550px;
      width: 550px;
    }
  }
`
export const ContactUsBackgroundDesktop: React.FC = (): JSX.Element => {
  const ContactUsBackgroundDesktopCircles = [
    { left: '50px', top: '10px', background: dark, size: 60 },
    {
      top: '230px',
      left: '-100px',
      background: light,
      size: 450,
    },
    { left: '200px', top: '210px', background: dark, size: 150 },
    {
      top: '125px',
      left: '42%',
      background: dark,
      size: 140,
    },
    {
      top: '565px',
      left: '60%',
      background: dark,
      size: 80,
    },
    {
      top: '-50px',
      right: '-650px',
      background: light,
      size: 800,
    },
    {
      top: '530px',
      right: '0px',
      background: dark,
      size: 150,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(ContactUsBackgroundDesktopCircles)}
    </div>
  )
}

export const ContactUsBackgroundMobile: React.FC = (): JSX.Element => {
  const ContactUsBackgroundMobileCircles = [
    {
      right: '-200px',
      top: '30px',
      background: `${dark}`,
      size: 300,
    },
    {
      top: '200px',
      left: '-150px',
      background: `${light}`,
      size: 200,
    },
    {
      left: '-130px',
      top: '540px',
      background: `${dark}`,
      size: 200,
    },
    {
      top: '620px',
      right: '-150px',
      background: `${light}`,
      size: 250,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(ContactUsBackgroundMobileCircles)}
    </div>
  )
}

export const FAQBackgroundDesktop: React.FC = (): JSX.Element => {
  const FAQBackgroundDesktopCircles = [
    {
      left: '-200px',
      top: '50px',
      background: `${light}`,
      size: 700,
    },
    {
      right: '-20px',
      top: '250px',
      background: `${dark}`,
      size: 130,
    },
    {
      right: '-100px',
      top: '375px',
      background: `${light}`,
      size: 400,
    },
    {
      left: '-70px',
      top: '900px',
      background: `${dark}`,
      size: 150,
    },
    {
      left: '20px',
      top: '1000px',
      background: `${light}`,
      size: 400,
    },
    {
      right: '-200px',
      top: '1300px',
      background: `${light}`,
      size: 700,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(FAQBackgroundDesktopCircles)}
    </div>
  )
}

export const AboutBackgroundDesktop: React.FC = (): JSX.Element => {
  const AboutBackgroundDesktopCircles = [
    {
      left: '-300px',
      top: '10px',
      background: `${light}`,
      size: 1000,
    },
    {
      right: '-50px',
      top: '250px',
      background: `${dark}`,
      size: 150,
      zIndex: 1,
    },
    {
      right: '-200px',
      top: '375px',
      background: `${light}`,
      size: 500,
    },
    {
      left: '-70px',
      top: '940px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '-180px',
      top: '1000px',
      background: `${light}`,
      size: 650,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(AboutBackgroundDesktopCircles)}
    </div>
  )
}

export const FAQBackgroundMobile: React.FC = (): JSX.Element => {
  const FAQBackgroundMobileCircles = [
    {
      right: '-250px',
      top: '10px',
      background: `${light}`,
      size: 300,
    },
    {
      left: '-185px',
      top: '200px',
      background: `${dark}`,
      size: 200,
    },
    {
      left: '-125px',
      top: '550px',
      background: `${light}`,
      size: 180,
    },
    {
      right: '-125px',
      top: '560px',
      background: `${dark}`,
      size: 160,
    },
    {
      right: '-100px',
      top: '840px',
      background: `${light}`,
      size: 200,
    },
    {
      left: '-120px',
      top: '1300px',
      background: `${light}`,
      size: 200,
    },
    {
      right: '-60px',
      top: '1400px',
      background: `${dark}`,
      size: 100,
    },
    {
      left: '-80px',
      top: '1650px',
      background: `${dark}`,
      size: 100,
    },
    {
      right: '-50px',
      top: '1960px',
      background: ` ${light}`,
      size: 280,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(FAQBackgroundMobileCircles)}
    </div>
  )
}

export const HowItWorksBackgroundDesktop: React.FC = (): JSX.Element => {
  const HowItWorksBackgroundDesktopCircles = [
    {
      left: '-150px',
      top: '10px',
      background: `${light}`,
      size: 700,
    },
    {
      right: '-20px',
      top: '380px',
      background: `${light}`,
      size: 400,
    },
    {
      right: '-15px',
      top: '270px',
      background: `${dark}`,
      size: 150,
    },
    {
      left: '-80px',
      top: '1000px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '-300px',
      top: '1350px',
      background: `${light}`,
      size: 950,
    },
    {
      left: '-20px',
      top: '1900px',
      background: `${dark}`,
      size: 200,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(HowItWorksBackgroundDesktopCircles)}
    </div>
  )
}

export const HowItWorksBackgroundMobile: React.FC = (): JSX.Element => {
  const HowItWorksBackgroundMobileCircles = [
    {
      right: '-50px',
      top: '10px',
      background: `${dark}`,
      size: 100,
    },
    {
      left: '-420px',
      top: '90px',
      background: `${light}`,
      size: 500,
    },
    {
      zIndex: -6,
      left: '-50px',
      top: '490px',
      background: `${dark}`,
      size: 100,
    },
    {
      right: '-230px',
      top: '350px',
      background: `${light}`,
      size: 300,
    },
    {
      right: '-50px',
      top: '1000px',
      background: `${dark}`,
      size: 100,
    },
    {
      zIndex: -6,
      right: '-450px',
      top: '1000px',
      background: `${light}`,
      size: 600,
    },
    {
      left: '-20px',
      top: '1300px',
      background: `${dark}`,
      size: 100,
    },
    {
      left: '-320px',
      top: '1650px',
      background: `${light}`,
      size: 400,
    },
    {
      right: '-30px',
      top: '1950px',
      background: `${dark}`,
      size: 100,
    },
    {
      right: '-150px',
      top: '2400px',
      background: `${light}`,
      size: 250,
    },
    {
      zIndex: -6,
      right: '-50px',
      top: '2620px',
      background: `${dark}`,
      size: 100,
    },
    {
      right: '-300px',
      top: '2820px',
      background: `${light}`,
      size: 450,
    },
    {
      zIndex: 1,
      left: '-50px',
      top: '3380px',
      background: `${dark}`,
      size: 100,
    },
    {
      left: '-110px',
      top: '3430px',
      background: `${light}`,
      size: 200,
    },
    {
      right: '-50px',
      top: '3750px',
      background: `${dark}`,
      size: 100,
    },
    {
      right: '-150px',
      top: '3820px',
      background: `${light}`,
      size: 300,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(HowItWorksBackgroundMobileCircles)}
    </div>
  )
}

export const SignUpBackgroundMobile: React.FC<{
  styles?: Record<any, any>
}> = ({ styles }: any): JSX.Element => {
  const SignUpBackgroundMobileCircles = [
    {
      right: '-110px',
      top: '80px',
      background: `${dark}`,
      size: 150,
    },
    {
      left: '-110px',
      top: '300px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '-250px',
      top: '400px',
      background: `${light}`,
      size: 300,
    },
    {
      left: '-150px',
      top: '800px',
      background: `${light}`,
      size: 250,
    },
  ]
  return (
    <div style={{ ...standardStyles, ...styles }}>
      {makeSmartCircles(SignUpBackgroundMobileCircles)}
    </div>
  )
}
export const ErrorPageDesktop: React.FC = (): JSX.Element => {
  const ErrorPageDesktopCircles = [
    {
      left: '50px',
      top: '60px',
      background: `${dark}`,
      size: 60,
    },
    {
      left: '-210px',
      top: '400px',
      background: `${light}`,
      size: 450,
    },
    {
      right: '-980px',
      top: '-100px',
      background: `${light}`,
      size: 1100,
    },
    {
      right: '-50px',
      top: '680px',
      background: `${dark}`,
      size: 160,
    },
  ]
  return (
    <div style={standardStyles}>
      {makeSmartCircles(ErrorPageDesktopCircles)}
    </div>
  )
}
export const ErrorPageMobile: React.FC = (): JSX.Element => {
  const ErrorPageMobileCircles = [
    {
      right: '-200px',
      top: '0px',
      background: `${light}`,
      size: 260,
    },
    {
      right: '-120px',
      top: '270px',
      background: `${dark}`,
      size: 150,
    },
    {
      left: '-50px',
      top: '420px',
      background: `${dark}`,
      size: 120,
    },
    {
      left: '-50px',
      top: '470px',
      background: `${light}`,
      size: 180,
    },
  ]
  return (
    <div style={standardStyles}>{makeSmartCircles(ErrorPageMobileCircles)}</div>
  )
}
export const SuccessBackground: React.FC = (): JSX.Element => {
  const SuccessCircles = [
    {
      left: '-90px',
      top: '20px',
      background: `${dark}`,
      size: 130,
    },
    {
      right: '-200px',
      top: '150px',
      background: `${light}`,
      size: 270,
    },
    {
      left: '-105px',
      top: '500px',
      background: `${dark}`,
      size: 120,
    },
    {
      left: '-100px',
      top: '790px',
      background: `${light}`,
      size: 160,
    },
    {
      right: '-110px',
      top: '750px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '-100px',
      top: '1200px',
      background: `${light}`,
      size: 200,
    },
    {
      right: '350px',
      top: '110px',
      background: `${light}`,
      size: 150,
    },
    {
      right: '950px',
      top: '210px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '950px',
      top: '300px',
      background: `${light}`,
      size: 100,
    },
  ]
  return <div style={standardStyles}>{makeSmartCircles(SuccessCircles)}</div>
}

export const FixerInfoMobile: React.FC = (): JSX.Element => {
  const FixerInfoMobileCircles = [
    {
      left: '-90px',
      top: '20px',
      background: `${dark}`,
      size: 130,
    },
    {
      right: '-200px',
      top: '150px',
      background: `${light}`,
      size: 270,
    },
    {
      left: '-105px',
      top: '500px',
      background: `${dark}`,
      size: 120,
    },
    {
      left: '-100px',
      top: '790px',
      background: `${light}`,
      size: 160,
    },
    {
      right: '-110px',
      top: '750px',
      background: `${dark}`,
      size: 150,
    },
    {
      right: '-100px',
      top: '1200px',
      background: `${light}`,
      size: 200,
    },
  ]
  return (
    <div style={standardStyles}>{makeSmartCircles(FixerInfoMobileCircles)}</div>
  )
}
