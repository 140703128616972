import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { setIsOpen } from '../../store/slice/ModalSlice'
import Modal from './Modal'
import posswordblob from '../../assets/global/blobs/forgot-password-fred.png'
import MainButton from '../common/MainButton'
import backgroundpassword from '../../assets/global/modals/auth-mobile-background.png'
import MyLogo from '../../assets/global/modals/bokafix-fixer-logo.svg'
import closeButton from '../../assets/global/modals/close-button.svg'
import inputlogo from '../../assets/global/modals/user-logo-black.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../api/firebaseConfig'
import { setIsClosed } from '../../store/slice/ModalSlice'
import { Navigate, Router, useNavigate } from 'react-router-dom'
import { RootState } from '../../store/rootstate'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { currentModal } = useSelector((state: RootState) => state.modalReducer)
  const [inputValue, setInputValue] = useState<string>('')
  const [isInvalid, setisInvalid] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const navigate = useNavigate()

  const { t } = useTranslation()

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
  }

  function closeModal() {
    dispatch(setIsClosed('forgotPasswordModal'))
  }

  async function resetPassword(): Promise<void> {
    return sendPasswordResetEmail(auth, inputValue)
  }

  async function onSubmit(event: React.SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault()
    if (inputValue === '' || !String(inputValue).includes('@')) {
      //TODO:  Change this by doens't match the email regex
      setErrorMessage(t('errors.invalid-email'))
      return setisInvalid(true)
    }
    await resetPassword().catch((e) => {
      setErrorMessage(t('errors.page-not-found.description'))

      return setisInvalid(true)
    })

    setInputValue('')
    setisInvalid(false)
    navigate('/success-mail-sent', { replace: true })
    closeModal()
  }

  return (
    <Modal option={currentModal === 'forgotPasswordModal' ? true : false}>
      {/* Success Modal */}
      <MobileElement>
        <CardMobile style={{ backgroundImage: `url(${backgroundpassword})` }}>
          <div className="wrap-container-forgotm">
            <div className="logo-container-forgotm">
              <img src={MyLogo} alt="logo" />
            </div>
            <div className="heading-container-forgotm">
              <p>{t('modals.forgot-password.mobile-heading')}</p>
            </div>
            <div className="input-container-forgotm">
              <div className="input-label-forgot">
                <img src={inputlogo} alt="logo" />
              </div>
              <label htmlFor="">
                <input
                  value={inputValue}
                  onChange={onChange}
                  type="email"
                  placeholder={t('form.email')}
                />
                {isInvalid ? <span>{errorMessage}</span> : null}
              </label>
            </div>
            <div className="btn-container-forgotm">
              <MainButton
                handleClick={onSubmit}
                isLarge={true}
                isLight={true}
                buttonName={t('modals.forgot-password.button-text')}
              />
            </div>
            <div className="link-container-forgotm">
              <a onClick={() => dispatch(setIsOpen('logInModal'))}>
                {t('button.login')}
              </a>
            </div>
          </div>
        </CardMobile>
      </MobileElement>
      <DesktopElement>
        <CardDesktop>
          <CloseContainer>
            <button onClick={closeModal}>
              <img src={closeButton} alt="close button icon" />
            </button>
          </CloseContainer>
          <div className="heading-forgotd">
            <h1>{t('modals.forgot-password.desktop-heading')}</h1>
          </div>
          <div className="image-container-forgotd">
            <img src={posswordblob} alt="password blob" />
          </div>
          <div className="input-container-forgotd">
            <label>
              <input
                value={inputValue}
                onChange={onChange}
                type="email"
                id="email-field"
                placeholder={t('form.email')}
              />
              {isInvalid ? <span>{errorMessage}</span> : null}
            </label>
          </div>
          <div className="btn-container-forgotd">
            <MainButton
              handleClick={onSubmit}
              isLarge={true}
              isLight={true}
              buttonName={t('modals.forgot-password.button-text')}
            />
          </div>
          <div className="link-container-forgotd">
            <a href="#">{t('button.login-button')}</a>
          </div>
        </CardDesktop>
      </DesktopElement>
    </Modal>
  )
}

export default ForgotPassword

const MobileElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
`
const DesktopElement = styled.div`
  width: 100%;
  display: none;
  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: center;
  }
`

const CardDesktop = styled.div`
  max-width: 895px;
  position: relative;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #ffffff;
  padding: 3rem 5rem 2rem 5rem;
  border-radius: 5px;
  .heading-forgotd {
    margin-bottom: 30px;
    h1 {
      color: #3d3d3d;
      font-weight: 300;
      text-align: start;
      font-size: 35px;
      letter-spacing: 1.5px;
    }
  }
  .image-container-forgotd {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .input-container-forgotd {
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    margin-bottom: 20px;
    input {
      width: 100%;
      padding: 10px;
      height: 48px;
      border: none;
      background: #efecef;
      border-radius: 2.5px;
    }
  }
  .btn-container-forgotd {
    margin-bottom: 30px;
    button {
      height: 48px;
    }
  }
  .link-container-forgotd {
    text-align: center;
    a {
      color: #727272;
      letter-spacing: 1px;
    }
  }
`

const CardMobile = styled.div`
  padding: 15px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  max-height: calc(100vh + 92px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: -92px;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 350px) {
    min-height: 1600px;
  }
  .wrap-container-forgotm {
    margin-top: 8rem;
  }
  .logo-container-forgotm {
    display: flex;
    justify-content: center;
    margin-bottom: 105px;
  }
  .heading-container-forgotm {
    margin-bottom: 65px;
    text-align: center;
    p {
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .input-container-forgotm {
    position: relative;
    margin-bottom: 30px;
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    input {
      height: 48px;
      width: 100%;
      border: none;
      border-radius: 2.5px;
      padding: 10px 3.5rem;
    }
    .input-label-forgot {
      position: absolute;
      top: 4px;
      left: 5px;
      border-radius: 2.5px;
      width: 40px;
      height: 40px;
      background: #f7f6f6;
      img {
        margin: 11px;
      }
    }
  }
  .btn-container-forgotm {
    button {
      height: 48px;
    }
  }
  .link-container-forgotm {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    a {
      font-size: 'Poppins', sans-serif;
      text-decoration: none;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 300;
      font-size: 13px;
    }
  }
`

const CloseContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #efecef;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
  }
`
