import React, { useState, useEffect } from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import Account from '../CustomerAccount/Account'
import MyBookingsDesktop from './MyBookingsDesktop'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { ICallData, IFixer, IRequest } from '../../../components/utils/interfaces'
import MyBookingsMobile from './MyBookingsMobile'
import { ContactUsBackgroundMobile } from '../../../components/Background'

const pendingStatus = ['pending', 'upcoming']

function MyBookings() {
  const isDesktop = useMediaQuery(900)

  const [filter, setFilter] = useState('all')
  const [bookings, setBookings] = useState<IRequest[]>([])
  const [filteredBookings, setFilteredBookings] = useState<IRequest[]>([])
  const [review, setReview] = useState('')

  useEffect(() => {
    async function fetch() {
      const invoices = (await FirebaseAPI.getInvoices()) as ICallData
      setBookings(invoices.data.data)
    }
    try {
      fetch()
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    if (filter === 'all') {
      setFilteredBookings(bookings)
    } else if (filter === 'upcoming') {
      setFilteredBookings(
        bookings.filter(
          (el) =>
            el.request_status &&
            pendingStatus.includes(el.request_status.toLowerCase())
        )
      )
    } else if (filter === 'completed') {
      setFilteredBookings(
        bookings.filter(
          (el) =>
            el.request_status && el.request_status.toLowerCase() === 'completed'
        )
      )
    }
  }, [filter, bookings])

  const [fixers, setFixers] = useState<IFixer[]>([])
  useEffect(() => {
    bookings.forEach(async (el) => {
      if (!el.tradesman_id) {
        return;
      }
      const fixer = await FirebaseAPI.fetchFixer(el.tradesman_id)
      setFixers((prev) => [...prev, fixer as IFixer])
    })
  }, [bookings])

  if (!isDesktop) {
    return (
      <>
        <ContactUsBackgroundMobile />
        <MyBookingsMobile
          bookings={filteredBookings}
          filter={filter}
          setFilter={setFilter}
          fixers={fixers}
        />
      </>
    )
  }

  return (
    <Account currentPage="my-bookings">
      <MyBookingsDesktop
      fixers={fixers}
        bookings={filteredBookings}
        filter={filter}
        setFilter={setFilter}
      />
    </Account>
  )
}

export default MyBookings
