import React, { useEffect, useState } from 'react'
import { FormType } from '../components/utils/interfaces';

const useFormValidation = (form: FormType) => {
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    const formState = form.getState();
    const { errors } = formState;
    if (!errors) return;
    const errorsList = Object.values(errors);
    const errorItem = errorsList[0]
    setError(errorItem);
  }, [form]);
  return error;
}

export default useFormValidation
