import React, { useEffect } from 'react'
import closeIcon from '../../../assets/global/icons/cancel-button.svg'
import Modal from '../Modal'
import {
  ModalParent,
  ModalWrapper,
  CloseButton,
  ModalWindow,
  ModalTitle,
  ModalBody,
} from '../styled-components'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Button } from '../../ui/styled-components'
import { useDispatch, useSelector } from 'react-redux'
import styles from './MissingAccountModal.module.css'
import errorBlob from '../../../assets/global/blobs/error-blob.svg'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'

function MissingAccountModal() {
  const isDesktop = useMediaQuery(900)
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const { t } = useTranslation()

  useEffect(() => {
    if (currentModal === 'missingAccountModal') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [currentModal])

  const dispatch = useDispatch()

  const [type, setType] = React.useState<string | null>(
    window.localStorage.getItem('userType')
  )
  const navigate = useNavigate()

  useEffect(() => {
    // fallback if for some reason user has no userType in localStorage (shouldn't happen)
    if (type === null) {
      dispatch(setIsClosed('missingAccountModal'))
    }
  }, [type])

  const goToLogin = () => {
    dispatch(setIsClosed('missingAccountModal'))
    dispatch(setIsOpen('logInModal'))
  }

  const goToRegistration = () => {
    dispatch(setIsClosed('missingAccountModal'))
    navigate('/sign-up')
  }

  const closeModal = () => dispatch(setIsClosed('missingAccountModal'))

  const titleText =
    type === 'customer'
      ? t('missing-account.title-customer')
      : t('missing-account.title-fixer')

  const buttonText =
    type === 'customer'
      ? t('missing-account.button-customer')
      : t('missing-account.button-fixer')
  const loginText =
    type === 'customer'
      ? t('missing-account.link-customer')
      : t('missing-account.link-fixer')

  return (
    <ModalParent style={{ position: 'relative' }}>
      <Modal
        className="modal-reactivation"
        option={currentModal === 'missingAccountModal'}
      >
        <ModalWrapper className="wrapper">
          <CloseButton onClick={closeModal} src={closeIcon} />
          <ModalWindow className={styles.body}>
            <img className={styles.blob} src={errorBlob} />
            <ModalTitle className={styles.title}>{titleText}</ModalTitle>
            <ModalBody className={styles.modalBody}>
              <Button
                type="button"
                className={`${styles.button} orange`}
                onClick={goToRegistration}
              >
                {buttonText}
              </Button>
              <button onClick={goToLogin} className={styles.link}>
                {loginText}
              </button>
            </ModalBody>
          </ModalWindow>
        </ModalWrapper>
      </Modal>
    </ModalParent>
  )
}

export default MissingAccountModal
