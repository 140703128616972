import { isValidPhoneNumber } from 'react-phone-number-input'

interface IFields {
    first_name: boolean;
    last_name: boolean;
    email: boolean;
    company_name: boolean;
    orgNumber: boolean;
    personNumber: boolean;
    category_id: boolean;
    phone_no: boolean;
    postcode: boolean;
    address: boolean;
}

class FieldsValidator {
  invalid: object;
  valid: object;
  setValidFields;
 constructor(valid: object, invalid: object, setValidFields: Function) {
  this.invalid = invalid;
  this.valid = valid;
  this.setValidFields = setValidFields;
 }
 
 validateName(name: string) {
  if (name && name.length > 0) {
    this.setValidFields((prev: IFields) => {return{...prev, first_name: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, first_name: false}});
    return this.invalid;
  }
 }
 validateLastName(name: string) {
  if (name && name.length > 0) {
    this.setValidFields((prev: IFields) => {return {...prev, last_name: true}});
    return this.valid;
  } 
  this.setValidFields((prev: IFields) => {return{...prev, last_name: false}});
  return this.invalid;
 }
 validateEmail(email: string) {
  const re = RegExp('^.+@[a-zA-Z]+\.{1}[a-zA-Z]+(\.{0,1}[a-zA-Z]+)$');
  if (re.test(email)) {
    this.setValidFields((prev: IFields) => {return{...prev, email: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, email: false}});
    return this.invalid;
  }
 }
 validateCompanyName(name: string) {
  if (name && name.length > 0) {
    this.setValidFields((prev: IFields) => {return{...prev, company_name: true}});
    return this.valid;
  }  else {
    this.setValidFields((prev: IFields) => {return{...prev, company_name: false}});
    return this.invalid;
  }
 }
 validateOrgNumber(number: string) {
  if (number && number.length > 0 && typeof +number === 'number') {
    this.setValidFields((prev: IFields) => {return{...prev, orgNumber: true}});
    return this.valid;
  } 
  this.setValidFields((prev: IFields) => {return{...prev, orgNumber: false}});
  return this.invalid;
 }
 validatePersonNumber(number: string) {
  if (number && number.length > 0 && typeof +number === 'number') {
    this.setValidFields((prev: IFields) => {return{...prev, accreditation_no: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, accreditation_no: false}});
    return this.invalid;
  }
 }
 validatePostcode(number: string) {
  if (number && number.length > 0 && typeof +number === 'number') {
    this.setValidFields((prev: IFields) => {return{...prev, postcode: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, postcode: false}});
    return this.invalid;
  }
 }
 validatePhone(phone: string) {
  if (phone && isValidPhoneNumber(phone)) {
    this.setValidFields((prev: IFields) => {return{...prev, phone_no: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, phone_no: false}});
  return this.invalid;
  }
 }
 validateTrades(trades: string[]) {
  if (trades && trades.length > 0) {
    this.setValidFields((prev: IFields) => {return{...prev, category_id: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, category_id: false}});
    return this.invalid; 
  }
 }
 validateAddress(address: string) {
  if (address && address.length > 0) {
    this.setValidFields((prev: IFields) => {return{...prev, address: true}});
    return this.valid;
  } else {
    this.setValidFields((prev: IFields) => {return{...prev, address: false}});
    return this.invalid;
  }
 }
}

export default FieldsValidator;