import styled from 'styled-components'
export const DesktopModalContainer = styled.div`
  .inputs-pass button {
    top: 22px;
  }
  .inputs-pass input::placeholder,
  .inputs-pass input {
    font-weight: 300;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgb(123, 123, 123);
  }
  background-color: white;
  position: relative;
  min-height: 800px;
  padding: 0 60px;
  -webkit-box-shadow: 0px 0px 7px 0px #8b8b8b;
  box-shadow: 0px 0px 7px 0px #8b8b8b;
  display: flex;
  .MuiCircularProgress-svg {
    color: orange;
  }
  .margin {
    margin-top: 10px;
    .file-input-container {
      height: 50px;
      .browse {
        height: 46px;
        color: #8b8b8b;
      }
      .input-file-image-container {
        height: 46px;
      }
      .image-source-name {
        text-align: center;
      }
    }
  }
  .spacer {
    height: 15px;
  }
  #phone-input-container {
    input {
      margin-top: 0;
    }
  }
  input {
    margin-top: 8px;
    background-color: #f5f5f5;
    padding-left: 10px;
  }
  #country-field {
    margin-top: 14px;
  }
  .row {
    display: flex;

    justify-content: center;
    width: 100%;
    .select-container {
      margin-top: 8px;
      border-radius: 6px;
      border: none;
      font-family: Avenir-heavy;
      background: #f5f5f5;
      select {
        border: none;
        width: 98%;
        padding: 10px;
        height: 50px;
        background: #f5f5f5;
        border-radius: 6px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .column1 {
      margin-right: 10px;
      h1 {
        font-weight: 100;
        color: #2f2f2f;
        font-size: 36px;
        margin-top: 50px;
      }
      .account-container {
        display: flex;
        align-items: baseline;
        /* margin: 20px 0; */
        h2 {
          font-size: 18px;
          font-family: Avenir-heavy;
          color: #2f2f2f;
          margin-bottom: 20px;
        }
        a {
          margin-left: 21px;
          color: #f27649;
          font-weight: 600;
          text-decoration: none;
        }
      }
      h3 {
        text-align: left;
        font: normal normal 900 16px/22px Avenir;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 10px;
      }

      .checkbox-container {
        gap: 7px;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        label {
          display: flex;
          align-items: flex-start;
          input {
            margin-top: 2px;
            margin-right: 10px;
            border-radius: 20%;
            transform: scale(1.5);
          }
          p {
            font: normal normal 900 14px/19px Avenir;
            letter-spacing: 0px;
            color: #3d3d3d;
            text-decoration: none;
          }

          a {
            color: #3d3d3d;
            text-decoration: underline;
          }
        }
        .eco-friendly {
          display: flex;
          align-items: flex-start;
          img {
            margin-left: 5px;
          }
        }
      }
      .first-column-inputs {
        flex-direction: column;

        .two-columns-field {
          width: 100%;
          display: flex;
          justify-content: space-between;
          input {
            width: 49%;
          }
        }
      }
    }
    .aside-select-field {
      margin-top: 8px;
    }
    .column2 {
      width: 100%;
      max-width: 352px;
      .column2-container {
        display: flex;
        flex-direction: column;
        #column2-spacer {
          margin-top: 170.5px;
        }
      }
    }
  }
  select,
  .PhoneInputInput,
  .image-source-name {
    font-family: poppins;
    font-size: 12px;
    font-weight: 500;
    color: #757575;
  }
  .step1inputs {
    width: 100%;
  }
`
export const MobileModalContainer = styled.div`
  .inputs-pass button {
    top: 24px;
  }
  .inputs-pass input::placeholder,
  .inputs-pass input {
    font-weight: 300;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgb(123, 123, 123);
  }
  .MuiCircularProgress-svg {
    color: orange;
  }
  width: 100vw;
  min-height: 800px;
  padding: 5%;

  color: #2f2f2f;
  display: flex;
  flex-direction: column;
  * {
    font-family: poppins;
    &:focus-visible {
      outline: none;
    }
  }
  p {
    font-weight: 500;
  }
  .select-container {
    margin-top: 10px;
    border-radius: 6px;
    border: none;
    background: #f5f5f5;
    select {
      font-family: poppins;
      font-size: 12px;
      font-weight: 500;
      border: none;
      width: 98%;
      padding: 10px 7px;
      height: 50px;
      background: #f5f5f5;
      border-radius: 6px;
      color: #757575;
      &:focus-visible {
        outline: none;
      }
    }
  }

  .column2-container {
    flex-direction: column;
  }
  .two-columns-field {
    display: flex;
    flex-direction: column;
  }
  .first-column-inputs {
    flex-direction: column;
  }
  input {
    margin-top: 10px;
    background-color: #f5f5f5;
    padding-left: 10px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    justify-content: start;
    label {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      input {
        margin-top: 5px;
        margin-right: 10px;
        border-radius: 20%;
        width: 17px;
        height: 17px;
      }
    }
    .eco-friendly {
      display: flex;
      align-items: flex-start;
      img {
        margin-left: 5px;
      }
    }
    .terms {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      a,
      p {
        margin: 1px;
        color: black;
      }
      a {
        margin: 0 5px;
      }
      .indent {
        margin-left: 30px;
      }
    }
  }
  #phone-input-container {
    input {
      margin-top: 0;
      margin-right: 5px;
      font-family: poppins;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .image-source-name {
    font-family: poppins;
    font-size: 12px;
    font-weight: 500;
    color: #757575;
  }

  .margin {
    margin-top: 10px;
    .file-input-container {
      height: 50px;
      .browse {
        height: 46px;
        color: #8b8b8b;
      }
      .input-file-image-container {
        height: 46px;
      }
    }
  }
  * ::placeholder {
    color: #757575;
  }
  .step1inputs:placeholder {
    color: #757575;
  }
  .step1inputs {
    width: 100%;
  }
`
export const CategoryInput = styled.input``

export const CloseContainerDesktop = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #efecef;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`
export const SelectTradeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  grid-row-gap: 15px;
  grid-column-gap: 15px;

  .category-box {
    padding: 5px;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    input {
      margin-top: 0;
    }
    .checkbox-paragraph {
      margin-left: 5px;
      font-size: 10px;
      font-family: Avenir-light;
      font-weight: 800;
      @media only screen and (min-width: 900px) {
        color: #757575;
        width: 70%;
      }
    }
  }
  .custom-check-container {
    position: relative;
    height: 48px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .custom-check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .custom-check-checkmark {
    background: white;
    height: 40px;
    width: 40px;
    border-radius: 4px;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-check-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .custom-check-container input:checked ~ .custom-check-checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .custom-check-container .custom-check-checkmark:after {
    left: 16px;
    top: 17px;
    width: 5px;
    height: 10px;
    border: solid #757575;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 900px) {
    font-family: Poppins;
    font-weight: 200;
    margin-bottom: 10px;

    .category-box {
      flex-direction: column;
      justify-content: center;
      height: 85px;
    }
    .checkbox-paragraph {
      white-space: nowrap;
      margin-top: 5px;
      color: #3d3d3d;
    }
    .custom-check-container .custom-check-checkmark:after {
      left: 48%;
      @media only screen and (max-width: 600px) {
        left: 46%;
      }
      top: 28%;
    }
  }
`
export const ModalParent = styled.div``
export const ModalBody = styled.div`
  @media (min-width: 900px) {
    display: flex;
    gap: 19px;
  }
  @media (max-width: 900px) {
    padding: 25px 20px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
`
export const ModalTitle = styled.h3`
  @media (min-width: 900px) {
    max-width: 687px;
    text-align: center;
    font: normal normal 100 52px/60px Avenir;
    letter-spacing: 1.56px;
    color: #3d3d3d;
    margin-bottom: 50px;
  }
  @media (max-width: 900px) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 14px 42px;
    background: #34344a;
    border: 1px solid #34344a;
    box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
    backdrop-filter: blur(13.5914px);
    border-radius: 8px;
    color: #ffffff;
  }
`
export const ModalWindow = styled.div`
  @media (min-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    box-shadow: 5px 5px 25px #0000001a;
    border-radius: 4px;
    padding: 160px 140px;
  }

  @media (max-width: 900px) {
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
    backdrop-filter: blur(13.5914px);
  }
`
export const ModalWrapper = styled.div`
  @media (min-width: 900px) {
    height: 100vh;
    top: -80px;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(209, 209, 209, 0.50967);
    backdrop-filter: blur(2.71828px);
  }
`

export const CloseButton = styled.img`
  position: absolute;
  top: 6%;
  right: 15px;
  @media (min-width: 900px) {
    display: none;
  }
`
