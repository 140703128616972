import React, { useEffect } from 'react'
import { Button } from '../../../../pages/Account/ui'
import styles from './ConfirmedSignup.module.css'
import blob from '../../../../assets/global/blobs/mya.png'
import check from '../../../../assets/global/icons/green-circle-check.svg'
import appstore from '../../../../assets/global/non-bokafix-logos/app-store-button.png'
import gplay from '../../../../assets/global/non-bokafix-logos/google-play-button.png'
import ProgressBar from '../../../common/ProgressBar/ProgressBar'
import useMediaQuery from '../../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'

function ConfirmedSignup(props: { goToStep: (step: number) => void }) {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(900)
  useEffect(() => {
    if (!isDesktop) {
      setTimeout(() => {
        props.goToStep(3)
      }, 3000)
    }
  }, [isDesktop])
  return (
    <div className={styles.wrapper}>
      {isDesktop && <img src={blob} alt="happy Mya" className={styles.blob} />}
      <h3 className={styles.title}>{t('registration.account-created')}</h3>
      {!isDesktop && <img className={styles.check} src={check} />}
      <div className={styles.buttons}>
        <ProgressBar className={styles.bar} progress={30} />
        {isDesktop && (
          <Button
            className={`${styles.nextButton} orange`}
            onClick={() => props.goToStep(3)}
          >
            {t('registration.complete-my-profile')}
          </Button>
        )}
      </div>
      <div className={styles.app}>
        <h4 className={styles.appTitle}>{t('registration.download-app')}</h4>
        <div className={styles.appLinks}>
          <button className={styles.appLink}>
            <a
              href="https://apps.apple.com/se/app/bokafix-fixer/id1606489189"
              target="_blank"
            >
              <img src={appstore} />
            </a>
          </button>
          <button className={styles.appLink}>
            <a
              href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
              target="_blank"
            >
              <img src={gplay} />
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmedSignup

//TODO: replace with actual text
