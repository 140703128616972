import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import arrow from '../assets/global/modals/dropdown-arrow.svg'
import { setFixerInfoNav } from '../store/slice/FixerInfoSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/rootstate'

const FixerInfoNav: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { fixerInfoNav } = useSelector(
    (state: RootState) => state.fixerInfoReducer
  )
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const dispatch = useDispatch()

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])
  const navButtons = [
    'requirements',
    'earnings',
    'the-basics',
    'take-control',
    'contact',
  ]
  return isDesktop ? (
    <ContainerDesktop>
      <h1>
        {fixerInfoNav === 'take-control'
          ? t('fixer-information.nav.title2')
          : t('fixer-information.nav.title')}
      </h1>
      <nav>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <Link
              key={index}
              style={
                fixerInfoNav === navButtons[index]
                  ? { background: '#f27649', color: 'white' }
                  : { background: 'white' }
              }
              onClick={() => dispatch(setFixerInfoNav(navButtons[index]))}
              to={`/fixer-information/${navButtons[index]}`}
            >
              {t(`fixer-information.nav.links.${index}`)}
            </Link>
          ))}
      </nav>
    </ContainerDesktop>
  ) : (
    <ContainerMobile>
      {showMobileMenu ? (
        <div className="dropdown-menu">
          <div className="options">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <Link
                  key={index}
                  onClick={() => {
                    dispatch(setFixerInfoNav(navButtons[index]))
                    setShowMobileMenu((prev) => !prev)
                  }}
                  to={`/fixer-information/${navButtons[index]}`}
                >
                  {t(`fixer-information.nav.links.${index}`)}
                </Link>
              ))}
          </div>
          <img src={arrow} />
        </div>
      ) : (
        <div
          onClick={() => setShowMobileMenu((prev) => !prev)}
          className="fixer-info-nav-menu"
        >
          <p> {t(`fixer-information.${fixerInfoNav}.title`)}</p>
          <img src={arrow} />
        </div>
      )}
    </ContainerMobile>
  )
}

export default FixerInfoNav

const ContainerDesktop = styled.div`
  width: 100%;
  padding: 30px 5%;
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 30px;
    width: 40%;
  }
  nav > * {
    text-decoration: none;
    font-size: 14px;
    color: black;
    margin: 10px;
    @media only screen and (max-width: 1050px) {
      margin: 10px 4px;
    }
    padding: 5px 10px;
    border-radius: 4px;
  }
`
const ContainerMobile = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 5px 0;
  .fixer-info-nav-menu {
    cursor: pointer;
    width: 40%;
    display: flex;
    justify-content: flex-end;
    * {
      margin: 5px;
    }
    p {
      text-transform: capitalize;
    }
  }
  .dropdown-menu {
    box-shadow: 10px 5px 5px grey;
    background: white;
    border-radius: 4px;
    width: 100%;
    display: flex;
    img {
      transform: scaleY(-1);
      align-self: flex-start;
      margin: 10px 10px 0 0;
    }
  }
  .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 10px;
    * {
      cursor: pointer;
      margin: 5px 0;
      text-align: right;
      text-decoration: none;
      color: black;
      width: 100%;
    }
  }
`
