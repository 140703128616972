import React, { useState, useEffect, useContext } from 'react'
import Account from './Account'
import InsuranceButton from './InsuranceButton'
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'
import { updateUserData } from '../../../store/slice/UserDataSlice'
import { convertToHex } from '../../../components/utils/convertToHex'
import { FireBaseContext } from '../../../context/FirebaseTranslationContext'
import {
  Button,
  ChangeEmailButton,
  ErrorDissolve,
  SectionHeader,
  UpdateEmailWrapper,
} from '../ui'
import GreenLeaf from '../../../assets/global/green-leaf.svg'
import { ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../../api/firebaseConfig'
import { DocumentData } from 'firebase/firestore'
import { ICategoryType } from '../../../constants/interfaces'
import FieldsValidator from '../fieldsValidator'
import styled from 'styled-components'
import { CountryCode } from 'libphonenumber-js/types'
import SelectCountry from '../../../components/ui/SelectCountry'
import DetailsHandler from '../statesHandler'
import SelectBusiness from '../../../components/ui/SelectBusiness'
import Checkbox from '../../../components/ui/Checkbox'
import { FileUpload } from '../FileUpload'
import {
  organizationListEN,
  organizationListSE,
} from '../../../constants/organizationsList'
import DeleteUserModal from '../DeleteUserModal'
import PasswordField from '../../../components/ui/PasswordField'
import { DetailsProps, mustHaveAccrJobs } from '../detailsUtils'
import dropdownArrow from '../../../assets/global/icons/dropdown-icon.svg'
import { useDispatch, useSelector } from 'react-redux'

const defaultCountry = 'SE'

function CompanyDetailsDesktop(props: DetailsProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const [validFields, setValidFields] = useState({
    first_name: false,
    last_name: false,
    email: false,
    company_name: false,
    orgNumber: true, // it is not required
    postcode: false,
    accreditation_no: true,
    category_id: false,
    phone_no: false,
    address: false,
  })

  useEffect(() => {
    fieldsValidator.validateName(userData.first_name)
    fieldsValidator.validateLastName(userData.last_name)
    fieldsValidator.validateEmail(userData.email)
    fieldsValidator.validateCompanyName(userData.company_name)
    fieldsValidator.validateTrades(userData.category_id)
    fieldsValidator.validatePostcode(userData.postcode)
    fieldsValidator.validateAddress(userData.address)
    fieldsValidator.validatePhone(userData.phone_no)
    if (validFields) {
    }
    setSubmitReady(!Object.values(validFields).includes(false))
  }, [userData])

  const [submitReady, setSubmitReady] = useState(
    !Object.values(validFields).includes(false)
  )
  const [initialEmail, setInitialEmail] = useState(userData.email)
  const [isEmailChanged, setEmailChanged] = useState(false)
  const [password, setPassword] = useState('')

  useEffect(() => {
    setEmailChanged(userData.email !== initialEmail)
  }, [userData])

  const [deleteModal, setDeleteModal] = useState(false)
  const [isEmailValid, setEmailValid] = useState(true)

  useEffect(() => {
    setSubmitReady(!Object.values(validFields).includes(false))
  }, [validFields])

  const { category } = useContext(FireBaseContext)
  const [country, setCountry] = useState(defaultCountry)
  const [countryCode, setCountryCode] = useState(
    getCountryCallingCode(country as any)
  )

  useEffect(() => {
    setCountryCode(getCountryCallingCode(country as any))
  }, [country])

  const currentDate = new Date()

  const storageLogoRef = ref(
    storage,
    `/company-logo/${currentDate.toISOString()}.png`
  )
  const storageCertificateRef = ref(
    storage,
    `/certificate/${currentDate.toISOString()}.png`
  )

  const fieldsValidator = new FieldsValidator(
    {},
    { border: '1px solid red' },
    setValidFields
  )

  async function handleSubmit(event: React.FormEvent<HTMLButtonElement>) {
    setSubmitReady(!Object.values(validFields).includes(false))
    event.preventDefault()
    if (!submitReady) {
      return
    }
    try {
      await props.handleSubmit()
      await uploadBytes(storageCertificateRef, userData.certificate, {
        contentType: 'image/png',
      })
      await uploadBytes(storageLogoRef, userData.company_logo, {
        contentType: 'image/png',
      })
      window.location.href = '/details-success'
    } catch (error) {}
  }

  const accreditingOrganisation =
    userData.country == 'Sweden'
      ? organizationListSE.map((element: string) => {
          return (
            <option key={element} value={element}>
              {element}
            </option>
          )
        })
      : organizationListEN.map((element: string) => {
          return (
            <option key={element} value={element}>
              {element}
            </option>
          )
        })

  if (userData && userData.activate_account_status === true) {
    return (
      <Account currentPage="/account/company-details">
        {deleteModal ? (
          <DeleteUserModal setDeleteModal={setDeleteModal} />
        ) : null}
        <DetailsWrapper>
          <SectionHeader>{t('account.nav.company-details')}</SectionHeader>
          <form className="details-form">
            <div className="inputs">
              <Input
                style={
                  validFields.first_name ? {} : { border: '1px solid red' }
                }
                type="text"
                required
                value={userData.first_name}
                onChange={(e) => {
                  DetailsHandler.handleFirstNameChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                placeholder={t('form.name')}
              />
              <Input
                style={validFields.last_name ? {} : { border: '1px solid red' }}
                type="text"
                required
                value={userData.last_name}
                onChange={(e) => {
                  DetailsHandler.handleLastNameChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                placeholder={t('form.last-name')}
              />
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Input
                  style={validFields.email ? {} : { border: '1px solid red' }}
                  value={userData.email}
                  required
                  onChange={(e) => {
                    DetailsHandler.handleEmailChange(
                      e,
                      updateUserData,
                      dispatch
                    )
                  }}
                  type="email"
                  placeholder={t('form.email')}
                />
                {!isEmailValid && (
                  <ErrorDissolve
                    className="dissolve"
                    style={{ opacity: '1', alignSelf: 'end', marginTop: '5px' }}
                  >
                    Invalid password or email!
                  </ErrorDissolve>
                )}
                {isEmailChanged && validFields.email && (
                  <UpdateEmailWrapper>
                    <PasswordField
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value)
                      }}
                      placeholder="Verify the password"
                      className="password-container"
                    />
                    <ChangeEmailButton
                      onClick={async () => {
                        const user = getAuth().currentUser
                        const credential = EmailAuthProvider.credential(
                          initialEmail,
                          password
                        )
                        if (user) {
                          try {
                            await reauthenticateWithCredential(user, credential)
                            props.updateEmail(userData.email)
                          } catch (error) {
                            setEmailValid(false)
                            console.log(error)
                          }
                        }
                      }}
                      type="button"
                    >
                      Update
                    </ChangeEmailButton>
                  </UpdateEmailWrapper>
                )}
              </div>
              <SelectBusiness
                onChange={(e) => {
                  DetailsHandler.handleBusinessTypeChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                value={userData.company_type}
              />
              <Input
                style={
                  validFields.company_name ? {} : { border: '1px solid red' }
                }
                type="text"
                value={userData.company_name}
                onChange={(e) => {
                  DetailsHandler.handleCompanyNameChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                placeholder={t('form.company.name')}
              />
              <Input
                value={userData.company_reg_no}
                onChange={(e) => {
                  DetailsHandler.handleCompanyRegChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                type="number"
                placeholder={t('form.company.registration')}
              />
              <Input
                style={
                  validFields.accreditation_no
                    ? {}
                    : { border: '1px solid red' }
                }
                value={userData.accreditation_no}
                onChange={(e) => {
                  DetailsHandler.handleAccrNoChange(e, updateUserData, dispatch)
                }}
                type="number"
                placeholder={t('form.accreditation-number')}
              />
              <SelectContainer className="select-container">
                <select
                  id="accrediting-organisation"
                  onChange={(e) => {
                    DetailsHandler.handleAccrOrgChange(
                      e,
                      updateUserData,
                      dispatch
                    )
                  }}
                  className="select-accrediting-organisation"
                >
                  {accreditingOrganisation}
                </select>
                <img
                  className="dropdown-svg"
                  src={dropdownArrow}
                  alt="dropdown"
                />
              </SelectContainer>
            </div>
            <div className="inputs">
              <SelectCountry
                onChange={(e) => {
                  DetailsHandler.handleCountryChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                value={userData.country}
              />
              <Input
                style={validFields.address ? {} : { border: '1px solid red' }}
                value={userData.address}
                onChange={(e) => {
                  DetailsHandler.handleAddressChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                type="text"
                placeholder={t('form.address')}
              />
              <Input
                style={validFields.postcode ? {} : { border: '1px solid red' }}
                value={userData.postcode}
                onChange={(e) => {
                  DetailsHandler.handlePostcodeChange(
                    e,
                    updateUserData,
                    dispatch
                  )
                }}
                type="number"
                placeholder={t('form.postcode')}
              />
              <MobileInput>
                <span className="mobile-input-code">
                  +{countryCode}
                  <img src={dropdownArrow} alt="dropdown" />
                </span>
                <PhoneInput
                  style={
                    validFields.phone_no ? {} : { border: '1px solid red' }
                  }
                  defaultCountry={country as CountryCode | any}
                  onCountryChange={(country) => {
                    if (country) {
                      setCountry(country)
                    }
                  }}
                  placeholder={t('form.phone')}
                  value={userData.phone_no || ''}
                  onChange={(e) => {
                    if (typeof e === 'string') {
                      DetailsHandler.handlePhoneChange(
                        e,
                        updateUserData,
                        dispatch
                      )
                    }
                  }}
                />
              </MobileInput>
              <div className="margin">
                <FileUpload
                  handleState={(url: string) => {
                    DetailsHandler.handleCertificateChange(
                      url,
                      updateUserData,
                      dispatch
                    )
                  }}
                  imageUrl={userData.certificate}
                  folderName="company-logo"
                  fileName={t('form.certificate')}
                  id="certificate-details-1"
                />
              </div>
              <div className="margin">
                <FileUpload
                  handleState={(url: string) => {
                    DetailsHandler.handleCompanyLogoChange(
                      url,
                      updateUserData,
                      dispatch
                    )
                  }}
                  imageUrl={userData.company_logo}
                  folderName="company-logo"
                  fileName={t('form.company.logo')}
                  id="company-logo-details-1"
                />
              </div>
              <SelectTradeContainer>
                {category &&
                  category.map((category: ICategoryType, index: number) => {
                    return (
                      <div
                        className="category-box custom-check-container"
                        style={
                          validFields.category_id
                            ? {
                                background: `${convertToHex(
                                  category.backgroundColor
                                )}`,
                              }
                            : {
                                background: `${convertToHex(
                                  category.backgroundColor
                                )}`,
                                border: '1px solid red',
                              }
                        }
                        key={index}
                      >
                        <input
                          checked={
                            userData &&
                            userData.category_id &&
                            userData.category_id.includes(category.id)
                          }
                          disabled={
                            mustHaveAccrJobs.includes(category.id.trim()) && // trim is required, because the id comes from db with an extra space
                            !userData.accreditation_no
                          }
                          onChange={() => {
                            props.changeSelectedTrades(category.id)
                          }}
                          value={category.name}
                          className="checkbox-input custom-check-checkbox"
                          type="checkbox"
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                        <span
                          className="custom-check-checkmark"
                          style={{
                            outline: `1px solid ${convertToHex(
                              category.fontColor
                            )}`,
                            width: '20px',
                            height: '20px',
                          }}
                        ></span>
                        <p
                          style={{
                            color: `${convertToHex(category.fontColor)}`,
                          }}
                          className="checkbox-paragraph"
                        >
                          {category.name}
                        </p>
                      </div>
                    )
                  })}
              </SelectTradeContainer>
              <label className="eco-friendly">
                <Checkbox
                  value={userData.eco_friendly_vehicle}
                  onChange={(e) => {
                    DetailsHandler.handleEcoFriendlyChange(
                      e,
                      updateUserData,
                      dispatch
                    )
                  }}
                />
                <p>{t('registration.eco-friendly')}</p>
                <img src={GreenLeaf} alt="green leaf" />
              </label>
              <Interactions>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  style={{ backgroundColor: '#F27649' }}
                >
                  {t('button.save')}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setDeleteModal(true)
                  }}
                >
                  {t('button.delete-account')}
                </Button>
              </Interactions>
              {InsuranceButton(
                userData.country == 'United Kingdom',
                t('button.get-insurance')
              )}

              <ErrorDissolve className={submitReady ? '' : 'dissolve'}>
                {t('errors.correct-fields')}
              </ErrorDissolve>
            </div>
          </form>
        </DetailsWrapper>
      </Account>
    )
  } else {
    return <div>Please, wait...</div>
  }
}

const Interactions = styled.div`
  display: flex;
  gap: 15px;
  max-width: 320px;
  & button {
    cursor: pointer;
    font-size: 16px;
    padding: 13px 0;
    width: 50%;
  }
`
const SelectContainer = styled.div`
  & select {
    width: 100%;
    border: none;
    outline: none;
    background-color: #efecef;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Avenir', Arial, sans-serif;
    color: #727272;
    font-weight: 900;
    padding: 14px 16px 12px 16px;
    appearance: none;
    -webkit-appearance: none;
  }
  & .dropdown-svg {
    position: absolute;
    right: 18px;
  }
  position: relative;
`

const MobileInput = styled.div`
  width: 100%;
  & img {
    margin-bottom: 3px;
  }
  display: flex;
  font-weight: 900;
  color: #727272;
  font-family: 'Avenir', Arial, sans-serif;
  position: relative;

  & > .PhoneInput {
    background-color: #efecef;
    width: 100%;
    margin-top: 0;
  }
  & > .PhoneInput > .PhoneInputCountry {
    padding-right: 9px;
    opacity: 0;
  }
  & > .PhoneInput .PhoneInputInput {
    background-color: #efecef;
    padding-right: 5px;
    padding-left: 5px;
    font-weight: 900;
    color: #727272;
  }
  & > .mobile-input-code {
    display: flex;
    gap: 5px;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 10px;
  }
`

const Input = styled.input`
  background-color: #efecef;
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #727272;
  font-weight: 900;
  width: 100%;
`

const DetailsWrapper = styled.div`
  width: 100%;
  .details-form {
    gap: 16px;
    display: flex;
  }
  .names {
    max-width: 352px;
    gap: 40px;
    display: flex;
    justify-content: space-between;
  }

  .inputs {
    .eco-friendly {
      margin-bottom: 40px;
      margin-top: 20px;
      color: #595960;
      font-family: 'Avenir', Arial, sans-serif;
      font-size: 12px;
      font-weight: 900;
      gap: 5px;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
      }
    }
    .dropdown-svg {
      top: 19px;
    }
    & input,
    & select {
      height: 48px;
    }
    max-width: 352px;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
`
const SelectTradeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -5px;
  gap: 11px 16px;

  .category-box {
    .checkbox-input:disabled ~ .checkbox-paragraph,
    .checkbox-input:disabled ~ .custom-check-checkmark {
      opacity: 0.2;
    }
    padding: 5px;
    border-radius: 5px;
    height: 50px;
    width: 29%;
    display: flex;
    align-items: center;
    input {
      margin-top: 0;
    }
    .checkbox-paragraph {
      word-break: break-all;
      margin-left: 5px;
      font-size: 10px;
      font-family: Avenir-light;
      font-weight: 800;
      @media only screen and (min-width: 900px) {
        color: #757575;
        width: 70%;
      }
    }
  }
  .custom-check-container {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .custom-check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .custom-check-checkmark {
    height: 40px;
    width: 40px;
    border-radius: 4px;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-check-checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .custom-check-container input:checked ~ .custom-check-checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .custom-check-container .custom-check-checkmark:after {
    left: 11px;
    width: 5px;
    height: 10px;
    border: solid #757575;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media only screen and (max-width: 900px) {
    font-family: Poppins;
    font-weight: 200;
    padding: 0 15px;
    margin-bottom: 10px;

    .category-box {
      flex-direction: column;
      justify-content: center;
      width: 28%;
      height: 85px;
    }
    .checkbox-paragraph {
      margin-top: 5px;
      color: #3d3d3d;
    }
    .custom-check-container .custom-check-checkmark:after {
      left: 48%;
      @media only screen and (max-width: 600px) {
        left: 46%;
      }
      top: 28%;
    }
  }
`

export default CompanyDetailsDesktop
