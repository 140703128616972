import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import mya from '../../assets/global/blobs/mya.png'
import { useTranslation } from 'react-i18next'
import appStore from '../../assets/global/non-bokafix-logos/app-store-green-large.png'
import playStore from '../../assets/global/non-bokafix-logos/play-store-green-large.png'
import check from '../../assets/global/icons/green-circle-check.svg'
import Modal from './Modal'
import { useSelector, useDispatch } from 'react-redux'
import { setIsClosed } from '../../store/slice/ModalSlice'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../store/rootstate'
import { Container } from './Registration/styled-components'

const FixerRegistrationSent: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)
  const { currentModal } = useSelector((state: RootState) => state.modalReducer)
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  const autoCloseModal = () => {
    setTimeout(() => {
      dispatch(setIsClosed('registrationFixerSent'))
      // useEffect(() => {
      navigate('/', { replace: true })
      // })
    }, 10000)
  }
  useEffect(() => {
    autoCloseModal()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <Modal option={currentModal === 'registrationFixerSent' ? true : false}>
      <Container>
        <div className="main">
          <img className="blob" src={isDesktop ? mya : check} alt="mya blob" />
          <div className="text">
            <h1>{t('modals.registration-fixer-sent.article-1')}</h1>
            <h3>{t('modals.registration-fixer-sent.article-2')}</h3>
          </div>
        </div>
        <p>{t('modals.registration-fixer-sent.article-3')}</p>
        <div className="links">
          <a href="https://apps.apple.com/us/app/bokafix-fixer/id1606489189">
            <img src={appStore} alt="apple app store button" height={'52px'} />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
          >
            <img
              src={playStore}
              alt="google play store button"
              height={'52px'}
            />
          </a>
        </div>
      </Container>
    </Modal>
  )
}

export default FixerRegistrationSent
