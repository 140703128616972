import { useDispatch, useSelector } from 'react-redux'
import Modal from './Modal'
import styled from 'styled-components'
import workingBlobsImage from '../../assets/global/blobs/workers-fred-and-sanjay.png'
import appStoreImage from '../../assets/global/non-bokafix-logos/app-store-green-large.png'
import googlePlayImage from '../../assets/global/non-bokafix-logos/play-store-green-large.png'
import cancelButtonImage from '../../assets/global/icons/cancel-button.svg'
import { useTranslation } from 'react-i18next'
import { setIsClosed } from '../../store/slice/ModalSlice'
import { useLocation } from 'react-router-dom'

const DownloadAppModal = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { currentModal } = useSelector((state: any) => state.modalReducer)

  if (currentModal === 'downloadAppModal') {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)
  }

  function closeModal() {
    dispatch(setIsClosed('downloadAppModal'))
    document.body.style.overflow = 'unset'
    if (location.pathname === '/sign-up') {
      window.location.reload()
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Modal option={currentModal === 'downloadAppModal' ? true : false}>
        <ModalWrapper>
          <CloseButton onClick={closeModal}>
            <CloseIcon src={cancelButtonImage} alt="Close Modal Icon" />
          </CloseButton>
          <ModalInnerWrapper>
            <ModalTitle>{t('modals.download-app-modal')}</ModalTitle>
            <img
              style={{ marginBottom: '73px', maxWidth: '302px', width: '100%' }}
              src={workingBlobsImage}
              alt="Green blob Fred passes tools to Sanjay, big yellow blog"
            />
            <ButtonsWrapper>
              <a
                href="https://apps.apple.com/se/app/bokafix/id1606480773"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: '100%' }}
                  src={appStoreImage}
                  alt="App Store button"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.bokafix.customer&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: '100%' }}
                  src={googlePlayImage}
                  alt="Google Play button"
                />
              </a>
            </ButtonsWrapper>
          </ModalInnerWrapper>
        </ModalWrapper>
      </Modal>
    </div>
  )
}

export default DownloadAppModal

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
`

const CloseIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 6%;
`

const ModalWrapper = styled.div`
  top: -97px;
  height: calc(110vh + 97px);

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(209, 209, 209, 0.50967);
  backdrop-filter: blur(2.71828px);
  padding: 0px 15px;
`

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 28px 14px 31px 29px;
`

const ModalTitle = styled.h3`
  margin-bottom: 43px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  max-width: 243px;
  width: 100%;
`
