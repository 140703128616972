import React from 'react'

function Star(props: {
  color: string
  size?: string
  onMouseOver?: () => void
  onMouseOut?: () => void
  onClick?: () => void
}) {
  return (
    <span
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      className="star"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ?? '16'}
        height={props.size ?? '16'}
        viewBox="0 0 16 16"
      >
        <g id="Icon" transform="translate(-0.46 -0.216)">
          <rect
            id="Area"
            width="16"
            height="16"
            transform="translate(0.46 0.216)"
            fill={props.color}
            opacity="0"
          />
          <g id="Icon-2" data-name="Icon" transform="translate(1.726 1.726)">
            <path
              id="Path"
              d="M8.621,1.667,10.769,6.02l4.8.7L12.1,10.108l.82,4.785-4.3-2.261-4.3,2.261.82-4.785L1.667,6.722l4.8-.7Z"
              transform="translate(-1.667 -1.667)"
              fill={props.color}
            />
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Star
