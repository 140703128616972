import {Helmet} from "react-helmet";

interface BlogMetaTagsProps{
  title:string,
  description:string,
  imgURL:string
}

const BlogMetaTags: React.FC<BlogMetaTagsProps> = ({
  title,
  description,
  imgURL
}) => {
  return (
    <div className="application">
            <Helmet>
                <meta property="title" content={title}/>
                <meta name="description" content={description}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={imgURL}/>
                <meta property="og:url" content="https://bokafix.com"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@bokafix"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image:src" content={imgURL}/>
                <meta name="twitter:creator" content="@bokafix"/>
            </Helmet>
            ...
        </div>
  )
}

  export default BlogMetaTags;