import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import checkmark from '../../assets/global/icons/white-check.svg'
import { SuccessBackground } from '../../components/Background'

const SuccessPage = (props: { title: string; paragraph: string }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    window.setTimeout(function () {
      navigate('/', { replace: true })
    }, 5000)
  }, [])

  return (
    <div>
      <SuccessBackground />
      <Container>
        <h2>{props.title}</h2>
        <div className="checkmark-container">
          <img src={checkmark} alt="checkmark" height={'60px'} width={'60px'} />
        </div>
        <p>{props.paragraph}</p>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  .checkmark-container {
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0f7173;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
`

export default SuccessPage
