import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './downloadApp.module.css'
import phoneImg from '../../assets/global/download-app/fixerApp.png'
import phoneImgCustomer from '../../assets/global/download-app/customerApp.png'
import appStoreBanner from '../../assets/global/non-bokafix-logos/appstore-orange.png'
import gplayBanner from '../../assets/global/non-bokafix-logos/gplay-orange.png'
import Background from './Background'
import usePageParams from '../../utils/usePageParams'

function DownloadApp(props: { isCustomer?: boolean }) {
  usePageParams('Download App', 'customerPage', 'Download App', 'Download App')
  const { t } = useTranslation()

  return (
    <div className={styles['wrapper']}>
      <Background isCustomer={props.isCustomer} />
      <div className={styles['content']}>
        <h2 className={styles['title']}>
          {props.isCustomer === true
            ? t('download-app-page.customer-title')
            : t('download-app-page.fixer-title')}
        </h2>
        <img
          className={styles['phone']}
          src={props.isCustomer ? phoneImgCustomer : phoneImg}
          alt=""
        />
        <h3 className={styles['subtitle']}>
          {props.isCustomer === true
            ? t('download-app-page.customer-subtitle')
            : t('download-app-page.fixer-subtitle')}
        </h3>
        <div className={styles['download-banners']}>
          {props.isCustomer !== true && (
            <>
              <a
                target="_blank"
                href="https://apps.apple.com/se/app/bokafix-fixer/id1606489189"
              >
                <img
                  src={appStoreBanner}
                  className={styles['download-banner']}
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
              >
                <img src={gplayBanner} className={styles['download-banner']} />
              </a>
            </>
          )}
          {props.isCustomer === true && (
            <>
              <a href="https://apps.apple.com/se/app/bokafix/id1606480773">
                <img
                  src={appStoreBanner}
                  className={styles['download-banner']}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bokafix.customer&pli=1">
                <img src={gplayBanner} className={styles['download-banner']} />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default DownloadApp
