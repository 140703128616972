import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorSpan, SectionHeader } from '../ui'
import styles from './Settings.module.css'
import { SettingsProps } from './Settings'
import PasswordField from '../../../components/ui/PasswordField'
import { Button } from '../../../components/component-library/index'
// import gearsBlob from '../../../assets/global/icons/gears-blob.svg'
import blob from '../../../assets/global/blobs/gears-blob.svg'

const selectedStyles = { border: '1px solid #F27649' }
function SeetingsDesktop(props: SettingsProps) {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.settings}>
      <SectionHeader>{t('account.nav.settings')}</SectionHeader>
      <div className={styles.wrapper}>
        <img className={styles.settingsBlob} src={blob} />
        <div className={styles.container}>
          <div className={styles.lang}>
            <h5 className={styles.title}>{t('settings.language')}</h5>
            <div className={styles.langButtons}>
              <button
                onClick={() => props.selectLanguage('sv')}
                style={props.selectedLanguageStyles('sv')}
                className={styles.btn}
              >
                {t('settings.swedish')}
              </button>
              <button
                onClick={() => props.selectLanguage('en')}
                style={props.selectedLanguageStyles('en')}
                className={styles.btn}
              >
                {t('settings.english')}
              </button>
            </div>
          </div>
          <div className={styles.country}>
            <h5 className={styles.title}>{t('settings.country')}</h5>
            <div className={styles.countryButtons}>
              <button
                onClick={() => props.setCountry('Sweden')}
                style={props.country === 'Sweden' ? selectedStyles : {}}
                className={styles.btn}
              >
                {t('country.sweden')}
              </button>
              <button
                onClick={() => props.setCountry('United Kingdom')}
                style={props.country === 'United Kingdom' ? selectedStyles : {}}
                className={styles.btn}
              >
                {t('country.uk-short')}
              </button>
              <button
                onClick={() => props.setCountry('Ireland')}
                style={props.country === 'Ireland' ? selectedStyles : {}}
                className={styles.btn}
              >
                {t('country.ireland')}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.password}>
          <h5 className={styles.title}>{t('settings.change-password')}</h5>
          <PasswordField
            value={props.oldPassword}
            onBlur={props.blurError}
            onChange={(e) => props.setOldPassword(e.target.value)}
            placeholder={t('form.password')}
            className={`${styles.passwordInput} ${styles.oldPassword}`}
          />

          {props.error && <ErrorSpan>{props.error}</ErrorSpan>}
          <div className={styles.changePassword}>
            <PasswordField
              value={props.password}
              onBlur={props.blurError}
              onChange={(e) => props.setPassword(e.target.value)}
              placeholder={t('form.reset-password')}
              className={styles.passwordInput}
            />
            <Button
              fullSize={false}
              color="green"
              name={t('settings.save-password')}
              handleClick={props.submitPassword}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SeetingsDesktop
