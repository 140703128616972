import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import checkmark from "../assets/global/icons/white-check.svg"
import { SuccessBackground } from "../components/Background"
const Success = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const autoRedirect = () => {
        setTimeout(() => {
          navigate('/', { replace: true })
        }, 5000)
      }
        autoRedirect()

    const title = t('success-page.title');
    const paragraph = t('success-page.paragraph-reset-email');
    
    const Container = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 80vh;
        .checkmark-container{
            margin: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #0F7173;
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
    `
    return(
        <div>
    <SuccessBackground />
    <Container>
        <h2>{title}</h2>
        <div className="checkmark-container">
            <img src={checkmark} alt="checkmark" height={"60px"} width={"60px"}/>
        </div>
        <p>{paragraph}</p>
    </Container>
    </div>
    )
   
}


export default Success;