import React from 'react';
import styled from 'styled-components';
import dropdownArrow from '../../assets/dropdown-icon.svg';

function Select(props: {
  value?: string;
  placeholder: string;
  options: { value: string; text: string; selected?: boolean }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}) {
  return (
    <SelectContainer>
      <select
        value={props.value}
        placeholder={props.placeholder}
        id="business"
        onChange={props.onChange}
        className="select-business"
      >
        {props.options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
      <img src={dropdownArrow} alt="dropdown arrow" />
    </SelectContainer>
  );
}

const SelectContainer = styled.div`
  & select {
    width: 100%;
    border: none;
    outline: none;
    background-color: #efecef;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Avenir', Arial, sans-serif;
    color: #727272;
    font-weight: 900;
    padding: 14px 16px 12px 16px;
    appearance: none;
    -webkit-appearance: none;
  }
  & img {
    position: absolute;
    top: 18px;
    right: 18px;
  }
  position: relative;
`;
export default Select;
