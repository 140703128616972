import { DetailedHTMLProps, Dispatch, InputHTMLAttributes, SetStateAction } from 'react';
import '../css/file-input.css';
import logo from '../assets/upload-arrow-up.png';
import { HTMLInputEvent } from '../types/file-input-types';

const fileInput = (props: {
  id: string;
  leftButtonName: string;
  imageName: string;
  image: string;
  imageAlt: string;
  acceptedDataTypes: string;
  passChildData: (arg0: File) => void;
  data?: any;
}) => {
  const inputChange = () => {
    document.getElementById(props.id)!.click();
  };
  return (
    <div className="file-input-container" onClick={inputChange}>
      <div className="browse">{props.leftButtonName}</div>
      <div className="image-source-name">{props.imageName}</div>
      <div className="input-file-image-container">
        <img
          className={props.image ? 'file-input-image-uploaded' : 'file-input-image'}
          src={props.image ? props.image : logo}
          alt={props.imageAlt}
        />
      </div>
      <input
        type="file"
        name="file"
        accept={props.acceptedDataTypes}
        id={props.id}
        className="file-input"
        onChange={(
          event: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
        ) => {
          props.passChildData(((event as Event).target as unknown as HTMLInputEvent).files[0]);
        }}
      />
    </div>
  );
};

export default fileInput;
