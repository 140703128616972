import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import { FireBaseContext } from '../../../context/FirebaseTranslationContext'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import '../../../utils/phone-input.css'
import { getToken } from 'firebase/messaging'
import { messaging, db } from '../../../api/firebaseConfig'
import { createUserWithEmailAndPassword, getAuth, signOut } from 'firebase/auth'
import ReactPixel from 'react-facebook-pixel'
import FixerRegistrationSent from '../FixerRegistrationSent'
import { IMiscFlags } from '../../utils/interfaces'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { initializePixel } from '../../../utils/initializePixel'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import FixerRegistrationDesktop from './FixerRegistrationDesktop'
import FixerRegistrationMobile from './FixerRegistrationMobile'
import { Form } from 'react-final-form'
import { FormState } from 'final-form'
import initialFormData from './initialFormData'
// import initialTradesmenDBData from './initialTradesmanDBData'
import validationRules from '../../utils/validation-rules'
import formatCountryCode from '../../../utils/formatCoutryCode'
import formatBusinessType from '../../../utils/formatBusinessType'

// Plumber 1DZKa0LuOuzD7Xh4UTD8
// Carpenter J2fla2h5femwCpJ7g9Zw
// Decorator Im5tgvsOKZrA5l71CSwn
// Gas & boiler  kH4yOjzRbgrZ4a0nazdi
// Handyman XaydqeBq2iYijNugLxNA
// Electrician bQTIPjF7YRvrA9cll5WI
initializePixel({
  autoConfig: false,
  debug: false,
})
const linkToBokafixAdminTool = 'https://admin-bokafix.firebaseapp.com/fixers/'

const FixerRegistration = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { category } = useContext(FireBaseContext)
  const [uid, setUid] = useState('')
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false)

  function displayIssue() {
    if (!miscFlags.issueDisplayed) {
      setMiscFlags((prev) => ({
        ...prev,
        issueDisplayed: true,
      }))
      setTimeout(() => {
        setMiscFlags((prev) => ({
          ...prev,
          issueDisplayed: false,
        }))
      }, 5000)
    }
  }

  const setIssue = (text: string) => {
    setMiscFlags((prev) => ({ ...prev, issueDetected: text }))
  }

  const handleIssue = (err: string) => {
    setIssue(err)
    displayIssue()
  }

  const [miscFlags, setMiscFlags] = useState({
    issueDisplayed: false,
    issueDetected: '',
    termsAndConditions: false,
    isLoaderDisplayed: false,
  })

  const { currentModal } = useSelector((state: any) => state.modalReducer)

  const [fileInputs, setFileInputs] = useState({
    companyLogoURL: '',
    companyLogoName: '',
    insuranceCertificateURL: '',
    insuranceCertificateName: '',
  })

  const [step, setStep] = useState(1)
  const isDesktop = useMediaQuery(900)

  function formHasErrors(
    formState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) {
    const errorsList = Object.values(formState.errors as { [s: string]: any })
    return (
      errorsList.length > 0 ||
      Object.values(formState.dirtyFields).length === 0 ||
      formState.values.category_id.length === 0
    )
  }
  async function handlePermissionRequest() {
    const permission = await Notification.requestPermission()
    if (permission !== 'granted') {
      handleIssue('You need to grant permission to continue')
      const permission = await Notification.requestPermission()
      if (permission !== 'granted') {
        dispatch(setIsClosed('registrationFixerModal'))
        throw new Error('Permission not granted')
      }
    }
  }

  function handleRegistrationSuccess() {
    dispatch(setIsClosed('registrationFixerModal'))
    setStep(1)
    setRegistrationSuccessful((prev) => !prev)
    window.scrollTo(0, 0)
    dispatch(setIsOpen('registrationFixerSent'))
  }

  function validateForm(
    formState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) {
    const errorsList = Object.values(formState.errors as { [s: string]: any })
    if (formHasErrors(formState)) {
      handleIssue(errorsList[0])
      return false
    }
    if (
      step > 3 &&
      validationRules.required(fileInputs.insuranceCertificateURL as any)
    ) {
      handleIssue(t('Please, upload an insurance certificate'))
      return false
    }
    return true
  }

  // IF function is called only with the first parameter which is NUMBER,
  // THEN validation errors are ignored and setStep() is called directly
  // OTHERWISE formState is used to check for validation errors

  const goToStep = async (
    formStateOrNumber:
      | FormState<Record<string, any>, Partial<Record<string, any>>>
      | number,
    step?: number
  ) => {
    const ignoreValidationErrors = typeof formStateOrNumber === 'number'

    if (ignoreValidationErrors) {
      setStep(formStateOrNumber)
      return
    }
    if (!validateForm(formStateOrNumber)) {
      return
    }
    handleIssue('')
    if (step) {
      setStep(step)
      return
    }
    setStep((prev) => prev + 1)
  }
  const registerUser = async (
    formState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    try {
      const formData = { ...formState.values }
      delete formData.password

      if (!validateForm(formState)) {
        return
      }
      setMiscFlags((prev: IMiscFlags) => ({
        ...prev,
        isLoaderDisplayed: true,
      }))
      // const business_type = formatBusinessType(formData?.company_type)
      // const country_code = formatCountryCode(formData?.country)

      // try {
      //   await handlePermissionRequest()
      // } catch (e) {
      //   console.log(e)
      // }

      // const stripeResponse = await FirebaseAPI.stripeCreateFixerAccount(
      //   business_type,
      //   country_code,
      //   formData?.email,
      //   formData?.company_name ||
      //     `${formData?.first_name} ${formData?.last_name}`,
      //   formData?.phone_no
      // )

      // let stripe_account_id = ''
      // if (stripeResponse.data.success) {
      //   stripe_account_id = stripeResponse.data.accountId
      // } else {
      //   handleIssue("Couldn't create your stripe account")
      // }

      const additionnalTradesmenDBData = {
        company_reg_no: formData?.company_reg_no,
        address: formData?.address,
        postcode: formData?.postcode,
        phone_no: formData?.phone_no,
        accreditation_no: formData?.accreditation_no,
        accreditation_org: formData?.accreditation_org,
        company_logo: formData?.company_logo,
        updated_on: Date.now(),
        is_team_lead: formData?.is_team_lead,
        company_type: formData?.company_type,
        insurance_provider: formData?.insurance_provider,
        company_name: formData?.company_name,
        eco_friendly_vehicle: formData?.eco_friendly_vehicle,
        // certificate: formData?.certificate, //TODO : add me later
        // profile_image: formData?.profile_image, //TODO : add me later
      }
      const tradesmanData = {
        ...additionnalTradesmenDBData,
        certificate: fileInputs.insuranceCertificateURL || '',
        company_logo: fileInputs.companyLogoURL || '',
      }
      await updateDoc(doc(db, 'tradesman', uid), tradesmanData)

      ReactPixel.grantConsent()
      ReactPixel.pageView()
      setMiscFlags((prev) => ({
        ...prev,
        isLoaderDisplayed: false,
      }))

      await FirebaseAPI.sendMail(
        t('register-mail.title'),
        t('register-mail.title-2'),
        t('register-mail.paragraph'),
        t('register-mail.paragraph-2'),
        t('register-mail.second-paragraph'),
        t('register-mail.second-paragraph-2'),
        t('register-mail.cta-button'),
        formData?.email
      )
      await FirebaseAPI.sendSupportEmail({
        link: linkToBokafixAdminTool + uid,
        email: formData?.email,
        completed: true,
      })
      handleRegistrationSuccess()
    } catch (e: any) {
      if (
        e.toString() ==
        'FirebaseError: Firebase: Error (auth/email-already-in-use).'
      ) {
        handleIssue('Error : Email already used')
      } else {
        handleIssue(e.message)
      }
      console.log(e)
    } finally {
      setMiscFlags((prev) => ({
        ...prev,
        isLoaderDisplayed: false,
      }))
    }
  }

  function rolesSelected(selectedTrades: string[], ...roleName: string[]) {
    if (!category) {
      return
    }
    return selectedTrades.some((el: string) => roleName.includes(el))
  }

  const props = {
    setFileInputs,
    fileInputs,
    registerUser,
    step,
    goToStep,
    setMiscFlags,
    miscFlags,
    displayIssue,
    setIssue,
    registerInitially,
    formHasErrors,
    rolesSelected,
  }
  async function registerInitially(
    formState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) {
    try {
      if (!validateForm(formState)) {
        return
      }
      setMiscFlags((prev) => ({
        ...prev,
        isLoaderDisplayed: true,
      }))
      const formValues = formState.values
      const formData = { ...formValues }
      // sign out in case user is logged in
      const auth = getAuth()
      let currentUser = auth.currentUser
      if (currentUser) {
        await signOut(auth)
      }
      const user = await createUserWithEmailAndPassword(
        auth,
        formData?.email,
        formData?.password
      )

      const userId = user.user.uid
      const mail = formData?.email
      const currentDate = Date.now()
      const initialTradesmenDBData = {
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        email: mail,
        tradesman_id: userId,
        company_reg_no: '',
        address: '',
        postcode: '',
        phone_no: '',
        accreditation_no: '',
        accreditation_org: '',
        certificate: '',
        company_logo: '',
        stripe_account_id: '',
        category_id: formData?.category_id,
        team_member_list: [],
        status: 'Pending',
        created_on: currentDate,
        updated_on: currentDate,
        reject_reason: 'NA',
        status_updated_on: currentDate,
        is_remember: true,
        fcm_token: '',
        block_reason: 'NA',
        block_status: false,
        location: '',
        hourly_rate: 0,
        active_status: 'online',
        is_available: true,
        has_agreed_to_terms: true,
        country: formData?.country,
        report_on: currentDate,
        activate_account_status: true,
        is_team_lead: false,
        no_of_rating_length: 0,
        total_rating: 0,
        avg_rating: 0,
        company_type: '',
        insurance_provider: '',
        company_name: '',
        eco_friendly_vehicle: false,
        admin_pannel_read: false,
        stripe_approved: false,
        hourly_rate_after_rot: 0,
        total_earnings: 0,
        profile_image: '',
      }

      setUid(userId)
      await setDoc(doc(db, 'tradesman', userId), initialTradesmenDBData)
      window.localStorage.setItem('userType', 'fixer')
      // if (formData.country === 'Sweden') {
      //   setStep(4)
      // } else {
      setStep(2)
      // }
    } catch (err: any) {
      handleIssue(err.message)
      console.log(err)
    } finally {
      setMiscFlags((prev) => ({
        ...prev,
        isLoaderDisplayed: false,
      }))
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      {registrationSuccessful ? <FixerRegistrationSent /> : <div></div>}
      <Modal
        style={{ marginBottom: '0px', paddingBottom: '200px' }}
        option={currentModal === 'registrationFixerModal' ? true : false}
      >
        {isDesktop ? (
          <Form
            initialValues={{ ...initialFormData }}
            onSubmit={() => {}}
            render={({ handleSubmit, form }) => {
              return (
                <FixerRegistrationDesktop
                  {...props}
                  formState={form.getState()}
                />
              )
            }}
          />
        ) : (
          <Form
            initialValues={{ ...initialFormData }}
            onSubmit={() => {}}
            render={({ handleSubmit, form }) => {
              return (
                <FixerRegistrationMobile
                  {...props}
                  formState={form.getState()}
                />
              )
            }}
          />
        )}
      </Modal>
    </div>
  )
}
export default FixerRegistration
