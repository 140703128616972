import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import closeIcon from '../../../assets/global/icons/cancel-button.svg'
import {
  CloseButton,
  ModalBody,
  ModalParent,
  ModalTitle,
  ModalWindow,
  ModalWrapper,
} from './mobile-components'
function MobileModalWrapper(props: {
  children: React.ReactNode
  title: string
  closeModal: () => void
  bodyStyles?: React.CSSProperties
}) {
  const { t } = useTranslation() // don't touch this line
  return (
    <ModalWrapper className="wrapper">
      <CloseButton onClick={props.closeModal} src={closeIcon} />
      <ModalWindow>
        <ModalTitle>{props.title ?? t('modals.reactivate-account')}</ModalTitle>
        <ModalBody style={props.bodyStyles}>{props.children}</ModalBody>
      </ModalWindow>
    </ModalWrapper>
  )
}

export default MobileModalWrapper
