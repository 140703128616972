import { initializeApp } from 'firebase/app'
import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getMessaging } from 'firebase/messaging'

const API_KEY = process.env.REACT_APP_FIREBASE_KEY ?? ''
const BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL ?? ''
const AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? ''
const PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ''
const STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? ''
const MESSAGE_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID ?? ''
const APP_ID = process.env.REACT_APP_FIREBASE_APP_ID ?? ''
const MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? ''

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: BASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGE_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app)

if ('serviceWorker' in navigator) {
  // navigator.serviceWorker.register('../firebase-messaging-sw.js')
  //
  // The line above is commented because it causes erros. 
  //The most likely cause is that we don't have this file on such path :/

  // .then(function (registration) {
  //     console.log(
  //         'Registration successful, scope is:',
  //         registration.scope
  //     )
  // })
  // .catch(function (err) {
  //     console.log('Service worker registration failed, error:', err)
  // })
}
