import { useTranslation } from "react-i18next"

export const useFormatTimestamp = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language;
  return function formatTimestamp(timestamp: Date) {
    const options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions
    return timestamp.toLocaleDateString(locale, options).replaceAll(',', '')
  }
}