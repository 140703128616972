import { useTranslation } from "react-i18next";

const useCountriesList = () => {
  const { t } = useTranslation();
  const countriesList = [
    { value: '', label: t('registration.select-country'), disabled: true },
    { value: 'Sweden', label: t('country.sweden') },
    { value: 'United Kingdom', label: t('country.united-kingdom') },
    { value: 'Ireland', label: t('country.ireland') },
  ]
  return countriesList;
};

export default useCountriesList