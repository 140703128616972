import React from 'react'
import styles from './Profile.module.css'
import placeholder from '../../../assets/global/icons/user-icon.png'
import notification from '../../../assets/global/icons/notification.svg'
import { Link } from 'react-router-dom'

function Profile(props: {userData: any}) {
  const link = window.localStorage.getItem('userType') === 'customer' ? '/customer-account' : '/account'
  return (
    <div className={styles.wrapper}>
    <Link to={link} className={styles.profile}>
      <div className={styles.circle} />
      <img className={styles.image} src={props.userData.profile_image || placeholder}  />
      <div className={styles.name}>{props.userData.first_name} {props.userData.last_name}</div>
    </Link>
    <div className={styles.notification}>
      <img className={styles.notificationImage} src={notification} />
    </div>
    </div>
  )
}

export default Profile