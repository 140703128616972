// TODO: restyle top button
import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import ServiceCard from './ServiceCard'
import cancelButton from '../../assets/global/icons/cancel-button.svg'
import { FireBaseContext } from '../../context/FirebaseTranslationContext'
import { useDispatch, useSelector } from 'react-redux'
import { setIsClosed, setIsOpen } from '../../store/slice/ModalSlice'
import { useTranslation } from 'react-i18next'
import { Link, To, useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import Profile from './components/Profile'
import BookButton from './components/BookButton'

interface Props {
  togglemenu: () => void
  isOpen: boolean
}

const grey = '#C4C4C41A'
const black = '#000000'

const greyLinkProps = {
  color: grey,
  font: black,
  function: null,
}

const Menu: React.FC<Props> = ({ togglemenu, isOpen }) => {
  const { t } = useTranslation()
  const auth = getAuth()
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  let navigate = useNavigate()
  const { category } = useContext(FireBaseContext)
  const dispatch = useDispatch()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const isCustomer = window.localStorage.getItem('userType') === 'customer'
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  const greenBtnProps = isCustomer
    ? {}
    : {
        children: t('menu.buttons.dashboard'),
        onClick: () => navigate('/account'),
      }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function openRegistrationModal() {
    setIsOpen('registration-modal')
  }
  openRegistrationModal()
  function openRegistration() {
    if (!isDesktop) navigate('/sign-up', { replace: true })
    dispatch(setIsOpen('registrationFixerModal'))
  }

  function closeModal() {
    dispatch(setIsClosed('registrationFixerModal'))
  }
  function goToLogin() {
    dispatch(setIsClosed('registrationFixerModal'))
    dispatch(setIsOpen('logInModal'))
  }
  const loginCustomerButtons: Array<Record<string, string | any> | null> = [
    {
      buttonName: 'menu.buttons.my-account',
      to: '/customer-account',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.my-bookings',
      to: '/bookings',
      ...greyLinkProps,
    },
    // {
    //   buttonName: 'menu.buttons.messages',
    //   to: '/working-on-it',
    //   ...greyLinkProps,
    // },
    {
      buttonName: 'menu.buttons.help',
      to: '/contact-us',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.invoices',
      to: '/invoices',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.settings',
      to: '/customer-account/settings',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.logout',
      to: '/',
      color: '#EEEEF3',
      font: '#000000',
      function: () => auth.signOut(),
    },
  ]

  const loginFixerButtons: Array<Record<string, string | any> | null> = [
    {
      buttonName: 'menu.buttons.my-bookings',
      to: '/working-on-it',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.my-account',
      to: '/account',
      ...greyLinkProps,
    },
    // {
    //   buttonName: 'menu.buttons.earnings',
    //   to: '/working-on-it',
    //   ...greyLinkProps,
    // },
    // {
    //   buttonName: 'menu.buttons.messages',
    //   to: '/working-on-it',
    //   ...greyLinkProps,
    // },
    {
      buttonName: 'menu.buttons.help',
      to: '/contact-us',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.settings',
      to: '/working-on-it',
      ...greyLinkProps,
    },
    {
      buttonName: 'menu.buttons.logout',
      to: '/',
      color: '#EEEEF3',
      font: '#000000',
      function: () => auth.signOut(),
    },
  ]
  const userTypeDependentButtons = !user
    ? [null]
    : isCustomer
    ? loginCustomerButtons
    : loginFixerButtons
  const unloggedLinks: Array<Record<string, string | any> | null> = user
    ? [null]
    : [
        {
          buttonName: 'menu.buttons.become-a-fixer',
          color: '#0F7173',
          font: '#ffffff',
          function: openRegistration,
          to: '/sign-up',
        },
        {
          buttonName: 'menu.buttons.sign-in',
          color: '#f9f9f9',
          font: '#000000',
          function: goToLogin,
          to: '/',
        },
        {
          buttonName: 'menu.buttons.about',
          color: '#f9f9f9',
          font: '#000000',
          function: closeModal,
          to: '/about',
        },
        {
          buttonName: 'menu.buttons.how-it-works',
          color: '#f9f9f9',
          font: '#000000',
          to: '/how-it-works',
          function: closeModal,
        },
        ...userTypeDependentButtons,
      ]

  const ButtonLinks = [...unloggedLinks, ...userTypeDependentButtons]
  const nonNullButtons = ButtonLinks.filter(
    (button) => button !== null
  ) as Record<string, any>[]

  return (
    <Aside isOpen={isOpen} onClick={togglemenu}>
      <section className="cancel-btn">
        <button onClick={togglemenu}>
          <img src={cancelButton} alt="cancel button" />
        </button>
      </section>
      <Profile userData={userData} />
      <section className="btn-section">
        <div className="btn-container">
          <BookButton {...greenBtnProps} />
          {nonNullButtons.map((button: Record<string, any>, index: number) => {
            return (
              <LinkButton
                key={index}
                onClick={button.function}
                fontcolor={button.font}
                buttonName={button.buttonName}
                color={button.color}
                to={button.to}
              />
            )
          })}
        </div>
      </section>
      {!user && (
        <section className="services-section">
          {/* <div className="heading">
          <b>{t('footer-links.services.title')}</b>
        </div> */}

          <div className="box-container">
            {category &&
              category.map(
                (
                  cat: {
                    name: string
                    image: string
                    backgroundColor: number
                  },
                  index: number
                ) => {
                  return <ServiceCard key={index} data={cat} />
                }
              )}
            <li></li>
          </div>
        </section>
      )}
    </Aside>
  )
}

export default Menu

interface ButtonProps {
  to: To
  color?: string
  buttonName: string
  fontcolor?: string
  onClick?: any
}

const LinkButton: React.FC<ButtonProps> = ({
  to,
  color,
  buttonName,
  fontcolor,
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <Link to={to}>
      <Button
        fontcolor={fontcolor}
        onClick={onClick != null ? onClick : () => {}}
        color={color}
      >
        {t(buttonName)}
      </Button>
    </Link>
  )
}

interface button {
  color?: string
  fontcolor?: string
}

const Button = styled.button<button>`
  background: ${(props) => props.color};
  padding: 1rem;
  color: ${(props) => props.fontcolor};
  margin: 10px 0px;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  height: 60px;
  width: 100%;
  cursor: pointer;
  :hover {
    filter: ${(props) => props.theme.button.hover};
  }
`

interface sidebarProps {
  isOpen?: boolean
}

const Aside = styled.aside<sidebarProps>`
  top: 76px;
  position: fixed;
  overflow: auto;
  -ms-overflow-style: none;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  bottom: 0;
  min-height: 100vh;
  background: #ffffff;
  transform: translateX(${(props) => (props.isOpen ? '0' : '-100%')});
  transition: 0.2s ease-in;
  padding-bottom: 76px;
  z-index: 9;
  ::-webkit-scrollbar {
    display: none;
  }
  .cancel-btn {
    padding: 2rem;
    display: flex;
    position: relative;
    justify-content: end;

    button {
      cursor: pointer;
      border: none;
      background: transparent;
      font-size: 16px;
    }
  }

  .btn-section {
    display: flex;
    padding-bottom: 37px;
    justify-content: center;
    margin-top: 32px;
    .btn-container {
      width: 90%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
    }
  }

  .services-section {
    margin: 2rem 0px;
    .heading {
      margin: 1rem 0px;
      max-width: 400px;
      width: 90%;
      b {
        margin: 15px;
        text-align: start;
      }
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .box-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      max-width: 360px;
    }
    @media screen and (max-width: 360px) {
      width: 240px;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 120px;
      margin: 0 auto;
    }
  }
`
