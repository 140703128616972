import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import Footerlinks from '../footer/Footerlinks'
import FooterLogo from '../footer/FooterLogo'
import { setIsOpen } from '../../store/slice/ModalSlice'
import LegalTerms from '../footer/LegalTerms'
import Menu from '../header/Menu'
import Navbar from '../header/Navbar'
import FixerRegistration from '../modals/Registration/FixerRegistration'
import ForgotPassword from '../modals/ForgotPassword'
import DownloadAppModal from '../modals/DownloadAppModal'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LogInModal from '../modals/LogIn/LogInModal'
import RegistrationCustomer from '../modals/RegistrationCustomer/RegistrationCustomer'
import SelectUserType from '../modals/SelectUserType/SelectUserType'
import MissingAccountModal from '../modals/MissingAccountModal/MissingAccountModal'
import ChangePasswordModal from '../modals/ChangePassword/ChangePasswordModal'

interface Props {
  children: ReactElement | ReactElement[]
}
const Layout: React.FC<Props> = ({ children }): JSX.Element => {
  // Show menu in navbar
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  // Show download-app modal on first visit (if mobile)
  let dispatchModalTimeout = 5000
  const location = useLocation()
  const dispatch = useDispatch()

  if (location.pathname === '/sign-up') {
    dispatchModalTimeout = 2000
  }

  let [isMobile, setIsMobile] = useState(window.innerWidth < 900)

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 900)
  }

  

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  if (isMobile && !window.localStorage.getItem('newUser')) {
    setTimeout(() => {
      dispatch(setIsOpen('downloadAppModal'))
      window.localStorage.setItem('newUser', 'false')
    }, dispatchModalTimeout)
  }

  return (
    <Container>
      {/* Header components */}
      <header>
        <Navbar togglemenu={toggleMenu} />
        <Menu isOpen={showMenu} togglemenu={toggleMenu} />
      </header>
      {/* Routes */}
      <main id="main">
        {children}
        <MissingAccountModal />
        <FixerRegistration />
        <DownloadAppModal />
        <ForgotPassword />
        <LogInModal />
        <RegistrationCustomer />
        <SelectUserType />
        <ChangePasswordModal />
      </main>
      <footer>
        <div className="container">
          <div className="grid">
            <div className="column-1">
              <FooterLogo />
            </div>
            <div className="column-2">
              <Footerlinks />
            </div>
          </div>
        </div>
        <div className="legal-section">
          <LegalTerms />
        </div>
      </footer>
    </Container>
  )
}

export default Layout

const Container = styled.div`
  overflow: scroll;
  position: relative;

  main {
    min-height: 75vh;
    margin-top: -10px;
    position: relative;
    z-index: 0;
  }
  footer {
    background: #f27649;
    position: relative;
    z-index: 8;
    .container {
      padding-top: 3.5rem;
      padding-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .grid {
    padding: 1rem;
    max-width: 1250px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 900px) {
      padding: 1rem 4rem;
    }
    @media screen and (min-width: 750px) {
      grid-template-columns: 0.7fr 1fr;
    }
  }
`
