const initialFormData = {
  "first_name": "",
  "last_name": "",
  "country": "",
  "email": "",
  "password": "",
  "category_id": [
  ],
  "eco_friendly_vehicle": false,
  "is_team_lead": false,
  "terms_and_conditions": false,
  "accreditation_org": "",
  "insurance_provider": "",
  "company_type": "",
  "company_name": "",
  "company_reg_no": "",
  "address": "",
  "postcode": "",
  "phone_no": "",
  "accreditation_no": ""
}

export default initialFormData;