import React from 'react'
import styled from 'styled-components'
import LinkItem from './FooterLink'

type Link = {
  name: string
  path: string
}

type Links = {
  title: string
  links: Array<{ name: string; path: string }>
}

interface Props {
  linkList: Links
}

const FooterLinkColumn: React.FC<Props> = ({ linkList }): JSX.Element => {
  return (
    <Column>
      <h3>{linkList.title}</h3>
      <ul>
        {linkList.links.map((link: Link, index: number) => {
          return <LinkItem key={index} link={link} />
        })}
      </ul>
    </Column>
  )
}

export default FooterLinkColumn

const Column = styled.div`
  padding: 0.5rem;
  text-align: start;
  font-family: Avenir;
  @media only screen and (max-width: 900px) {
    font-family: Poppins;
    font-weight: 300;
  }

  h3 {
    color: ${({ theme }) => theme.text.heading3.footer.fontColor};
    margin-bottom: 20px;
    @media only screen and (max-width: 900px) {
      font-size: 19px;
      font-weight: 300;
    }
  }
  ul {
    list-style: none;
    li {
      margin-top: 10px;
      a {
        text-decoration: none;
        @media only screen and (max-width: 900px) {
          font-size: 15px;
        }

        color: ${({ theme }) => theme.text.link.footer.fontColor};
      }
    }
  }
  padding: 0.5rem;
  text-align: start;
`
