import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  FAQBackgroundDesktop,
  FAQBackgroundMobile,
} from '../components/Background'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
const FAQPage = () => {
  const { t } = useTranslation()
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  usePageParams("FAQ's", 'customerPage', '')

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const [showCustomerList, setShowCustomerList] = useState(false)
  const [showFixerList, setShowFixerList] = useState(false)
  const [ShowSubArticles, setShowSubArticles] = useState<{
    [key: string]: boolean
  }>({
    articlecustomers1: false,
    articlecustomers2: false,
    articlecustomers3: false,
    articlefixer1: false,
    articlefixer2: false,
    articlefixer3: false,
    articlefixer4: false,
  })

  const toggleCustomerList = () => {
    setShowCustomerList(!showCustomerList)
    setShowFixerList(false)
  }
  const toggleFixerList = () => {
    setShowFixerList(!showFixerList)
    setShowCustomerList(false)
  }
  const toggleAccordion = (articleName: string) => {
    setShowSubArticles((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [articleName]: !prevState[articleName],
    }))
  }

  return (
    <div>
      {isDesktop ? <FAQBackgroundDesktop /> : <FAQBackgroundMobile />}
      <Container>
        <div className="container">
          <h1>{t('faqs-page.title')}</h1>
          <section>
            <article>
              <div
                className="display-flex-row element-click"
                onClick={toggleCustomerList}
              >
                <h4>{t('faqs-page.customer')}</h4>
                <div className="sign">{showCustomerList ? '-' : '+'}</div>
              </div>
              {showCustomerList && (
                <div>
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlecustomers1')}
                  >
                    <h5>{t(`faqs-page.articles.article-customers-1.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlecustomers1 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlecustomers1 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-2.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-3.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-3.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-4.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-4.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-5.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-1.subarticle-5.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlecustomers2')}
                  >
                    <h5>{t(`faqs-page.articles.article-customers-2.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlecustomers2 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlecustomers2 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-2.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-2.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-2.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-2.subarticle-2.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlecustomers3')}
                  >
                    <h5>{t(`faqs-page.articles.article-customers-3.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlecustomers3 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlecustomers3 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-2.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-3.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-3.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-4.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-customers-3.subarticle-4.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                className="display-flex-row element-click"
                onClick={toggleFixerList}
              >
                <h4>{t('faqs-page.fixer')}</h4>
                <div className="sign">{showFixerList ? '-' : '+'}</div>
              </div>
              {showFixerList && (
                <div>
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlefixer1')}
                  >
                    <h5>{t(`faqs-page.articles.article-fixer-1.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlefixer1 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlefixer1 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-2.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-3.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-1.subarticle-3.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlefixer2')}
                  >
                    <h5>{t(`faqs-page.articles.article-fixer-2.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlefixer2 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlefixer2 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-2.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-3.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-2.subarticle-3.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlefixer3')}
                  >
                    <h5>{t(`faqs-page.articles.article-fixer-3.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlefixer3 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlefixer3 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-2.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-3.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-3.subarticle-3.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="display-flex-row element-click"
                    onClick={() => toggleAccordion('articlefixer4')}
                  >
                    <h5>{t(`faqs-page.articles.article-fixer-4.title`)}</h5>
                    <div className="sign">
                      {ShowSubArticles.articlefixer4 ? '-' : '+'}
                    </div>
                  </div>
                  {ShowSubArticles.articlefixer4 && (
                    <div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-4.subarticle-1.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-4.subarticle-1.content`
                          )}
                        </p>
                      </div>
                      <div>
                        <h6>
                          {t(
                            `faqs-page.articles.article-fixer-4.subarticle-2.subtitle`
                          )}
                        </h6>
                        <p>
                          {t(
                            `faqs-page.articles.article-fixer-4.subarticle-2.content`
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </article>
          </section>
        </div>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default FAQPage

const Container = styled.div`
  font-family: Poppins;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    max-width: 800px;
    width: 100%;
    margin-top: 1rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  .display-flex-row {
    border-top: 1px rgba(0, 0, 0, 0.2) solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .display-flex-row:last-child {
    border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  }
  .sign {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-weight: 800;
    font-size: 20px;
  }
  h1 {
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: Poppins;
    font-size: 30px;
  }
  p {
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 20px;
    white-space: pre-line;
  }
  h5 {
    font-size: 16px;
    font-family: Avenir;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 24px;
  }
  h6 {
    font-size: 12px;
    font-family: Avenir;
    line-height: 18px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  h4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: Poppins;
    font-size: 20px;
    line-height: 30px;
  }
  .element-click {
    cursor: pointer;
  }
  @media only screen and (min-width: 900px) {
    font-size: 18px;
    line-height: 24px;
    .container {
      margin-top: 5rem;
      margin-bottom: 10rem;
    }
    h1 {
      display: block;
      text-align: center;
      margin-bottom: 8.5rem;
      font-family: Avenir;
      font-size: 63px;
      line-height: 86px;
    }
    h4 {
      font-size: 35px;
      line-height: 48px;
    }
    h5 {
      font-size: 28px;
      line-height: 38px;
    }
    h6 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-family: Avenir;
      line-height: 24px;
    }
  }
`
