import { useTranslation } from 'react-i18next'
import { Button } from '../component-library/index'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../../store/slice/ModalSlice'
import ToPageTop from '../footer/ToPageTop'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const FixerArmy: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  let navigate = useNavigate()
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function openRegistrationModal() {
    window.scrollTo(0, 0)
    useEffect(() => {
      if (!isDesktop) navigate('/sign-up', { replace: true })
    }, [])
    dispatch(setIsOpen('registrationFixerModal'))
  }
  return (
    <Container>
      <div className="fixer-army">
        <h3>{t('about-page.join-as-fixer')}</h3>
        <div className="button-container">
          <a href="/sign-up">
            <Button
              fullSize={true}
              color="green"
              name={t('about-page.dark-button')}
              handleClick={openRegistrationModal}
            />
          </a>
        </div>
      </div>
      <ToPageTop />
    </Container>
  )
}

export default FixerArmy

const Container = styled.div`
  .fixer-army {
    padding: 100px;
    background: #ffffff;
    width: 100%;
    margin-bottom: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fixer-army h3 {
    font-family: Avenir-light;
    font-size: 35px;
    font-weight: 100;
    color: #3d3d3d;
  }

  .button-container {
    width: 260px;
  }
  @media only screen and (max-width: 900px) {
    .fixer-army {
      padding: 0;
      margin-top: -20px;
      margin-bottom: 30px;
      background: transparent;
      flex-direction: column;
    }
    .fixer-army h3 {
      width: 250px;
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .button-container {
      width: 200px;
    }
  }
`
