import React, { useState, useEffect, useReducer } from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import SettingsMobile from './FixerSettingsMobile'
import SettingsDesktop from '../Settings/SettingsDesktop'
import Account from '../FixerAccount/Account'
import { ContactUsBackgroundMobile } from '../../../components/Background'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { doc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../../api/firebaseConfig'
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth'
import errorReducer from '../../../reducers/errorReducer'
import usePageParams from '../../../utils/usePageParams'
import { useNavigate } from 'react-router-dom'
export interface SettingsProps {
  selectedLanguageStyles: (lang: string) => React.CSSProperties
  selectLanguage: (lang: 'sv' | 'en') => void
  country: string
  setCountry: (country: string) => Promise<void>
  password: string
  setPassword: (password: string) => void
  submitPassword: () => void
  error: string
  blurError: () => void
  oldPassword: string
  setOldPassword: (password: string) => void
}

function Settings() {
  const [state, dispatch] = useReducer(errorReducer, { message: '' })
  const [oldPassword, setOldPassword] = useState<string>('')
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const { user } = useSelector((state: any) => state.userReducer)
  const { t, i18n } = useTranslation()
  const isDesktop = useMediaQuery(900)
  const [country, setCountry] = useState<string>(userData.country)
  const [initial, setInitial] = React.useState<any>({})
  const navigate = useNavigate()
  useEffect(() => {
    setCountry(userData.country)
    if (window.localStorage.getItem('userType') !== 'fixer') {
      navigate('/')
    }
  }, [userData])

  const [password, setPassword] = useState<string>('')

  usePageParams(t('account.nav.settings'), 'customerPage', '')
  const submitPassword = async () => {
    try {
      const auth = getAuth()
      const user = auth.currentUser
      if (!user) {
        dispatch({ type: 'AUTH_ERROR', payload: '' })
        return
      }
      if (password.length < 8) {
        dispatch({ type: 'LENGTH_ERROR', payload: '' })
        return
      }
      if (user && user.email) {
        await signInWithEmailAndPassword(auth, user.email, oldPassword)
        await updatePassword(user, password)
        alert('Password updated')
      }
    } catch (error: any) {
      console.log(error)
      dispatch({ type: 'CUSTOM_ERROR', payload: error.toString() })
    }
  }

  const selectedLanguageStyles = (lang: string) => {
    if (lang === i18n.language) {
      return { border: '1px solid #F27649' }
    }
    return {}
  }
  const selectLanguage = (lang: 'sv' | 'en') => {
    i18n.changeLanguage(lang)
  }

  const updateCountry = async (country: string) => {
    try {
      await updateDoc(doc(db, 'tradesman', user.uid), { country })
      setCountry(country)
    } catch (err) {
      alert(err)
    }
  }

  const childProps = {
    selectedLanguageStyles,
    selectLanguage,
    country,
    setCountry: updateCountry,
    password,
    setPassword,
    submitPassword,
    oldPassword,
    setOldPassword,
    error: state.message,
    blurError: () => {
      dispatch({ type: 'CUSTOM_ERROR', payload: '' })
    },
  }
  if (!isDesktop) {
    return (
      <>
        <ContactUsBackgroundMobile />
        <SettingsMobile {...childProps} />
      </>
    )
  }
  return (
    <Account currentPage="/account/settings">
      <SettingsDesktop {...childProps} />
    </Account>
  )
}

export default Settings
