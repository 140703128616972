import React, { useEffect, useState } from 'react'
import { AccountWrapper, Header, Button } from '../ui'
import styled from 'styled-components'
import { ContactUsBackgroundDesktop } from '../../../components/Background'
import AccountNav from './AccountNav'
import { useTranslation } from 'react-i18next'
import usePageParams from '../../../utils/usePageParams'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { doc, DocumentData, getDoc } from 'firebase/firestore'
import { getAuth, User, onAuthStateChanged } from 'firebase/auth'
import { db } from '../../../api/firebaseConfig'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'

function Account(props: { currentPage?: string; children: React.ReactNode }) {
  const navigate = useNavigate()
  const { userData } = useSelector((state: any) => state.userDataReducer)
  usePageParams('Account', 'customerPage', 'my-account', '', true)
  const { t } = useTranslation()

  const [user, setUser] = useState<DocumentData | null>()

  function genitifyName(name: string) {
    if (name) {
      return name[name.length - 1] === 's' ? `${name}'` : `${name}'s`
    }
  }

  function redirectToWorkingPage() {
    navigate('/working-on-it')
  }

  function redirectToAppDownload() {
    navigate('/download-fixer-app')
  }

  const [filter, setFilter] = useState('all')

  return (
    <>
      <ContactUsBackgroundDesktop />
      <AccountWrapper>
        <Header>{genitifyName(userData?.first_name)} Bokafix account</Header>
        <Stats>
          <StatsWrapper>
            <Interactions className="interactions">
              <Select
                onChange={(event) => {
                  setFilter(event.target.value)
                }}
                defaultValue="All"
              >
                <option>{t('filter.all')}</option>
                <option>{t('filter.past-week')}</option>
                <option>{t('filter.past-month')}</option>
                <option>{t('filter.past-6-months')}</option>
                <option>{t('filter.all-time')}</option>
              </Select>
              <Button>{t('account.view-calendar')}</Button>
              <Button>{t('nav-links.earnings')}</Button>
            </Interactions>
            <Tile onClick={redirectToWorkingPage} className="dark-green">
              <TileNumber>00</TileNumber>
              <TileTitle>{t('account.completed-jobs')}</TileTitle>
            </Tile>
            <Tile onClick={redirectToWorkingPage} className="orange">
              <TileNumber>00</TileNumber>
              <TileTitle>{t('account.to-do')}</TileTitle>
            </Tile>
            <Job className="job">
              <StatLink className="dark-green" onClick={redirectToWorkingPage}>
                <span className="earnings">{t('account.total-earnings')}</span>
                <span className="price">00.00</span>
              </StatLink>
              <NewJobs onClick={redirectToAppDownload} className="orange">
                {t('account.new-jobs')}
              </NewJobs>
            </Job>
          </StatsWrapper>
        </Stats>
        <Main>
          <AccountNav currentPage={props.currentPage ?? 'Company details'} />
          <Content>{props.children}</Content>
        </Main>
      </AccountWrapper>
    </>
  )
}

const Content = styled.div`
  padding: 47px 22px 78px 22px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
`

const Main = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`

const Stats = styled.div`
  a {
    text-decoration: none;
    color: white;
    outline: none;
  }

  .dark-green {
    background: #0f7173;
  }

  .orange {
    background: #f27649;
  }
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  background-color: white;
  font-family: 'Avenir', Arial, sans-serif;
  color: white;
  margin-bottom: 42px;
`

const StatsWrapper = styled.div`
  padding: 30px 45px;
  display: flex;
  gap: 25px;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    .job {
      width: 100%;
    }
    .interactions {
      flex-grow: 1;
    }
  }
`

const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Select = styled.select`
  border: none;
  background-color: #f27649;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px 14px;
  font-weight: 900;
  padding-right: 0;
  color: white;
  outline: none;
  border-right: 16px solid #f27649;
`

const Tile = styled.div`
  cursor: pointer;
  padding: 22px 33px;
  border-radius: 4px;
`

const TileNumber = styled.h1`
  font-size: 130px;
`

const TileTitle = styled.h3`
  text-align: center;
  font-weight: 200;
  font-size: 20px;
`

const Job = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 26px;
`

const StatLink = styled.a`
  border-radius: 4px;
  padding: 36px 18px;
  display: flex;
  justify-content: space-between;
  gap: 45px;
  font-size: 20px;

  & .earnings {
    font-weight: 100;
  }
  & .price {
    font-weight: 900;
  }
`

const NewJobs = styled.a`
  border-radius: 4px;
  text-align: center;
  padding: 36px 0;
  font-weight: 900;
  font-size: 20px;
`

export default Account
