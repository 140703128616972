import React, { useContext } from 'react'
import { FireBaseContext } from '../../../context/FirebaseTranslationContext'
import { convertToHex } from '../../utils/convertToHex'
import { Field } from 'react-final-form'
import validationRules from '../../utils/validation-rules'
import { ICategoryType } from '../../../constants/interfaces'
import { SelectTradeContainer } from '../styled-components'

function TradesSelector() {
  const { category } = useContext(FireBaseContext)
  return (
    <SelectTradeContainer>
      {category &&
        category.map((category: ICategoryType, index: number) => {
          return (
            <div
              className="category-box custom-check-container"
              style={{
                background: `${convertToHex(category.backgroundColor)}`,
              }}
              key={index}
            >
              <Field
                validate={validationRules.requiredCheckbox}
                name="category_id"
                type="checkbox"
                component="input"
                value={category.id.trim() as any}
                id={
                  category.name === 'Gas & boiler'
                    ? 'gas_and_boiler'
                    : category.name.toLocaleLowerCase()
                }
                className="checkbox-input custom-check-checkbox"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
              <span
                className="custom-check-checkmark"
                style={{
                  outline: `1px solid ${convertToHex(category.fontColor)}`,
                  width: '32px',
                  height: '32px',
                  flexShrink: 0,
                }}
              ></span>
              <p
                style={{
                  color: `${convertToHex(category.fontColor)}`,
                }}
                className="checkbox-paragraph"
              >
                {category.name}
              </p>
            </div>
          )
        })}
    </SelectTradeContainer>
  )
}

export default TradesSelector
