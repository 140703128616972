import { useEffect, useState } from 'react'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'

const PrivacyPolicyPage = () => {
  usePageParams('Privacy Policy', 'customerPage', 'Privacy Policy', '', true)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      <Container>
        <section className="heading">
          <h1>
            PRIVACY POLICY
            <br />
            BOKAFIX
          </h1>
          <p>Last updated: [16th December 2022]</p>
        </section>
        <section className="background">
          <h2>1. BACKGROUND</h2>
          <p>
            Bokafix understands that your privacy is important to you and that
            you care about how your personal data is used. We respect and value
            the privacy of everyone who visits the Apps and/or Website
            www.bokafix.com. This Global Privacy Policy{' '}
            <b>(“Privacy Policy”)</b>
            describes how Bokafix AB, and its affiliates will gather, use, and
            maintain your Personal Data on the Bokafix Platform. It will also
            explain your legal rights with respect to that information.
          </p>
          <p>
            By using the Bokafix Platform, you confirm that you have read and
            understood this Privacy Policy, and our Terms and Conditions for
            Fixers (if you are a Fixer) and out Terms and Conditions for
            Customers (if you are a Customer) (together referred to herein as
            the “<b>Contract</b>”). The Contract governs the use of the Bokafix
            Platform. Bokafix will collect, use, and maintain information
            consistent with the Contract.
          </p>
          <p>
            If you have any questions or wish to exercise your rights and
            choices, please contact us as set out in the “Contact Us” section
            below.
          </p>
        </section>
        <section className="definitions">
          <h2>2. DEFINITIONS AND INTERPRETATION</h2>
          <p>
            In this Policy the following terms shall have the following
            meanings:
          </p>
          <div className="table">
            <div className="term-container">
              <p className="term">"Apps"</p>
              <p className="definition">
                means the Bokafix applications for Customer and Fixer available
                to download on Google Play and App Store;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix"</p>
              <p className="definition">
                means Bokafix AB as set out in clause 3 (“<b>we</b>” “<b>our</b>
                ” or “<b>us</b>”);
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix Platform"</p>
              <p className="definition">
                means the Website, Apps and related services, information and
                communications;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Customer"</p>
              <p className="definition">
                means an individual or company seeking to obtain Fixer Services
                from Fixers using the Bokafix Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Customer Terms"</p>
              <p className="definition">
                means to our Terms of Condition for Customers
              </p>
            </div>
            <div className="term-container">
              <p className="term">"the Data Protection Legislation"</p>
              <p className="definition">means the UK GDPR and the EU GDPR;</p>
            </div>
            <div className="term-container">
              <p className="term">"Device Information"</p>
              <p className="definition">means as set out in clause 4;</p>
            </div>
            <div className="term-container">
              <p className="term">"EU GDPR"</p>
              <p className="definition">
                means the (EU) General Data Protection Regulation
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer"</p>
              <p className="definition">
                means an independent third parties contractors and include, but
                are not limited to sole traders and companies seeking to perform
                Fixer Services for Customers and registered on the Bokafix
                Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer Terms"</p>
              <p className="definition">
                means to our Terms of Condition for Fixer;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer Service"</p>
              <p className="definition">
                means a short term service of electrical, plumbing, decorating,
                carpentry, gas and boiler services, provided by a Fixer to a
                Customer. These are made available to you through your use of
                the Bokafix Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Personal Data"</p>
              <p className="definition">
                means personally identifiable information about you, including
                information that can identify, relate to, describe, be
                associated with you, or is reasonably capable of being
                associated with you;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"PSP"</p>
              <p className="definition">
                means a third party service provider approved by Bokafix for
                making and receiving payments for Fixer Services and other
                payments related to the Bokafix;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Profile"</p>
              <p className="definition">means as set out in clause 9;</p>
            </div>
            <div className="term-container">
              <p className="term">"Terms"</p>
              <p className="definition">
                means the Fixer Terms and the Customer Terms;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Third-Party Agents"</p>
              <p className="definition">
                means our agents, representatives, vendors and service
                providers;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"UK GDPR"</p>
              <p className="definition">
                means United Kingdom General Data Protection Regulation and the
                Data Protection Act 2018;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Website"</p>
              <p className="definition">
                means Bokafix's website www.bokafix.com; and
              </p>
            </div>
            <div className="term-container">
              <p className="term">"User(s)" or "You", "Your"</p>
              <p className="definition">means Customers and Fixers</p>
            </div>
          </div>
        </section>
        <section className="information-about">
          <h2>3. INFORMATION ABOUT US</h2>
          <p>
            Our Website is owned and operated by Bokafix AB a private limited
            liability company registered in Sweden under company number
            559317-4278.
          </p>
          <p>Registered address: Harrvägen 8a, 181 30 Lidingö</p>
          <p>Email address: info@bokafix.com</p>
          <p>Postal address: Harrvägen 8a, 181 30 Lidingö</p>
          <p>
            We are regulated by Information Commissioner's Office in the UK (
            <a href="https://ico.org.uk">https://ico.org.uk</a>) and
            Integritetsskyddsmyndigheten (
            <a href="https://www.imy.se">https://www.imy.se</a>) in Sweden.
          </p>
        </section>
        <section className="what-is-personal">
          <h2>4. WHAT IS PERSONAL DATA?</h2>
          <p>
            Personal data is defined by the Data Protection Legislation as any
            information relating to an identifiable person who can be directly
            or indirectly identified in particular by reference to an
            identifier.
          </p>
          <p>
            Personal data is, in simpler terms, any information about you that
            enables you to be identified. Personal data covers obvious
            information such as your name and contact details, but it also
            covers less obvious information such as identification numbers,
            electronic location data, and other online identifiers.
          </p>
        </section>
        <section className="what-are-my-rights">
          <h2>5. WHAT ARE MY RIGHTS?</h2>
          <p>
            Under the Data Protection Legislation, you have the following
            rights, which we will always work to uphold:
          </p>
          <ol type="a">
            <li>
              The right to be informed about our collection and use of your
              personal data. This Privacy Policy should tell you everything you
              need to know, but you can always contact us to find out more or to
              ask any questions using the details in clause 3.
            </li>
            <li>
              The right to access the personal data we hold about you. Clause 11
              will tell you how to do this.
            </li>
            <li>
              The right to have your personal data rectified if any of your
              personal data held by us is inaccurate or incomplete. Please
              contact us using the details in clause 3 to find out more.
            </li>
            <li>
              The right to be forgotten, i.e. the right to ask us to delete or
              otherwise dispose of any of your personal data that we hold.
              Please contact us using the details in clause 3 to find out more.
            </li>
            <li>
              The right to restrict (i.e. prevent) the processing of your
              personal data.
            </li>
            <li>
              The right to object to us using your personal data for a
              particular purpose or purposes.
            </li>
            <li>
              The right to withdraw consent. This means that, if we are relying
              on your consent as the legal basis for using your personal data,
              you are free to withdraw that consent at any time.
            </li>
            <li>
              The right to data portability. This means that, if you have
              provided personal data to us directly, we are using it with your
              consent or for the performance of a contract, and that data is
              processed using automated means, you can ask us for a copy of that
              personal data to re-use with another service or business in many
              cases.
            </li>
            <li>Rights relating to automated decision-making and profiling.</li>
          </ol>
          <p>
            For more information about our use of your personal data or
            exercising your rights as outlined above, please contact us using
            the details provided in clause 3.
          </p>
          <p>
            It is important that your personal data is kept accurate and
            up-to-date. If any of the personal data we hold about you changes,
            please keep us informed as long as we have that data.
          </p>
          <p>
            Further information about your rights can also be obtained from the
            Information Commissioner's Office (UK) or IMO (Sweden).
          </p>
          <p>
            If you have any cause for complaint about our use of your personal
            data, you have the right to lodge a complaint with the Information
            Commissioner's Office. We would welcome the opportunity to resolve
            your concerns ourselves, however, so please contact us first, using
            the details in clause 3.
          </p>
        </section>
        <section className="what-data">
          <h2>6. WHAT DATA DO YOU COLLECT?</h2>
          <p>
            <b>Information you provide to Bokafix</b>
          </p>
          <p>
            Depending on the use of our Website, some or all of the Personal
            Data we collect and hold includes (but are not limited to):
          </p>
          <p>
            <b>Contact Information.</b> This may include your first and last
            name, postal address, telephone number, and email address.
          </p>
          <p>
            <b>Billing Data,</b> including your payment instrument number (such
            as a credit or debit card number), expiration date, and security
            code as necessary to process your payments. Bokafix do not collect
            or store billing data but the PSP will do so. Please review the
            PSP's terms and conditions to find out more.
          </p>
          <p>
            <b>Identity Information.</b> We may collect Personal Data, such as
            your date of birth and address, personal identity number if
            applicable as well as such information as may be needed to validate
            your identity.
          </p>
          <p>
            <b>Financial Information.</b> To help Fixers set up a payment
            account and help Customers make payments to Fixers and pay fees to
            Bokafix, the PSP may collect financial information, including credit
            card number, bank routing numbers, tax information, taxpayer
            identification number, and other payment information, as applicable.
            We use Financial Information in order to operate the Bokafix
            Platform and to ensure that Fixers are paid by Customers. We do this
            as necessary for our legitimate interests in providing our platform
            and services and to fulfill our contracts with Users. To keep your
            financial data secure, we have contracted with a third party to
            maintain and process your payment information.
          </p>
          <p>
            <b>Promotional Information.</b> Certain offerings of the Bokafix
            Platform, such as newsletters, surveys, contests, and the like are
            optional and so you are not required to enter them or to give us
            your data in connection with them. Where you do consent to take
            advantage of Bokafix Platform offerings, we will use your data to
            (as applicable) send you newsletters and other communications that
            are tailored based on information we have about you, or to operate
            and manage the survey, contest or similar offering in connection
            with our legitimate interest in promoting our business and the
            Bokafix Platform. To opt-out of receiving marketing communications
            from us, please see clause 11, Your Choices.
          </p>
          <p>
            <b>Employment Information.</b> We collect employment and education
            history, transcripts, writing samples, and references as necessary
            to consider your job application for open positions.
          </p>
          <p>
            <b>Content Information.</b> You also may choose to send Bokafix
            Personal Data in an email or chat message containing inquiries about
            the Bokafix Platform and we use this Information in order to help us
            respond to your inquiry. We also collect content within any messages
            you exchange with other Users through the Bokafix Platform (such as
            through our chat functionality).
          </p>
          <p>
            We require that you furnish certain information when you register an
            account with us in order to provide services through the Bokafix
            Platform. For example, if you are a Customer, we collect your first
            and last name, email address, and your postal code in order to
            create and administer your Bokafix account. We also collect
            additional information in order to facilitate your booking request,
            such as information about the Fixer Service you are seeking, the
            time, date and location of the Fixer Service, and Billing Data. If
            you are a Fixer, we collect your first and last name, email address,
            mobile phone number and postal code in order to create and
            administer your Bokafix account and facilitate communications
            between you and your Customers through the Bokafix Platform. We also
            collect information about your Fixer Service, rates, and skills, as
            well as Identity Information and Financial Information.
          </p>
        </section>
        <section className="data-collected">
          <h2>7. DATA COLLECTED AUTOMATICALLY</h2>
          <p>
            We automatically collect certain data when you use the Bokafix
            Platform. The categories of data we automatically collect and have
            collected, including in the last 12 months, are as follows:
          </p>
          <p>
            <b>Service Use Data</b>, including data about features you use,
            pages you visit, emails and advertisements you view, portions of the
            Bokafix Platform that you view and interact with, the time of day
            you browse, and your referring and exiting pages.
          </p>
          <p>
            <b>Device Data</b>, including data about the type of device or
            browser you use, your device's operating system, your internet
            service provider, your device's regional and language settings, and
            device identifiers such as IP address and Ad Id. When you visit and
            interact with the Bokafix Platform, Bokafix may collect certain
            information automatically through cookies or other technologies,
            including, but not limited to, the type of computer or mobile device
            you use, your mobile device's unique device ID, the IP address of
            your computer or mobile device, your operating system, the type(s)
            of internet browser(s) you use, and information about the way you
            use the Bokafix Platform <b>(“Device Information”)</b>. We may use
            Device Information to monitor the geographic regions from which
            Users navigate the Bokafix Platform, and for security and fraud
            prevention purposes. Use of any IP-masking technologies or similar
            technologies (like the TOR network) may render portions of the
            Bokafix Platform unavailable and are forbidden on the Bokafix
            Platform.
          </p>
          <p>
            <b>Location Data</b>, including imprecise location data (such as
            location derived from an IP address or data that indicates a city or
            postal code level), and, with your consent, precise location data
            (such as latitude/longitude data). When you visit the Bokafix
            Platform via a native mobile application, we use, with your consent
            when required under applicable law, GPS technology (or other similar
            technology) to determine your current location in order to determine
            the city you are located in and display a relevant location map. We
            will share your current location obtained in this manner with Fixers
            when you have requested a Fixer Service.
          </p>
          <p>
            <b>Cookies and similar technologies</b> are described in our Cookie
            Policy, which sets out the different categories of cookies and
            similar technologies that the Bokafix Platform uses and why we use
            them.
          </p>
        </section>
        <section className="how-do-you">
          <h2>8. HOW DO YOU USE THE PERSONAL DATA?</h2>
          <p>
            We collect and use data for business and commercial purposes in
            accordance with the practices described in this Privacy Policy. Our
            business purposes for collecting and using information include:
          </p>
          <ol type="a">
            <li>
              To operate and make available the Bokafix Platform. We use your
              data to connect you with other Users to enable the posting of,
              selection for, completion of, and payment for Fixer Services, in
              order to fulfill our contracts with Users;
            </li>
            <li>
              For billing and fraud prevention, on the basis of our legitimate
              interests in ensuring a safe and secure environment in which
              Customers and Fixers can meet and conduct business, and in order
              to comply with our legal obligations;
            </li>
            <li>
              To analyze Bokafix Platform usage as necessary for our legitimate
              interest in improving the Bokafix Platform to grow our business;
            </li>
            <li>
              To contact you and deliver (via email, SMS, push notifications, or
              otherwise) transactional information, administrative notices,
              marketing notifications, offers and communications relevant to
              your use of the Bokafix Platform, with your consent when required
              under applicable law, as necessary for our legitimate interests in
              proper communication and engagement with our Users, and in
              promoting our business;
            </li>
            <li>
              To provide you with customer support in order to fulfill our
              contracts with Users;
            </li>
            <li>
              For internal market research for our legitimate interest in
              improving the Bokafix Platform to grow our business;
            </li>
            <li>
              For troubleshooting problems for our legitimate interests in
              ensuring a safe and secure environment in which Users can meet;
            </li>
            <li>
              To assist Users in the resolution of complaints and disputes
              between them, as necessary for our legitimate interests in
              facilitating good relations among Users;
            </li>
            <li>
              To enforce our Terms and our legitimate interests in ensuring our
              Contract is complied with; and
            </li>
            <li>As otherwise set forth in the Contract.</li>
          </ol>
          <p>
            <b>Interest-Based Advertising</b>. Ads are a standard part of user
            experience on the Internet, and Bokafix believes that targeted
            advertising enhances this experience. Bokafix and affiliate third
            parties may use cookies and other technologies to place ads where
            they believe interested Users will see them. In addition to banner
            ads, Bokafix may advertise products, companies and events that we
            think might interest you through the email address and/or phone
            number you provide. We may incorporate Tracking Technologies on the
            Bokafix Platform (including our Website and emails) as well as into
            our ads displayed on other websites and services. Some of these
            Tracking Technologies may track your activities across time and
            services for purposes of associating the different devices you use,
            and delivering relevant ads and/or other content to you (
            <b>“Interest-Based Advertising”</b>).
          </p>
          <p>
            For more information and to understand your choices regarding
            third-party ads, please see our Cookie Policy. Advertising and
            marketing is carried out as necessary for our legitimate interests
            in providing an engaging and relevant experience, promoting our
            services, and growing our business.
          </p>
          <p>
            <b>Analytics and Market Analysis</b>. Bokafix may analyze
            information (<b>“Market Analysis”</b>) as necessary for our
            legitimate interests in providing an engaging and relevant
            experience, and promoting and growing the Bokafix Platform.
          </p>
          <p>
            Bokafix uses information to offer services to Users who express an
            interest in these services, through a poll for example, or to Users
            who can be presumed to have an interest based on results from our
            Market Analysis. We do this as necessary for our legitimate
            interests in providing an engaging and relevant experience,
            promoting our services, and growing our business.
          </p>
          <p>
            <b>Mobile Phone Numbers</b>. Bokafix may use your mobile phone
            number to call or send recurring text messages to you, using an
            autodialer or prerecorded voice, in order to provide you
            notifications about Fixer Services, for marketing purposes (with
            your consent where required by law), and to administer the Bokafix
            Platform. If you would like more information about our policy, or
            how to opt out, please review the “Your Choices” section below. You
            may be liable for standard SMS and per-minute charges by your mobile
            carrier. Bokafix may also message you via push notifications (with
            your consent when required under applicable law), which you can
            opt-out of on your mobile device. Data rates may apply.
          </p>
          <p>
            <b>Call recordings</b>. Calls to or from Bokafix or its Third Party
            Agents may be monitored and recorded for the purposes of quality
            control and training. Such calls may contain Personal Information.
          </p>
        </section>
        <section className="information-sharing">
          <h2>9. INFORMATION SHARING</h2>
          <p>
            We only share the Information we collect about you as described in
            this Privacy Policy or as described at the time of collection or
            sharing, including as follows:
          </p>
          <p>
            <b>Third Party Agents</b>. We share information, including Identity
            Information, with entities that process information on our behalf
            for our business purposes. We contractually prohibit our Third-Party
            Agents from retaining, using, or disclosing information about you
            for any purposes other than performing the services for us, although
            we may permit them to use information that does not identify you
            (including information that has been aggregated or de-identified)
            for any purpose except as prohibited by applicable law.
          </p>
          <p>
            To operate the Bokafix Platform, including processing your
            transactions, we may share your Personal Data with our Third Party
            Agents so they can provide us with support services as follows:
          </p>
          <ol type="a">
            <li>Email origination;</li>
            <li>
              Identity, and criminal background checks, to the extent permitted
              by applicable law;
            </li>
            <li>
              Fraud prevention and detection. Our fraud detection providers also
              use information relating to you and your use of and activity on
              the Bokafix Platform to provide fraud detection services to their
              other Customers;
            </li>
            <li>Receipt, invoice, or support services;</li>
            <li>Customer relationship management services;</li>
            <li>Data analytics;</li>
            <li>Marketing and advertising;</li>
            <li>Website hosting;</li>
            <li>Communications services;</li>
            <li>Technical support;</li>
            <li>
              Financial transaction fulfillment (in which we are currently
              supported by Stripe, who processes your Personal Data in
              accordance with its own privacy policy) and related chargeback and
              collection services; and
            </li>
            <li>To otherwise help us provide the Bokafix Platform.</li>
          </ol>
          <p>
            <b>Promotions</b>. When you voluntarily enter a contest or other
            promotion, we share information as set out in the official rules
            that govern the promotion as well as for administrative purposes and
            as required by law (e.g., on a winners' list). By entering a
            promotion, you agree to the official rules that govern that
            promotion, and may, except where prohibited by applicable law, allow
            the sponsor and/or other entities to use your name, voice, and/or
            likeness in advertising or marketing materials. We may occasionally
            contact you, if you want us to, with information about special
            events, activities, promotions, contects, submission opportunities,
            and programs. You always have the ability, in your Account, to ask
            Bokafix not to contact you with this type of information. Please see
            clause 11, Your Choices, for more information.
          </p>
          <p>
            <b>Matched Ads</b>. We use Matched Ads by sharing personal data with
            another party or incorporating a pixel from another party into our
            own Websites, and the other party matching common factors between
            our data and their data or other datasets for advertising purposes.
            For instance, we incorporate the Facebook pixel on our Websites and
            may share your email address with Facebook as part of our use of
            Facebook Custom Audiences.
          </p>
          <p>
            <b>Other Users</b>. Bokafix facilitates contact between Customers
            and Fixers and reserves the right to share one User's contact
            information (e.g., name, phone number, email, or postal address)
            with other Users, or with the recipient User's legal or other
            authorized representative, in order to: (1) facilitate or
            communicate the resolution of an investigation or dispute related to
            or arising from an interaction between two or more Users of the
            Bokafix Platform; or (2) facilitate the performance of a Fixer
            Service. This exchange of information is essential to the operation
            of the Bokafix Platform.
          </p>
          <p>
            <b>Legal Obligations</b>. Bokafix and our Third Party Agents may
            disclose your Personal Data or User Generated Content to courts, law
            enforcement, governmental or public authorities, tax authorities or
            authorised third parties, if and to the extent required or permitted
            to do so by law or where disclosure is reasonably necessary to: (i)
            comply with Bokafix's legal or regulatory obligations; (ii) respond
            to a court order, warrant or subpoena; (iii) respond to a valid
            legal request relating to an investigation into alleged criminal or
            illegal activity; or (iv) respond to or address any other activity
            that may expose us to legal or regulatory liability.
          </p>
          <p>
            In jurisdictions where Bokafix is, according to applicable law,
            required to collect or remit information about Users' permits,
            licences, tax, or social security registrations, Bokafix and our
            Third Party Agents may disclose information, including but not
            limited to, User contact details, identification information,
            transaction dates and amounts, license and permit status, and tax
            identification number(s) to applicable governmental authorities.
          </p>
          <p>
            Bokafix reserves the right to disclose Personal Data from both
            private and public areas of the Bokafix Platform in the absence of a
            court order, warrant, or subpoena, to the extent permitted by
            applicable law, if we are given reason to believe, in our sole
            discretion, that someone is causing injury to or interfering with
            the rights of Users, the general public, or Bokafix or its partners,
            parents or Affiliates.
          </p>
          <p>
            It is our policy to provide notice to Users before producing their
            information in response to law enforcement requests unless (i) we
            are prohibited or otherwise constrained by law or court order from
            doing so, (ii) we have reason to believe the User's account has been
            compromised such that the notice would go to the wrong person, or
            notice would otherwise be counterproductive or would create a risk
            to safety, or (iii) it is an emergency request and prior notice
            would be impractical (in which case we may provide notice after the
            fact).
          </p>
          <p>
            <b>Merger or Acquisition</b>. Bokafix reserves the right to share
            information in connection with, or during negotiations of, any
            proposed or actual merger, purchase, sale, or any other type of
            acquisition or business combination of all or any portion of our
            assets, or transfer of all or a portion of our business to another
            business.
          </p>
          <p>
            <b>Public Areas</b>. Your profile on the Bokafix Platform consists
            of information about you and your business, and may include
            information such as photographs, your years in business, skills and
            expertise, hourly pay rates, feedback/rating information, and other
            information, including your username (<b>“Profile”</b>). The
            information in your User Profile may be visible to all Users and the
            general public. If you choose to post a Fixer Service via the
            Bokafix Platform, the contents of such listing will be viewable to
            Fixers you select on the Bokafix Platform.
          </p>
        </section>
        <section className="how-and-where">
          <h2>10. HOW AND WHERE DO YOU STORE OR TRANSFER MY PERSONAL DATA?</h2>
          <p>
            We will only store your personal data within the European Economic
            Area (the “EEA”) and the UK. The EEA consists of all EU member
            states, plus Norway, Iceland, and Liechtenstein. This means that
            your personal data will be fully protected under the UK GDPR and EU
            GDPR and/or to equivalent standards by law.
          </p>
        </section>
        <section className="your-choices">
          <h2>11. YOUR CHOICES</h2>
          <p>
            You may opt-out of receiving promotional communications from us,
            including promotional communications related to the Bokafix
            Platform, and request the removal of your Personal Data from our
            databases, or deactivate your account, by logging on to the Website
            or Apps and clicking on the “Account” tab, or by contacting us on
            support@bokafix.com
          </p>
          <p>
            <b>Corrections to Profile</b>. You have the right to access, update,
            and correct inaccuracies in your Bokafix Profile at any time by
            logging in and clicking on the “Account” tab. There, you can view,
            update, and correct your Account information.
          </p>
          <p>
            So that we may protect the integrity of the Bokafix Platform, there
            are certain pieces of information, such as your age, that you cannot
            alter yourself. For example, since children under the age of 18
            years old are not allowed to register as Users, we need to take
            reasonable measures to preserve the accuracy of our Users' ages. You
            may contact us at [support@bokafix.com] if there is a need to make a
            correction to such data.
          </p>
          <p>
            <b>Promotional Communications and marketing correspondence</b>. You
            can opt out of receiving promotional communications and or marketing
            correspondence from Bokafix by following the unsubscribe
            instructions in any any e-mail message and/or other communications
            from Bokafix. You can opt out of promotional communications from
            Bokafix sent via text message at any time by following the
            unsubscribe instructions provided in the text message. Please note
            that your opt-out is limited to the phone number used and will not
            affect subsequent subscriptions. If you opt-out of only certain
            communications, other subscription communications may continue. Even
            if you opt-out of receiving promotional communications, Bokafix will
            continue to send you non-promotional communications, such as those
            about your account, Fixer Services, transactions, servicing, or
            Bokafix's ongoing business relationship with you. If you receive
            unsolicited promotional communications from a Bokafix domain, please
            let us know.
          </p>
        </section>
        <section className="how-long-will">
          <h2>12. HOW LONG WILL YOU KEEP MY PERSONAL DATA?</h2>
          <p>
            We retain Personal Data for as long as you are a User in order to
            meet our contractual obligations to you, and for such longer period
            as may be in our legitimate interests and to comply with our legal
            obligations.
          </p>
        </section>
        <section className="notification">
          <h2>13. NOTIFICATION OF CHANGES</h2>
          <p>
            Bokafix's Privacy Policy is periodically reviewed and enhanced as
            necessary. This Privacy Policy might change as Bokafix updates and
            expands the Bokafix Platform. Bokafix will endeavor to notify you of
            any material changes by email. Bokafix also encourages you to review
            this Privacy Policy periodically.
          </p>
        </section>
        <section className="contacting">
          <h2>14. CONTACTING US</h2>
          <p>
            If you have any questions about this Privacy Policy or the manner in
            which we or our Third-Party Agents treat your Personal Information,
            the practices of the Website, your dealings with the Bokafix
            Platform, or if you have technical problems, you may contact us on
            [support@bokafix.com].
          </p>
          <p>
            Bokafix's staff will respond to all mail or email from Users with
            questions about privacy, including the types of Personal Data stored
            on the Bokafix databases, and requests to know, delete or rectify
            such Personal Information.
          </p>
          <p>Data protection registration no. ZB488669</p>
        </section>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default PrivacyPolicyPage

const Container = styled.div`
  font-family: Avenir;
  width: 100%;
  padding: 20px 10%;
  h1 {
    line-height: 40px;
    text-align: center;
    margin: 50px auto;
  }
  h2 {
    margin: 50px 0 20px;
  }
  p {
    margin-top: 20px;
  }
  ol {
    margin-top: 20px;
    padding-left: 70px;
  }
  li {
    padding-left: 10px;
    position: relative;
    margin-top: 10px;
  }
  .italic {
    font-style: italic;
  }
  .grey {
    color: #707070;
  }

  .definitions {
    .table {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .term-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .term {
          width: 25%;
          font-weight: 600;
        }
        .definition {
          width: 70%;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 5%;
    ol {
      padding-left: 40px;
    }
    .letter-list {
      padding-left: 20px;
    }
  }
`
