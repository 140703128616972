import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
const FixerTermsConditions = () => {
  const { t } = useTranslation()

  usePageParams('Fixer terms & conditions', 'fixerPage', '')
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      <Container>
        <div className="container">
          <h3>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE AGREEING TO
            PROVIDE YOUR SERVICES IN THE BOKAFIX APPLICATIONS AND/OR WEBSITE AS
            THEY INCLUDE IMPORTANT INFORMATION ABOUT THE SERVICES, YOUR RIGHTS
            AND OTHER IMPORTANT INFORMATION.
          </h3>
          <b>
            TERMS AND CONDITIONS FOR FIXERS IN UNITED KINGDOM, SWEDEN AND
            IRELAND
          </b>
          <p>Last updated: 22/03/2024</p>
          <h1>
            Fixer
            <br />
            Terms &amp; Conditions
          </h1>
          <section className="general-section">
            <article>
              <p>
                THESE TERMS APPLY TO BOKAFIX'S PLATFORM (AS DEFINED BELOW) IN
                SWEDEN, IRELAND AND THE UNITED KINGDOM AND ARE LEGALLY BINDING
                BETWEEN YOU AND BOKAFIX.
              </p>
              <p>
                THE BOKAFIX PLATFORM ENABLES A CUSTOMER TO REQUEST FIXER
                SERVICES FROM FIXERS'. WHEN YOU HAVE ACCEPTED A REQUEST FROM A
                CUSTOMER FOR A FIXER SERVICE THROUGH THE BOKAFIX MOBILE
                APPLICATIONS AND WEBSITE, YOU ARE CONTRACTING DIRECTLY WITH THAT
                COSTUMER AND BOKAFIX IS NOT PART OF THAT CONTRACT.
              </p>
              <p>
                FIXERS ARE INDEPENDENT CONTRACTORS OF CUSTOMERS AND NOT
                EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURES OR
                FRANCHISEES OF BOKAFIX. BOKAFIX DOES NOT PERFORM FIXER SERVICES
                AND DOES NOT EMPLOY INDIVIDUALS TO PERFORM FIXER SERVICES. BY
                CONNECTING CUSTOMERS AND FIXERS SEEKING FIXER SERVICES, BOKAFIX
                OPERATES AS AN ONLINE MARKETPLACE THAT CONNECTS CUSTOMERS WITH
                FIXERS WHO WISH TO PERFORM A VARIETY OF FIXER SERVICES.
              </p>
              <p>
                YOUR CONSENT AT REGISTRATION AND CONTINUED USE OF THE BOKAFIX
                PLATFORM CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO ALL OF
                THE TERMS AND CONDITIONS IN THESE TERMS AND THE PRIVACY POLICY
                FOR THE COUNTRY IN WHICH THE FIXER SERVICES ARE PERFORMED, AS
                WELL AS ANY FUTURE AMENDMENTS AND ADDITIONS TO THESE TERMS
                AND/OR PRIVACY POLICY WE MAY PUBLISH FROM TIME TO TIME. IF ANY
                FUTURE CHANGES TO THESE TERMS AND/OR PRIVACY POLICY ARE
                UNACCEPTABLE TO YOU OR CAUSE YOU TO NO LONGER BE IN COMPLIANCE
                WITH THESE TERMS OR PRIVACY POLICY YOU MUST DEACTIVATE YOUR
                ACCOUNT AND STOP USING THE BOKAFIX PLATFORM.
              </p>
              <p>
                IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, THE SERVICE
                T&amp;C AND/OR THE PRIVACY POLICY AND ABIDE BY ITS TERMS, YOU
                MAY NOT USE OR ACCESS THE BOKAFIX PLATFORM.
              </p>
              <p>
                THESE TERMS DO NOT CREATE AN EMPLOYMENT RELATIONSHIP WHATSOEVER
                BETWEEN YOU AND BOKAFIX.
              </p>
              <p>
                BY AGREEING TO THESE TERMS, YOU ALSO AGREE TO COMPLY WITH OUR
                PRIVACY POLICY.
              </p>
            </article>
          </section>
          <section>
            <article>
              <h5>1. ABOUT US</h5>
              <p>
                We are Bokafix AB a private limited liability company
                established in Sweden registered under the company number
                559317-4278 and having its offices at Harrvägen 8a, 181 30
                Lidingö. You can contact us at info@bokafix.com.
              </p>
              <p>
                Bokafix operates as an online marketplace that connects
                Customers with Fixers who wish to perform a variety of Fixer
                Services. Bokafix does not perform Fixer Services and does not
                employ people to perform Fixer Services. Fixers operate as
                independent contractors and are customarily engaged in an
                independently established business of the same nature as that
                involved in the services performed for Customers through the
                Bokafix Platform. Bokafix does not control or direct the Fixers'
                performance of their services or set their work locations, work
                hours, or terms of work. Fixers provide services under their own
                name or business name, and not under Bokafix's name. Fixers
                provide their own tools and supplies to perform their services;
                Bokafix does not provide the tools or supplies. Fixers are free
                to maintain a clientele without any restrictions from Bokafix
                and are free to offer and provide their services elsewhere,
                including through competing platforms. Fixers are free to accept
                or reject Customers and contracts. Fixers are not penalized for
                rejecting Customers or contracts, though if Fixer accept a
                Customer or contract through the Bokafix Platform, they are
                expected to fulfill their contractual obligations to their
                Customer. Fixers set their own rates for services performed in
                the Bokafix general marketplace.
              </p>
            </article>
          </section>
          <section className="definitions">
            <article>
              <h5>2. DEFINITIONS</h5>
              <ul>
                <li className="justifyContent">
                  <b className="width40">Apps</b>
                  <p className="width60">
                    means the Bokafix application(s) for Customer and for Fixers
                    available to download on Google Play and App Store;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Affiliates</b>
                  <p className="width60">
                    means Bokafix, its parents, affiliates or licensors,
                    including their respective directors, officers,
                    shareholders, agents, investors, subsidiaries, attorneys,
                    representatives, insurers, employees successors and assigns;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Background Check</b>
                  <p className="width60">
                    means the review process before a Fixer can register on the
                    Apps and/or Website as set out in clause 6 ;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Bokafix Commission Fee</b>
                  <p className="width60">
                    means the fee Bokafix charge you for each completed and paid
                    Fixer Service which is currently set at 11% of the Fixer
                    Payment and Out of Pocket Expenses and 5% of the Material
                    Payment;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Bokafix Platform</b>
                  <p className="width60">
                    means the Bokafix Website, Bokafix Apps and related
                    services, information and communications;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Bokafix</b>
                  <p className="width60">
                    means Bokafix AB, a private limited liability company
                    incorporated and registered in Sweden with company number
                    559317-4278, whose registered office is at Harrvägen 8a, 181
                    30 Lidingö (“we” or “us”);
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Bokafix Names, Marks or Works</b>
                  <p className="width60">
                    means Bokafix's or its licensor's company names, logos,
                    products or service names, trademarks, service marks, trade
                    dress, other indicia of ownership, or copyrights;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Confidential Information</b>
                  <p className="width60">
                    means any and all of Bokafix's trade secrets, confidential
                    and proprietary information, and all other information and
                    data of Bokafix that is not generally known to the public or
                    other third parties who could derive value, economic or
                    otherwise, from its use or disclosure. Confidential
                    Information shall be deemed to include technical data,
                    know-how, research, product plans, products, services,
                    customers, markets, software, developments, inventions,
                    processes, formulas, technology, designs, drawings,
                    engineering, hardware configuration information, marketing,
                    finances, strategic and other Proprietary Materials and
                    confidential information relating to Bokafix or Bokafix's
                    business, operations or properties, including information
                    about Bokafix's staff, Fixers, Customer, or partners, or
                    other business information disclosed or obtained directly or
                    indirectly in writing, orally or by drawings or observation;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Customer</b>
                  <p className="width60">
                    means an individual or company seeking to obtain Fixer
                    Services from Fixers using the Bokafix Platform;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Fixer</b>
                  <p className="width60">
                    means an independent third party contractors including it's
                    employees and/or assistants which include, but are not
                    limited to, sole traders and companies seeking to perform
                    Fixer Services for Customers and registered on the Bokafix
                    Platform, (“you” or “your”);
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Fixer Payment</b>
                  <p className="width60">
                    means the pricing terms of the Fixer Service provided by the
                    Fixer and agreed upon by you and the Customer;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Fixer Service(s)</b>
                  <p className="width60">
                    means a short-term service of handyman services, such as
                    electrical, plumbing, decorating, carpentry, gas and boiler
                    services, provided by a Fixer to a Customer. These are made
                    available through your use of the Bokafix Platform;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Notices</b>
                  <p className="width60">
                    means agreements, notices, disclosures and other
                    communications to which these Terms refers;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Material Payment</b>
                  <p className="width60">
                    means any expenses for materials needed in order to carry
                    out the Fixer Service;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Order Details</b>
                  <p className="width60">
                    means the order details on the Bokafix Platform detailing
                    information about you, the Fixer Services you will provide
                    to the Customer, including but not limited to the Start Time
                    and your hourly rate;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Out of Pocket Expense</b>
                  <p className="width60">
                    means any out of pocket expenses agreed upon by you and the
                    Customer and submitted by the Fixer in connection with the
                    Fixer Service;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Privacy Policy</b>
                  <p className="width60">
                    means Bokafix Privacy policy available here
                    https://bokafix.com/privacy-policy;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">PSP</b>
                  <p className="width60">
                    means a third party service provider approved by Bokafix for
                    making and receiving payments for Fixer Services and other
                    payments related to the Bokafix Platform;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">PSP Services Agreement</b>
                  <p className="width60">means as set out in clause 9.8 ;</p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Public Areas</b>
                  <p className="width60">
                    means profiles, email systems, blogs, message boards,
                    reviews, ratings, postings, chat areas, news groups, forums,
                    communities and/or other message or communication facilities
                    on Bokafix Platform that allow users to communicate with
                    other users;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Rating</b>
                  <p className="width60">
                    means reviews and/or rating of Customers and/or Fixers as
                    described in clause 13.3;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Service Contract</b>
                  <p className="width60">
                    means the Fixer Service Contract, which is an agreement
                    between the Customer and a Fixer regarding Fixer Services,
                    as detailed in clause 5;
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Terms</b>
                  <p className="width60">means these Terms and Conditions;</p>
                </li>
                <li className="justifyContent">
                  <b className="width40">User Generated Content</b>
                  <p className="width60">
                    means any information and materials you provide to Bokafix,
                    its agents, Affiliates, and corporate partners, Customers or
                    other use in connection with your registration for and use
                    of the Bokafix Platform including without limitation the
                    information and materials posted or transmitted for use in
                    Public Areas.
                  </p>
                </li>
                <li className="justifyContent">
                  <b className="width40">Website</b>
                  <p className="width60">
                    means Bokafix's website www.bokafix.com.
                  </p>
                </li>
              </ul>
            </article>
          </section>
          <section className="overview">
            <article>
              <h5>3. Overview</h5>
              <ol>
                <li>
                  <p>
                    These Terms cover your access and use of the Bokafix
                    Platform.
                  </p>
                </li>
                <li>
                  <p>
                    You must read carefully and agree to the Terms prior to
                    accessing and using Bokafix Platform. These Terms expressly
                    supersede prior agreements or arrangements about the Bokafix
                    Platform between you and Bokafix.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms apply to the extent they do not conflict with
                    any user and supplemental terms that specifically apply to
                    Bokafix Platform. You will be asked to agree to any such
                    terms separately.
                  </p>
                </li>
                <li>
                  <p>
                    By using Bokafix Platform, you confirm that you accept these
                    Terms. If you do not agree to these Terms, you may not
                    access or use Bokafix Platform. We recommend that you keep a
                    copy of these Terms for future reference.
                  </p>
                </li>
                <li>
                  <p>
                    If we have to contact you, we will do so by push
                    notification from the App and/or Website, phone call, text
                    message or email, using the details you provided to us on
                    registration for the Apps and contain in the account details
                    section of the Apps and/or Website. When we use the words
                    'writing' or 'written' in these terms, this includes emails,
                    texts and push notifications.
                  </p>
                </li>
                <li>
                  <p>
                    Unless otherwise agreed separately in writing, the Bokafix
                    Platform are made available to you for your use in Sweden,
                    Ireland and the United Kingdom only.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="fixer-services">
            <article>
              <h5>4. FIXER SERVICES</h5>
              <ol>
                <li>
                  <p>
                    Bokafix does not provide Fixer Services. Bokafix enables you
                    to connect with Customers who request Fixer Services, and
                    you have the opportunity to offer Customers Fixer Services.
                    You are an independent third-party contractors, registered
                    businesses or other legal entity who are not employed by or
                    acting on behalf of Bokafix.
                  </p>
                </li>
                <li>
                  <p>
                    Before carrying out any Fixer Service for a Customer you
                    agree to the Service Contract, including the Order Details..
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix does not supervise, scope direct, control, or
                    monitor your work and the Fixer Services performed.
                  </p>
                </li>
                <li>
                  <p>
                    When you are logged in, online and in the service area of a
                    Customer you will be given information in the Apps and/or
                    Website about the Fixer Services requested from a Customer
                    before you decide to make a request. The Customer will also
                    be given identifying information about you, including your
                    first name, company information (if any), photo, location
                    and other related information.
                  </p>
                </li>
                <li>
                  <p>
                    You are free to decide to accept, reject or ignore a
                    request. Acceptance is communicated to you through the Apps
                    and/or Website. When you have accepted a request, it is up
                    to the Customer to accept you to carry out the Fixer
                    Service. If the Customer approves your acceptance to carry
                    out the Fixer Service, this will give rise to a direct
                    contract between you and the Customer, consisting of the
                    Service Contract.
                  </p>
                </li>
                <li>
                  <p>
                    If you agree on the terms of a Fixer Service with a
                    Customer, you and such Customer form a Service Contract
                    directly between the two of you as set forth in more detail
                    in clause 5 below.
                  </p>
                </li>
                <li>
                  <p>
                    After a Fixer Service is completed, you will be asked to
                    provide a Rating on the Customer, who may also give you a
                    Rating. Although your Rating by itself will not make you
                    lose access to Bokafix Platform, Customer will see your
                    Rating in the Apps and/or Website and this Rating may affect
                    whether or not they decide to accept you to carry out the
                    Fixer Service requested.
                  </p>
                </li>
                <li>
                  <p>
                    You are responsible for any obligations that may arise from
                    the provision of the Fixer Services.
                  </p>
                </li>
                <li>
                  <p>
                    You have full and sole responsibility for the acts and
                    omissions of your employees and/or assistants and it is your
                    responsibility that each employee and/or assistant follow
                    these Terms. You are also fully responsible for the lawful
                    payment of all compensation, benefits and expenses of
                    employee or assistant, if any, and for all required and
                    applicable tax withholdings as to such employee and/or
                    assistant.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="service-contract">
            <article>
              <h5>5. SERVICE CONTRACT</h5>
              <ol>
                <li>
                  <p>
                    You acknowledge and agree that a legally binding contract
                    with a Customer is formed when you and that Customer have
                    accepted the Service Contract on the Bokafix Platform.
                  </p>
                </li>
                <li>
                  <p>The Service Contract include:</p>
                  <ol>
                    <li>
                      <p>
                        the Order Details proposed and accepted in the Apps
                        and/or Website;
                      </p>
                    </li>
                    <li>
                      <p>
                        the terms and conditions of the Service Contract, which
                        you must review and accept before you accept any Fixer
                        Services in the Bokafix Platform.; and{' '}
                      </p>
                    </li>
                    <li>
                      <p>
                        any other contractual terms accepted by you and the
                        Customer to the extent such terms do not conflict with
                        the terms in these Terms or the Service Terms and do not
                        expand Bokafix's obligations or restrict Bokafix rights
                        under these Terms.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Subject to Bokafix approval, you cannot make amendments or
                    changes to the Service Contract..
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix is not a party to any Service Contract and the
                    formation of a Service Contract will not create an
                    employment or other service relationship between Bokafix and
                    you, nor will it create an employment relationship between
                    Bokafix or the Customer. Neither you or the Customer have
                    authority to enter into written or oral, whether implied or
                    express, contracts on behalf of Bokafix.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="background-checks">
            <article>
              <h5>6. BACKGROUND CHECKS</h5>
              <ol>
                <li>
                  <p>
                    All Fixers' must provide certain documents before they can
                    register on the Bokafix Platform which include, but is not
                    limited to, qualifications documents, using third party
                    services as appropriate (“Background Checks”).
                  </p>
                </li>
                <li>
                  <p>
                    The Background Checks are provided by third parties and
                    Bokafix cannot and does not assume any responsibility for
                    the accuracy or reliability of Background Check information.
                    Please note that Customers are not subject to Background
                    Checks and hence Bokafix cannot confirm that a Customer is
                    who they claim to be.
                  </p>
                </li>
                <li>
                  <p>
                    Please also note that the PSP may provide additional checks
                    on you in order for you to register with them. If you do not
                    pass the applicable checks asked by the PSP, you will not be
                    able to register with Bokafix.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="insurance">
            <article>
              <h5>7. INSURANCE</h5>
              <ol>
                <li>
                  <p>
                    You must have an adequate and valid insurance that covers
                    the Fixer Service you carry out at all times during your use
                    of the Bokafix Platform and everytime you carry out any
                    Fixer Service.
                  </p>
                </li>
                <li>
                  <p>
                    If your insurance changes, expires, terminates or if your
                    insurance is not adequate for the Fixer Services you carry
                    out for any reasons, you are under no circumstances allowed
                    to book or carry out any Fixer Services or use our Platform.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="privacy-policy">
            <article>
              <h5>8. PRIVACY POLICY</h5>
              <p>
                Bokafix collects, uses and discloses information from or about
                you as described in Bokafix Privacy Policy. You acknowledge that
                by accepting these Terms and by using the Apps and/or Website
                you have reviewed and accepted the Privacy Policy. Additional
                privacy notices may apply, including from third party
                controllers for Fixer Services.
              </p>
            </article>
          </section>
          <section className="charges">
            <article>
              <h5>9. CHARGES AND PAYMENTS</h5>
              <h6>Charges</h6>
              <ol>
                <li>
                  <p>
                    You enter into contract with Customers directly and Bokafix
                    will not be a party to any contract for Fixer Services. The
                    Customer is responsible for payment for all Fixer Services
                    they book through the Bokafix Platform. Such payment must be
                    made via the PSP. Payments made or received outside of the
                    Bokafix Platform is not allowed and these Term will not be
                    applicable to Fixer Service paid partly or in full outside
                    the Bokafix Platform. Bokafix is not obliged to compensate
                    you for the Customers' failure to pay for the Fixer
                    Services.
                  </p>
                </li>
                <li>
                  <p>Payment for Fixer Services must be made via the PSP.</p>
                </li>
                <li>
                  <p>
                    The Customer will be responsible for paying the Invoice(s)
                    for each Fixer Service as detailed in the Service Contract.
                  </p>
                </li>
                <li>
                  <p>You will be responsible for paying;</p>
                  <ol>
                    <li>
                      <p>Bokafix Commission Fee for each Fixer Service;</p>
                    </li>
                    <li>
                      <p>registration fees, if applicable;</p>
                    </li>
                    <li>
                      <p>repayment of erroneous payments;</p>
                    </li>
                    <li>
                      <p>
                        all applicable taxes relating to the Fixer Service and
                      </p>
                    </li>
                    <li>
                      <p>credit card and/or payment processing fee.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    You may be charged supplementary charges, costs and/or
                    fines, for your use or misuse of Fixer Services.
                  </p>
                </li>
                <li>
                  <p>
                    The charges may be modified in the Apps and/or Website from
                    time to time.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix Commission fee is inclusive of applicable taxes
                    including VAT unless specifically stated otherwise.
                  </p>
                </li>
                <li>
                  <p>fixer-terms-and-conditions.charges-payments.charges.p-7</p>
                </li>
              </ol>
              <h6>Payments through PSP</h6>
              <ol>
                <li>
                  <p>
                    You will be required to set up an account with a PSP, which
                    may require any or all of the following: registration with
                    the PSP, consent to the terms of service of the PSP, and
                    completion of a vetting process and/or account validation at
                    the request of the PSP. By accepting these Terms, each Fixer
                    agrees that they have downloaded or printed, and reviewed
                    and agreed to, the PSP Services Agreement (the “PSP Services
                    Agreement” ). Please note that Bokafix is not a party to the
                    PSP Services Agreement and that you, the PSP and any other
                    parties listed in the PSP Services Agreement are the parties
                    to the PSP Services Agreement and that Bokafix has no
                    obligations, responsibility or liability to any Fixer or
                    other party under the PSP Services Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    When Customer receives confirmation through the Bokafix
                    Platform or via email that a Fixer Service has been
                    completed, the Customer automatically authorizes the PSP to
                    process the Invoice(s). Customers' may be billed a one-hour
                    cancellation charge as damages at the Fixer's hourly rate
                    through the PSP if they book a Fixer Service, but cancel it
                    less than 24 hours from the scheduled start time of the
                    Fixer Service.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix reserves the right (but not the obligation) upon
                    request from Customer or Fixer, or upon notice of any
                    potential fraud, unauthorized charges or other misuse of the
                    Bokafix Platform, to place on hold any Fixer Payment, Out of
                    Pocket expenses, Material Payment or refund or provide
                    credits, or arrange for the PSP to do so.
                  </p>
                </li>
                <li>
                  <p>
                    Your payout from the PSP may take 7-14 days to reach your
                    account.
                  </p>
                </li>
                <li>
                  <p>
                    For more information about charges and supplementary
                    charges, fees, costs and/or fines specific to the relevant
                    Fixer Service(s) please consult the support section in
                    Bokafix's Apps and/or Website.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="when-to-charge">
            <article>
              <h5>When to Charge a Customer</h5>
              <p>
                Unless otherwise agreed, all charges due and invoices should be
                accurately sent via the Bokafix Platform to the Customer
                immediately when a Fixer Service is completed. For the avoidance
                of doubt, if a Fixer Service takes three days to complete,
                invoices should be sent directly when the Fixer Service is
                completed in full, i.e. after the third day. Payment will be
                facilitated by a PSP using the preferred payment method
                associated with the Customer's account, after which Bokafix will
                send the Customer a receipt by email. If the Customer's primary
                payment method is not able to be charged, PSP may use a
                secondary payment method if available. If the Customer's payment
                method(s) are unavailable, PSP and Bokafix reserve the right to
                continue to try to take payment.
              </p>
            </article>
          </section>
          <section className="cancel">
            <article>
              <h5>10. CANCELLATION OF FIXER SERVICES</h5>
              <ol>
                <li>
                  <p>
                    Rules on cancellation of a Fixer Service can be found in the
                    Service Contract.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix may refuse requests and cancel the Fixer Services if
                    there is reasonable doubt about the correctness or
                    authenticity of the request and or approval or about the
                    contact information. We may also end any contract in place
                    between you and Bokafix.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="terminations">
            <article>
              <h5>11. TERMINATIONS</h5>
              <ol>
                <li>
                  <p>
                    You are free at all times to use the Bokafix Platform (where
                    and when available) and may terminate these Terms and the
                    Bokafix Platform by closing your account, providing all
                    outstanding balances are paid and/or scheduled and agreed
                    Fixer Services have been carried out.
                  </p>
                </li>
                <li>
                  <p>
                    We may terminate these Terms and your access to the Bokafix
                    Platform with immediate effect if we conclude that there is
                    a breach by you of these Terms or any other agreement
                    between you and Bokafix.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix may, in its sole discretion, terminate these Terms
                    or discontinue the Bokafix Platform at any time by giving
                    you reasonable advance notice in writing.
                  </p>
                </li>
                <li>
                  <p>
                    If we cannot charge the relevant charges to your preferred
                    payment method, we may suspend or remove your access to the
                    Bokafix Platform until payment is made.
                  </p>
                </li>
                <li>
                  <p>
                    You must immediately pay any outstanding charges due to
                    Bokafix by you upon termination. Any such charges will
                    survive termination and we reserve all rights to collect
                    payment after termination.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="bokafix-platform">
            <article>
              <h5>12. BOKAFIX PLATFORM</h5>
              <h6>Your access to and use of the Bokafix Platform</h6>
              <ol>
                <li>
                  <p>
                    By accessing and using the Bokafix Platform you accept these
                    Terms.
                  </p>
                </li>
                <li>
                  <p>
                    The Apps and/or Website can be downloaded or accessed on
                    most modern mobile devices with an internet connection and
                    equipped with popular operating systems such as Android or
                    iOS.
                  </p>
                </li>
                <li>
                  <p>
                    You are responsible for obtaining the network access
                    necessary to use the Apps and/or Website and are responsible
                    for any rates and fees from your mobile network provider,
                    including from data consumption while using the Apps and/or
                    Website. You are also responsible for obtaining compatible
                    devices and operating systems necessary to use the Apps
                    and/or Website, including any software or hardware updates.
                  </p>
                </li>
                <li>
                  <p>
                    We provide the Bokafix Platform “as is” and “as available.”
                    The Apps and/or Website, or any related digital content, may
                    be subject to limitations, delays, and/or other problems
                    inherent to the use of the internet and electronic
                    communications and are not guaranteed to be available,
                    uninterrupted or error free at all times. Bokafix may
                    suspend or withdraw or restrict the availability of all or
                    any part of the Bokafix Platform for business and
                    operational reasons. Bokafix will try to give you reasonable
                    notice of any suspension or withdrawal.
                  </p>
                </li>
                <li>
                  <p>
                    To use the Bokafix Platform in Sweden, Ireland and the
                    United Kingdom, you must register in the Apps and/or Website
                    and set up an account and you must be eighteen (18) years
                    old or older.
                  </p>
                </li>
                <li>
                  <p>
                    You must provide us with certain personal information to
                    register and set up your account, including your name,
                    personal number (if applicable), phone number, address,
                    qualification information (if applicable) and email address
                    and if you are providing Fixer Services through a company,
                    company details such as company registration number and
                    other related information, You may need to provide
                    additional information and documents before offering certain
                    Fixer Services.
                  </p>
                </li>
                <li>
                  <p>
                    You are under no obligation to log in to, or use, Bokafix's
                    Apps and/or Website. If you choose to stop using Bokafix's
                    Apps and/or Website you may do so without giving us any
                    notice. You further agree that Customers are under no
                    obligation to log in to, or use, Bokafix's Apps and/or
                    Website.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix may temporarily restrict your access to and use of
                    the Bokafix Platform if there is a suspected breach of your
                    obligations (see clause 13 below) or otherwise of these
                    Terms, including where we receive a complaint in relation to
                    fraud. There may be circumstances in which we are unable to
                    provide you with information about the complaint whilst an
                    investigation is ongoing (by us and/or a third party such as
                    the police).
                  </p>
                </li>
              </ol>
              <h6>Your Obligations when using the Bokafix Platform</h6>
              <ol>
                <li>
                  <p>
                    When using the Bokafix Platform, you must comply with all
                    applicable laws. You may only use the Bokafix Platform for
                    lawful purposes and for the purposes for which they were
                    intended as otherwise referenced in these Terms. You must
                    not misuse Bokafix's Apps and/or Website or attempt to
                    defraud either us or Customers.
                  </p>
                </li>
                <li>
                  <p>
                    You must provide accurate and complete information when you
                    register an account and when using the Bokafix Platform.
                  </p>
                </li>
                <li>
                  <p>
                    Your account cannot be licensed or shared unless expressly
                    permitted by Bokafix. Once you have set up an account, you;
                  </p>
                  <ol>
                    <li style={{ fontStyle: 'italic' }}>
                      <p>
                        When using the Bokafix Platform, you must comply with
                        all applicable laws. You may only use the Bokafix
                        Platform for lawful purposes and for the purposes for
                        which they were intended as otherwise referenced in
                        these Terms. You must not misuse Bokafix's Apps and/or
                        Website or attempt to defraud either us or Customers.
                      </p>
                    </li>
                    <li style={{ fontStyle: 'italic' }}>
                      <p>
                        You must provide accurate and complete information when
                        you register an account and when using the Bokafix
                        Platform.
                      </p>
                    </li>
                    <li style={{ fontStyle: 'italic' }}>
                      <p>
                        Your account cannot be licensed or shared unless
                        expressly permitted by Bokafix. Once you have set up an
                        account, you;
                      </p>
                    </li>
                    <li style={{ fontStyle: 'italic' }}>
                      <p>
                        You will be responsible for paying any fees or charges
                        that may be incurred as a result of the provision of
                        Fixer Services requested through your account.
                      </p>
                    </li>
                    <li style={{ fontStyle: 'italic' }}>
                      <p>
                        You must not, in your use of the Bokafix Platform, cause
                        any nuisance, annoyance, inconvenience, or property
                        damage, whether to the Customers, Bokafix or any other
                        party.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    You will be responsible for paying any fees or charges that
                    may be incurred as a result of the provision of Fixer
                    Services requested through your account.
                  </p>
                </li>
                <li>
                  <p>
                    You must not, in your use of the Bokafix Platform, cause any
                    nuisance, annoyance, inconvenience, or property damage,
                    whether to the Customers, Bokafix or any other party.
                  </p>
                </li>
                <li>
                  <p>
                    We reserve the right to introduce a fee, and we will inform
                    you, for specific features on the Apps and/or Website, in
                    which case you can decide if you wish to proceed. These may
                    be subject to separate terms, which Bokafix will make
                    available to you at the time.
                  </p>
                </li>
              </ol>
              <h6>What if there's a problem with my Bokafix Platform?</h6>
              <ol>
                <li>
                  <p>
                    If there is a problem with the Bokafix Platform, or if you
                    have a complaint, please contact us on the details set out
                    in the “About Us” section above.
                  </p>
                </li>
              </ol>
              <h6>
                Do not rely on information made available to you on the Bokafix
                Platform.
              </h6>
              <ol>
                <li>
                  <p>
                    Although Bokafix makes reasonable efforts to update the
                    information provided through the Bokafix Platform, Bokafix
                    makes no representations, warranties, or guarantees, whether
                    express or implied, that the content on the Apps and/or
                    Website is accurate, complete, or up to date.
                  </p>
                </li>
              </ol>
              <h6>Ownership of and rights in the Bokafix Platform</h6>
              <ol>
                <li>
                  <p>
                    Bokafix reserves all rights not expressly granted in these
                    Terms. The Bokafix Platform, Bokafix's devices and all data
                    gathered through the Apps and/or Website (including all
                    intellectual property rights in all of the foregoing) are
                    and remain Bokafix's property.
                  </p>
                </li>
                <li>
                  <p>
                    You may not, and may not allow any other party to: (a)
                    license, sublicense, copy, modify, distribute, create, sell,
                    resell, transfer, or lease any part of Bokafix's Apps and/or
                    Website; (b) reverse engineer or attempt to extract the
                    source code of the Apps and/or Website except as allowed
                    under law; (c) launch or cause to launch any programs or
                    scripts for the purpose of scraping, indexing, surveying, or
                    otherwise data mining any part of the Apps and/or Website or
                    data; (d) use, display, or manipulate any of Bokafix Names,
                    Marks, or Works for any purpose other than to use the
                    Bokafix Platform; (e) create or register any (i) businesses,
                    (ii) URLs, (iii) domain names, (iv) software application
                    names or titles, or (v) social media handles or profiles
                    that include Bokafix Names, Marks, or Works or any
                    confusingly or substantially similar mark, name, title, or
                    work; (f) use Bokafix Names, Marks, or Works as your social
                    media profile picture or wallpaper; (g) purchase keywords
                    (including, but not limited to Google AdWords) that contain
                    any Bokafix Names, Marks, or Works; or (h) apply to
                    register, reference, use, copy, and/or claim ownership in
                    Bokafix's Names, Marks, or Works, or in any confusingly or
                    substantially similar name, mark, title, or work, in any
                    manner for any purposes, alone or in combination with other
                    letters, punctuation, words, symbols, designs, and/or any
                    creative works.
                  </p>
                </li>
                <li>
                  <p>
                    Subject to your compliance with these Terms, we grant you a
                    personal, non-exclusive, non-transferable, revocable,
                    non-sublicensable license to install and/or make use of the
                    Apps and/or Website on your device solely for your use and
                    for you to access and use information made available through
                    the Apps and/or Website. Any rights not expressly granted
                    herein are reserved by Bokafix. This license will terminate
                    when these Terms are terminated in accordance with clause
                    11.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="user-generated-content">
            <article>
              <h5>13. USER GENERATED CONTENT</h5>
              <ol>
                <li>
                  <p>
                    You are solely responsible for User Generated Content, and
                    we act merely as a passive conduit for your online
                    distribution and publication of your User Generated Content.
                    You acknowledge and agree that Bokafix is not involved in
                    the creation, development of User Generated Content,
                    disclaims any responsibility for User Generated Content, and
                    cannot be liable for claims arising out of or relating to
                    User Generated Content. Further, you acknowledge and agree
                    that Bokafix has no obligation to monitor or review User
                    Generated Content, but reserves the right to limit or remove
                    User Generated Content if it is not compliant with the terms
                    of these Terms.
                  </p>
                </li>
                <li>
                  <p>
                    You hereby represent and warrant to Bokafix that your User
                    Generated Content (a) will not be false, inaccurate,
                    incomplete or misleading; (b) will not be fraudulent or
                    involve the transfer or sale of illegal, counterfeit or
                    stolen items; (c) will not infringe on any third party's
                    privacy, or copyright, patent, trademark, trade secret or
                    other proprietary right or rights of publicity or
                    personality; (d) will not violate any law, statute,
                    ordinance, code, or regulation (including without limitation
                    those governing export control, consumer protection, unfair
                    competition, anti-discrimination, incitement of hatred or
                    false or misleading advertising, anti-spam or privacy); (e)
                    will not be defamatory, libellous, malicious, threatening,
                    or harassing; (f) will not be obscene or contain pornography
                    (including but not limited to child pornography) or be
                    harmful to minors; (g) will not contain any viruses, scripts
                    such as Trojan Horses, worms, time bombs, corrupt files,
                    cancelbots or other computer programming routines that are
                    intended to damage, detrimentally interfere with,
                    surreptitiously intercept or expropriate any system, data or
                    personal information; (h) will not claim or suggest in any
                    way that you are employed or directly engaged by or
                    affiliated with Bokafix or otherwise purport to act as a
                    representative or agent of Bokafix; and (i) will not create
                    liability for Bokafix or cause Bokafix to lose (in whole or
                    in part) the services of its Internet Service Providers
                    (ISPs) or other partners or suppliers.
                  </p>
                </li>
                <li>
                  <p>
                    The Bokafix Platform hosts User Generated Content relating
                    to ratings of specific Fixers and Customers ( “Rating” ).
                    Rating is such Fixer's or Customer's opinion and not the
                    opinion of Bokafix, and has not been verified or approved by
                    Bokafix. You agree that Bokafix is not responsible or liable
                    for any Rating or other User Generated Content. Bokafix
                    encourages Customer and/or Fixer to give objective,
                    constructive and honest Ratings about the other party with
                    whom they have transacted. Bokafix is not obligated to
                    investigate any remarks posted by Fixers or Customers for
                    accuracy or reliability or to consider any statements or
                    materials posted or submitted by a Customer or Fixer about
                    any Rating but may do so at its discretion. You agree that
                    Rating enables Fixers and/or Customers to post and other
                    Fixers and Customers to read about their experiences and
                    that you will not complain or take any action merely because
                    you happen to disagree with such Rating. You may request
                    removal of a Rating that violates these Terms by contacting
                    the Support team at upport@bokafix.com. Each Customer should
                    undertake their own research prior to booking to be
                    satisfied that a specific Fixer has the right qualifications
                    for a Fixer Service.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix respects the personal and other rights of others,
                    and expects you to do the same. Bokafix is entitled to
                    identify a Fixer or Customer to other Fixer and/or Customers
                    or to third parties who claim that their rights have been
                    infringed by User Generated Content submitted by that Fixer
                    or Customer, so that they may attempt to resolve the claim
                    directly.
                  </p>
                </li>
                <li>
                  <p>
                    If a user believes, in good faith, that any User Generated
                    Content provided on or in connection with the Bokafix
                    Platform is objectionable or infringes any of its rights or
                    the rights of others (e.g. counterfeiting, insult, invasion
                    of privacy), the Fixer or Customer is encouraged to notify
                    Bokafix. If a Fixer or Customer discovers that User
                    Generated Content promotes crimes against humanity, incites
                    hatred and/or violence, or concerns child pornography, the
                    Fixer or Customer must notify Bokafix. Such notification can
                    be made at support@bokafix.com.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="links">
            <article>
              <h5>14. LINKS TO THIRD PARTY WEBSITES</h5>
              <ol>
                <li>
                  <p>
                    The Bokafix Platform may contain links (such as hyperlinks)
                    to third-party websites. Such links do not constitute
                    endorsement by Bokafix or association with those websites,
                    their content or their operators. Such links (including
                    without limitation external websites that are framed by the
                    Bokafix Platform as well as any advertisements displayed in
                    connection therewith) are provided as an information
                    service, for reference and convenience only. Bokafix does
                    not control any such websites, and is not responsible for
                    their availability, accuracy, content, advertising,
                    products, or services. It is your responsibility to evaluate
                    the content and usefulness of the information obtained from
                    other websites. You acknowledge and agree that Bokafix is
                    not involved in the creation or development of third-party
                    websites and disclaims any responsibility for third-party
                    websites, and cannot be liable for claims arising out of or
                    relating to third-party websites. Further, you acknowledge
                    and agree that Bokafix has no obligation to monitor, review,
                    or remove links to third-party websites, but reserves the
                    right to limit or remove links to third-party websites on
                    the Bokafix Platform at its sole discretion.
                  </p>
                </li>
                <li>
                  <p>
                    The use of any website controlled, owned or operated by a
                    third party is governed by the terms and conditions of use
                    and privacy policy for that website. You access such
                    third-party websites at your own risk. Bokafix expressly
                    disclaims any liability arising in connection with your use
                    and/or viewing of any websites or other material associated
                    with links that may appear on the Bokafix Platform. You
                    hereby agree to hold Bokafix harmless from any liability
                    that may result from the use of links that may appear on the
                    Bokafix Platform.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="confidential">
            <article>
              <h5>15. CONFIDENTIAL INFORMATION</h5>
              <p>
                You acknowledge that Confidential Information is a valuable,
                special and unique asset of Bokafix and agree that you will not,
                for the lifetime of your account on Bokafix plus 10 years
                thereafter, disclose, transfer, or use (or seek to induce others
                to disclose, transfer or use) any Confidential Information for
                any purpose other than use of the Bokafix Platform in accordance
                with these Terms. If relevant, you may disclose the Confidential
                Information to your authorized employees and agents provided
                that they are also bound to maintain the confidentiality of
                Confidential Information. You shall use best efforts to protect
                Confidential Information from unauthorized disclosure, transfer
                or use. You shall promptly notify Bokafix in writing of any
                circumstances that may constitute unauthorized disclosure,
                transfer, or use of Confidential Information. You shall return
                all originals and any copies of any and all materials containing
                Confidential Information to Bokafix promptly upon deactivation
                of your account or termination of these Terms for any reason
                whatsoever.
              </p>
            </article>
          </section>
          <section className="liability">
            <article>
              <h5>16.YOUR LIABILITY AND INDEMNITY</h5>
              <ol>
                <li>
                  <p>
                    You are liable for any damage suffered by us as a result of
                    your violation of these Terms, your misuse of the Bokafix
                    Platform or your violation of any laws or third party
                    rights. You are liable for all activities conducted through
                    your account unless such activities are not authorised by
                    you and you are not otherwise negligent.
                  </p>
                </li>
                <li>
                  <p>
                    In order to have access to the Bokafix Platform, you agree
                    to indemnify, defend (at our option) and hold us and our
                    respective officers, directors, and employees harmless from
                    and against all claims, liabilities, expenses, damages,
                    penalties, fines, social security contributions and taxes
                    arising out of or related to a breach of these Terms, breach
                    of applicable law or third party claims directly or
                    indirectly related to your use of the Fixer Services
                    generally.
                  </p>
                </li>
              </ol>
            </article>
            <article>
              <h5>17. LIMITATION OF LIABILITY</h5>
              <ol>
                <li>
                  <p>
                    Nothing in these Terms limits or excludes any liability
                    which cannot legally be limited or excluded, including
                    liability for death or personal injury caused by negligence
                    and liability for fraud or fraudulent misrepresentation or
                    alters your rights as a consumer that cannot be excluded
                    under applicable law.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix and its Affiliates are not liable under or in
                    relation to these Terms including, but not limited to,
                    liability in contract, tort (including negligence,
                    misrepresentation), breach of statutory duty, restitution or
                    otherwise for any of the following arising out of or in
                    connection with the use of the Bokafix Platform made
                    available through the Bokafix Platform: (i) loss of profits;
                    (ii) loss of sales or business; (iii) loss of agreements or
                    contracts; (iv) loss of anticipated savings; (v) loss of use
                    or corruption of software, data or information; (vi) loss of
                    or damage to goodwill and (vii) indirect or consequential
                    loss.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix, its Affiliates and subcontractors not liable for:
                  </p>
                  <ol>
                    <li>
                      <p>
                        any Booking that is not accepted or is otherwise
                        cancelled by Bokafix.
                      </p>
                    </li>
                    <li>
                      <p>
                        damages or losses arising from any transaction between
                        you and a Customer;
                      </p>
                    </li>
                    <li>
                      <p>
                        the availability and accuracy of the content, products
                        or services requested from a Customer; or
                      </p>
                    </li>
                    <li>
                      <p>
                        delay or failure in performance resulting from causes
                        beyond our reasonable control.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Our total liability to you, arising out of these Terms or
                    their subject matter, or in connection with the provision to
                    you of the Bokafix in contract, tort (including negligence,
                    misrepresentation), breach of statutory duty, restitution or
                    otherwise, shall not exceed five hundred pounds sterling
                    (£500), unless otherwise agreed.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix, and its Affiliate are not liable for business
                    losses. If you use Bokafix Platform for any commercial or
                    business purpose Bokafix will have no liability to you for
                    any loss of profit, loss of business, loss of revenue,
                    business interruption, or loss of business opportunity
                    (whether or not such loss could be reasonably expected to
                    flow from our negligence or breach of these Terms).
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="terms-changes">
            <article>
              <h5>18. CHANGES TO THESE TERMS AND THE BOKAFIX PLATFORM</h5>
              <ol>
                <li>
                  <p>
                    Bokafix may, at its sole and absolute discretion, change,
                    modify, add to, supplement, suspend, discontinue, or delete
                    any of the terms and conditions of these Terms (Privacy
                    Policy,) and review, improve, modify or discontinue,
                    temporarily or permanently, the Bokafix Platform or any
                    content or information through the Bokafix Platform at any
                    time, effective with or without prior notice and without any
                    liability to Bokafix. Bokafix may also impose limits on
                    certain features or restrict your access to part or all of
                    the Bokafix Platform without notice or liability.
                  </p>
                </li>
                <li>
                  <p>
                    Bokafix will endeavor to notify you of material changes to
                    these Terms by email, but will not be liable for any failure
                    to do so. If any future changes to these Terms are
                    unacceptable to you or cause you to no longer be in
                    compliance with these Terms, you must deactivate your
                    account, and immediately stop using the Bokafix Platform.
                    Your continued use of the Bokafix Platform following any
                    revision to these Terms constitutes your complete and
                    irrevocable acceptance of any and all such changes, except
                    where prohibited by any laws or regulations in your
                    jurisdiction.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="general-info">
            <article>
              <h5>19. GENERAL</h5>
              <ol>
                <li>
                  <p>
                    We may assign, subcontract or transfer these Terms or any of
                    our rights or obligations in them, in whole or in part,
                    without your prior consent, provided this will not lead to a
                    reduction of the rights you are entitled to by virtue of
                    these Terms or by law. We will tell you if this happens. You
                    may not assign, subcontract or transfer these Terms or any
                    of our rights or obligations, in whole or in part, as your
                    use of the Bokafix Platform. Bokafix is personal, unless
                    agreed otherwise.
                  </p>
                </li>
                <li>
                  <p>
                    These Terms replace all previous agreements relating to your
                    access and use of the Bokafix Platform. In addition, in the
                    event of any conflict between these Terms and any previous
                    agreements relating to your use of Fixer Services, these
                    Terms shall prevail.
                  </p>
                </li>
                <li>
                  <p>
                    You may be required to accept additional terms to access or
                    use the Bokafix Platform. If there is a conflict between
                    these Terms and the separate agreement, the latter will have
                    precedence unless specified otherwise in the separate
                    agreement.
                  </p>
                </li>
                <li>
                  <p>
                    There are no third party beneficiaries to these Terms except
                    as provided for in these Terms.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="notices">
            <article>
              <h5>20. NOTICES AND CONSENT TO ELECTRONIC SIGNATURES</h5>
              <ol>
                <li>
                  <p>
                    By agreeing to these Term, you consent to receive any
                    Notices electronically, including without limitation by
                    email or by posting Notices on the Apps and/or Website. You
                    agree that all Notices that we provide to you electronically
                    satisfy any legal requirement that such communications be in
                    writing. Unless otherwise specified in these Terms, all
                    Notices under these Terms will be in writing and will be
                    deemed to have been duly given when received, if personally
                    delivered or sent by certified or registered mail, return
                    receipt requested; when receipt is electronically confirmed,
                    if transmitted by facsimile or email; or the day it is shown
                    as delivered by the overnight delivery service's tracking
                    information, if sent for next day delivery by a recognized
                    overnight delivery service.
                  </p>
                </li>
                <li>
                  <p>
                    By using the Bokafix Platform, you agree to transact
                    electronically through the Bokafix Platform. You also agree
                    to the following: (i) your electronic signature is the legal
                    equivalent of your manual signature and has the same legal
                    effect, validity and enforceability as a paper-based
                    signature; (ii) your use of a keypad, mouse or other device
                    to select an item, button, icon or similar act/action,
                    constitutes your signature as if actually signed by you in
                    writing; and (iii) no certification authority or other third
                    party verification is necessary to validate your electronic
                    signature, and the lack of such certification or third party
                    verification will not in any way affect the enforceability
                    of your electronic signature.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="dispute">
            <article>
              <h5>21. DISPUTE</h5>
              <ol>
                <li>
                  <p>
                    In the event you have a dispute with a Customer that you
                    cannot resolve with the Customer directly, we may offer to
                    mediate between you and the Customer. If you would like our
                    help, we would ask you to raise a complaint using the report
                    an issue function in the Apps and/or Website. If we decide
                    to mediate, we will let you know our decision within 4 weeks
                    of the dispute being referred to us. Please note that
                    Bokafix is not a legal mediator and any suggestions made by
                    Bokafix how the dispute should be resolved are not legally
                    binding. If you wish to have a legally binding decision you
                    will have to bring legal proceedings in court. Bokafix does
                    not commit to using an online dispute resolution platform to
                    resolve consumer disputes.
                  </p>
                </li>
                <li>
                  <p>
                    In the event of a dispute with Bokafix, we would ask you to
                    raise a complaint using the report an issue function in the
                    Apps and/or Website. In the unlikely event that we cannot
                    resolve the dispute, we will work with you to discuss a way
                    forward to resolve the dispute, which may include mediation.
                    Bokafix's right to refer the dispute to a court is expressly
                    reserved. Bokafix does not commit to using an online dispute
                    resolution platform to resolve consumer disputes.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section className="law-jurisdiction">
            <article>
              <h5>22. GOVERNING LAW AND JURISDICTION</h5>
              <p>
                For Fixers registered on our UK and Irish Apps and/or Website,
                these Terms shall be governed by and construed in accordance
                with the laws of England and any dispute regarding these Terms
                or the use of the Bokafix Platform will only be dealt with by
                the English courts.
              </p>
              <p>
                For Fixers registered on our Swedish Apps and/ior Website, these
                Terms shall be governed by and construed in accordance with the
                laws of Sweden and any dispute regarding these Terms or the use
                of the Bokafix Platform will only be dealt with by the Swedish
                courts.
              </p>
            </article>
          </section>
        </div>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}
export default FixerTermsConditions

const ItalicList = styled.li`
  font-style: italic;
`

const List = styled.ol.attrs((props) => ({
  listType: (props as any).listType,
  initialCounter: (props as any).initialCounter,
}))`
  & {
    counter-reset: ${(props) =>
      props.initialCounter ? 'counter ' + props.initialCounter : 'counter'};
  }
  & > li > p {
    counter-increment: counter;
    display: flex;
  }
  & > li > p:before {
    ${(props: any) => {
      if (props.listType === 'alphabetical') {
        return (
          'content: ' +
          '"(" ' +
          'counter(counter, lower-alpha)' +
          ' ")"; margin-left: 32px;'
        )
      } else {
        return (
          'content: ' + '"' + props.start + '." ' + 'counter(counter, decimal);'
        )
      }
    }}
    white-space: nowrap;
    margin-right: 10px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 900px) {
    padding: 15px;
  }

  .container {
    max-width: 800px;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 900px) {
      margin: 0;
    }
    @media only screen and (min-width: 900px) {
      padding: 2.5rem;
    }
  }

  b {
    max-width: 260px;
    display: block;
    margin-bottom: 25px;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
    font-style: italic;
    margin-bottom: 25px;
  }
  h1 {
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 5.5rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  p {
    margin-bottom: 25px;
    font-size: ${(props) => props.theme.text.paragraph.fontSize};
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 25px;
  }
  h6 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  li {
    margin-top: 15px;
    list-style: none;
  }
  section {
    margin-top: 2.5rem;
    @media screen and (max-width: 900px) {
      margin-top: 0;
    }
  }
  .bullets {
    margin-top: 25px;
  }
  .bullets p {
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    .bullets-2 {
      margin-bottom: 25px;
    }
  }
  @media only screen and (min-width: 900px) {
    h1 {
      font-family: Avenir-heavy;
    }
    font-family: Avenir;
    p,
    h5,
    h6,
    li {
      line-height: 22px;
    }
    h6 {
      font-family: Avenir-medium;
      font-weight: 600;
    }
  }
  @media only screen and (max-width: 900px) {
    margin-top: 20px;
    p,
    h5,
    h6,
    li {
      font-family: Poppins;
    }
    h5 {
      font-weight: 500;
    }
    h6,
    p,
    li {
      font-weight: 400;
    }
  }

  .button-container {
    width: 250px;
  }
  .width60 {
    width: 60%;
  }
  .width40 {
    width: 40%;
  }
  .justifyContent {
    display: flex;
    justify-content: space-between;
  }
`
