import React from 'react'
import { DesktopModalContainer } from '../styled-components'
import { useDispatch } from 'react-redux'
import { setIsClosed } from '../../../store/slice/ModalSlice'
import CloseBtn from '../../common/CloseBtn'
import styles from './SelectUserType.module.css'
import { useTranslation } from 'react-i18next'
import customBlob from '../../../assets/global/blobs/customerBlob.svg'
import fixerBlob from '../../../assets/global/blobs/fixerBlob.svg'
import { Button } from '../../component-library/index'
import { ISelectTypeProps } from '../../utils/interfaces'

function SelectUserTypeDesktop({
  setUserType,
  userType,
  proceedToRegistration,
  openLogin,
}: ISelectTypeProps) {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(setIsClosed('registrationCustomerModal'))
  const { t } = useTranslation()

  return (
    <DesktopModalContainer style={{ minHeight: 'unset' }}>
      <CloseBtn onClick={closeModal} />
      <div className={styles.wrapper}>
        <h3 className={styles.title}>{t('registration.create')}</h3>
        <div className={styles.haveAccount}>
          <span className={styles.alreadyMember}>
            {t('registration.already-member')}
          </span>
          <a className={styles.login} onClick={openLogin}>
            {t('button.login')}
          </a>
        </div>
        <div className={styles.userTypes}>
          <button
            style={
              userType === 'customer' ? { border: '1px solid #F27649' } : {}
            }
            onClick={() => setUserType('customer')}
            className={styles.button}
          >
            <img className={styles.blob} src={customBlob} alt="customer-blob" />
            <h6 className={styles.userTitle}>{t('button.customer')}</h6>
          </button>
          <button
            style={userType === 'fixer' ? { border: '1px solid #F27649' } : {}}
            onClick={() => setUserType('fixer')}
            className={styles.button}
          >
            <img className={styles.blob} src={fixerBlob} alt="fixer-blob" />
            <h6 className={styles.userTitle}>{t('button.fixer')}</h6>
          </button>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            handleClick={proceedToRegistration}
            fullSize={true}
            name={t('button.continue')}
          />
        </div>
      </div>
    </DesktopModalContainer>
  )
}

export default SelectUserTypeDesktop
