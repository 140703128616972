import styled from 'styled-components'
import { ReactComponent as BokafixCustomLogo } from '../../assets/global/bokafix-logo.svg'
import DownloadLinks from './DownloadLinks'
import { IconLinkList } from '../../constants/LinkList'
import FooterIconLink from './FooterIconLink'

type _Link = {
    icon: string | undefined
    iconAlt: string
    path: string
    space?: boolean
}

const FooterLogo: React.FC = (): JSX.Element => {
    return (
        <Container>
            <BokafixCustomLogo fill="#ffffff" height={120} width={206} />
            <ul>
                {IconLinkList.map((icon: _Link, index: number) => {
                    return <FooterIconLink key={index} links={icon} />
                })}
            </ul>
            <DownloadLinks />
        </Container>
    )
}

export default FooterLogo

const Container = styled.div`
    img {
        display: flex;
        justify-content: start;
    }
    p {
        color: #ffffff;
        margin: 5px;
        text-align: start;
        width: 80%;
    }
    ul {
        display: flex;
        justify-content: start;
        list-style: none;
        @media screen and (max-width: 499px) {
            display: none;
        }
        li {
            margin: 5px;
        }
    }
`
