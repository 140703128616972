import React from 'react'

type Link = {
  icon: string | undefined
  iconAlt: string
  path: string
  space?: boolean
}

interface Props {
  links: Link
}

const FooterIconLink: React.FC<Props> = ({ links }): JSX.Element => {
  return (
    <div>
      <li
        style={{
          marginLeft: `${links.space ? '25px' : '0'}`,
          margin: '5px',
        }}
      >
        <a href={links.path} target="_blank" rel="noopener noreferrer">
          <img src={links.icon} alt={links.iconAlt} height={29} width={29} />
        </a>
      </li>
    </div>
  )
}

export default FooterIconLink
