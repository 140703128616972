import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import myaNotepad from '../assets/global/blobs/mya-notepad-checklist.png'
import ralphLadderCircle from '../assets/global/blobs/ralph-ladder-circle.png'
import myaWaving from '../assets/global/blobs/mya-waving.png'
import paulaHammer45deg from '../assets/global/blobs/paula-hammer-45-deg.png'
import { Button } from '../components/component-library/index'
import { HomePageBackground } from '../components/Background'
import usePageParams from '../utils/usePageParams'
import { Link, useNavigate } from 'react-router-dom'
import { setIsOpen } from '../store/slice/ModalSlice'

const HomePage: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  usePageParams('Bokafix', 'customerPage', 'noDot', 'logo')

  const dispatch = useDispatch()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  let navigate = useNavigate()
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function openRegistrationModal() {
    try{

    useEffect(() => {
      if (!isDesktop) navigate('/sign-up', { replace: true })
    }, [])
  }catch(e){
    console.log('error');
  }

    dispatch(setIsOpen('registrationFixerModal'))
  }

  return (
    <>
      <HomePageBackground />
      <Container>
        <div className="info join">
          <h1>{t('homepage.join-us.title')}</h1>
          <p>{t('homepage.join-us.content')}</p>
          <Button
            fullSize={false}
            color="orange"
            name={t('button.signup')}
            handleClick={openRegistrationModal}
          ></Button>
        </div>
        <div className="info how">
          <h1>{t('homepage.how-it-works.title')}</h1>
          <p>{t('homepage.how-it-works.content')}</p>
          <Link to="/fixer-how-it-works">
            <Button
              fullSize={false}
              color="orange"
              name={t('button.features')}
              handleClick={() => null}
            ></Button>
          </Link>
        </div>
        <div className="info whats">
          <h1>{t('homepage.whats-involved.title')}</h1>
          <p>{t('homepage.whats-involved.content')}</p>
          <Link to="/fixer-information">
            <Button
              fullSize={false}
              color="orange"
              name={t('button.more-info')}
              handleClick={() => {}}
            ></Button>
          </Link>
        </div>
        <div className="image-container ladder-blob">
          <img
            className="blob"
            src={ralphLadderCircle}
            alt="ralph blob with Ladder"
          />
        </div>
        <div className="image-container pointing-blob">
          <img className="blob mya" src={myaWaving} alt="Mya Blob waving" />
        </div>
        <div className="image-container works-blob">
          <img
            className="paula blob"
            src={paulaHammer45deg}
            alt="Paula Blob with Hammer"
          />
        </div>
        <div className="image-container involved-blob">
          <img className="blob" src={myaNotepad} alt="Mya Blob with Notepad" />
        </div>
        <div className="circle-one"></div>
        <div className="circle-two"></div>
        <div className="circle-three"></div>
      </Container>
    </>
  )
}
export default HomePage

const Container = styled.div`
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 1500px;
  display: grid;
  grid-template-columns: repeat(50, 1fr);
  grid-template-rows: repeat(100, 1fr);
  margin-bottom: 200px;
  
  @media only screen and (max-width: 1200px) {
    grid-template-rows: repeat(65, 1fr);

    grid-template-columns: repeat(20, 1fr);
    height: 1000px;
  }

  .info {
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .info h1 {
    font-size: 60px;
    font-family: Avenir-heavy;

    @media only screen and (max-width: 1200px) {
      font-size: 38px;
    }
  }
  .info > p {
    font-family: Avenir-heavy;
    font-size: 20px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1200px) {
      font-size: 14px;
      font-family: Avenir;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  .image-container {
    object-fit: contain;
  }

  .ladder-blob {
    grid-area: 7/1/25/20;
    margin-left: -200px;
    @media only screen and (max-width: 1200px) {
      grid-area: 2/1/40/10;
      margin-left: -170px;
      transform: rotate(8deg);
      .blob {
        height: 300px;
      }
    }
  }
  .pointing-blob {
    position: absolute;
    top: 400px;
    left: 400px;
    @media only screen and (max-width: 1200px) {
      justify-self: end;
      img {
        height: 120px;
      }
      position: static;
      grid-area: 13/16/22/25;
    }
  }
  .works-blob {
    position: absolute;
    right: 80px;
    top: 630px;
    position: absolute;
    z-index: -1;
    @media only screen and (max-width: 1200px) {
      margin-right: -40px;
      justify-self: end;

      grid-area: 32/16/70/25;
      img {
        height: 170px;
        transform: rotate(12deg);
      }
      position: static;
    }
  }
  .involved-blob {
    grid-area: 77/5/100/40;
    img {
      height: 250px;
    }
    @media only screen and (max-width: 1200px) {
      grid-area: 56/1/100/5;
      img {
        height: 140px;
      }
    }
  }

  .join {
    width: 100%;
    grid-area: 10/24/40/46;
    @media only screen and (max-width: 1200px) {
      width: 100%;
      grid-area: 7/6/30/17;
    }
  }
  .how {
    width: 600px;
    grid-area: 58/5/80/30;
    @media only screen and (max-width: 1200px) {
      grid-area: 25/4/80/18;
      width: 100%;
    }
  }
  .whats {
    width: 100%;
    grid-area: 80/22/90/46;
    @media only screen and (max-width: 1200px) {
      grid-area: 46/2/70/20;
      width: 100%;
    }
    p {
      width: 90%;
    }
  }

  .blob {
    object-fit: contain;
  }

  .circle-one {
    position: absolute;
    right: -140px;
    top: 580px;
    background: #dff2d8;
    border-radius: 100%;
    height: 180px;
    width: 180px;
    @media only screen and (min-width: 1200px) {
      background: #dff2d8;
      right: 0px;
      top: 800px;
      height: 200px;
      width: 200px;
    }
  }
  .circle-two {
    position: absolute;
    bottom: -765px;
    left: -280px;
    background: #dff2d8;
    border-radius: 100%;
    height: 800px;
    width: 800px;
    @media only screen and (min-width: 1200px) {
      bottom: -650px;
      left: -200px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .circle-three {
      position: absolute;
      z-index: 6;
      bottom: 40px;
      left: 80px;
      height: 110px;
      width: 110px;
      background: #0f7173;
      border-radius: 100%;
    }
  }
  .mya {
    width: 200px;
    @media only screen and (max-width: 1200px) {
      width: 100px;
    }
  }
  .paula {
    width: 190px;
    @media only screen and (max-width: 1200px) {
      width: 120px;
    }
  }
`
