import React from 'react'
import { IRequest } from '../../../components/utils/interfaces'
import styles from './Invoices.module.css'
import { Timestamp } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { useFormatTimestamp } from '../../../hooks/useFormatTimestamp'
interface IProps {
  invoices: IRequest[]
  fetchPdf: (request: IRequest) => void
}
function InvoicesTable(props: IProps) {
  const { t } = useTranslation()
  const format = useFormatTimestamp()
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.row}>
          <th className={styles.header}>{t('general.date')}</th>
          <th className={styles.header}>{t('general.fixer')}</th>
          <th className={styles.header}>{t('general.job')}</th>
        </tr>
      </thead>
      <tbody>
        {props.invoices.map((invoice, index) => {
          const date = new Date(invoice.created_on._seconds * 1000)
          return (
            <tr key={invoice.created_on._seconds} className={styles.bodyRow}>
              <td className={styles.bodyColumn}>{format(date)}</td>
              <td className={styles.bodyColumn}>{invoice.tradesman_name}</td>
              <td className={styles.bodyColumn}>
                {invoice.sub_category_details[0].sub_category_name}
              </td>
              <td style={{ width: '100%' }} className={styles.bodyColumn}>
                <button
                  onClick={() => props.fetchPdf(invoice)}
                  className={styles.tableButton}
                >
                  {t('button.download')}
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default InvoicesTable
