import React from 'react'
// import closeButton from '../../../assets/global/modals/close-button.svg'
import closeButton from '../../assets/global/modals/close-button.svg'
import styled from 'styled-components'
function CloseRegistrationBtn(props: { onClick: () => void }) {
  return (
    <CloseContainerDesktop>
      <button onClick={props.onClick}>
        <img src={closeButton} alt="close-button" />
      </button>
    </CloseContainerDesktop>
  )
}

export default CloseRegistrationBtn

const CloseContainerDesktop = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #efecef;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`
