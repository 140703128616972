import React from 'react'
import styles from './MyBookings.module.css'
import { useTranslation } from 'react-i18next'
import { Button, CustomSelect } from '../../../components/component-library/index'
import useInvoicesData from '../Invoices/useInvoicesData'
import placeholder from '../../../assets/global/icons/user-icon.png'
import { IMyBookingProps } from '../../../components/utils/interfaces'
import BookingsListDesktop from './BookingsListDesktop'
import useBookingsList from '../../../hooks/useBookingsList'
function MyBookingsDesktop({ filter, setFilter, bookings, fixers }: IMyBookingProps) {
  const options = useBookingsList()
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{t('account.nav.my-bookings')}</h3>
      <div className={styles.selectWrapper}>
        <CustomSelect
          list={options.map((el) => ({
            displayName: el.label,
            value: el.value,
          }))}
          selected={options.find((el) => el.value === filter)?.label ?? ''}
          setSelected={setFilter}
          placeholder={t('account.all')}
        />
      </div>
      <BookingsListDesktop fixers={fixers} data={bookings} />
    </div>
  )
}

export default MyBookingsDesktop
