import React from 'react'
import styles from './MyBookings.module.css'
import { useTranslation } from 'react-i18next'

function PayButton({ status }: { status: string }) {
  const { t } = useTranslation()
  let onClick = () => {}
  let btnStyles = {
    border: 'none',
    color: 'white',
    backgroundColor: '#F27649',
  }
  let text = t('button.pay')

  if (status !== 'pending') {
    onClick = () => {}
    btnStyles = {
      backgroundColor: 'transparent',
      border: '1px solid #0F7173',
      color: '#0F7173',
    }
    text = t('button.paid')
  }
  return (
    <button onClick={onClick} style={btnStyles} className={styles.payBtn}>
      {text}
    </button>
  )
}

export default PayButton
