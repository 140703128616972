import React, { useEffect, useState } from 'react'
import { IRequest } from '../../../components/utils/interfaces'
import { SectionHeader } from '../ui'
import { useTranslation } from 'react-i18next'
import styles from './Invoices.module.css'
import InvoicesSelect from './InvoicesSelect'
import InvoicesTable from './InvoicesTable'
import useMediaQuery from '../../../hooks/useMediaQuery'
import InvoicesTableMobile from './InvoicesTableMobile'

interface IProps {
  invoices: IRequest[] | null
  fetchPdf: (request: IRequest) => void
  value: string
  setValue: (value: string) => void
}

function InvoicesLayoutMobile(props: IProps) {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(900)
  if (!props.invoices) {
    return <div>Loading</div>
  }
  return (
    <div>
      <div className={styles.wrapper}>
        <InvoicesSelect value={props.value} setValue={props.setValue} />
        <InvoicesTableMobile
          fetchPdf={props.fetchPdf}
          invoices={props.invoices}
        />
      </div>
    </div>
  )
}

export default InvoicesLayoutMobile
