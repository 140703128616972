import { useLayoutEffect, useRef } from "react"

const useMinHeightAdjustment = (additionalSpace = 100) => {
  const ref = useRef(null)
  useLayoutEffect(() => {
    const node = ref.current as HTMLElement | null
    if (node) {
      const main = document.querySelector('#main') as HTMLDivElement | null
      if (main) {
        main.style.minHeight = `${node.clientHeight + additionalSpace}px`
      }
    }
  }, [ref])
return ref;
}
export default useMinHeightAdjustment 