import styled from 'styled-components'
import appstorelogos from '../../assets/global/non-bokafix-logos/appstoreLarge.png'
import googleplaylogos from '../../assets/global/non-bokafix-logos/googlePlayLarge.png'

const DownloadLinks = () => {
  return (
    <Container>
      <a
        href="https://apps.apple.com/us/app/bokafix-fixer/id1606489189"
        target="_blank"
      >
        <img src={appstorelogos} alt="app store logo" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.bokafix.fixer"
        target="_blank"
      >
        <img src={googleplaylogos} alt="google play logo" />
      </a>
    </Container>
  )
}

export default DownloadLinks

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0;
  img {
    margin: 0 5px;
    max-width: 40vw;
  }
  @media screen and (min-width: 499px) {
    display: none;
  }
`
