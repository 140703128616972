import React from 'react'
import useInvoicesData from './useInvoicesData'
import { CustomSelect } from '../../../components/component-library/index'
import { Select as MobileSelect } from '../../../components/ui/styled-components'
import useMediaQuery from '../../../hooks/useMediaQuery'
import styles from './Invoices.module.css'
import { useTranslation } from 'react-i18next'

interface IProps {
  value: string
  setValue: (value: string) => void
}

function InvoicesSelect(props: IProps) {
  const { t } = useTranslation()
  const options = useInvoicesData()
  const isDesktop = useMediaQuery(900)
  if (!isDesktop) {
    return (
      <MobileSelect
        className={styles.select}
        onChange={(event) => props.setValue((event.target as any).value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </MobileSelect>
    )
  }
  return (
    <div className={styles.selectWrapper}>
      <CustomSelect
        list={options.map((el) => ({ displayName: el.label, value: el.value }))}
        selected={options.find((el) => el.value === props.value)?.label ?? ''}
        setSelected={props.setValue}
        placeholder={t('account.all')}
      />
    </div>
  )
}

export default InvoicesSelect
