import React, { useState } from 'react'
import styles from './MyAccount.module.css'
import { ChangeEmailButton, SectionHeader, UpdateEmailWrapper } from '../ui'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import { Button, TextInput } from '../../../components/component-library/index'
import { Input, Select } from '../../../components/ui/styled-components'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import PasswordField from '../../../components/ui/PasswordField'
import FileUpload from '../FileUpload'
import validationRules from '../../../components/utils/validation-rules'
import useCountriesList from '../../../hooks/useCountriesList'
import Options from '../../../components/common/Options'
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from 'firebase/auth'
import useFormValidation from '../../../hooks/useFormValidation'
import { FormType } from '../../../components/utils/interfaces'
import SubmitBtn from './SubmitBtn'
import useMediaQuery from '../../../hooks/useMediaQuery'

function MyAccountLayout({
  form,
  changeEmail,
  urlObject,
  saveFormChanges,
  setDeleteModal,
}: {
  form: FormType
  changeEmail: (
    initialEmail: string,
    password: string,
    newEmail: string
  ) => void
  saveFormChanges: (form: FormType) => Promise<void>
  setDeleteModal: (value: boolean) => void
  urlObject: { url: string; setUrl: (url: string) => void }
}) {
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const isDesktop = useMediaQuery(900)

  const error = useFormValidation(form)

  const countriesList = useCountriesList()
  return (
    <div className={styles.wrapper}>
      <SectionHeader className={styles.pageTitle}>
        {t('account.nav.my-account')}
      </SectionHeader>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.doubleField}>
            <Field
              validate={validationRules.required.bind(
                'First name must be valid'
              )}
              name="first_name"
            >
              {({ input, meta }) => (
                <Input
                  className={styles.input}
                  {...input}
                  placeholder={t('form.first-name')}
                />
              )}
            </Field>
            <Field
              validate={validationRules.required.bind(
                'Last name must be valid'
              )}
              name="last_name"
            >
              {({ input, meta }) => (
                <Input
                  className={styles.input}
                  {...input}
                  placeholder={t('form.last-name')}
                />
              )}
            </Field>
          </div>
          <Field validate={validationRules.requiredEmail} name="email">
            {({ input, meta }) => {
              const initialEmail = form.getState().initialValues.email
              const email = input.value
              return (
                <>
                  <Input
                    type="email"
                    className={styles.input}
                    {...input}
                    placeholder={t('form.email')}
                  />
                  {meta.touched && meta.valid && (
                    <UpdateEmailWrapper className={styles.emailWrapper}>
                      <PasswordField
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value)
                        }}
                        placeholder="Verify the password"
                        className="password-container"
                      />
                      <ChangeEmailButton
                        onClick={() =>
                          changeEmail(initialEmail, password, email)
                        }
                        type="button"
                      >
                        Update
                      </ChangeEmailButton>
                    </UpdateEmailWrapper>
                  )}
                </>
              )
            }}
          </Field>
          <Field validate={validationRules.phoneRequired} name="phone_no">
            {({ input, meta }) => (
              <PhoneInput
                className={styles.phone}
                {...input}
                placeholder={t('form.phone')}
              />
            )}
          </Field>
          <Field name="password">
            {({ input, meta }) => (
              <PasswordField
                placeholder={t('form.password')}
                className={styles.input}
              />
            )}
          </Field>
          {isDesktop && (
            <FileUpload
              id="profile-photo"
              fileName={t('form.profile-picture')}
              folderName="customer-profile"
              imageUrl={urlObject.url}
              handleState={urlObject.setUrl}
            />
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.doubleField}>
            <Field
              validate={validationRules.required.bind(
                'Personal number is required'
              )}
              name="personal_no"
            >
              {({ input, meta }) => (
                <Input
                  style={{ width: '50%' }}
                  className={styles.input}
                  {...input}
                  placeholder={t('form.personnumber')}
                />
              )}
            </Field>
            <Field
              validate={validationRules.required.bind('Select country')}
              name="country"
              className={`select-country ${styles.input}`}
              options={countriesList}
            >
              {({ input, options }) => {
                return (
                  <div
                    className={`select-container ${styles.input}`}
                    id="country-field"
                  >
                    <Select
                      className={`${styles.selectField} select-country`}
                      name="country"
                      onChange={(value) => input.onChange(value)}
                    >
                      <Options options={options} />
                    </Select>
                  </div>
                )
              }}
            </Field>
          </div>
          <Field
            validate={validationRules.required.bind('Address is required')}
            name="address"
          >
            {({ input, meta }) => (
              <Input
                className={styles.input}
                {...input}
                placeholder={t('form.address')}
              />
            )}
          </Field>
          <Field
            validate={validationRules.required.bind('City is invalid')}
            name="city"
          >
            {({ input, meta }) => (
              <Input
                className={styles.input}
                {...input}
                placeholder={t('form.city')}
              />
            )}
          </Field>
          <Field
            validate={validationRules.required.bind('Postcode is required')}
            name="postcode"
          >
            {({ input, meta }) => (
              <Input
                className={styles.input}
                {...input}
                placeholder={t('form.postcode')}
              />
            )}
          </Field>
          {form.getFieldState('country')?.value === 'Sweden' && (
            <Field
              validate={validationRules.required.bind(
                'Fastighetsbeteckning is required'
              )}
              name="fastighetsbeteckning"
            >
              {({ input, meta }) => (
                <Input
                  className={styles.input}
                  {...input}
                  placeholder={'Fastighetsbeteckning'}
                />
              )}
            </Field>
          )}
          <div className={styles.interact}>
            <button
              onClick={() => setDeleteModal(true)}
              className={`${styles.interactButton} dark`}
            >
              {t('button.delete-account')}
            </button>
            <SubmitBtn submit={saveFormChanges} form={form} />
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </div>
  )
}

export default MyAccountLayout
