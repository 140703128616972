import React, { useEffect, useState } from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import MyAccountLayout from './MyAccountLayout'
import Account from '../CustomerAccount/Account'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from 'firebase/auth'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../../api/firebaseConfig'
import { FormType, ISubmitUserData } from '../../../components/utils/interfaces'
import { ContactUsBackgroundMobile } from '../../../components/Background'
import { useTranslation } from 'react-i18next'
import DeleteUserModal from '../DeleteUserModal'
import { useNavigate } from 'react-router-dom'

function MyAccount() {
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const isDesktop = useMediaQuery(900)
  const [deleteModal, setDeleteModal] = useState(false)
  const [initial, setInitial] = React.useState<any>({})
  const [url, setUrl] = useState<string>('')
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (userData) {
      setInitial({ ...userData })
    }
    if (window.localStorage.getItem('userType') !== 'customer') {
      navigate('/')
    }
  }, [userData])

  const saveFormChanges = async (form: FormType) => {
    try {
      const isValid = form.getState().valid
      if (!isValid) return
      const data = form.getState().values as ISubmitUserData
      const submitData: ISubmitUserData = {
        ...data,
        profile_image: url ?? '',
      }
      const uid = getAuth().currentUser?.uid
      if (!uid) {
        throw new Error('No user found')
      }
      await setDoc(doc(db, 'customers', uid), submitData)
      alert('Changes saved successfully')
      window.location.reload()
    } catch (err) {
      alert(err)
    }
  }

  const changeEmail = async (
    initialEmail: string,
    password: string,
    newEmail: string
  ) => {
    const user = getAuth().currentUser
    const credential = EmailAuthProvider.credential(initialEmail, password)
    if (user) {
      try {
        await reauthenticateWithCredential(user, credential)
        await FirebaseAPI.updateEmail(newEmail)
        alert('Email changed successfully')
        window.location.reload()
      } catch (error) {
        alert(error)
        console.log(error)
      }
    }
  }

  const Modal = deleteModal ? (
    <DeleteUserModal setDeleteModal={setDeleteModal} />
  ) : null

  if (!isDesktop) {
    return (
      <>
        {Modal}
        <ContactUsBackgroundMobile />
        <Form
          initialValues={initial}
          onSubmit={() => {}}
          render={({ handleSubmit, form }) => {
            return (
              <MyAccountLayout
                saveFormChanges={saveFormChanges}
                urlObject={{ url, setUrl }}
                changeEmail={changeEmail}
                form={form}
                setDeleteModal={setDeleteModal}
              />
            )
          }}
        />
      </>
    )
  } else {
    return (
      <Account>
        {Modal}
        <Form
          initialValues={initial}
          onSubmit={() => {}}
          render={({ handleSubmit, form }) => {
            return (
              <MyAccountLayout
                setDeleteModal={setDeleteModal}
                saveFormChanges={saveFormChanges}
                urlObject={{ url, setUrl }}
                changeEmail={changeEmail}
                form={form}
              />
            )
          }}
        />
      </Account>
    )
  }
}

export default MyAccount
