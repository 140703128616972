function getStatusColor(status: string) {
  const s = status.toLowerCase()
  if (s === 'completed') {
    return '#7ED321'
  } else if (s === 'looking for fixer' || s === 'ongoing' || s === 'pending') {
    return '#F4BF50'
  } else if (s === 'cancelled' || s === 'in dispute') {
    return '#FF000A'
  }
}
export default getStatusColor