import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import styles from './BookingDetails.module.css'
import icon from '../../../assets/global/icons/green-circle-check.svg'
import { useTranslation } from 'react-i18next'

function SubmitttedReview() {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(900)
  return isDesktop ? (
    <h3 className={styles.submittedReviewTitle}>
      {t('customer-bookings.thanks-for-rating')}
    </h3>
  ) : (
    <div className={styles.submittedReview}>
      <h3 className={styles.submittedReviewTitle}>
        {t('customer-bookings.thanks-for-rating')}
      </h3>
      <img src={icon} className={styles.submittedReviewIcon} />
    </div>
  )
}

export default SubmitttedReview
