import styled from 'styled-components';


export const ModalTitle = styled.h3`
cursor: default;
font-weight: 100;
text-align: start;
font-size: 35px;
letter-spacing: 20;
color: #3d3d3d;
font-family: 'Avenir', 'Arial', sans-serif;
`;

export const ErrorItem = styled.p`
color: red;
margin-top: 5px;
opacity: .7;
`;

export const CloseButton = styled.button`
background: none;
border: none;
outline: none;
position: absolute;
right: 0;
top: 0;
transition: .3s;

&:hover {
  cursor: pointer;
  transition: 0.3s;
  opacity: .7;
}
`;

export const ModalMobileWrapper = styled.div`
top: -97px;
height: calc(110vh + 97px);

position: fixed;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
background: rgba(209, 209, 209, 0.50967);
backdrop-filter: blur(2.71828px);
padding: 0px 15px;
`;

export const ModalBoldSpan = styled.span`
cursor: default;
margin-right: 6px;
font-size: 18px;
color: #3d3d3d;
font-weight: 500;
font-family: 'Avenir', 'Arial', sans-serif;
`;

export const ModalOrangeLink = styled.a`
font-size: 16px;
color: #f27649;
font-family: 'Avenir', 'Arial', sans-serif;
font-weight: bold;
text-decoration: none;
cursor: pointer;
`;

export const ModalInput = styled.input`
padding-right: 25px;
border-radius: 6px;
background-color: #f6f4f6;
border: none;
color: black;
opacity: 1;
padding: 16px;
font-size: 16px;
font-weight: bold;

&::placeholder {
  color: black;
}
`;

export const GreyLink = styled.a`
color: #727272;
font-size: 16px;
font-weight: light;
text-decoration: underline;
transition: .3s;
cursor: pointer;
&:hover {
  transition: .3s;
  opacity: .7;
}
`;

export const ModalOrangeBtn = styled.button`
padding: 15px;
cursor: pointer;
background-color: #f27649;
text-align: center;
width: 100%;
color: white;
border: none;
border-radius: 6px;
font-size: 16px;
font-weight: bold;
transition: .3s;
&:hover {
  transition: .3s;
  opacity: .7;
}
`;