type CountActionKind = 'LENGTH_ERROR' | 'AUTH_ERROR' | 'CUSTOM_ERROR'
interface CountAction {
  type: CountActionKind
  payload: string
}

export default function errorReducer(
  state: { message: string },
  action: CountAction
) {
  const { type, payload } = action
  if (type === 'LENGTH_ERROR') {
    return { ...state, message: 'Password must be at least 8 characters' }
  }
  if (type === 'AUTH_ERROR') {
    return { ...state, message: 'You are not logged in' }
  }
  if (type === 'CUSTOM_ERROR') {
    return { ...state, message: payload }
  }
  return state
}
