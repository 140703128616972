import React from 'react'
import { IFixer, IRequest } from '../../../components/utils/interfaces'
import styles from './MyBookings.module.css'
import placeholder from '../../../assets/global/icons/user-icon.png'
import verified from '../../../assets/global/icons/verified.svg'
import getStatusColor from '../../../utils/getStatusColor'
import { useFormatTimestamp } from '../../../hooks/useFormatTimestamp'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import normalizeTimestamp from '../../../utils/normalizeTimestamp'
function MyBookingsListMobile(props: {
  fixers: IFixer[]
  bookings: IRequest[]
}) {
  const format = useFormatTimestamp()
  const { t, i18n } = useTranslation()
  return (
    <div>
      {props.bookings.map((el) => {
            const date = normalizeTimestamp(el.created_on)
            const localizedDate = date ? date.toLocaleDateString(i18n.language, { day: 'numeric' , month: 'long', year: 'numeric'}) : 'Invalid';
            const hhMM = date ? date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit', hour12:false }) : 'Invalid'  
        return (
          <Link
            to={'/bookings/' + el.customer_request_id}
            key={el.updated_on._nanoseconds}
            className={styles.mobileItem}
          >
            <div className={styles.top}>
              <div className={styles.bookingProfile}>
                <img
                  src={
                    props.fixers.find(
                      (fixer) => fixer.tradesman_id === el.tradesman_id
                    )?.company_logo || placeholder
                  }
                  className={styles.profileImage}
                />
                <img className={styles.verified} src={verified} />
              </div>
              <h6 className={styles.nameTitle}>{el.tradesman_name}</h6>
            </div>
            <p className={styles.description}>
              {el.sub_category_details[0].sub_category_name}
            </p>
            <div className={styles.divider} />
            <div className={styles.bottom}>
              <div className={styles.tasks}>
                {el.sub_category_details.map((el) => (
                  <img
                    key={el.sub_category_id}
                    className={styles.task}
                    src={el.sub_category_image}
                  />
                ))}
              </div>
              <div className={styles.dateInfo}>
                <p className={styles.dateText}>
                  {localizedDate}, {hhMM}
                </p>
                <div className={styles.status}>
                  {t('general.status')}:
                  <p
                    style={{ color: getStatusColor(el.request_status) }}
                    className={styles.statusInfo}
                  >
                    {' '}
                    {el.request_status}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default MyBookingsListMobile
