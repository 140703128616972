import { db } from '../../api/firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from '../../store/slice/UserDataSlice'
import { useEffect } from 'react'
import { RootState } from '../../store/store'

export const useFetchData = (
) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userReducer.user)
  useEffect(() => {
    const loadData = async () => {
      const tablePath = window.localStorage.getItem('userType') === 'customer' ? 'customers' : 'tradesman'
      if (!user) return;
      const docRef = doc(db, tablePath, user.uid)
      try {
        const doc = await getDoc(docRef)
        const data = doc.data()
        if (data) {
          dispatch(setUserData(data))
          return data
        }
      } catch (error) {
        console.log(error)
      }
    }
    loadData()
  }, [user])
  return user;
}

