import React from 'react'
import styled from 'styled-components'

interface IProps {
  // Add your props here
  onClick?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

function Checkbox(props: IProps) {


  return (
    <CheckboxContainer className='container'>
      <CheckboxInput onChange={props.onChange} type="checkbox" checked={Boolean(props.value)} value={props.value} />
      <CheckboxSpan className='checkmark'></CheckboxSpan>
    </CheckboxContainer>
  )
}

export default Checkbox

const CheckboxInput = styled.input`
position: absolute;
opacity: 0;
cursor: pointer;
height: 25px;
width: 25px;
`
const CheckboxSpan = styled.span`
border-radius: 5px;
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: transparent;
border: 1px solid rgba(242, 118, 73, 1);

&:after {
  content: "";
  position: absolute;
  display: none;
}

`;

const CheckboxContainer = styled.label`
display: block;
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: rgba(242, 118, 73, .5);
  }

  & input:checked ~ .checkmark {
    background-color: rgba(242, 118, 73, .5);
    border: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
