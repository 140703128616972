import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import usePageParams from '../utils/usePageParams'
import Natascha from '../assets/global/meet-the-team/Natascha.png'
import Dorde from '../assets/global/meet-the-team/Dorde.png'
import Josefin from '../assets/global/meet-the-team/Josefin.png'
import Raphael from '../assets/global/meet-the-team/Raphael.png'
import Josephine from '../assets/global/meet-the-team/Josephine.png'
import Gytis from '../assets/global/meet-the-team/Gytis.png'
import Lauren from '../assets/global/meet-the-team/Lauren.png'
import Jana from '../assets/global/meet-the-team/Jana.png'
import Tobias from '../assets/global/meet-the-team/Tobias.jpg'
import Jana2 from '../assets/global/meet-the-team/Jana2.png'
import MainButton from '../components/common/MainButton'
import FixerArmy from '../components/layout/FixerArmy'
import Paula from '../assets/global/meet-the-team/blobs/Paula.png'
import Mya from '../assets/global/meet-the-team/blobs/Mya.png'
import Jeff from '../assets/global/blobs/jeff-with-brush.png'
import colors from '../assets/global/meet-the-team/blobs/colors.png'

const meetTheTeam: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('Meet the team', 'customerPage', 'Meet the team')

  const [isDesktop, setDesktop] = useState(window.innerWidth > 600)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 600)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  const dark = '#0f7173'
  const light = '#dff2d8'
  const orange = '#f27649'

  const members = isDesktop
    ? [
        {
          name: 'Natascha',
          image: Natascha,
          imgTop: 0,
          imgRight: 20,
          bubbleColor: light,
          bubbleBottom: -20,
          bubbleLeft: -20,
        },
        {
          name: 'Dorde',
          image: Dorde,
          imgBottom: -20,
          imgRight: 0,
          bubbleColor: dark,
          bubbleTop: -20,
          bubbleLeft: -20,
          color: 'white',
        },
        {
          name: 'Josefin',
          image: Josefin,
          imgTop: 0,
          imgLeft: 0,
          bubbleColor: orange,
          bubbleBottom: -30,
          bubbleRight: -20,
          color: 'white',
        },
        {
          name: 'Raphael',
          image: Raphael,
          imgTop: 0,
          imgLeft: 0,
          bubbleColor: orange,
          bubbleBottom: 0,
          bubbleRight: 0,
          color: 'white',
        },
        {
          name: 'Tobias',
          image: Tobias,
          imgBottom: -20,
          imgRight: -20,
          bubbleColor: light,
          bubbleTop: 0,
          bubbleLeft: -20,
        },
        {
          name: 'Josephine',
          image: Josephine,
          imgTop: 0,
          imgLeft: 0,
          bubbleColor: dark,
          bubbleBottom: 0,
          bubbleRight: 0,
          color: 'white',
        },
        {
          name: 'Gytis',
          image: Gytis,
          imgTop: 0,
          imgRight: 0,
          bubbleColor: dark,
          bubbleBottom: 0,
          bubbleLeft: 0,
          color: 'white',
        },
        {
          name: 'Lauren',
          image: Lauren,
          imgBottom: -40,
          imgLeft: -20,
          bubbleColor: orange,
          bubbleTop: 20,
          bubbleRight: 0,
          color: 'white',
        },
        {
          name: 'Jana',
          image: Jana,
          imgTop: 0,
          imgRight: 0,
          bubbleColor: light,
          bubbleBottom: 0,
          bubbleLeft: 0,
        },
      ]
    : [
        {
          name: 'Natascha',
          image: Natascha,
          imgTop: 0,
          imgLeft: 0,
          bubbleColor: light,
          bubbleBottom: 0,
          bubbleRight: 0,
        },
        {
          name: 'Tobias',
          image: Tobias,
          imgBottom: 0,
          imgRight: 0,
          bubbleColor: orange,
          bubbleTop: 0,
          bubbleLeft: 0,
          color: 'white',
        },
        {
          name: 'Josefin',
          image: Josefin,
          imgBottom: 0,
          imgLeft: 0,
          bubbleColor: dark,
          bubbleTop: 0,
          bubbleRight: 0,
          color: 'white',
        },
        {
          name: 'Raphael',
          image: Raphael,
          imgTop: 0,
          imgRight: 0,
          bubbleColor: light,
          bubbleBottom: 40,
          bubbleLeft: 0,
        },
        {
          name: 'Josephine',
          image: Josephine,
          imgTop: 0,
          imgLeft: 0,
          bubbleColor: dark,
          bubbleBottom: 0,
          bubbleRight: 0,
          color: 'white',
        },
        {
          name: 'Gytis',
          image: Gytis,
          imgBottom: 0,
          imgRight: 0,
          bubbleColor: orange,
          bubbleTop: 0,
          bubbleLeft: 0,
          color: 'white',
        },
        {
          name: 'Jana',
          image: Jana2,
          imgBottom: 0,
          imgLeft: 0,
          bubbleColor: light,
          bubbleTop: 0,
          bubbleRight: 0,
        },
        {
          name: 'Lauren',
          image: Lauren,
          imgTop: 0,
          imgRight: 0,
          bubbleColor: orange,
          bubbleBottom: 30,
          bubbleLeft: 0,
          color: 'white',
        },
        {
          name: 'Dorde',
          image: Dorde,
          imgBottom: 0,
          imgLeft: 0,
          bubbleColor: dark,
          bubbleTop: 0,
          bubbleRight: 0,
          color: 'white',
        },
      ]

  const tiles = members.map((each: any, index) => (
    <div key={index} className={`member-container ${each.name}`}>
      <img
        style={{
          top: each.imgTop,
          bottom: each.imgBottom,
          left: each.imgLeft,
          right: each.imgRight,
        }}
        src={`${each.image}`}
      />
      <p
        style={{
          color: each.color,
          background: `${each.bubbleColor}`,
          top: each.bubbleTop,
          bottom: each.bubbleBottom,
          left: each.bubbleLeft,
          right: each.bubbleRight,
        }}
        className="bubble"
      >
        {t(`meet-the-team.${each.name}`)}
      </p>
    </div>
  ))

  return (
    <div>
      {isDesktop ? null : (
        <Blobs>
          <img className="paula" src={Paula} alt="blob" />
          <img className="mya" src={Mya} alt="blob" />
          <img className="jeff" src={Jeff} alt="blob" />
          <img className="colors" src={colors} alt="blob" />
        </Blobs>
      )}
      <Container>
        <h1>{t('meet-the-team.title')}</h1>
        <section className="row row1">{tiles.slice(0, 3)}</section>
        <section className="row row2">{tiles.slice(3, 6)}</section>
        <section className="row row3">{tiles.slice(6, 9)}</section>
        <div className="button-container">
          <a href="/careers">
            <MainButton
              buttonName={t('meet-the-team.button')}
              isLarge={true}
              isLight={true}
            />
          </a>
        </div>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default meetTheTeam

const Container = styled.div`
  width: 100%;
  padding: 50px;
  margin: 0 auto;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 63px;
    margin: 50px auto;
  }
  section {
    margin-bottom: 40px;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-between;
  }
  .member-container {
    width: 32%;
    position: relative;
  }
  img {
    width: 75%;
    position: absolute;
  }
  .bubble {
    position: absolute;
    z-index: 5;
    height: 241px;
    width: 241px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: 600px) {
    .Natascha {
      margin-top: 20px;
    }
    .Dorde {
      .bubble {
        margin-left: -10px;
      }
    }
    .Josefin {
      margin-bottom: 20px;
    }
    .Tobias {
      margin-left: -80px;
      margin-top: -20px;
      margin-bottom: 20px;
    }
    .Lauren {
      margin-top: -30px;
      margin-left: 30px;
      margin-right: -50px;
      margin-bottom: 20px;
      .bubble {
        margin-right: -20px;
      }
    }
    .button-container {
      width: 300px;
      margin: 50px auto;
    }
  }

  @media screen and (max-width: 1200px) {
    section {
      height: 380px;
    }
    img {
      width: 75%;
    }
    .bubble {
      width: 190px;
      height: 190px;
    }
    @media screen and (max-width: 900px) {
      section {
        height: 260px;
      }
      img {
        width: 75%;
      }
      .bubble {
        width: 140px;
        height: 140px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 45px;
    section {
      margin: 0;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 1000px;
    }
    h1 {
      display: none;
    }
    .member-container {
      width: 100%;
      height: 32%;
    }
    img {
      width: 60%;
    }

    .bubble {
      width: 174px;
      height: 174px;
    }
    .button-container {
      margin: 20px auto 0;
    }
    .Tobias {
      margin-top: -70px;
    }
    .Raphael {
      margin-bottom: -30px;
    }
    .Gytis {
      margin-top: -70px;
    }
    .Dorde {
      margin-top: -70px;
    }

    @media only screen and (max-width: 450px) {
      /* section {
        margin: 0;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 1000px;
      } */
      .button-container {
        width: 100%;
        margin: 50px auto;
      }
    }
    @media screen and (min-width: 280px) {
      .member-container {
        margin: -150px 0;
      }
    }
  }
`

const Blobs = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  .mya {
    position: absolute;
    top: 60px;
    right: 0;
  }
  .paula {
    position: absolute;
    top: 920px;
    right: 0;
  }
  .jeff {
    position: absolute;
    height: 130px;
    top: 600px;
    left: -40px;
  }
  .colors {
    position: absolute;
    height: 100px;
    top: 1860px;
    left: 30px;
  }
`
