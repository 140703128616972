import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import calendar from '../../../assets/global/icons/calendar.svg'

function BookButton(props: {children?: React.ReactNode, onClick?: () => void}) {
  const { t } = useTranslation() 
  return (
    <Button onClick={props.onClick}>
      <Img src={calendar} />
      {props.children ?? t('menu.buttons.book-a-fix')}</Button>
  )
}

export default BookButton

const Button = styled.button`
position: relative;
background: #0F7173;
border: none;
color: white;
border-radius: 7px;
padding: 18px 0;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
`

const Img = styled.img`
position: absolute;
left: 48px;
`