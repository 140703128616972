import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as MyLogo } from '../../assets/global/bokafix-logo.svg'
import ukFlag from '../../assets/global/countries-flags/uk-flag.png'
import swedenFlag from '../../assets/global/countries-flags/sweden-flag.png'

import MenuLogo from '../../assets/global/icons/menu.svg'
import { setIsOpen } from '../../store/slice/ModalSlice'
import MainButton from '../common/MainButton'
import NavLink from '../common/NavLink'
import UseModalHeading from '../../hooks/useModalHeading'
import search from '../../assets/global/icons/search.svg'
import { Link, useNavigate } from 'react-router-dom'

import { RootState } from '../../store/rootstate'

import i18next from 'i18next'
import i18n from 'i18next'
import { getAuth } from 'firebase/auth'

interface Props {
  togglemenu?: () => void
}

// interface PropLinks {
//   links: {
//     link: string
//     path: string
//   }
//   className: string
// }

const Navbar: React.FC<Props> = ({ togglemenu }) => {
  const { t } = useTranslation()
  const { currentDot } = useSelector((state: RootState) => state.dotReducer)
  const { currentNav } = useSelector((state: RootState) => state.navReducer)
  // const { currentModal } = useSelector((state: RootState) => state.modalReducer)
  const [language, setLanguage] = useState(i18n.language)
  const dispatch = useDispatch()
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function openForgotPasswordModal() {
    dispatch(setIsOpen('forgotPasswordModal'))
  }

  function openFixerRegistrationModal() {
    if (!isDesktop)
      useEffect(() => {
        navigate('/sign-up', { replace: true })
      }, [])
    dispatch(setIsOpen('registrationFixerModal'))
  }

  const changeLanguage = useCallback(
    (language: string) => {
      i18next.changeLanguage(language)
    },
    [i18next]
  )

  useEffect(() => {
    const dotOptions = [
      'my-account',
      'About',
      'Howitworks',
      'Services',
      'earnings',
      'Messageus',
    ]
    for (let each of dotOptions) {
      let hidden: HTMLElement | null = document.getElementById(each)
      hidden && (hidden.style.visibility = 'hidden')
    }
    if (currentDot === 'noDot') {
      return
    } else {
      let current = dotOptions.filter((each: string) => {
        return each === currentDot
      })
      for (let each of current) {
        let visible: HTMLElement | null = document.getElementById(each)

        visible && (visible.style.visibility = 'visible')
      }
    }
  }, [currentDot, currentNav])

  const auth = getAuth()
  const user = auth.currentUser
  const accountLink = user
    ? {
        link: t('nav-links.my-account'),
        path:
          window.localStorage.getItem('userType') === 'fixer'
            ? '/account'
            : '/customer-account',
      }
    : {
        link: t('nav-links.login-or-signup'),
        path: '/sign-in',
      }

  let NavLinks: Array<Record<string, string>> = []
  let Buttons: Array<Record<string, any>> = []
  let navigate = useNavigate()
  switch (currentNav) {
    case 'homePage':
      NavLinks = [
        {
          link: t('nav-links.how-it-works'),
          path: '/how-it-works',
        },

        {
          link: t('nav-links.message-us'),
          path: '/contact-us',
        },
      ]
      Buttons = [
        {
          isLarge: false,
          isLight: false,
          buttonName: t(`button.login`),
          handleClick: openFixerRegistrationModal,
        },
      ]
      break
    case 'customerPage':
      NavLinks = [
        accountLink,
        {
          link: t('nav-links.about'),
          path: '/about',
        },
        {
          link: t('nav-links.how-it-works'),
          path: '/how-it-works',
        },
        // {
        //   link: t('nav-links.services'),
        //   path: '/working-on-it',
        // },
      ]
      Buttons = [
        {
          isLarge: false,
          isLight: true,
          buttonName: t(`button.book-a-fix`),
          handleClick: () => {
            // useEffect(() => {
            navigate('/download-customer-app', { replace: true })
            // })
          },
        },
        {
          isLarge: false,
          isLight: false,
          buttonName: t(`button.become-a-fixer`),
          handleClick: openFixerRegistrationModal,
        },
      ]
      break
    case 'fixerPage':
      NavLinks = [
        {
          link: t('nav-links.my-account'),
          path: '/working-on-it',
        },
        {
          link: t('nav-links.earnings'),
          path: '/working-on-it',
        },
        {
          link: t('nav-links.how-it-works'),
          path: '/how-it-works',
        },
        {
          link: t('nav-links.message-us'),
          path: '/contact-us',
        },
      ]
      Buttons = [
        {
          isLarge: false,
          isLight: false,
          buttonName: t(`button.become-a-fixer`),
          handleClick: openFixerRegistrationModal,
        },
      ]
      break
  }
  return (
    <>
      <Container>
        <Nav>
          <div className="logo-container">
            <Heading>
              <UseModalHeading />
            </Heading>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="desktop-size">
            <ul>
              {NavLinks.map((links: Record<string, string>, index: number) => {
                return (
                  <NavLink
                    className={`${links.link.replace(/\s/g, '')}`}
                    key={index}
                    links={{
                      link: links.link,
                      path: links.path,
                    }}
                  />
                )
              })}
            </ul>
            <div className="language">
              <img
                src={language === 'en' ? ukFlag : swedenFlag}
                alt={language === 'en' ? 'uk flag' : 'sweden flag'}
                width={22}
              />
              <select
                // Default value
                defaultValue={language}
                onChange={async (e) => {
                  setLanguage(e.target.value)
                  changeLanguage(e.target.value)
                }}
              >
                <option value="en">ENG</option>
                <option value="sv">SWE</option>
              </select>
            </div>
            {/* Here as an example */}
            {/* <button onClick={openForgotPasswordModal}>RESET PASSWORD</button> */}
            <img className="search" src={search} alt="search image" />
            <div className="btn-container">
              {Buttons.map((each: any, index: number) => {
                return (
                  <MainButton
                    key={index}
                    isLarge={each.isLarge}
                    isLight={each.isLight}
                    buttonName={each.buttonName}
                    handleClick={each.handleClick}
                  />
                )
              })}
            </div>
          </div>
          <button onClick={togglemenu} className="toggle-menu">
            <img src={MenuLogo} alt="menu logo" />
          </button>
        </Nav>
      </Container>
    </>
  )
}

export default Navbar

const Logo = styled(MyLogo)`
  height: 48px;
  fill: #000000;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    display: none;
  }
`

const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 900px) {
    display: none;
  }
`

const Container = styled.div`
  width: 100%;
  height: 10.7vh;
  min-height: 75px;
  display: flex;
  z-index: 1;
  justify-content: center;
  @media screen and (min-width: 900px) {
    padding: 1rem;
  }
  @media screen and (max-width: 900px) {
    background: #f27649;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1350px;
  width: 100%;
  position: relative;

  .dot {
    visibility: hidden;
    margin-right: 10px;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    background: #f27649;
  }

  ul {
    display: flex;
    list-style: none;
    margin-right: 50px;

    li {
      display: flex;
      margin: 0 10px;
      @media only screen and (max-width: 1020px) {
        margin: 0px;
      }

      align-items: center;
      position: relative;
      a {
        font-size: 12px;
        text-decoration: none;
        font-weight: 500;
        color: #000000;
      }
    }
  }
  .btn-container {
    display: flex;
    * {
      margin: 5px;
    }
    margin-left: 1rem;
  }
  .desktop-size {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .toggle-menu {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 33%;
    left: 21px;
    @media screen and (min-width: 900px) {
      display: none;
    }
    .search {
      cursor: pointer;
    }
  }
  .language {
    display: flex;
    align-items: center;
    margin: 0 20px;
    img {
      margin-right: 10px;
    }
    select {
      font-family: Avenir-heavy;
      border: none;
    }
  }
`
function useHistory() {
  throw new Error('Function not implemented.')
}
