import styled from 'styled-components'
import { HomePageBackground } from '../components/Background'
import usePageParams from '../utils/usePageParams'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../store/slice/ModalSlice'
const SignUpPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  usePageParams('Sign Up', 'fixerPage', 'Sign Up')
  setTimeout(() => {
    window.localStorage.getItem('userType') === 'customer'
      ? dispatch(setIsOpen('registrationCustomerModal'))
      : dispatch(setIsOpen('registrationFixerModal'))
  }, 1000)

  return (
    <SignUp>
      <HomePageBackground />
    </SignUp>
  )
}

const SignUp = styled.div`
  @media (min-width: 900px) {
    min-height: 800px !important;
  }
`
export default SignUpPage
