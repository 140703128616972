import { createSlice } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'
import { auth } from '../../api/firebaseConfig'

const initialState: Record<string, User | null> = {
  user: auth.currentUser
}

const AuthSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { setUser } = AuthSlice.actions
export default AuthSlice.reducer
