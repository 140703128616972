import React, { useState } from 'react';
import arrow from '../../assets/arrow.svg';
import '../../css/select.css';
import trimText from '../../helpers/trimText';
import topArrow from '../../assets/top-arrow.svg';

interface IProps {
  selectStyles?: React.CSSProperties;
  selectWrapperStyles?: React.CSSProperties;
  selectOptionsStyles?: React.CSSProperties;
  imgStyles?: React.CSSProperties;
  list: ISelectItem[];
  placeholder: string;
  setSelected: (value: string) => void;
  selected: string;
}

interface ISelectItem {
  displayName: string;
  value: string;
}

function CustomSelect(props: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  const selectItem = (item: string) => {
    props.setSelected(item);
    setIsOpen(false);
  };

  const toggleSelect = () => {
    setIsOpen(prev => !prev);
  };

  const placeholder = props.selected !== '' ? props.selected : props.placeholder;

  const selectedStyle = { fontWeight: 900, color: '#000' };
  const imgStyle = isOpen ? { transform: 'rotate(180deg) translate(-25%)' } : {};

  return (
    <div className={'select-wrapper'}>
      <div onClick={toggleSelect} className={'select-select'} style={props.selectWrapperStyles}>
        {trimText(placeholder, 13)}
        <img className={'select-icon'} style={{ ...props.imgStyles, ...imgStyle }} src={arrow} />
      </div>
      {isOpen && (
        <div className={'select-dropdown'}>
          {isOpen && <img className={'select-icon-bottom'} src={topArrow} alt="arrow top" />}
          {props.list.map((item, key) => (
            <div
              onClick={() => selectItem(item.value)}
              style={
                item.value === props.selected
                  ? { ...props.selectOptionsStyles, ...selectedStyle }
                  : props.selectOptionsStyles
              }
              className={'select-dropdown-option'}
              key={key}
            >
              {item.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomSelect;
