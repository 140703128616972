import React, { useState, useEffect } from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { ContactUsBackgroundMobile } from '../../../components/Background'
import BookingDetailsMobile from './BookingDetailsMobile'
import Account from '../CustomerAccount/Account'
import {
  ICallData,
  IFixer,
  IRequest,
  RatingNumber,
} from '../../../components/utils/interfaces'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { useNavigate, useParams } from 'react-router-dom'
import BookingDetailsDesktop from './BookingDetailsDesktop'
import { useTranslation } from 'react-i18next'
import { Timestamp } from 'firebase/firestore'
import CloseRegistrationBtn from '../../../components/modals/Registration/CloseRegistrationBtn'
import normalizeTimestamp from '../../../utils/normalizeTimestamp'

function BookingDetails() {
  const { i18n } = useTranslation()
  const [submitted, setSubmitted] = useState(false)
  const [rating, setRating] = useState(0)
  const [review, setReview] = useState('')
  const [isAlreadyReviewed, setIsAlreadyReviewed] = useState(false)
  const isDesktop = useMediaQuery(900)
  const { id } = useParams()
  const navigate = useNavigate()
  const [fixer, setFixer] = useState<IFixer | null>(null)
  const [booking, setBooking] = useState<IRequest | null>(null)
  useEffect(() => {
    if (id === undefined) {
      navigate('/')
      return
    }

    const getReview = async () => {
      try {
        const id = booking?.customer_request_id
        if (!id) {
          return null
        }
        const review = await FirebaseAPI.getReview(booking?.customer_request_id)
        if (!review) {
          setIsAlreadyReviewed(false)
        } else {
          setIsAlreadyReviewed(true)
        }
        return review
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }
    const  fetchFixer = async () => {
      if (!booking) {
        return
      }
      const fixerId = booking.tradesman_id
      if (fixerId) {
        const fixer = (await FirebaseAPI.fetchFixer(fixerId)) as IFixer
        setFixer(fixer)
      }
    }
     fetchFixer() 
    getReview()
  }, [booking])

  useEffect(() => {
    async function fetch() {
      const request = (await FirebaseAPI.fetchItem(
        'customer_requests',
        id!
      )) as IRequest
      setBooking(request as IRequest)
    }
    fetch()
  }, [id])


  if (fixer === null || booking === null) {
    return <div>Loading...</div>
  }


  const date = normalizeTimestamp(booking.completed_on)
  const localizedDate = date ? date.toLocaleDateString(i18n.language, { day: 'numeric' , month: 'long', year: 'numeric'}) : 'Invalid';
  const hhMM = date ? date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit', hour12:false }) : 'Invalid'

  async function uploadReview() {
    try {
      if (id && booking && rating !== 0 && review.trim() !== '') {
        await FirebaseAPI.setReview(id, {
          rating: rating as RatingNumber,
          review,
          booking_id: id,
          customer_id: booking.customer_id,
          date: Timestamp.fromDate(new Date()),
          tradesman_id: booking.tradesman_id,
        })
        setSubmitted(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const fetchPdf = async () => {
    try {
      if (!booking) {
        throw new Error('No request found')
      }
      const customer = await FirebaseAPI.fetchCustomer(booking.customer_id)!
      if (!customer) {
        return;
      }
      FirebaseAPI.getPdfInvoice({
        fixerName: booking.tradesman_name,
        customerName: booking.customer_name,
        fixerCompany: fixer.company_name,
        fixerStreet: fixer.address,
        customerStreet: booking.address,
        fixerCity: fixer.country,
        customerCity: booking.city,
        currency: i18n.language === 'sv' ? 'SEK' : '£',
        total: booking.total_price,
        customerPersonnummer: customer.personal_no,
        rotAvdrag: +booking.rot_avdrag,
        priceMaterials: booking.price_of_materials,
        priceOfHour: +booking.price_of_hour_work,
        hoursWorked: new Date(booking.completed_time).getHours(),
      })
    } catch (e) {
      alert(e)
    }
  }
  const ratingState = {
    value: rating,
    setRating,
    review,
    setReview,
    uploadReview,
    submitted,
    isAlreadyReviewed,
  }
  const props = {
    ratingState,
    fixer,
    booking,
    fetchPdf,
    hhMM,
    date: localizedDate,
  }
  if (!isDesktop) {
    return (
      <>
        <ContactUsBackgroundMobile />
        <BookingDetailsMobile
         {...props}
        />
      </>
    )
  }

  return (
    <Account currentPage="my-bookings">
      <BookingDetailsDesktop
        {...props}
      />
    </Account>
  )
}

export default BookingDetails


// move props to an object
// move interface to a file