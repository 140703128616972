import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '../components/component-library/index'
import { useDispatch } from 'react-redux'
import { setCurrentNav } from '../store/slice/NavSlice'
import { setCurrentTitle } from '../store/slice/TitleSlice'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { Link } from 'react-router-dom'
import trafficCone from '../assets/global/error-pages/traffic-cone.png'
import fredSad from '../assets/global/blobs/fred-sad-smartphone.png'
import hat from '../assets/global/error-pages/hard-hat.png'
import screwdriver from '../assets/global/error-pages/screwdriver.png'
import sanjay from '../assets/global/blobs/sanjay-questionmark.png'
import { ErrorPageDesktop, ErrorPageMobile } from '../components/Background'

const WereWorkingOnIt = () => {
  const { t } = useTranslation()
  useDocumentTitle('404')

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setCurrentNav('customerPage'))
    dispatch(setCurrentTitle(''))
  }, [])
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      {isDesktop ? <ErrorPageDesktop /> : <ErrorPageMobile />}
      <Container>
        <h1>{t('errors.page-not-found.title')}</h1>
        <p>{t('errors.page-not-found.description')}</p>
        <div className="grid">
          <div className="image-container trafficCone1">
            <img className="blob" src={trafficCone} alt="traffic trafficCone" />
          </div>
          <div className="image-container fredSad">
            <img className="blob" src={fredSad} alt="Fred Blob sad face" />
          </div>
          <div className="image-container trafficCone2">
            <img className="blob" src={trafficCone} alt="traffic trafficCone" />
          </div>
          <div className="image-container hat">
            <img className="blob" src={hat} alt="hard hat" />
          </div>
          <div className="image-container sanjay">
            <img className="blob" src={sanjay} alt="Sanjay Blob" />
          </div>
          <div className="image-container screwdriver">
            <img className="blob" src={screwdriver} alt="screwdriver" />
          </div>
        </div>
        <Link to="/">
          <Button
            fullSize={false}
            name={t('button.home')}
            handleClick={() => {}}
          ></Button>
        </Link>
      </Container>
    </div>
  )
}
export default WereWorkingOnIt

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 22px;
    margin: 70px 0 20px;
  }
  p {
    text-align: center;
    margin: 0 25px;
    font-family: Avenir;
  }
  .grid {
    margin: 30px 0;
    width: 100%;
    height: 300px;
    display: grid;
    grid-template-rows: repeat(18, 1fr);
    grid-template-columns: repeat(30, 1fr);
  }
  .blob {
    object-fit: contain;
  }
  .trafficCone1 {
    grid-area: 8/1/18/10;
    margin-left: -30px;
    img {
      height: 121px;
    }
    @media only screen and (min-width: 900px) {
      grid-area: 9/4/18/10;

      img {
        height: 212px;
      }
    }
  }
  .sanjay {
    z-index: -1;
    grid-area: 1/5/18/12;
    img {
      height: 174px;
    }
    @media only screen and (min-width: 900px) {
      grid-area: 3/7/18/20;

      img {
        height: 284px;
      }
    }
  }
  .screwdriver {
    grid-area: 13/10/18/15;
    img {
      height: 66px;
    }
    @media only screen and (min-width: 900px) {
      img {
        height: 121px;
      }
      grid-area: 15/12/18/15;
    }
  }

  .fredSad {
    grid-area: 7/17/18/30;
    img {
      height: 101px;
    }
    @media only screen and (min-width: 900px) {
      grid-area: 6/21/18/30;

      img {
        height: 210px;
      }
    }
  }
  .trafficCone2 {
    z-index: -1;
    grid-area: 6/24/18/30;
    img {
      height: 76px;
    }
    @media only screen and (min-width: 900px) {
      grid-area: 7/26/24/30;

      img {
        height: 146px;
      }
    }
  }
  .hat {
    grid-area: 13/22/18/30;
    img {
      height: 80px;
    }
    @media only screen and (min-width: 900px) {
      grid-area: 14/19/18/30;
      img {
        height: 133px;
      }
    }
  }
  @media only screen and (min-width: 900px) {
    margin-bottom: 100px;
    .grid {
      height: 450px;
      margin: 30px 0;
    }
    h1 {
      font-size: 40px;
    }
    p {
      width: 700px;
      font-size: 30px;
    }
  }
`
