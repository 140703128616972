import { createSlice } from '@reduxjs/toolkit'

/* Each page have an individual boolean state, functionallity to only have one state set to true at any given time */
/* To add page to state, add object with following properties type, status, translation as shown below. */
const initialState = {
    currentTitle: '',
}

const titleSlice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        setCurrentTitle(state, action) {
            state.currentTitle = action.payload
        },
    },
})

export const { setCurrentTitle } = titleSlice.actions

export default titleSlice.reducer
