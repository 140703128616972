import { isValidPhoneNumber } from 'react-phone-number-input'
const validationRules = {
  validString: function (value: string) { 
    const regex = /^[a-zA-Z]+$/;
   return (value && value.trim().length > 0 && regex.test(value)  ? undefined : this || 'Invalid field')
   },
  requiredToBeChecked: function (value: boolean) { return value === true ? undefined : this || 'Required' },
  required: function (value: string) { return (value && value.trim().length > 0 ? undefined : this || 'Required') },
  requiredPassword: (value: string) => (value && value.trim().length > 6 ? undefined : 'Password is too short'),
  requiredEmail: (value: string) => (value && value.trim().length > 0 && value.match(/^\S+@\S+\.\S+$/) ? undefined : 'Email is invalid'),
  requiredCheckbox: function (value: string[]) {
    return (value && value.length > 0 ? undefined : this || 'Select at least one option')
  },
  phoneRequired: function (value: string) {
    return (value && isValidPhoneNumber(value) ? undefined : this || 'Enter valid phone number')
  }
}

const composeValidators = (...validators: Function[]) => (value: Function) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export default validationRules;