import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './LogInModal.module.css'
import { setCustomerType, setFixerType } from '../../../utils/setUserType'
import selectStyles from '../SelectUserType/SelectUserType.module.css'
import useMediaQuery from '../../../hooks/useMediaQuery'
import customerIcon from '../../../assets/global/icons/customer-icon.png'
import fixerIcon from '../../../assets/global/icons/fixer-icon.png'

function SelectType({ setStep }: { setStep: (value: string) => void }) {
  const isDesktop = useMediaQuery(900)
  const { t } = useTranslation()
  const selectCustomer = () => {
    setCustomerType()
    setStep('logIn')
  }
  const selectFixer = () => {
    setFixerType()
    setStep('logIn')
  }
  return (
    <div className={styles.typeButtons}>
      {isDesktop ? (
        <>
          <button
            onClick={selectCustomer}
            className={`${styles.typeButton} orange`}
          >
            {t('button.customer')}
          </button>
          <button
            onClick={selectFixer}
            className={`${styles.typeButton} green`}
          >
            {t('button.fixer')}
          </button>
        </>
      ) : (
        <>
          <button onClick={selectCustomer} className={selectStyles.type}>
            <img src={customerIcon} />
            <h4 className={selectStyles.typeTitle}>{t('button.customer')}</h4>
          </button>
          <button onClick={selectFixer} className={selectStyles.type}>
            <img src={fixerIcon} />
            <h4 className={selectStyles.typeTitle}>{t('button.fixer')}</h4>
          </button>
        </>
      )}
    </div>
  )
}

export default SelectType
