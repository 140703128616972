import { useEffect } from 'react'

const useDocumentTitle = (title: any) => {
  useEffect(() => {
    window.document.title = title + ' - Bokafix'
    // add " - Bokafix" to the page title everywhere
  }, [title])
}

export default useDocumentTitle
