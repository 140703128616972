import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dropdownArrow from '../../assets/global/icons/dropdown-icon.svg'

function SelectBusiness(props: {
  value: string
  onChange: React.ChangeEventHandler<HTMLSelectElement>
}) {
  const { t } = useTranslation()

  return (
    <SelectContainer>
      <select
        placeholder={t('form.company.type')}
        id="business"
        onChange={props.onChange}
        className="select-business"
      >
        <option value={t('form.company.sole-trader')}>
          {t('form.company.sole-trader')}
        </option>
        <option value={t('form.company.partnership')}>
          {t('form.company.partnership')}
        </option>
        <option value={t('form.company.limited')}>
          {t('form.company.limited')}
        </option>
      </select>
      <img src={dropdownArrow} alt="dropdown arrow" />
    </SelectContainer>
  )
}

const SelectContainer = styled.div`
  & select {
    width: 100%;
    border: none;
    outline: none;
    background-color: #efecef;
    border: none;
    outline: none;
    border-radius: 4px;
    font-family: 'Avenir', Arial, sans-serif;
    color: #727272;
    font-weight: 900;
    padding: 14px 16px 12px 16px;
    appearance: none;
    -webkit-appearance: none;
  }
  & img {
    position: absolute;
    top: 18px;
    right: 18px;
  }
  position: relative;
`
export default SelectBusiness
