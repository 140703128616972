import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { collection, getDocs } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { db } from '../api/firebaseConfig'
import { useTranslation } from 'react-i18next'
import { Button } from '../components/component-library/index'
import sanjay from '../assets/global/blobs/sanjay-questionmark.png'
import fred from '../assets/global/blobs/fred-sad-smartphone.png'
import frontendIntern from '../assets/global/icons/developer.png'
import QuestionMark from '../assets/global/icons/question-mark.svg'
import usePageParams from '../utils/usePageParams'
import {
  FAQBackgroundDesktop,
  FAQBackgroundMobile,
} from '../components/Background'
import FixerArmy from '../components/layout/FixerArmy'

const Careers = () => {
  const { t } = useTranslation()
  usePageParams('Careers', 'customerPage', '')
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const [allCareers, setAllCareers]: any = useState([])

  useEffect(() => {
    const careersDatabase = collection(db, 'careers')

    const getCareers = async () => {
      const data = await getDocs(careersDatabase)
      const categoryCollections = data.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      const careersData = [
        ...categoryCollections.map((each: any) => {
          return {
            title: `${each.title}`,
            department: `${each.department}`,
            description: `${each.description}`,
            image: `${each.image}`,
            linkedin: `${each.linkedin}`,
          }
        }),
      ]

      setAllCareers(careersData)
    }
    getCareers()
  }, [])

  const jobImages = [frontendIntern]

  /* ---------------- ---------------- ---------------- */
  const jobs = t('careers.jobs', { returnObjects: true })
  // TODO : Replace this by a correct implementation.
  const jobCard = (each: any, index: any) => {
    return (
      <Link
        state={each}
        key={index}
        to="/Career-Details"
        className="apply"
        style={{ textDecoration: 'none' }}
      >
        <div className="job-container">
          <div className="image-container">
            <img
              className="job-image"
              src={jobImages[index]}
              alt="question mark logo"
            />
            {/* TODO: Fix the question mark */}
            {/* TODO: Fix the alt */}
            {/* That should come from the database */}
          </div>
          <h1 className="job-title job-text text">{each.title}</h1>
          <p className="job-department job-text text">{each.department}</p>
          <Button
            fullSize={false}
            name={t('careers.apply-button')}
            color={'orange'}
            handleClick={applyToggle}
          />
        </div>
      </Link>
    )
  }

  const applyToggle = () => {}

  const mobileAllJobs: any = allCareers.map((each: any, index: any) => {
    return jobCard(each, index)
  })

  const jobbyMcJobs: any = allCareers.map((each: any, index: any) => {
    return jobCard(each, index)
  })

  /* ---------------- ---------------- ---------------- */

  const [showMoreJobs, setShowMoreJobs] = useState(false)
  const [indexToStart, setIndexToStart] = useState(6)
  const [extraJobsShowing, setExtraJobsShowing]: any = useState([])
  const [isJobs, setIsJobs]: any = useState(false)
  const desktopMore = extraJobsShowing.map((each: any, index: any) => {
    return jobCard(each, index)
  })
  useEffect(() => {
    allCareers.length > 0 ? setIsJobs(true) : setIsJobs(false)
  }, [allCareers])

  const moreJobsToggle = () => {
    setShowMoreJobs(true)
    if (allCareers.length > indexToStart) {
      for (let i = indexToStart; i <= indexToStart + 5; i++) {
        if (i <= allCareers.length - 1) {
          setExtraJobsShowing((prev: any) => [...prev, allCareers[i]])
        }
      }
    }
    setIndexToStart((prev) => prev + 6)
  }

  /* ---------------- ---------------- ---------------- */

  return (
    <div>
      {isDesktop ? <FAQBackgroundDesktop /> : <FAQBackgroundMobile />}
      <Container>
        <section className="main">
          <h1 className="page-title">{t('careers.title')}</h1>
          {isJobs ? (
            <div>
              <p className="text header first">{t('careers.paragraph-1')}</p>
              <p className="text header second">{t('careers.paragraph-2')}</p>
              <div className="jobs mobile-jobs">{mobileAllJobs}</div>
              <div className="jobs desktop desktop-jobs">{jobbyMcJobs}</div>
              {showMoreJobs ? (
                <div className="jobs desktop desktop-more-jobs">
                  {desktopMore}
                </div>
              ) : null}
              <Button
                fullSize={false}
                name={t('careers.more-jobs')}
                color={'black'}
                handleClick={moreJobsToggle}
              />
            </div>
          ) : (
            <NoJobs>
              <p>{t('careers.no-jobs')}</p>
              <div className="blobs">
                <div className="blob-container">
                  <img className="sanjay" src={sanjay} alt="sanjay blob" />{' '}
                </div>
                <div className="blob-container">
                  <img className="fred" src={fred} alt="fred blob" />
                </div>
              </div>
            </NoJobs>
          )}
        </section>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default Careers

const NoJobs = styled.div`
  width: 100%;
  margin-top: -30px;
  .blobs {
    margin: 0 auto;
    width: 450px;
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
    margin-top: 50px;
  }
  @media only screen and (max-width: 900px) {
    padding: 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
    .blobs {
      margin-top: 80px;
      width: 100%;
    }
    .sanjay {
      height: 200px;
    }
    .fred {
      height: 120px;
    }
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 63px;
  }
  .main {
    width: 100%;
    padding: 50px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media only screen and (max-width: 900px) {
    .page-title {
      display: none;
    }
  }

  .text {
    @media only screen and (max-width: 900px) {
      font-family: Poppins;
    }
    font-style: normal;
    text-align: center;
    margin: 0 auto;
  }
  .header {
    @media only screen and (max-width: 900px) {
      font-weight: 500;
      color: #444444;
      font-size: 13px;
      line-height: 24px;
    }

    letter-spacing: -0.13125px;
  }

  .second {
    margin: 19px 0 37px 0;
  }

  .job-container {
    margin: 6px;
  }

  .image-container {
    height: 107px;
    width: 107px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(186, 186, 186, 0.5);
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .job-image {
    width: 100%;
    @media only screen and (max-width: 900px) {
      width: 60%;
    }
  }

  .job-title {
    @media only screen and (max-width: 900px) {
      max-width: 100px;
      margin-bottom: 5px;
      line-height: 15px;
    }
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    text-decoration: none;
    color: black;
  }

  .job-department {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: black;
  }
  Button {
    display: none;
  }

  .desktop {
    display: none;
  }
  .mobile-jobs {
    width: 357px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
  }

  .apply {
    text-decoration: none;
    color: black;
  }

  @media only screen and (min-width: 900px) {
    .first {
      line-height: 26px;
    }

    .page-title {
      display: block;
      font-family: Avenir-heavy;
      font-size: 63;
      margin-bottom: 50px;
    }

    .first {
      width: 70%;
    }

    .first,
    .second {
      font-family: Avenir;
    }

    .job-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 320px;
      height: 296px;
      margin: 50px 20px;
    }

    Button {
      display: block;
      margin: 20px auto;
    }

    .image-container {
      height: 100px;
      width: 100px;
      box-shadow: none;
    }

    .job-image {
      margin: 0;
    }

    .job-text {
      font-family: Avenir-light;
      color: #3d3d3d;
      font-weight: 200;
    }

    .job-title {
      font-family: Avenir-light;

      color: #3d3d3d;
      font-size: 27px;
    }
    .job-department {
      color: black;
      font-size: 16px;
    }
    .mobile-jobs {
      display: none;
    }
    .desktop {
      min-width: 900px;
      max-width: 1080px;
      padding: 0 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`
