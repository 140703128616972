import arrow from '../../assets/global/fixer-how-it-works/page-top-arrow.svg'
import styled from 'styled-components'

const ToPageTop: React.FC = (): JSX.Element => {
  return (
    <Container>
      <a className="arrow-container" href="#">
        <img className="arrow" src={arrow} />
      </a>
    </Container>
  )
}
export default ToPageTop

const Container = styled.div`
  @media only screen and (min-width: 900px) {
    position: relative;
    .arrow-container {
      position: absolute;
      bottom: 0;
      right: 20px;
      img {
        height: 60px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`
