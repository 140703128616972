import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import FixerInfoNav from '../components/FixerInfoNav'
import Sanjay from '../assets/global/blobs/sanjay-questionmark.png'
import Fred from '../assets/global/blobs/fred-happy-smartphone.png'
import accreditation from '../assets/global/icons/accreditation.png'
import id from '../assets/global/icons/id.png'
import invoice from '../assets/global/icons/invoice.png'
import { AboutBackgroundDesktop } from '../components/Background'
import { FixerInfoMobile } from '../components/Background'
const FixerInfoRequirements: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('Fixer information', 'customerPage', 'Fixer information')

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const icons = [accreditation, id, invoice]

  let iconsAndText = icons.map((each, index) => (
    <div key={index} className={`icon-container container-${index}`}>
      <img src={each} alt={each} />
      <p>{t(`fixer-information.requirements.list.${index}`)}</p>
    </div>
  ))
  return (
    <div>
      {isDesktop ? <AboutBackgroundDesktop /> : <FixerInfoMobile />}
      <FixerInfoNav />
      <Container>
        {isDesktop ? null : (
          <div className="text-and-blobs-mobile">
            <div className="blobs-mobile">
              <img className="sanjay-mobile" src={Sanjay} alt="sanjay" />
              <img className="fred-mobile" src={Fred} alt="fred" />
            </div>
            <h1>{t('fixer-information.nav.title')}</h1>
          </div>
        )}
        <section className="text-and-blobs">
          <div className="top-text">
            <h2>{t('fixer-information.requirements.subtitle')}</h2>
            <p>{t('fixer-information.requirements.article')}</p>
          </div>
          {isDesktop ? (
            <div className="blobs">
              <img src={Sanjay} alt="sanjay" />
              <img src={Fred} alt="fred" />
            </div>
          ) : null}
        </section>
        <section className="text-and-icons">
          <h2>{t('fixer-information.requirements.subtitle')}</h2>
          <div className="three-icons">{iconsAndText.slice(0, 2)}</div>
          <div className="last-icon">{iconsAndText[2]}</div>
        </section>
      </Container>
      <FixerArmy />
    </div>
  )
}

export default FixerInfoRequirements

const Container = styled.div`
  @media only screen and (min-width: 900px) {
    width: 100%;
    padding: 0 5%;
    font-family: Avenir;
    p {
      line-height: 28px;
    }
    h2 {
      font-size: 20px;
    }
  }

  .text-and-blobs {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    .top-text {
      width: 45%;

      p {
        margin-top: 20px;
      }
    }
    .blobs {
      width: 45%;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      img {
        object-fit: contain;
      }
    }
  }
  .text-and-icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    img {
      margin: 20px 0;
    }
    .three-icons {
      height: 250px;
      display: flex;
      justify-content: space-around;
      .icon-container {
        width: 25%;
      }
    }
    .last-icon {
      height: 250px;
      display: flex;
      justify-content: center;
      .icon-container {
        width: 40%;
      }
    }
    .icon-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .container-2 {
      img {
        padding-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    font-family: poppins;
    padding: 15px;
    margin-bottom: 50px;
    font-size: 13px;
    h1 {
      font-size: 22px;
      font-weight: 500;
    }
    h2 {
      font-size: 14px;
      font-weight: 600;
    }
    p {
      font-weight: 500;
    }
    .text-and-blobs {
      margin-top: 0;
      .top-text {
        line-height: 24px;
        width: 90%;
        p {
          margin-top: 10px;
        }
      }
      h2 {
        margin-top: 10px;
      }
    }
    .text-and-blobs-mobile {
      .top-text {
      }
      .blobs-mobile {
        padding: 30px 20px;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        img {
          object-fit: contain;
        }
      }
      .sanjay-mobile {
        width: 127px;
      }
      .fred-mobile {
        width: 127px;
      }
    }
    .text-and-icons {
      text-align: center;
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
      .three-icons {
        width: 100%;
        height: 500px;
        flex-direction: column;
        align-items: center;
        .icon-container {
          width: 100%;
        }
      }
      .last-icon {
        width: 100%;
        height: 200px;
        .icon-container {
          width: 100%;
        }
      }
    }
  }
`
