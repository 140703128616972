import { url } from 'inspector'
import React, { useEffect } from 'react'
import Account from '../CustomerAccount/Account'
import MyAccountLayout from '../MyAccount/MyAccountLayout'
import InvoicesLayout from './InvoicesLayout'
import { useSelector } from 'react-redux'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { IRequest } from '../../../components/utils/interfaces'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import usePageParams from '../../../utils/usePageParams'
import { useState } from 'react'
import { Select } from '../../../components/component-library/index'
import { Timestamp } from 'firebase/firestore'
import {
  ContactUsBackgroundDesktop,
  ContactUsBackgroundMobile,
} from '../../../components/Background'
import getDaysDiff from '../../../utils/getDaysDiff'
import InvoicesLayoutMobile from './InvoicesLayoutMobile'

function Invoices() {
  const [value, setValue] = useState('all')
  const { i18n, t } = useTranslation()
  const { user } = useSelector((state: any) => state.userReducer)
  usePageParams(t('account.nav.invoices'), 'customerPage', 'Invoices')

  const [invoices, setInvoices] = React.useState<IRequest[] | null>(null)
  const [filteredInvoices, setFilteredInvoices] = React.useState<
    IRequest[] | null
  >(null)
  const fetchInvoicePdf = async (request: IRequest) => {
    try {
      if (!request.tradesman_id || !request.customer_id) {
        throw new Error('Fixer or customer not found')
      }
      const fixer = await FirebaseAPI.fetchFixer(request.tradesman_id)
      const customer = await FirebaseAPI.fetchCustomer(request.customer_id)
      if (!fixer || !customer) {
        throw new Error('Fixer or customer not found')
      }
      await FirebaseAPI.getPdfInvoice({
        fixerName: request.tradesman_name,
        customerName: request.customer_name,
        fixerCompany: fixer.company_name,
        fixerStreet: fixer.address,
        customerStreet: request.address,
        fixerCity: fixer.country,
        customerCity: request.city,
        currency: i18n.language === 'sv' ? 'SEK' : '£',
        total: request.total_price,
        customerPersonnummer: customer.personal_no,
        rotAvdrag: +request.rot_avdrag,
        priceMaterials: request.price_of_materials,
        priceOfHour: +request.price_of_hour_work,
        hoursWorked: new Date(request.completed_time).getHours(),
      })
    } catch (error) {
      console.log(error)
      alert('Error occured while fetching pdf')
    }
  }
  useEffect(() => {
    if (user) {
      FirebaseAPI.getInvoices().then((res: any) => {
        const data: IRequest[] = res.data.data
        setInvoices(data)
      })
    }
  }, [user])
  useEffect(() => {
    if (invoices) {
      const filteredItems = invoices.filter((invoice) => {
        const date = new Date(invoice.created_on._seconds * 1000)
        let currentDate = new Date()
        const diffDays = getDaysDiff(currentDate, date)
        if (value === 'lastMonth') {
          return diffDays < 30
        } else if (value === 'last6Months') {
          return diffDays < 180
        } else if (value === 'lastYear') {
          return diffDays < 365
        }
      })
      if (value !== 'all') {
        setFilteredInvoices(filteredItems)
      } else {
        setFilteredInvoices(invoices)
      }
    }
  }, [invoices, value])
  const isDesktop = useMediaQuery(900)
  if (!isDesktop) {
    return (
      <>
        <ContactUsBackgroundMobile />
        <InvoicesLayoutMobile
          value={value}
          setValue={setValue}
          fetchPdf={fetchInvoicePdf}
          invoices={filteredInvoices}
        />
      </>
    )
  }
  return (
    <Account currentPage="my-invoices">
      <InvoicesLayout
        value={value}
        setValue={setValue}
        fetchPdf={fetchInvoicePdf}
        invoices={filteredInvoices}
      />
    </Account>
  )
}

export default Invoices
