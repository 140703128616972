import React from 'react'
import styles from './BookingDetails.module.css'
import {
  IBookingDetailsProps,
  IFixer,
  IRatingState,
  IRequest,
} from '../../../components/utils/interfaces'
import { useTranslation } from 'react-i18next'
import placeholder from '../../../assets/global/icons/user-icon.png'
import verified from '../../../assets/global/icons/verified.svg'
import Star from '../Star'
import msg from '../../../assets/global/icons/message-icon.svg'
import phoneIcon from '../../../assets/global/icons/phone-icon.svg'
import CardInfo from './CardInfo'
import { Button } from '../ui'
import Rating from './Rating'
import SubmitttedReview from './SubmitttedReview'
import { Link } from 'react-router-dom'

interface IProps {
  booking: IRequest
  fixer: IFixer
  ratingState: IRatingState
  fetchPdf: () => Promise<void>
  date: string
  hhMM: string
}

function BookingDetailsMobile({
  booking,
  fixer,
  ratingState,
  fetchPdf,
  date,
  hhMM,
}: IBookingDetailsProps) {
  const { t, i18n } = useTranslation()

  const phone_no = fixer?.phone_no ? 'tel:' + fixer?.phone_no : '#'
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.card} ${styles.flexCard}`}>
        <span className={styles.cardItem}>{t('general.date')}</span>
        <span className={`${styles.cardItem} ${styles.boldItem}`}>{date}</span>
      </div>
      <div className={`${styles.card} ${styles.flexCard}`}>
        <div className={styles.profileLeft}>
          <div className={styles.icon}>
            <img
              className={styles.image}
              src={fixer?.company_logo || placeholder}
            />
            <img className={styles.verified} src={verified} />
          </div>
          <div className={styles.profileInfo}>
            <h6 className={styles.name}>{booking?.tradesman_name}</h6>
            <div className={styles.rating}>
              <Star size="15" color={'#FFCC00'} />
              <p className={styles.ratingText}>
                {fixer?.avg_rating} (0 {t('account.reviews')})
              </p>
            </div>
          </div>
        </div>
        <div className={styles.interact}>
          <button type="button">
            <Link to="/working-on-it">
              <img className={styles.interactBtn} src={msg} />
            </Link>
          </button>
          <a
            className={fixer.phone_no ? '' : styles.disabledBtn}
            href={phone_no}
          >
            <img className={styles.interactBtn} src={phoneIcon} />
          </a>
        </div>
      </div>
      <div className={`${styles.card}`}>
        <div className={styles.cardInfoItem}>
          <p className={styles.cardInfoTitle}>
            {t('customer-bookings.service-type')}
          </p>
          <img className={styles.cardInfoImage} src={booking.category_image} />
        </div>
        <div className={styles.cardInfoItem}>
          <p className={styles.cardInfoTitle}>
            {t('customer-bookings.service')}
          </p>
          <p className={styles.cardInfoText}>
            {booking.sub_category_details[0].sub_category_name}
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <CardInfo booking={booking} />
      </div>
      {ratingState.isAlreadyReviewed ||
        (ratingState.submitted && (
          <>
            <div className={styles.rate}>
              <Rating
                activeColor="#D3460B"
                inactiveColor="#24253D80"
                className={styles.centeredRating}
                ratingState={ratingState}
                size={29}
              />
              <span className={styles.rateText}>Rate your tradesman</span>
            </div>
            <div className={styles.card}>
              <textarea
                onChange={(e) => ratingState.setReview(e.target.value)}
                value={ratingState.review}
                placeholder="Explain your rating..."
                className={styles.review}
              />
            </div>
          </>
        ))}
      <div className={styles.buttonsContainer}>
        {!ratingState.isAlreadyReviewed && ratingState.submitted ? (
          <SubmitttedReview />
        ) : !ratingState.isAlreadyReviewed ? (
          <Button onClick={() => ratingState.uploadReview()} className="orange">
            {t('button.submit')}
          </Button>
        ) : null}
        <Button onClick={() => fetchPdf()} className="dark">
          {t('button.download-invoice')}
        </Button>
        <Link
          className={styles.link}
          to={`/contact-us/${booking.customer_request_id}`}
        >
          <Button className="green">{t('button.help')}</Button>
        </Link>
      </div>
    </div>
  )
}

export default BookingDetailsMobile
