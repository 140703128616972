import FirebaseAPI from '../firebase-api/FirebaseAPI'

function doesUserExists(uid: string) {
  const userType = window.localStorage.getItem('userType');
  if (!userType) throw new Error('User type is not set')
  const path =
    window.localStorage.getItem('userType') === 'customer'
      ? 'customers'
      : 'tradesman'
  return FirebaseAPI.doesUserExistInPath(uid, path)
}

export default doesUserExists