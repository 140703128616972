import React, { useEffect, useState } from 'react'
import { IFixer, IMyBookingProps } from '../../../components/utils/interfaces'
import { useTranslation } from 'react-i18next'
import useBookingsList from '../../../hooks/useBookingsList'
import styles from './MyBookings.module.css'
import { Select } from '../../../components/ui/styled-components'
import filterIcon from '../../../assets/global/icons/filter.svg'
import { useFormatTimestamp } from '../../../hooks/useFormatTimestamp'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import NoBookings from './NoBookings'
import BookingsListDesktop from './BookingsListDesktop'
import MyBookingsListMobile from './MyBookingsListMobile'

function MyBookingsMobile({ filter, setFilter, bookings, fixers }: IMyBookingProps) {
  const options = useBookingsList()
  const { t } = useTranslation()
  const format = useFormatTimestamp()
  return (
    <div className={styles.wrapper}>
      <Select
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className={styles.select}
        dropdown={filterIcon}
      >
        {options.map((el) => (
          <option key={el.value} value={el.value}>
            {el.label}
          </option>
        ))}
      </Select>
      {bookings.length > 0 ? (
        <MyBookingsListMobile fixers={fixers} bookings={bookings} />
      ) : (
        <NoBookings />
      )}
    </div>
  )
}

export default MyBookingsMobile
