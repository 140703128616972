const initialFirebaseData = {
  activate_account_status: false,
  active_status: 'offline',
  address: '',
  agree_to_terms: false,
  avg_rating: 0,
  block_reason: 'NA',
  block_status: false,
  city: '',
  country: '',
  created_on: '',
  customer_id: '',
  email: '',
  fastighetsbeteckning: '',
  fcm_token: '',
  first_name: '',
  is_remember: true,
  is_initial_fee_paid: false,
  last_name: '',
  location: '',
  no_of_rating_length: 0,
  personal_no: '',
  phone_no: '',
  postcode: '',
  profile_image: '',
  total_rating: 0,
}

export default initialFirebaseData
