import styled from 'styled-components'
import dropdown from '../../assets/global/icons/arrow-1.svg'

export const PageTitle = styled.h1`
  font-size: 63px;
  text-align: center;
  margin-bottom: 53px;
`
export const BlogWrapper = styled.div`
  margin-top: 198px;
  width: 100%;
  @media (max-width: 900px) {
    margin-top: 11px;
  }
`

export const ContentWrapper = styled.div`
  padding: 0 15px;
`
export const NavWrapper = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 45px;
  @media (max-width: 900px) {
    display: block;
  }
`

export const NavItem = styled.li`
  font-size: 16px;
  cursor: pointer;
  font-weight: 900;
  color: #8b8b8b;
  transition: 0.5s;
`

export const NavOption = styled.option`
  width: 100%;
`

export const NavSelect = styled.select`
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  height: 58px;
  color: black;
  box-shadow: 0px 4px 14px rgba(87, 91, 125, 0.215854);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent url(${dropdown}) no-repeat 100% center;
  background-color: white;
  background-position: center right 20px;
  text-transform: capitalize;
`

export const ArticlesWrapper = styled.div`
  margin-top: 80px;
  column-gap: 15px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1062px;
  margin-left: auto;
  margin-right: auto;
  justify-content: start;

  @media (max-width: 900px) {
    margin-top: 35px;
  }
`

export const Article = styled.a`
  text-decoration: none;
  color: #3d3d3d;
  display: block;
  cursor: pointer;
  flex: 1 0 50%;
  max-width: 344px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-basis: 31%;
  }
`

export const ArticleTitle = styled.h3`
  text-align: center;
  color: #3d3d3d;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 24px;
  @media (max-width: 900px) {
    font-size: 10px;
    color: black;
    margin-bottom: 2px;
  }
`

export const ArticleMetaDataItem = styled.span``

export const ArticleMetaData = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  gap: 2px;
  font-size: 13px;
  @media (max-width: 900px) {
    font-size: 5px;
    margin-bottom: 2px;
  }
`

export const ArticleImage = styled.img`
  width: 100%;
  margin-bottom: 17px;
  @media (max-width: 900px) {
    box-shadow: 0px 2px 10px rgba(186, 186, 186, 0.5);
    border-radius: 4px;
  }
`

export const ArticleSummary = styled.p`
  text-align: center;
  color: #737373;
  font-size: 16px;
  @media (max-width: 900px) {
    font-size: 8px;
    color: black;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 107px;
`
