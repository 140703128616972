import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import { useTranslation } from 'react-i18next'
import BecomeFixerBanner from '../components/common/BecomeFixerBanner/BecomeFixerBanner'
function ServiceTerms() {
  const { t } = useTranslation()
  usePageParams(
    'Fixer Service Agreement',
    'customerPage',
    'Terms and Conditions',
    t('service-terms.title'),
    true
  )

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  function mergeStyles(...styles: string[]) {
    return styles.join(' ')
  }

  return (
    <div>
      <Container>
        <div className="container">
          <main id="main">
            <div>
              <h1 className="heading">FIXER SERVICE CONTRACT</h1>
              <div>
                <p className="conditions-text bold-p mb-lg">
                  THIS SERVICE CONTRACT IS A CONTRACT BETWEEN YOU AS A CUSTOMER
                  AND A FIXER CARRYING OUT FIXER SERVICES VIA THE BOKAFIX
                  PLATFORM.
                </p>
                <p className="conditions-text bold-p mb-lg">
                  FIXERS ARE INDEPENDENT CONTRACTORS AND NOT EMPLOYEES,
                  PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURES OR
                  FRANCHISEES OF BOKAFIX.
                </p>
                <p className="conditions-text bold-p mb-lg">
                  BOKAFIX IS NOT A PARTY TO THIS SERVICE CONTRACT.
                </p>
                <h4 className="subtitle mb-md">BACKGROUND:</h4>
                <p className="conditions-text mb-lg">
                  This Service Contract is the standard terms which apply to the
                  provision of Fixer Services by the Fixer, named in the Order
                  Details, to customers who require Fixer Services via the
                  Bokafix Platform. Please read them carefully and ensure that
                  you understand and agree to them. If you have any questions,
                  please contact the Fixer.
                </p>
                <h4 className="mb-md">1. Definitions and Interpretation</h4>
                <p className="conditions-text mb-lg">
                  1.1 In these Service Contract, unless the context otherwise
                  requires, the following expressions have the following
                  meanings:
                </p>
                <div className="term">
                  <h4 className="definition"></h4>
                  <p className="meaning"></p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Bokafix”</h4>
                  <p className="meaning">
                    means Bokafix AB, a private limited liability company
                    incorporated and registered in Sweden with company number
                    559317-4278, whose registered office is at Harrvägen 8a, 181
                    30 Lidingö
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Bokafix App”</h4>
                  <p className="meaning">
                    means the Bokafix application&#40;s&#41; available to
                    download on Google Play and App Store;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Bokafix Platform”</h4>
                  <p className="meaning">
                    means the Bokafix Website, Bokafix App and related services,
                    information and communications;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Consumer Law”</h4>
                  <p className="meaning">
                    means all applicable consumer protection laws from time to
                    time in the UK, Sweden and/or Ireland as the case may be;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Customer”</h4>
                  <p className="meaning">
                    means an individual or company seeking to obtain Fixer
                    Services from Fixers using the Bokafix Platform, below also
                    defined as “you” and “your”;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Final Fee”</h4>
                  <p className="meaning">
                    means the total of all sums you must pay which will be shown
                    on the invoice issued in accordance with clause of this
                    Service Contract;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Fixer”</h4>
                  <p className="meaning">
                    means a company or individual specified as the “Fixer” in
                    the Order Details, and includes all employees, agents and
                    sub-contractors of the Fixer, who will be responsible for
                    providing the Fixer Service
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Fixer Services”</h4>
                  <p className="meaning">
                    means a short-term service, specified in the Order Details,
                    provided by the Fixer to you.
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Instant Booking”</h4>
                  <p className="meaning">
                    means an Order that is made less than 24 hours before the
                    Start Time;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Materials”</h4>
                  <p className="meaning">
                    means the material required for the provision of the Fixer
                    Services which the Fixer will supply &#40;if any&#41; and as
                    specified on the Invoice;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Binding Order”</h4>
                  <p className="meaning">
                    means a legally binding order between you and the Fixer as
                    detailed in clause 3.1
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Order”</h4>
                  <p className="meaning">
                    means the information provided by you on the Bokafix
                    Platform as set out in clause 3.1a&#41;;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Order Details”</h4>
                  <p className="meaning">
                    means the order details on the Bokafix Platform detailing
                    information about the Fixer, the Fixer Services including
                    but not limited to the Start Time and the Fixer's hourly
                    rate;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Out-of-pocket Expenses”</h4>
                  <p className="meaning">
                    means as set out in clause 5.1b&#41;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Property”</h4>
                  <p className="meaning">
                    means your home or such other place, as detailed in the
                    Order Details at which the Fixer Service is to take place;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“PSP”</h4>
                  <p className="meaning">
                    means a third-party service provider approved by Bokafix for
                    making and receiving payments for Fixer Services and other
                    payments related to the Bokafix Platform;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Start Time”</h4>
                  <p className="meaning">
                    means the date and time you and the Fixer agree on for the
                    Fixer to start providing the Fixer Services as specified in
                    the Order Details. If the time is stated as a time interval
                    &#40;e.g. 09.00-11.00&#41; the Start Time is, for the
                    purpose of this Service Contract, the first hour &#40;e.g.
                    09.00&#41;. For an Instant Order the Start Time is when you
                    confirm the Instant Order in the Bokafix Platform;
                  </p>
                </div>
                <div className="term mb-md">
                  <h4 className="definition">“Service Contract”</h4>
                  <p className="meaning">
                    means the Order Details and this Fixer Service Contract;
                  </p>
                </div>
                <div className="term mb-lg">
                  <h4 className="definition">“Website”</h4>
                  <p className="meaning">
                    means Bokafix's website www.bokafix.com.
                  </p>
                </div>
                <p className="conditions-text mb-md">
                  1.2 Each reference in this Service Contract to “writing”, and
                  any similar expression, includes electronic communications
                  whether sent by e-mail, text message, push notifications or
                  other means.
                </p>
                <p className="conditions-text mb-md">
                  1.3 The headings used in this Service Contract are for
                  convenience only and do not affect the interpretation of the
                  Service Contract.
                </p>
                <p className="conditions-text mb-md">
                  1.4 Words signifying the singular number will include the
                  plural and vice versa.
                </p>
                <p className="conditions-text mb-lg">
                  1.5 References to persons, unless the context otherwise
                  requires, include corporations.
                </p>
                <h4 className="subheading mb-md">
                  2. Communication and Contact Details
                </h4>
                <p className="conditions-text mb-md">
                  2.1 If you wish to contact the Fixer with questions or
                  complaints, you may contact the Fixer by using the Bokafix
                  Platform.
                </p>
                <p className="conditions-text mb-lg">
                  2.2 In certain circumstances you must contact the Fixer in
                  writing &#40;as stated in various Clauses throughout this
                  Service Contract&#41;. When contacting the Fixer in writing
                  you will do so by using the Bokafix Platform.
                </p>
                <h4 className="subheading mb-md">
                  3. The Order and Order Details
                </h4>
                <p className="conditions-text mb-md">
                  3.1 A legally binding contract between you and the Fixer,
                  consisting of the Order Details and this Service Contract, has
                  been created for the Fixer to provide the Fixer Service and
                  for you to pay for them when ALL of the following events have
                  occurred:
                </p>
                <p className="conditions-text mb-md ml-md">
                  a&#41; you request a Fixer Service including the Start Time
                  you wish it to be carried out via the Bokafix Platform
                  &#40;the “Order”&#41;; and
                </p>
                <p className="conditions-text mb-md ml-md">
                  b&#41; you have accepted the Fixer as your Fixer to carry out
                  the requested Fixer Service as set out in the Order; and
                </p>
                <p className="conditions-text mb-md ml-md">
                  c&#41; you have agreed on; 1&#41; the pricing terms; 2&#41;
                  the Start Time; 3&#41; and 4&#41; any Out of Pocket Expenses,
                  if applicable; and
                </p>
                <p className="conditions-text mb-md ml-md">
                  d&#41; The Fixer has accepted you as his Customer. &#40;a
                  “Binding Order”&#41;
                </p>
                <p className="conditions-text mb-md">
                  3.2. All written communication between the Fixer and you with
                  regards to the Fixer Service and the Order can be found under
                  your profile in the Bokafix Platform.
                </p>
                <p className="conditions-text mb-lg">
                  3.3 A Binding Order can be terminated in accordance with
                  clause 12.
                </p>
                <h4 className="subheading mb-md">4. Changes to the Order</h4>
                <p className="conditions-text mb-md">
                  4.1. If the Customer makes changes to a Binding Order more
                  than 24 hours before the Start Time, that Binding Order is
                  cancelled, and a new Order has been made by the Customer. The
                  new Order is considered a Binding Order when ALL the steps in
                  clause 3.1 a&#41; – d&#41; have been carried out.
                </p>
                <p className="conditions-text mb-lg">
                  4.2 If a Fixer Service specified in the Order Details is more
                  complicated than expected or the Fixer can't carry out the
                  work due to inadequate information from you, the Fixer will
                  stop all work and if he can, provide you with a new estimated
                  price for the Fixer Service. If a new price is required, no
                  work will continue unless you agree to the additional work in
                  writing and the change is approved by you. If the Fixer can't
                  complete the Fixer Service, the Fixer will inform you the
                  reason he can't complete it. For these situations, you will be
                  charged for the time the Fixer has been at your Property, the
                  minimum charge is always a one-hour fee.
                </p>
                <h4 className="mb-md">5. Fees and Payment</h4>
                <p className="conditions-text">
                  5.1 When a Fixer Service is completed, the Fixer will send you
                  an invoice with the Final Fee via the Bokafix Platform
                  specifying:
                </p>
                <p className="conditions-text ml-md">
                  a&#41; the pricing terms of the Fixer Service provided by the
                  Fixer on and approved by you on the Bokafix Platform when
                  making an Order &#40;“Fixer Payment”&#41;;
                </p>
                <p className="conditions-text ml-md">
                  b&#41; any out-of-pocket expenses agreed upon by you and
                  submitted by the Fixer in connection with the Fixer Service
                  &#40;“Out of Pocket Expense”&#41;;
                </p>
                <p className="conditions-text ml-md">
                  c&#41; any expenses for materials used in order to carry out
                  the Fixer Service &#40;“Material Payment”&#41;; and
                </p>
                <p className="conditions-text">
                  5.2 When you receive confirmation through the Bokafix Platform
                  or via email that a Fixer Service has been completed, you
                  automatically authorise the PSP to process the
                  Invoice&#40;s&#41;. You may be billed a one-hour cancellation
                  charge as damages at the Fixer's hourly rate through the PSP
                  if you book a Fixer Service, but cancel it less than 24 hours
                  from the Start Time of the Fixer Service as set out in clause
                  12.
                </p>
                <p className="conditions-text">
                  5.3 The Final Fee are inclusive of VAT. If the rate of VAT
                  changes, the Fixer will adjust the amount of VAT that you must
                  pay.
                </p>
                <p className="conditions-text">
                  5.4 If you do not pay an invoice by the due date the Fixer may
                  charge you interest on the overdue sum at the rate of 8% above
                  the base rate of Bank of England from time to time until
                  payment is made in full. Interest will accrue on a daily basis
                  from the due date until the actual date of payment, whether
                  before or after judgment.
                </p>
                <p className="conditions-text mb-lg">
                  5.5 If you have promptly contacted the Fixer to dispute an
                  invoice in good faith, the Fixer will not charge interest
                  while such a dispute is ongoing.
                </p>
                <h4 className="mb-md">6. Fixer Services</h4>
                <p className="conditions-text mb-md">
                  6.1 The Fixer will provide the Fixer Services in accordance
                  with the specification set out in the Order Details and in the
                  Service Contract &#40;as may be amended by agreement between
                  you and the Fixer from time to time&#41;.
                </p>
                <p className="conditions-text mb-md">
                  6.2 The Fixer may provide sketches, impressions, plans or
                  similar documents in advance of the Fixer Service. Any such
                  material is intended for illustrative purposes only and is not
                  intended to provide an exact specification of the Fixer
                  Service nor to guarantee specific results.
                </p>
                <p className="conditions-text mb-md">
                  6.3 The Fixer will use reasonable endeavours to ensure that
                  the Materials he uses match those that are consistent
                  throughout the Property &#40;or relevant parts of the
                  Property&#41;. There may be slight variations to the same
                  Materials as a result of differences between photographs,
                  catalogues and other materials, and the Materials themselves,
                  or as a result of minor technical changes which will not
                  impact your use of the Material in question. Material
                  packaging may also vary.
                </p>
                <p className="conditions-text mb-md">
                  6.4 The responsibility &#40;sometimes referred to as the
                  “risk”&#41; for the Materials remains with the Fixer until
                  they have been delivered to you or installed in the Property
                  at which point it will pass to you.
                </p>
                <p className="conditions-text mb-md">
                  6.5 The Fixer will ensure that the Fixer Services are
                  performed with reasonable care and skill and to a reasonable
                  standard which is consistent with best trade practice.
                </p>
                <p className="conditions-text mb-md">
                  6.6 The Fixer will ensure that he complies with all relevant
                  codes of practice.
                </p>
                <p className="conditions-text mb-md">
                  6.7 The Fixer will ensure that no parts of the Property suffer
                  damage as a result of his provision of the Fixer Services. The
                  Fixer will make good any damage that occurs at no additional
                  expense to you as soon as is reasonably possible.
                </p>
                <p className="conditions-text mb-md">
                  6.8 The Fixer will properly dispose of all waste that results
                  from his provision of the Fixer Services.
                </p>
                <p className="conditions-text mb-lg">
                  6.9 Where a Fixer Service is to last for more than one working
                  day, the Fixer will, where reasonably possible, leave the
                  Property in a clean and tidy state and minimise any disruption
                  to your use and enjoyment of the Property while work is being
                  carried out. The Fixer will wherever possible store all tools
                  and materials only in areas where work is being carried out or
                  remove them from the Property at the end of each working day.
                </p>
                <h4 className="mb-md">7. Faulty Materials</h4>
                <p className="conditions-text mb-md">
                  7.1 If any Materials are supplied in the course of the Fixer
                  providing the Fixer Services, and you discover a defect with
                  one or more of those Materials, you should inform the Fixer
                  using the contact details supplied to you in the Bokafix
                  Platform.
                </p>
                <p className="conditions-text mb-md">
                  7.2 Within the first 30 calendar days, you are entitled, at
                  your option, to a full refund, to keep the Material&#40;s&#41;
                  at a reduced price, or to a repair or replacement.
                </p>
                <p className="conditions-text mb-md">
                  7.3 After the first 30 calendar days, and for the first six
                  months, the Fixer will, at his option, repair or replace any
                  defective Materials or, if a repair or replacement is not
                  practicable or possible, or if a repair or replacement is
                  unsuccessful, you are entitled to a full refund.
                  Alternatively, you may keep the Material&#40;s&#41; at a
                  reduced price. This right may not apply if the Fixer can prove
                  that the defect has been caused deliberately or negligently by
                  you, or as a result of your failure to follow instructions
                  given by the Fixer or as included with the Product.
                </p>
                <p className="conditions-text mb-md">
                  7.4 After the first six months, if any Material develops a
                  fault, you must prove that the Material in question was faulty
                  at the time the Fixer supplied it and You took ownership of
                  it. You may be entitled to a repair or replacement, or to a
                  partial refund for up to six years depending upon the nature
                  of the Product and how long it can reasonably be expected to
                  last.
                </p>
                <h4 className="mb-md">8. Guarantee</h4>
                <p className="conditions-text mb-md">
                  8.1 The Fixer provide to you, in addition to any statutory
                  rights which you may have, a guarantee that the Fixer Services
                  provided under this Service Contract shall be free from
                  defective or flawed materials or workmanship for a period of
                  12 months from the completion of the Fixer Services,
                  notwithstanding that this guarantee shall not apply to:
                </p>
                <p className="conditions-text mb-md ml-md">
                  a&#41; defects or flaws which are as a result of any misuse,
                  failure to adequately and properly maintain, neglect or
                  failure to follow instructions or recommendations on your
                  part; or
                </p>
                <p className="conditions-text mb-md ml-md">
                  b&#41; any defect or flaw which is caused by mechanical or
                  chemical damage &#40;which is not in itself a result of some
                  defect in the workmanship or materials&#41; and which arises
                  after risk has passed to the Customer.
                </p>
                <p className="conditions-text mb-md">
                  8.2 The Fixer shall, at his sole discretion, determine the
                  manner in which he will satisfy this guarantee, whether by
                  repairing, re-performing or replacing the Materials or by
                  refunding to you all or part of the monies which has been
                  paid.
                </p>
                <p className="conditions-text mb-md">
                  8.3 If you consider that the Fixer Services are defective upon
                  performance and/or installation then you must notify the Fixer
                  via the Bokafix Platform of this within 30 days, by failing to
                  do so means you are not entitled to claim the benefit of this
                  guarantee.
                </p>
                <p className="conditions-text mb-lg">
                  8.4 This guarantee shall not become effective until you have
                  paid the Fixer in full, failing to do this you are not
                  entitled to claim the benefit of this guarantee.
                </p>
                <h4 className="mb-md">9. Your Obligations</h4>
                <p className="conditions-text mb-md">
                  9.1 If any consents, licences, or other permissions are needed
                  from any third parties such as landlords, planning
                  authorities, local authorities or similar, you must obtain
                  them before the Fixer begins to provide the Fixer Services.
                </p>
                <p className="conditions-text mb-md">
                  9.2 The Fixer may ask you to move or remove certain furniture,
                  fixtures, and fittings in the Property before he begins work.
                  Unless you and the Fixer specifically agree otherwise, this is
                  your responsibility.
                </p>
                <p className="conditions-text mb-md">
                  9.3 You will ensure that the Fixer can access the Property at
                  the Start Time to provide the Fixer Services.
                </p>
                <p className="conditions-text mb-md">
                  9.4 If you do not provide the required access to the Property
                  or make it impossible for the Fixer to provide the Fixer
                  Services by failing to comply with any other provision in this
                  Clause 9, the Fixer may invoice you for any additional charges
                  incurred as a result and a minimum call out charge.
                </p>
                <p className="conditions-text mb-lg">
                  9.5 You must ensure that the Fixer has access to electrical
                  outlets and a supply of hot and cold running water.
                </p>
                <h4 className="mb-md">10. Complaints and Feedback</h4>
                <p className="conditions-text mb-md">
                  10.1 The Fixer welcomes feedback from his customers and, while
                  the Fixer always uses all reasonable endeavours to ensure that
                  your experience as a customer is a positive one, he
                  nevertheless wants to hear from you if you have any cause for
                  complaint.
                </p>
                <p className="conditions-text mb-lg">
                  10.2 All complaints in relation to the Fixer Service should be
                  directed to the Fixer.
                </p>
                <h4 className="mb-md">11. Changing the Order Details</h4>
                <p className="conditions-text mb-md">
                  11.1 If you ask the Fixer to change anything in the Order
                  Details:
                </p>
                <p className="conditions-text mb-md ml-md">
                  11.1.1 the Fixer will where reasonably possible agree a
                  revised Start Time with you;
                </p>
                <p className="conditions-text mb-md ml-md">
                  11.1.2 If it is not possible to agree a revised Start Time
                  either you or the Fixer may terminate the Service Contract
                  &#40;see Clause 15&#41;, if the termination is made less than
                  24 hours before the Start Time you will be charged a minimum
                  one-hour charge based on the Fixer's hourly rate.
                </p>
                <p className="conditions-text mb-md">
                  11.2 If the Fixer asks You to change the Start Time, you may
                  either:
                </p>
                <p className="conditions-text mb-md ml-md">
                  11.2.1 agree a revised Start Time with the Fixer; or
                </p>
                <p className="conditions-text mb-lg ml-md">
                  11.2.2 terminate this Service Contract &#40;see Clause 12&#41;
                  with no cancellation fee incurred.
                </p>
                <h4 className="mb-md">12. Cancellation and Termination</h4>
                <p className="conditions-text mb-md">
                  12.1 You may terminate the Service Contract &#40;i.e. cancel
                  the Fixer Service&#41; with immediate effect at any time in
                  the Bokafix Platform with more than 24 hours of its scheduled
                  Start Time with no charge or payment to the Fixer.
                </p>
                <p className="conditions-text mb-md">
                  12.2 If you terminate &#40;i.e. cancel the Fixer Service&#41;
                  within less than 24 hours of the confirmed Start Time the
                  Fixer will charge you a one-hour cancellation fee based on the
                  Fixer's hourly rate. Cancellation of an Instant Order will
                  always be subject to a one-hour cancellation fee.
                </p>
                <p className="conditions-text mb-lg">
                  12.3 The Fixer has a right to terminate &#40;i.e. cancel the
                  Fixer Service&#41; the Service Contract with immediate effect
                  at any time before or after the Start Time. Cancellations will
                  be notified in the Bokafix Platform. You will not be charged
                  anything if the Fixer terminates the Service Contract.
                </p>
                <h4 className="mb-md">13. Effects of Termination</h4>
                <p className="conditions-text mb-md">
                  13.1 If the Service Contract is terminated for any reason:
                </p>
                <p className="conditions-text ml-md">
                  13.1.1 Any Clauses which, either expressly or by their nature,
                  relate to the period after the expiry or termination of the
                  Service Contract will remain in full force and effect.
                </p>
                <p className="conditions-text mb-lg ml-md">
                  13.1.2 Termination will not remove or reduce any right to
                  damages or another remedy which either you or the Fixer may
                  have in respect of any breach of the Service Contract which
                  exists at or before the date of termination.
                </p>
                <h4 className="mb-md">14. Liability</h4>
                <p className="conditions-text mb-md">
                  14.1 The Fixer will be responsible for any foreseeable loss or
                  damage that you may suffer as a result of his breach of this
                  Service Contract or as a result of the Fixer's negligence.
                  Loss or damage is foreseeable if it is an obvious consequence
                  of the breach or negligence or if it is contemplated by you
                  and the Fixer when the Service Contract is entered into. The
                  Fixer will not be responsible for any loss or damage that is
                  not foreseeable.
                </p>
                <p className="conditions-text mb-md">
                  14.2 The Fixer will maintain suitable and valid insurance
                  including public liability insurance.
                </p>
                <p className="conditions-text mb-md">
                  14.3 The Fixer provides Fixer Services for domestic and
                  private purposes only. The Fixer makes no warranty or
                  representation that the Services are fit for commercial,
                  business, or industrial purposes of any kind. The Fixer will
                  not be liable to you for any loss of profit, loss of business,
                  interruption to business or for any loss of business
                  opportunity.
                </p>
                <p className="conditions-text mb-md">
                  14.4 If the Fixer causes any damage to the Property, he will
                  make good that damage at no additional cost to you. The Fixer
                  is not responsible for any pre-existing faults or damage in or
                  to your property that he may discover while providing the
                  Fixer Services.
                </p>
                <p className="conditions-text mb-md">
                  14.5 The Fixer is not liable for any loss or damage you suffer
                  which results from your failure to follow any reasonable
                  instructions given by the Fixer or the Fixer's employees or
                  assistants.
                </p>
                <p className="conditions-text mb-md">
                  14.6 Nothing in this Service Contract is intended to or will
                  limit or exclude his liability for death or personal injury
                  caused by his negligence or for fraud or fraudulent
                  misrepresentation.
                </p>
                <p className="conditions-text mb-lg">
                  14.7 Nothing in this Service Contract is intended to or will
                  limit your legal rights as a consumer under any Consumer Law.
                  For more details of your legal rights please refer to your
                  local Citizens Advice Bureau or Trading Standards Office in
                  the UK, Konsumentverket in Sweden and/or Competition and
                  Consumer Protection Commission in Ireland.
                </p>
                <h4 className="mb-md">
                  15. How the Fixer Use Your Personal Data &#40;Data
                  Protection&#41;
                </h4>
                <p className="conditions-text mb-lg">
                  The Fixer will only use your personal data as set out in his
                  Privacy Policy that the Fixer will provide to you on request.
                </p>
                <h4 className="mb-md">16. Other Important Terms</h4>
                <p className="conditions-text mb-md">
                  16.1 This Service Contract may from time to time change
                  without giving you notice, but if changed the Fixer will use
                  his reasonable endeavours to inform you as soon as is
                  reasonably possible of any such changes.
                </p>
                <p className="conditions-text mb-md">
                  16.2 You may not transfer &#40;assign&#41; your obligations
                  and rights under the Service Contract without the Fixer's
                  express written permission &#40;such permission not to be
                  unreasonably withheld&#41;.
                </p>
                <p className="conditions-text mb-md">
                  16.3 The Service Contract is between you and the Fixer. It is
                  not intended to benefit any other person or third party in any
                  way and no such person or party will be entitled to enforce
                  any provision of the Service Contract.
                </p>
                <p className="conditions-text mb-md">
                  16.4 If any provision of the Service Contract is held by any
                  competent authority to be invalid or unenforceable in whole or
                  in part, the validity of the other provisions of the Service
                  Contract and the remainder of the provision in question will
                  not be affected.
                </p>
                <p className="conditions-text mb-lg">
                  16.5 No failure or delay by the Fixer or you in exercising any
                  rights under the Service Contract means that he or you have
                  waived that right, and no waiver by him or you of a breach of
                  any provision of the Service Contract means that the Fixer or
                  you will waive any subsequent breach of the same or any other
                  provision.
                </p>
                <h4 className="mb-md">17. Regulations and Information</h4>
                <p className="conditions-text mb-md">
                  17.1 For Fixer Services ordered in the UK, the Fixer is
                  required by Consumer Law to ensure that certain information is
                  given or made available to you as a consumer before the Fixer
                  makes the contract with you &#40;i.e. before you have accepted
                  the Order Details and the Service Contract&#41; except where
                  that information is already apparent from the context of the
                  transaction. The Fixer has included the information itself,
                  either in the Service Contract or Order Details for you to
                  see, or the Fixer will make it available to you before the
                  Service Contract is signed and you accept the Order Details.
                  All of that information will, as required by the Consumer Law,
                  be part of the terms of the Fixer's contract with you as a
                  consumer.
                </p>
                <p className="conditions-text mb-md">
                  17.2 As required by the Consumer Law:
                </p>
                <p className="conditions-text mb-md ml-md">
                  17.2.1 all of the information described in sub-Clause 17.1 ;
                  and
                </p>
                <p className="conditions-text mb-lg ml-md">
                  17.2.2 any other information which the Fixer gives to You
                  about the Fixer Services or his business which you take into
                  account when deciding to accept the Order Details and sign the
                  Service Contract, or when making any other decision about the
                  Fixer Services,
                </p>
                <h4 className="mb-md">18. Law and Jurisdiction</h4>
                <p className="conditions-text mb-md">
                  18.1 If you reside and order Fixer Services' in the UK:
                </p>
                <p className="conditions-text mb-md ml-md">
                  a&#41; the Service Contract and the relationship between you
                  and the Fixer &#40;whether contractual or otherwise&#41; shall
                  be governed by and construed in accordance with the law of
                  England &amp; Wales.
                </p>
                <p className="conditions-text mb-md ml-md">
                  b&#41; Any dispute, controversy, proceedings or claim between
                  you and the Fixer relating to the Service Contract or the
                  relationship between you and the Fixer &#40;whether
                  contractual or otherwise&#41; shall be subject to the
                  jurisdiction of the courts of England, Wales, Scotland or
                  Northern Ireland, as determined by your residency.
                </p>
                <p className="conditions-text mb-md">
                  18.2 If you reside and order Fixer Services in Ireland;
                </p>
                <p className="conditions-text mb-md ml-md">
                  a&#41; The Service Contract and the relationship between you
                  and the FIxer &#40;whether contractual or otherwise&#41; shall
                  be governed by, and construed in accordance with the law of
                  Ireland.
                </p>
                <p className="conditions-text mb-md ml-md">
                  b&#41; Any dispute, controversy, proceedings or claim between
                  you and the Fixer relating to the Service Contract or the
                  relationship between you and the Fixer &#40;whether
                  contractual or otherwise&#41; shall be subject to the
                  jurisdiction of the courts of Ireland, as determined by your
                  residency.
                </p>
                <p className="conditions-text mb-md">
                  18.3 If you reside and order Fixer Services in Sweden:
                </p>
                <p className="conditions-text mb-md ml-md">
                  a&#41; If you reside and order Fixer Services in Sweden, the
                  Service Contract and the relationship between you and the
                  Fixer &#40;whether contractual or otherwise&#41; shall be
                  governed by, and construed in accordance with the law of
                  Sweden.
                </p>
                <p className="conditions-text mb-md ml-md">
                  b&#41; Any dispute, controversy, proceedings or claim between
                  you and the Fixer relating to these Service Contracts or the
                  relationship between you and the Fixer &#40;whether
                  contractual or otherwise&#41; shall be subject to the
                  jurisdiction of the courts of Sweden, as determined by your
                  residency.
                </p>
                <p className="conditions-text mb-md">
                  18.4 As a consumer, you will benefit from any mandatory
                  provisions of the law in your country of residence. Nothing in
                  clauses 18.1-18.3 above takes away or reduces your rights as a
                  consumer to rely on those provisions.
                </p>
              </div>
            </div>
          </main>

          <BecomeFixerBanner />
        </div>
      </Container>
    </div>
  )
}

export default ServiceTerms

const Container = styled.div`
  .container {
    max-width: 852px;
    margin: 0 auto;
    margin-top: 198px;
    padding: 10px;
  }
  .position-relative {
    position: relative;
  }

  @media screen and (max-width: 768px) {
    .container {
      margin-top: 10px;
    }
  }
  .heading {
    text-align: center;
    font: normal normal 900 63px/64px Avenir;
    margin-bottom: 96px;
  }

  .conditions-text {
    font: normal normal medium 16px/26px Avenir;
    margin-bottom: 18px;
    letter-spacing: 0.11px;
  }

  .mb-lg {
    margin-bottom: 48px;
  }

  .mb-md {
    margin-bottom: 18px;
  }

  @media (max-width: 900px) {
    .mb-lg {
      margin-bottom: 18px;
    }
  }

  @media (max-width: 900px) {
    .conditions-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.13125px;

      color: #444444;
    }

    h4,
    .subtitle,
    .subheading,
    .bold-p,
    .definition {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.13125px;

      color: #444444;
    }
  }

  .ml-md {
    margin-left: 18px;
  }

  .bold-p {
    font: normal normal bold 16px/26px Avenir;
  }

  .subtitle {
    font: normal normal 900 16px/26px Avenir;
  }

  .term {
    display: flex;
    justify-content: space-between;
  }

  .definition {
    min-width: 30%;
  }

  .meaning {
    width: 100%;
  }
`
