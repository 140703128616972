import React, { useState } from 'react'
import styled from 'styled-components'
import posswordblob from '../assets/global/blobs/fred-with-padlock.png'
import MainButton from '../components/common/MainButton'
import lock from '../assets/global/modals/lock.svg'
import crosseyes from '../assets/global/modals/crossed-eye-logo-grey.png'
import eye from '../assets/global/modals/uncrossed-eye-logo-grey.svg'
import { useTranslation } from 'react-i18next'
import { confirmPasswordReset, sendPasswordResetEmail, verifyPasswordResetCode } from 'firebase/auth'
import { auth } from '../api/firebaseConfig'
import { useNavigate } from 'react-router-dom'


const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState<string>('')
  /* Show password */
  const [visible, setvisible] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const toggleVisiblilty: React.MouseEventHandler<HTMLDivElement> = () => {
    setvisible((prev) => !prev)
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
  }

  function getParameterByName(name:string){
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( window.location.href );
    if( results == null )
      return "";
    else
      return decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  function resetPassword() {
  const actionCode = getParameterByName('oobCode');

    try{
      verifyPasswordResetCode(auth, actionCode).then((_) => {
        confirmPasswordReset(auth, actionCode, inputValue).then(() => {
        }).catch((error) => {
          if(error.toString() == "auth/invalid-action-code"){ 
            
            console.error('auth/invalid-action-code');  
          }
          if(error.toString() == "auth/missing-email")
          {
            console.error('auth/missing-email');  
          }
        });
      })
    }catch(e){
      console.warn(e);

    }
    
}

  function onSubmit(event: React.SyntheticEvent<HTMLButtonElement>) {
    event.preventDefault()
    if (
      inputValue === '' ||
      inputValue.length < 6 ||
      !String(inputValue).trim()
    ) {
      return setIsValid(true)
    }
    resetPassword()
    setInputValue('')
    setIsValid(false)
    navigate('/success-reset-password', { replace: true })
  }

  const { t } = useTranslation()

  return (
    <Container>
      <MobileElement>
        <CardMobile>
          <div className="heading-container-reset">
            <p>{t('reset-password-page.mobile-heading')}</p>
          </div>
          <div className="input-container-reset">
            <div className="input-box-lock-label">
              <img src={lock} alt="lock" />
            </div>
            <div onClick={toggleVisiblilty} className="input-box-eye-label">
              <img
                src={visible ? crosseyes : eye}
                alt={visible ? 'crossed eye' : 'eye'}
              />
            </div>
            <form action="">
            <label>
              <input
                value={inputValue}
                onChange={onChange}
                placeholder={t('form.reset-password')}
                type={visible ? 'text' : 'password'}
              />
              {isValid ? <span>{t('errors.invalid-password')}</span> : null}
            </label>
            </form>
            
          </div>
          <div className="btn-container-reset">
            <MainButton
              handleClick={onSubmit}
              isLarge={true}
              isLight={true}
              buttonName={t('reset-password-page.button-text')}
            />
          </div>
        </CardMobile>
      </MobileElement>
      <DesktopElement>
        <CardDesktop>
          <div className="heading-reset">
            <h1>{t('reset-password-page.dekstop-heading')}</h1>
          </div>
          <div className="image-container-reset">
            <img src={posswordblob} alt="password blob" />
          </div>
          <div className="input-container-reset">
            <label>
              <input
                value={inputValue}
                onChange={onChange}
                type="text"
                placeholder={t('form.password')}
              />
              {isValid ? <span>{t('errors.invalid-password')}</span> : null}
            </label>
          </div>
          <div className="btn-container-reset">
            <MainButton
              handleClick={onSubmit}
              isLarge={true}
              isLight={true}
              buttonName={t('reset-password-page.button-text')}
            />
          </div>
          <div className="link-container-reset">
            <a href="#">{t('button.login')}</a>
          </div>
        </CardDesktop>
      </DesktopElement>
    </Container>
  )
}

export default ResetPasswordPage

const Container = styled.div`
  /* height: 100vh; */
`

const MobileElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media screen and (min-width: 700px) {
    display: none;
  }
`
const DesktopElement = styled.div`
  width: 100%;
  display: none;
  @media screen and (min-width: 700px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

const CardDesktop = styled.div`
  max-width: 895px;
  position: relative;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #ffffff;
  padding: 3rem 5rem 2rem 5rem;
  border-radius: 5px;
  .heading-reset {
    margin-bottom: 30px;
    h1 {
      color: #3d3d3d;
      font-weight: 300;
      text-align: start;
      font-size: 35px;
      letter-spacing: 1.5px;
    }
  }
  .image-container-reset {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .input-container-reset {
    margin-bottom: 20px;
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    input {
      width: 100%;
      padding: 10px;
      height: 48px;
      border: none;
      background: #efecef;
      border-radius: 2.5px;
    }
  }
  .btn-container-reset {
    margin-bottom: 30px;
    button {
      height: 48px;
    }
  }
  .link-container-reset {
    text-align: center;
    a {
      color: #727272;
      letter-spacing: 1px;
    }
  }
`

const CardMobile = styled.div`
  margin-top: 8rem;
  background: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  max-width: 345px;
  height: 240px;
  width: 100%;
  .heading-container-reset {
    background: #34344a;
    padding: 2.5rem;
    height: 60px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 500;
      color: #ffffff;
      font-size: 16px;
    }
  }
  .input-container-reset {
    position: relative;
    .input-box-eye-label {
      width: 25px;
      height: 25px;
      border-radius: 2.5px;
      position: absolute;
      top: 21px;
      right: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px;
      img {
        width: 30px;
      }
    }
    .input-box-lock-label {
      width: 25px;
      height: 25px;
      border-radius: 2.5px;
      background: #e8e8e8;
      position: absolute;
      top: 21px;
      left: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
    label {
      span {
        color: red;
        font-weight: bold;
        font-size: 0.8rem;
      }
    }
    padding: 1rem;
    input {
      width: 100%;
      padding: 0 3.5rem;
      background: #f5f5f5;
      border: none;
      height: 50px;
    }
  }
  .btn-container-reset {
    padding: 0 1rem;
    button {
      width: 100%;
      padding: 0.5rem;
      height: 50px;
    }
  }
`
