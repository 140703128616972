import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from './slice/AuthSlice'
import ModalSlice from './slice/ModalSlice'
import NavSlice from './slice/NavSlice'
import TitleSlice from './slice/TitleSlice'
import DotSlice from './slice/DotSlice'
import FixerInfoSlice from './slice/FixerInfoSlice'
import UserDataSlice from './slice/UserDataSlice'

export const store = configureStore({
  reducer: {
    modalReducer: ModalSlice,
    userReducer: AuthSlice,
    navReducer: NavSlice,
    titleReducer: TitleSlice,
    dotReducer: DotSlice,
    fixerInfoReducer: FixerInfoSlice,
    userDataReducer: UserDataSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>