import styled from 'styled-components'
import dropdown from '../../assets/global/icons/dropdown-icon.svg'
export const Button = styled.button`
  font-size: 16px;
  &:disabled {
    opacity: 0.7;
  }
  &.orange {
    background-color: #f27649;
  }
  outline: none;
  border: none;
  padding: 14px 38px;
  background-color: #34344a;
  border-radius: 4px;
  font-weight: 900;
  color: white;
  font-family: 'Avenir', Arial, sans-serif;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s;
  }
`
export const Input = styled.input`
  background-color: #efecef;
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #727272;
  font-weight: 900;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

export const Option = styled.option`
  width: 100%;
`

export const Select = styled.select.attrs((props) => ({
  type: 'text',
  dropdown: (props as any).dropdown || dropdown,
}))`
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #727272;
  font-weight: 900;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent url(${(props) => props.dropdown}) no-repeat 100%
    center;
  background-color: #efecef;
  background-position: center right 20px;
  text-transform: capitalize;
`
