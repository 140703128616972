import React, { useEffect, useState } from 'react'
import placeholder from './placeholder.svg'
import { IArticle } from './interfaces'
import { useNavigate } from 'react-router-dom'
import { trimString } from './utils'
import {
  HowItWorksBackgroundDesktop,
  HowItWorksBackgroundMobile,
} from '../../components/Background'
import FirebaseAPI from '../../firebase-api/FirebaseAPI'
import {
  PageTitle,
  BlogWrapper,
  NavWrapper,
  NavItem,
  ContentWrapper,
  ArticlesWrapper,
  Article,
  ArticleTitle,
  ArticleMetaData,
  ArticleImage,
  ArticleSummary,
  ArticleMetaDataItem,
  ButtonWrapper,
  NavSelect,
  NavOption,
} from './blog-components'
import { Button } from '../../components/component-library/index'
import { setIsOpen } from '../../store/slice/ModalSlice'
import { useDispatch } from 'react-redux'
import usePageParams from '../../utils/usePageParams'
import { useTranslation } from 'react-i18next'
import { DocumentData } from 'firebase/firestore'
import BecomeFixerBanner from '../../components/common/BecomeFixerBanner/BecomeFixerBanner'

const articleTypes = ['View all', 'News', 'Blog', 'Video']
const activeLinkStyle = { color: 'black', transition: 'all 0.3s ease-in-out' }
const ARTICLES_PER_PAGE = 9

function Blog(props: { initialCategory?: string }) {
  const [articlesPerPage, setArticlesPerPage] = useState(9)
  const [currentType, setCurrentType] = useState(
    props.initialCategory || 'View all'
  )
  const [articles, setArticles] = useState<DocumentData>([])
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const { t } = useTranslation()

  const dispatch = useDispatch()
  usePageParams('Blog', 'customerPage', '', '', true)
  const navigate = useNavigate()

  const increaseArticlesNumber = () => {
    setArticlesPerPage(articlesPerPage + ARTICLES_PER_PAGE)
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  const changeArticleType = (type: string) => {
    if (type !== currentType) setCurrentType(type)
  }

  useEffect(() => {
    if (currentType === 'View all') {
      FirebaseAPI.getBlogPosts(articlesPerPage, false).then((articles) => {
        setArticles(articles)
      })
    } else {
      FirebaseAPI.getBlogPosts(articlesPerPage, false, currentType).then(
        (articles) => {
          setArticles(articles)
        }
      )
    }
  }, [currentType, articlesPerPage])

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const DesktopNav = () => {
    return articleTypes.map((type) => (
      <NavItem
        key={type}
        onClick={() => changeArticleType(type)}
        style={currentType === type ? activeLinkStyle : {}}
      >
        {type}
      </NavItem>
    ))
  }

  const MobileNav = () => {
    return (
      <NavSelect
        onChange={(event) => {
          changeArticleType(event.target.value)
        }}
      >
        {articleTypes.map((type) => (
          <NavOption key={type} value={type}>
            {type}
          </NavOption>
        ))}
      </NavSelect>
    )
  }

  return (
    <BlogWrapper>
      {isDesktop ? (
        <HowItWorksBackgroundDesktop />
      ) : (
        <HowItWorksBackgroundMobile />
      )}
      <ContentWrapper>
        {isDesktop ? <PageTitle>Blog</PageTitle> : null}
        <NavWrapper>{isDesktop ? DesktopNav() : MobileNav()}</NavWrapper>
        <ArticlesWrapper>
          {articles.length > 0 &&
            articles.map((article: IArticle, key: number) => {
              return (
                <Article href={'blog/' + article.id} key={article.id}>
                  <ArticleImage
                    src={article?.articlePreviewImage || placeholder}
                  />
                  <div>
                    <ArticleMetaData>
                      <ArticleMetaDataItem>
                        {article.articleType}
                      </ArticleMetaDataItem>{' '}
                      {'|'}
                      <ArticleMetaDataItem>
                        {new Date(article.date).toLocaleDateString('en-GB')}
                      </ArticleMetaDataItem>
                    </ArticleMetaData>
                    <ArticleTitle>{article.title}</ArticleTitle>
                    <ArticleSummary>
                      {trimString(article.summary, 120)}
                    </ArticleSummary>
                  </div>
                </Article>
              )
            })}
        </ArticlesWrapper>
        <ButtonWrapper>
          <Button
            name={'+ More options'}
            fullSize={false}
            handleClick={increaseArticlesNumber}
          ></Button>
        </ButtonWrapper>
      </ContentWrapper>
      <BecomeFixerBanner />
    </BlogWrapper>
  )
}

export default Blog
