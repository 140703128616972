import React from 'react'
import styles from './MyBookings.module.css'
import { Button } from '../../../components/component-library/index'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function NoBookings() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className={styles.noBookings}>
      <h3 className={styles.noBookingsTitle}>
        {t('customer-bookings.no-bookings')}
      </h3>
      <Button
        handleClick={() => navigate('/working-on-it')}
        fullSize={true}
        name={t('button.book-a-fix')}
      />
    </div>
  )
}

export default NoBookings
