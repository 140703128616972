import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage'
import { storage } from '../../api/firebaseConfig'
import { useEffect, useState } from 'react'
import { FileInput } from '../../components/component-library/index'
import { useTranslation } from 'react-i18next'

interface IFileProps<T> {
  id: string
  fileName: string
  folderName: string
  imageUrl: string
  handleState: T
}
export function FileUpload<T>(props: IFileProps<T>) {
  const { t } = useTranslation()
  const [image, setimage] = useState(props.imageUrl)
  const [imageName, setimageName] = useState('')
  const [_childData, setChildData] = useState('' as any)

  async function passimageData(data: Blob | MediaSource) {
    setimage(URL.createObjectURL(data))
    setChildData(data)
    setimageName((data as unknown as Record<string, string>).name)
  }
  useEffect(() => {
    if (_childData) {
      submitFile()
    }
  }, [_childData])

  async function submitFile() {
    const currentDate = new Date()
    const storageLogoRef = ref(
      storage,
      `/${props.folderName}/${currentDate.toISOString()}.png`
    )
    const uploadTask = uploadBytesResumable(storageLogoRef, _childData)
    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          ;(props.handleState as any)(url)
        })
      }
    )
  }
  return (
    <div style={{ cursor: 'pointer' }}>
      {FileInput({
        leftButtonName: t('button.browse'),
        imageAlt: imageName,
        imageName: imageName != '' ? imageName : props.fileName,
        image: image,
        id: props.id,
        acceptedDataTypes: '.png',
        data: (e: any) => {
          const file = e.target[0]?.files[0]
          setChildData(file)
        },
        passChildData: passimageData,
      })}
    </div>
  )
}

export default FileUpload
