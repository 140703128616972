import styled from 'styled-components'
import dropdown from '../../assets/dropdown-icon.svg'

export const Option = styled.option`
  width: 100%;
`

export const Select = styled.select`
  padding: 14px 16px 12px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #727272;
  font-weight: 900;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent url(${dropdown}) no-repeat 100% center;
  background-color: #efecef;
  background-position: center right 20px;
  text-transform: capitalize;
`