import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '../components/component-library/index'
import phoneDashboard from '../assets/global/how-it-works/phone-dashboard-customer.png'
import phoneChooseTrade from '../assets/global/how-it-works/phone-choose-trade.png'
import phoneChooseFixer from '../assets/global/how-it-works/phone-choose-fixer.png'
import phoneApproveWork from '../assets/global/how-it-works/phone-approve-work.png'
import phoneOnTheWay from '../assets/global/how-it-works/phone-on-the-way.png'
import phoneMessages from '../assets/global/how-it-works/phone-messages.png'
import paulaWrench from '../assets/global/blobs/paula-with-wrench.png'
import fredPlunger from '../assets/global/blobs/fred-with-plunger.png'
import myaPainter from '../assets/global/blobs/mya-with-paint-roller.png'
import ralphSaw from '../assets/global/blobs/ralph-with-wood-saw.png'
import jeffDrill from '../assets/global/blobs/jeff-with-drill.png'
import sanjayPlank from '../assets/global/blobs/sanjay-with-wood-plank.png'
import verifiedFixer from '../assets/global/how-it-works/verified-fixer-icon.png'
import trophee from '../assets/global/how-it-works/trophee.png'
import sparklingStar from '../assets/global/how-it-works/sparkling-star.png'
import thumbUp from '../assets/global/how-it-works/thumbs-up.png'
import mapLocation from '../assets/global/how-it-works/map-location.png'
import coinPound from '../assets/global/how-it-works/coin-with-pound-symbol.png'
import { HowItWorksBackgroundDesktop } from '../components/Background'
import { HowItWorksBackgroundMobile } from '../components/Background'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import appstore from '../assets/global/non-bokafix-logos/apple-app-banner.svg'
import googleplay from '../assets/global/non-bokafix-logos/google-app-banner.svg'
import ralphBanner from '../assets/global/blobs/ralph-banner.svg'
import myaBanner from '../assets/global/blobs/mya-banner.svg'
import closeBanner from '../assets/global/non-bokafix-logos/close-banner.svg'
import closeBannerMobile from '../assets/global/non-bokafix-logos/close-banner-mobile.svg'
import { useNavigate } from 'react-router-dom'
import { CloseButton } from '../components/ui/ModalUI'
const HowItWorks: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('How it works', 'customerPage', 'Howitworks', '', true)
  let navigate = useNavigate()
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }
  const openStore = (button: number) => {
    let url
    if (button == 1) {
      url =
        'https://play.google.com/store/apps/details?id=com.bokafix.customer&pli=1'
    } else if (button == 2) {
      url = 'https://apps.apple.com/se/app/bokafix/id1606480773'
    }
    window.open(url, '_blank')
  }
  const [isBannerVisible, setIsBannerVisible] = useState(true)
  const CloseBannerButton = () => {
    setIsBannerVisible(false)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const handleClick = () => {
    // Wrapped in a <a>tag</a>
    // useEffect(() => {
    // navigate('/working-on-it')
    // })
  }

  return (
    <div>
      {isDesktop ? (
        <HowItWorksBackgroundDesktop />
      ) : (
        <HowItWorksBackgroundMobile />
      )}

      <Container>
        {isBannerVisible && isDesktop ? (
          <div className="banner-box">
            <img
              src={ralphBanner}
              alt="ralphbanner"
              className="ralph-container"
            />
            <div className="banner-middle">
              <div className="banner-title">Download the app</div>
              <div className="group-icons">
                <button className="button-link" onClick={() => openStore(1)}>
                  <img src={googleplay} alt="googleplay" />
                </button>
                <button className="button-link" onClick={() => openStore(2)}>
                  <img src={appstore} alt="appstore" />
                </button>
              </div>
            </div>
            <button className="close-button" onClick={CloseBannerButton}>
              <img src={closeBanner} alt="closeBanner " />
            </button>
            <img src={myaBanner} alt="myaBanner " className="mya-container" />
          </div>
        ) : (
          isBannerVisible && (
            <div className="banner-box">
              <img
                src={ralphBanner}
                alt="ralphbanner"
                className="ralph-container"
              />
              <div className="banner-middle">
                <div className="banner-title">Download the app</div>
                <div className="group-icons">
                  <button className="button-link" onClick={() => openStore(1)}>
                    <img src={googleplay} alt="googleplay" />
                  </button>
                  <button className="button-link" onClick={() => openStore(2)}>
                    <img src={appstore} alt="appstore" />
                  </button>
                </div>
              </div>
              <button className="close-button" onClick={CloseBannerButton}>
                <img src={closeBannerMobile} alt="closeBanner " />
              </button>
              <img src={myaBanner} alt="myaBanner " className="mya-container" />
            </div>
          )
        )}
        <div className="main">
          <h1>{t('how-it-works.how-it-works.title')}</h1>
          <p className="bold bold2 narrow first">
            {t('how-it-works.how-it-works.subtitle')}
          </p>
          <p className="bold width-limiter first">
            {t('how-it-works.how-it-works.sub-subtitle')}
          </p>
          <div className="phones-container">
            <div className="phone-and-caption-how-it-works">
              <p className="bold numbered">
                {t('how-it-works.how-it-works.captions.one')}
              </p>
              <img
                className="phone-dashboard"
                src={phoneDashboard}
                alt="phone dashboard"
              />
            </div>
            <div className="phone-and-caption-how-it-works">
              <p className="bold numbered">
                {t('how-it-works.how-it-works.captions.two')}
              </p>
              <img src={phoneChooseTrade} alt="phone choose trade" />
            </div>
            <div className="phone-and-caption-how-it-works">
              <p className="bold numbered">
                {t('how-it-works.how-it-works.captions.three')}
              </p>
              <img src={phoneChooseFixer} alt="phone choose fixer" />
            </div>
            <div className="phone-and-caption-how-it-works">
              <p className="bold numbered">
                {t('how-it-works.how-it-works.captions.four')}
              </p>
              <img src={phoneApproveWork} alt="phone approve work" />
            </div>
          </div>
          <p className="p">{t('how-it-works.how-it-works.paragraph-1')}</p>
          <p className="p">{t('how-it-works.how-it-works.paragraph-2')}</p>
          <p className="p twelve">
            {t('how-it-works.how-it-works.paragraph-3')}
          </p>
          {isDesktop ? (
            <p className="p">
              {t('how-it-works.how-it-works.paragraph-4')}{' '}
              {t('how-it-works.how-it-works.paragraph-5')}
            </p>
          ) : (
            <div>
              <p className="p">{t('how-it-works.how-it-works.paragraph-4')}</p>
              <p className="p gap">
                {t('how-it-works.how-it-works.paragraph-5')}
              </p>
            </div>
          )}
          <p className="bold bold2 narrow margin">
            {t('how-it-works.how-it-works.paragraph-6')}
          </p>
          <div className="button-container">
            <a href="https://bokafix.com/download-customer-app">
              <Button
                fullSize={true}
                name={t('how-it-works.how-it-works.button')}
                handleClick={handleClick}
              />
            </a>
          </div>
          <h1>{t('how-it-works.track-your-fixer.title')}</h1>
          <div className="phones-container">
            <div className="phone-and-caption">
              <p className="bold numbered">
                {t('how-it-works.track-your-fixer.captions.one')}
              </p>
              <img src={phoneOnTheWay} alt="phone on the way" />
            </div>
            <div className="phone-and-caption">
              <p className="bold numbered">
                {t('how-it-works.track-your-fixer.captions.two')}
              </p>
              <img src={phoneMessages} alt="phone message" />
            </div>
          </div>
          <p className="p">{t('how-it-works.track-your-fixer.paragraph-1')}</p>
          <p className="bold bold2 narrow margin">
            {t('how-it-works.track-your-fixer.paragraph-2')}
          </p>
          <div className="button-container">
            <a href="https://bokafix.com/download-customer-app">
              <Button
                handleClick={() => {} /* handleClick */}
                fullSize={true}
                name={t('how-it-works.track-your-fixer.button')}
              />
            </a>
          </div>
          <section className="fixers">
            <h2>{t('how-it-works.meet-our-fixers.title')}</h2>
            <div className="first-blobs">
              <img className="paulaWrench" src={paulaWrench} />
              <img className="fredPlunger" src={fredPlunger} />
              <img className="myaPainter" src={myaPainter} />
            </div>
            <div className="meet-text">
              <div className="icon-and-text">
                <img src={verifiedFixer} alt="verified fixer icon" width={29} />
                <p>{t('how-it-works.meet-our-fixers.one')}</p>
              </div>
              <div className="icon-and-text">
                <img src={trophee} alt="trophee icon" width={29} />
                <p>{t('how-it-works.meet-our-fixers.two')}</p>
              </div>
              <div className="icon-and-text">
                <img src={sparklingStar} alt="sparkling star" width={29} />
                <p>{t('how-it-works.meet-our-fixers.three')}</p>
              </div>
              <div className="icon-and-text">
                <img src={thumbUp} alt="thumb up" width={29} />
                <p>{t('how-it-works.meet-our-fixers.four')}</p>
              </div>
              <div className="icon-and-text">
                <img src={mapLocation} alt="map Location" width={29} />
                <p>{t('how-it-works.meet-our-fixers.five')}</p>
              </div>
              <div className="icon-and-text">
                <img src={coinPound} alt="Pound Coin icon" width={29} />
                <p>{t('how-it-works.meet-our-fixers.six')}</p>
              </div>
            </div>
            <div className="second-blobs">
              <img
                className="ralphSaw"
                src={ralphSaw}
                alt="Ralph blob with a handsaw"
              />
              <img
                className="jeffDrill"
                src={jeffDrill}
                alt="Jeff blob with drill"
              />
              <img
                className="sanjayPlank"
                src={sanjayPlank}
                alt="Sanjay blob with a plank"
              />
            </div>
          </section>
        </div>
      </Container>
      <FixerArmy />
    </div>
  )
}
export default HowItWorks

const Container = styled.div`
  .main {
    padding: 40px 20px;
    font-family: Avenir;
    font-size: 13px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* min-height: ; */
  }

  h1 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  .bold {
    font-size: 16px;
    font-weight: 500;
  }

  .narrow {
    margin: 0 35px;
  }

  .first {
    margin-bottom: 20px;
  }

  .p {
    margin: 8px 0;
    font-family: Avenir;
    font-weight: 300;
    line-height: 20px;
  }

  .margin {
    margin: 30px 60px;
  }
  .phone {
    height: 100px;
    width: 100px;
  }

  .phones-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .phone-and-caption {
    width: 100%;
    p {
      width: 100%;
      color: #3d3d3d;
    }
    img {
      width: 100%;
    }
  }
  .phone-and-caption-how-it-works {
    width: 100%;
    p {
      width: 100%;
      color: #3d3d3d;
    }
    img {
      width: 75%;
      height: 75%;
    }
  }

  .button-container {
    width: 200px;
    margin-bottom: 75px;
  }

  h2 {
    font-size: 28px;
    font-weight: 800;
  }

  .first-blobs {
    margin: 20px 0;
  }

  .icon-and-text {
    display: flex;
    align-items: center;
    padding-left: 22%;
    margin: 20px 0;
    display: flex;
  }
  .meet-text p {
    font-family: Avenir-light;
    color: #3d3d3d;
    margin-left: 20px;
  }

  .second-blobs {
    margin: 20px 0;
  }

  .paulaWrench {
    height: 93px;
  }
  .fredPlunger {
    height: 84px;
  }
  .myaPainter {
    height: 140px;
  }
  .ralphSaw {
    height: 101px;
  }
  .jeffDrill {
    height: 72px;
  }
  .sanjayPlank {
    height: 115px;
  }
  .twelve {
    font-size: 20px;
    font-family: Avenir-heavy;
  }
  .banner-box {
    background-color: #f27649;
    width: 100%;
    display: flex;
    height: 250px;
    flex-direction: row;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  .banner-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    row-gap: 20px;
  }
  .group-icons {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
  }
  .button-link {
    width: 243px;
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
  }

  .button-link img {
    max-width: 100%;
    max-height: 100%;
  }
  .close-button {
    position: absolute;
    border: none;
    cursor: pointer;
    appearance: none;
    background-color: inherit;
    top: 10px;
    right: 10px;
    z-index: 1;
    border-radius: 50%;
  }
  .mya-container {
    width: 231px;
    position: absolute;
    top: 70px;
    right: 10px;
  }
  .ralph-container {
    width: 264px;
    position: absolute;
    top: 70px;
    left: -35px;
  }
  .banner-title {
    grid-area: title;
    justify-self: start;
    color: #def1d7;
    font-size: 72px;
    line-height: 98px;
    font-weight: 800;
    white-space: nowrap;
  }
  @media only screen and (max-width: 900px) {
    .banner-box {
      margin-top: 20px;
      background-color: #34344a;
      width: 100%;
      display: flex;
      height: 100px;
      flex-direction: row;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      position: relative;
      overflow: hidden;
    }
    .banner-middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      row-gap: 12px;
    }
    .group-icons {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
    }
    .button-link {
      width: 87px;
      border: none;
      cursor: pointer;
      appearance: none;
      background-color: inherit;
    }

    .button-link img {
      max-width: 100%;
      max-height: 100%;
    }
    .close-button {
      position: absolute;
      border: none;
      cursor: pointer;
      appearance: none;
      background-color: transparent;
      top: 5px;
      right: 5px;
      z-index: 1;
      border-radius: 50%;
    }
    .mya-container {
      display: none;
    }
    .ralph-container {
      display: none;
    }
    .banner-title {
      grid-area: title;
      justify-self: start;
      color: #f27649;
      font-size: 32px;
      line-height: 44px;
      font-weight: 800;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    .gap {
      margin-top: 16px;
    }
    .twelve {
      font-size: 16px;
    }
    .phone-and-caption img {
      margin: 0 -60px;
      width: 100%;
    }
  }

  @media only screen and (min-width: 900px) {
    .main {
      padding: 40px 20px 0;
      font-size: 16px;
      width: 100%;
    }

    h1 {
      font-size: 63px;
      font-family: Avenir-heavy;
    }
    .first {
      font-family: Avenir-heavy;
    }
    .numbered {
      font-family: Avenir-light;
      font-weight: 800;
      font-size: 1.7vw;
    }

    .fixers {
      margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-rows: 150px 100px 200px;
      grid-template-areas:
        'firstblobs title'
        'firstblobs text'
        'secondblobs text';
      margin-bottom: 50px;
    }
    .paulaWrench {
      height: 145px;
    }
    .fredPlunger {
      height: 127px;
    }
    .myaPainter {
      height: 240px;
    }
    .ralphSaw {
      height: 161px;
    }
    .jeffDrill {
      height: 103px;
    }
    .sanjayPlank {
      height: 190px;
    }
    .phone-and-caption {
      width: 40%;

      img {
        width: 100%;
      }
    }

    @media screen and (min-width: 1270px) {
      .paulaWrench {
        height: 215px;
      }
      .fredPlunger {
        height: 197px;
      }
      .myaPainter {
        height: 310px;
      }
      .ralphSaw {
        height: 211px;
      }
      .jeffDrill {
        height: 153px;
      }
      .sanjayPlank {
        height: 240px;
      }
    }

    .first-blobs {
      grid-area: firstblobs;
      justify-self: end;
      align-self: end;
      margin-bottom: -30px;
    }

    .second-blobs {
      grid-area: secondblobs;
      justify-self: end;
    }
    h2 {
      grid-area: title;
      justify-self: start;
      color: #3d3d3d;
      font-size: 36px;
      padding-left: 50px;
      align-self: end;
    }
    .meet-text {
      grid-area: text;
    }
    .icon-and-text {
      padding-left: 50px;
    }
    .icon-and-text p {
      font-weight: 300;
    }

    .p {
      line-height: 24px;
      width: 850px;
      margin: 20px 0;
    }
    .bold2 {
      width: 475px;
    }
    .width-limiter {
      width: 525px;
    }

    .phones-container {
      margin: 30px 0 50px;
      flex-direction: row;
      justify-content: center;
    }

    .button-container {
      margin-top: 30px;
    }
  }
`
