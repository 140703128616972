import React from 'react'
import styles from './BookingDetails.module.css'
import Star from '../Star'

function Rating(props: {
  className?: string
  ratingState: {
    value: number
    setRating: (rating: number) => void
  }
  activeColor?: string
  inactiveColor?: string
  size?: number
}) {
  const [hoveredIndex, setHoveredIndex] = React.useState(0)
  return (
    <div
      className={
        props.className
          ? `${styles.ratingContainer} ${props.className}`
          : styles.ratingContainer
      }
    >
      {Array.from({ length: 5 }, (_, i) => i + 1).map((el) => {
        return (
          <Star
            onClick={() => {
              props.ratingState.setRating(el)
            }}
            onMouseOver={() => {
              setHoveredIndex(el)
            }}
            onMouseOut={() => {
              setHoveredIndex(0)
            }}
            key={el}
            color={
              hoveredIndex >= el || props.ratingState.value >= el
                ? props.activeColor ?? '#FFCC00'
                : props.inactiveColor ?? '#EFECEF'
            }
            size={props.size ? props.size.toString() : '26'}
          />
        )
      })}
    </div>
  )
}

export default Rating
