import React, { useState, useEffect } from 'react'
import styles from './BookingDetails.module.css'
import BookingDetails from './BookingDetails'
import backArrow from '../../../assets/global/icons/back-icon.svg'
import { Button, SectionHeader } from '../ui'
import { useTranslation } from 'react-i18next'
import { IBookingDetailsProps } from '../../../components/utils/interfaces'
import { Link } from 'react-router-dom'
import placeholder from '../../../assets/global/icons/user-icon.png'
import clock from '../../../assets/global/icons/clock-icon.svg'
import msg from '../../../assets/global/icons/message-icon.svg'
import Star from '../Star'
import humanizeDuration from 'humanize-duration'
import CardInfo from './CardInfo'
import Rating from './Rating'
import SubmitttedReview from './SubmitttedReview'

function BookingDetailsDesktop({
  booking,
  fixer,
  ratingState,
  fetchPdf,
  date,
  hhMM,
}: IBookingDetailsProps) {
  const { i18n } = useTranslation()
  const language = i18n.language
  const { t } = useTranslation()

  const submittedStyles =
    ratingState.submitted || ratingState.isAlreadyReviewed
      ? { justifyContent: 'start' }
      : {}
  const invoiceStyles = fixer.certificate ? { marginTop: '0px' } : {}

  return (
    <div>
      <div className={styles.content}>
        <div style={invoiceStyles} className={styles.row}>
          <div className={styles.back}>
            <Link to={'/bookings'}>
              <img className={styles.arrow} src={backArrow} />
            </Link>
            <SectionHeader className={styles.pageTitle}>
              {t('account.nav.my-bookings')}
            </SectionHeader>
          </div>
          <div className={styles.profile}>
            <div className={styles.icon}>
              <img
                className={styles.image}
                src={fixer?.company_logo || placeholder}
              />
            </div>
            <h6 className={styles.name}>{booking?.tradesman_name}</h6>
            <div className={styles.rating}>
              <Star size="24" color={'#FFCC00'} />
              <p className={styles.ratingText}>
                {fixer?.avg_rating} (0 {t('account.reviews')})
              </p>
            </div>
            <Link to="/working-on-it">
              <img src={msg} />
            </Link>
          </div>
          <div className={styles.worked}>
            <img src={clock} />
            <div className={styles.workedText}>
              <h6 className={styles.workedTitle}>
                {t('customer-bookings.hours-worked')}
              </h6>
              <p className={styles.workedHours}>
                {humanizeDuration((booking?.hours_worked || 0) * 1000, {
                  language:
                    language === 'en-SE' || language === 'en' ? 'en' : 'se',
                  delimiter: ' ',
                  round: true,
                  units: ['h', 'm'],
                })}
              </p>
            </div>
          </div>
          <div className={`${styles.buttons} ${styles.fullWidth}`}>
            {fixer.certificate && (
              <Button
                onClick={() => window.open(fixer.certificate, '_blank')}
                className="orange"
              >
                {t('button.view-certificate')}
              </Button>
            )}
            <Button onClick={() => fetchPdf()} className="green">
              {t('button.download-invoice')}
            </Button>
          </div>
        </div>
        <div style={submittedStyles} className={styles.row_lg}>
          <div
            className={`${styles.card} ${styles.flexCard} ${styles.dateCard}`}
          >
            <span className={styles.cardItem}>
              {t('customer-bookings.date')}: {date}
            </span>
            <span className={styles.cardItem}>
              {t('customer-bookings.time')}: {hhMM}
            </span>
          </div>
          <div className={styles.card}>
            <h6 className={styles.cardTaskName}>
              {booking.sub_category_details[0].sub_category_name}
            </h6>
            <CardInfo booking={booking} />
          </div>
          {!ratingState.submitted && !ratingState.isAlreadyReviewed ? (
            <>
              <div className={styles.card}>
                <div className={styles.stars}>
                  <p className={styles.cardText}>
                    {t('customer-bookings.rate-fixer')}
                  </p>
                  <Rating ratingState={ratingState} />
                </div>
              </div>
              <div className={styles.card}>
                <textarea
                  onChange={(e) => ratingState.setReview(e.target.value)}
                  value={ratingState.review}
                  placeholder="Explain your rating..."
                  className={styles.review}
                />
              </div>
            </>
          ) : !ratingState.isAlreadyReviewed ? (
            <SubmitttedReview />
          ) : null}
          <div>
            <div className={styles.buttonsContainer}>
              {!ratingState.submitted && !ratingState.isAlreadyReviewed && (
                <Button
                  onClick={() => ratingState.uploadReview()}
                  className="orange"
                >
                  {t('button.submit')}
                </Button>
              )}
              <Link
                className={styles.link}
                to={`/contact-us/${booking.customer_request_id}`}
              >
                <Button className="green">{t('button.help')}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingDetailsDesktop
