import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import logo from '../assets/global/modals/bokafix-fixer-logo.svg'
import { RootState } from '../store/rootstate'

const UseModalHeading: React.FC = () => {
  const { isModal, currentModal, modals } = useSelector(
    (state: RootState) => state.modalReducer
  )
  const { currentTitle } = useSelector((state: RootState) => state.titleReducer)
  let modalTitle: ReactNode
  isModal &&
    (modalTitle = modals.filter(
      (
        modal:
          | {
              type: string
              status: boolean
              translation: string
              title: string
            }
          | {
              type: string
              status: boolean
              translation: string
              title?: undefined
            }
      ) => modal.type === currentModal
    )[0].title)

  return isModal ? (
    <h1 style={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>
      {modalTitle}
    </h1>
  ) : currentTitle === 'logo' ? (
    <img style={{ color: '#ffffff', height: '35px' }} src={logo} alt="logo" />
  ) : (
    <div style={{ color: '#ffffff', fontWeight: '600', fontSize: '14px' }}>
      {currentTitle}
    </div>
  )
}

export default UseModalHeading
