import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { setIsOpen } from '../../../store/slice/ModalSlice'
import { useDispatch } from 'react-redux'

function SignInWrapper() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    navigate('/', { replace: true })
    dispatch(setIsOpen('selectUserTypeModal'))
  }, [])

  return <></>
}

export default SignInWrapper
