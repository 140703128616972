import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

interface Props {
  buttonName: string
  handleClick?: any
  isLight?: boolean
  isLarge?: boolean
  fullWidth?: boolean
}

const MainButton: React.FC<Props> = ({
  buttonName,
  handleClick,
  isLarge,
  isLight,
}): JSX.Element => {
  return (
    <Button isLarge={isLarge} isLight={isLight} onClick={handleClick}>
      {buttonName}
    </Button>
  )
}

export default MainButton

interface ButtonProps {
  isLarge?: boolean
  isLight?: boolean
}
const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.isLight ? props.theme.button.orange : props.theme.button.green};
  border: none;
  border-radius: 0.2rem;
  padding: 0.6rem;
  cursor: pointer;
  height: 40px;
  width: ${({ isLarge }) => (isLarge ? '100%' : '130px')};
  color: #ffffff;
  font-weight: bold;
  :hover {
    filter: ${({ theme }) => theme.button.hover};
  }
  :active {
    filter: ${({ theme }) => theme.button.active};
  }
`
