import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import i18next from 'i18next'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import list from '../assets/global/icons/list-icon.svg'
import man from '../assets/global/icons/user-logo-grey.svg'
import sanjay from '../assets/global/blobs/sanjay-with-plane.png'
import { Button } from '../components/component-library/index'
import CustomerContactUsSent from '../components/modals/CustomerContactUsSent'
import { setIsOpen } from '../store/slice/ModalSlice'
import { ContactUsBackgroundDesktop } from '../components/Background'
import { ContactUsBackgroundMobile } from '../components/Background'
import usePageParams from '../utils/usePageParams'
import { useDispatch } from 'react-redux'
import FirebaseAPI from '../firebase-api/FirebaseAPI'
import CloseRegistrationBtn from '../components/common/CloseBtn'

interface Props {
  handleClick?: () => void
}

const CustomerContactUs: React.FC<Props> = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  usePageParams('Contact Us', 'customerPage', '')

  const options = i18next
    .t('customer-contact-us.subjects', { joinArrays: '+' })
    .split('+')
  const needsBookingReference = i18next.t(
    'customer-contact-us.subjects-need-booking',
    { joinArrays: '' }
  )
  const currentBookingOption = options[2]
  const subjectValue = id ? currentBookingOption : 'default'

  const [formInfo, setFormInfo] = useState({
    name: '',
    subject: id ? currentBookingOption : 'x',
    bookingReference: '',
    message: '',
    email: '',
  })
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const [messageSent, setMessageSent] = useState(false)
  const [showBookingReference, setShowBookingReference] = useState(!!id)

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  /* ---------------- ---------------- ---------------- */

  let dropdownOptions = options.map((each, index) => {
    return (
      <option key={index} value={each}>
        {each}
      </option>
    )
  })

  useEffect(() => {
    if (needsBookingReference.includes(formInfo.subject)) {
      setShowBookingReference(true)
    } else {
      setShowBookingReference(false)
    }
  }, [formInfo.subject])

  const subjectHandle = () => {
    setFormInfo((prev) => ({
      ...prev,
      subject:
        (document.getElementById('subject') as HTMLInputElement) &&
        (document.getElementById('subject') as HTMLInputElement).value,
    }))
  }

  function onChange(e: any) {
    setFormInfo((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }))
    return
  }

  const onSubmit = () => {
    const meetsRequirements: Array<string> = []
    const requiredFields = [
      {
        error: 'customer-error',
        input: formInfo.name,
      },
      {
        error: 'subject-error',
        input: formInfo.subject,
      },
      {
        error: 'email-error',
        input: formInfo.email,
      },
      {
        error: 'message-error',
        input: formInfo.message,
      },
    ]
    // Removed, because it ruins validation
    // showBookingReference &&
    //   requiredFields.push({
    //     error: 'booking-error',
    //     input: formInfo.bookingReference,
    //   })

    requiredFields.filter((each) => {
      if (each.input.length <= 1) {
        meetsRequirements.push(each.error)
        console.log(each.error)
        console.log(each.input)
      }
      if (each.error === 'email-error' && !each.input.includes('@' && '.')) {
        meetsRequirements.push(each.error)
      }
    })

    if (meetsRequirements.length === 0) {
      setMessageSent((prev) => !prev)
      dispatch(setIsOpen('customerContactUsSent'))
      FirebaseAPI.sendMessage({
        to: 'support@bokafix.com',
        name: formInfo.name,
        message: formInfo.message,
        subject: formInfo.subject,
        bookingReference: formInfo.bookingReference,
        email: formInfo.email,
      })

        .then(function (response) {
          return FirebaseAPI.contactUsEmail({
            to: formInfo.email,
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      for (let each of requiredFields) {
        if (each.input.length <= 1) {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'block'
        } else if (
          each.error === 'email-error' &&
          !each.input.includes('@' && '.')
        ) {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'block'
        } else {
          ;(
            document.getElementById(each.error) as HTMLInputElement
          ).style.display = 'none'
        }
      }
    }
  }

  return messageSent ? (
    <CustomerContactUsSent />
  ) : isDesktop ? (
    <div>
      <ContactUsBackgroundDesktop />

      <DesktopContainer>
        <h1>{t('customer-contact-us.desktop.title')}</h1>
        <div className="main-container">
          <form>
            <p id="subject-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <div className="subject-container small-input all-inputs">
              <select
                className="subject"
                id="subject"
                defaultValue={subjectValue}
                onChange={subjectHandle}
              >
                <option value="default" disabled>
                  {t('customer-contact-us.subject-placeholder')}
                </option>
                {dropdownOptions}
              </select>
            </div>
            <p id="customer-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <input
              type="text"
              className="inputs small-input all-inputs"
              id="name"
              onChange={onChange}
              placeholder={t('customer-contact-us.desktop.name-placeholder')}
            />
            <p id="email-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <div className="email-container">
              <input
                type="text"
                className="inputs small-input all-inputs"
                id="email"
                onChange={onChange}
                placeholder={t('form.email')}
              />
            </div>
            {showBookingReference ? (
              <div>
                <p id="booking-error" className="error">
                  {t('customer-contact-us.error')}
                </p>
                <div className="booking-container small-input all-inputs">
                  <select
                    defaultValue={id}
                    className="booking-reference"
                    id="booking-reference"
                  >
                    <option value="default">{id}</option>
                  </select>
                </div>
              </div>
            ) : null}
            <p id="message-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <textarea
              className="inputs all-inputs"
              id="message"
              name="message"
              onChange={onChange}
              placeholder={t('customer-contact-us.desktop.message-placeholder')}
            ></textarea>
          </form>
          <img src={sanjay} alt="sanjay blob bokafix" />
        </div>
        <div className="buttons">
          <div className="button-container">
            <Button
              fullSize={true}
              name={t('customer-contact-us.desktop.dark-button')}
              color={'black'}
              handleClick={() => {
                // useEffect(() => {
                navigate(-1)
                // })
              }}
            />
          </div>
          <div className="button-container">
            <Button
              handleClick={onSubmit}
              fullSize={true}
              color={'orange'}
              name={t('customer-contact-us.light-button')}
            />
          </div>
        </div>
      </DesktopContainer>
    </div>
  ) : (
    <div>
      <ContactUsBackgroundMobile />
      <MobileContainer>
        <p id="subject-error" className="error">
          {t('customer-contact-us.error')}
        </p>
        <div className="subject-container">
          <img
            className="subject-logo logo"
            src={list}
            alt="customer contact us logo"
          />
          <select
            id="subject"
            className="inputs small-input"
            defaultValue={subjectValue}
            onChange={onChange}
          >
            <option value="default" disabled>
              {t('customer-contact-us.subject-placeholder')}
            </option>
            {dropdownOptions}
          </select>
        </div>
        <p id="customer-error" className="error">
          {t('customer-contact-us.error')}
        </p>
        <div className="customer-container">
          <img className="customer-logo logo" src={man} alt="customer logo" />
          <input
            type="text"
            className="inputs small-input"
            id="name"
            onChange={onChange}
            placeholder={t('form.name')}
          />
        </div>
        <div className="email-container">
          <img className="customer-logo logo" src={man} alt="customer logo" />
          <input
            type="text"
            className="inputs small-input"
            id="email"
            onChange={onChange}
            placeholder={t('form.email')}
          />
        </div>
        {showBookingReference ? (
          <div>
            <p id="booking-error" className="error">
              {t('customer-contact-us.error')}
            </p>
            <div className="booking-container">
              <select
                defaultValue={id}
                className="inputs small-input"
                id="booking-reference"
              >
                <option value="default" disabled>
                  {id}
                </option>
              </select>
            </div>
          </div>
        ) : null}
        <p id="message-error" className="error">
          {t('customer-contact-us.error')}
        </p>
        <textarea
          className="inputs"
          id="message"
          name="message"
          onChange={onChange}
          placeholder={t('customer-contact-us.mobile.message-placeholder')}
        ></textarea>

        <div className="mobile-button-container">
          <Button
            handleClick={onSubmit}
            color={'orange'}
            name={t('customer-contact-us.light-button')}
            fullSize={true}
          />
        </div>
      </MobileContainer>
    </div>
  )
}

export default CustomerContactUs

const MobileContainer = styled.div`
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  .subject-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f5f5f5;
    border-radius: 6px;
    border: none;
    margin-bottom: 10px;
    padding-right: 10px;
  }
  .subject {
    height: 100%;
    width: 100%;
    font-family: Avenir;
    font-weight: 500;
  }
  .logo {
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    background: #e8e8e8;
    border-radius: 6px;
  }
  .small-input {
    height: 100%;
    width: 100%;
  }
  .inputs {
    background: #f5f5f5;

    border: none;
    :focus {
      outline: none;
    }
    font-family: Poppins;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7b7b7b;
  }
  .customer-container,
  .email-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f5f5f5;
    border-radius: 6px;
    border: none;
    margin-bottom: 10px;
  }
  #name::placeholder,
  #email::placeholder {
    /* color: #7b7b7b; */
  }
  #name,
  #email {
    padding-left: 2px;
    border-radius: 6px;
    :-webkit-autofill {
      background-color: white;
    }
  }
  #message {
    padding: 15px;
    min-height: 400px;
    border-radius: 6px;
    margin-bottom: 50px;
  }
  button {
    height: 50px;
    background: #f27649;
    border-radius: 4px;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .mobile-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .booking-container {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 15px;
    background: #f5f5f5;
    border-radius: 6px;
    border: none;
    :focus {
      outline: none;
    }
  }
  .error {
    font-size: 12px;
    color: red;
  }
  .error {
    display: none;
  }
`

const DesktopContainer = styled.div`
  font-family: Avenir-heavy;
  padding: 50px 100px;
  h1 {
    width: 100%;
    text-align: center;
    font-size: 63px;
    font-family: Avenir-heavy;
  }
  .main-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 481px;
  }
  .small-input {
    width: 100%;
    height: 50px;
    border: 1px solid #707070;
    border-radius: 4px;
    font-family: Avenir-heavy;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .inputs {
    font-family: Avenir-heavy;
    background: white;
    color: black;
  }
  #name::placeholder,
  #email::placeholder,
  #message::placeholder {
    color: #000000;
  }
  #message {
    padding: 15px;
    font-family: Avenir-heavy;
    font-weight: 500;
    height: 260px;
    border-radius: 6px;
  }

  .subject-container,
  .booking-container {
    padding-right: 15px;
    background: #ffffff;
  }
  .subject,
  .booking-reference {
    border: none;
    padding-left: 12px;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    :focus {
      outline: none;
    }
    font-family: Avenir-heavy;
    font-weight: 500;
  }

  #name,
  #email {
    padding-left: 15px;
    font-family: Avenir-heavy;
    font-size: 13px;
  }
  .all-inputs {
    font-family: Avenir-heavy;
    :focus {
      outline: none;
    }
    border: none;
    box-shadow: 0 3px 6px #707070;
  }
  .buttons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .button-container {
    margin: 5px;
    width: 160px;
  }
  img {
    max-width: 50%;
    object-fit: contain;
    object-position: left bottom;
  }
  .error {
    font-size: 12px;
    color: red;
  }
  .error {
    display: none;
  }
`
