import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 107px;
`

export const BannerWrapper = styled.div`
  margin-top: 104px;
  background-color: white;
  padding: 90px 0;
`
export const BannerContent = styled.div`
  margin: 0 auto;
  max-width: 1082px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const BannerText = styled.h3`
  text-align: left;
  font: normal normal 100 35px/40px Avenir;
  letter-spacing: 0.7px;
  color: #3d3d3d;
  opacity: 1;
`
export const BannerButton = styled.button`
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
  transition: 0.3s;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 14px 70px;
  text-align: center;
  font: normal normal 900 16px/24px Avenir;
  letter-spacing: 0.38px;
  color: #ffffff;
  color: white;
  background: #0f7173 0% 0% no-repeat padding-box;
  border-radius: 4px;
`
