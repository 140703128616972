import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

interface Props {
  children: ReactElement | ReactElement[]
  option: boolean
  className?: string
  style?: React.CSSProperties
}

const Modal: React.FC<Props> = ({ children, style, option, className }) => {
  const modalElement: HTMLElement | null = document.getElementById('main')
  if (!option) return null
  if (!modalElement) return null
  return ReactDOM.createPortal(
    <ContainerParent style={style} className={className}>
      <Container className="container">{children}</Container>
    </ContainerParent>,
    modalElement as Element
  )
}

export default React.memo(Modal)

const ContainerParent = styled.div`
  margin-bottom: 300px;
  @media only screen and (max-width: 900px) {
    margin-bottom: 0px;
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  margin-top: 10px;
  width: 100%;
  z-index: 10;
  @media only screen and (min-width: 900px) {
    left: 50%;
  }
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 0;
    height: 100%;
    margin-bottom: 100px;
  }
`
