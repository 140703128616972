import React, { useLayoutEffect, useState } from 'react'
import { ICustomerRegistrationProps } from '../../utils/interfaces'
import { SignUpBackgroundMobile } from '../../Background'
import { MobileModalContainer } from '../styled-components'
import styles from './RegistrationCustomer.module.css'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import validationRules from '../../utils/validation-rules'
import { Select } from '../../ui/styled-components'
import Options from '../../common/Options'
import icon from './Path.png'
import { Button, TextInput } from '../../component-library/index'
import PasswordField from '../../ui/PasswordField'
import FileUpload from '../../../pages/Account/FileUpload'
import PhoneInput from 'react-phone-number-input'

function RegistrationCustomerMobile({
  form,
  handleSubmit,
  profileImage,
  setProfileImage,
  displayIssue,
  error,
}: ICustomerRegistrationProps) {
  const { t } = useTranslation()
  const countriesList = [
    { label: t('registration.select-country'), value: '', disabled: true },
    { value: 'Sweden', label: t('country.sweden') },
    { value: 'United Kingdom', label: t('country.united-kingdom') },
    { label: 'Ireland', value: t('country.ireland') },
  ]

  const [step, setStep] = useState(1)
  const proceed = () => {
    const isValid = form.getState().valid
    if (!isValid) {
      const errors = form.getState().errors
      displayIssue(Object.values(errors as {}))
      return
    }
    setStep(2)
  }

  const ref = React.useRef(null)
  useLayoutEffect(() => {
    const node = ref.current as HTMLElement | null
    if (node) {
      const main = document.querySelector('#main') as HTMLDivElement | null
      if (main) {
        main.style.minHeight = `${node.clientHeight}px`
      }
    }
  }, [ref])
  // This effect fixes error when some content is cropped

  return (
    <>
      <MobileModalContainer ref={ref} className={styles.mobileContainer}>
        {step === 1 && (
          <>
            <h4 className={styles.fieldTitle}>
              {t('registration.select-country')}
            </h4>
            <Field
              validate={validationRules.required.bind('Select country')}
              name="country"
              className={`select-country ${styles.input}`}
              options={countriesList}
            >
              {({ input, options }) => {
                return (
                  <div
                    className={`select-container ${styles.input}`}
                    id="country-field"
                  >
                    <Select
                      style={{
                        backgroundImage: `url(${icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right 10px center',
                      }}
                      className={`${styles.arrowIcon} ${styles.selectField} select-country`}
                      name="country"
                      defaultValue={''}
                      onChange={(value) => input.onChange(value)}
                    >
                      <Options options={options} />
                    </Select>
                  </div>
                )
              }}
            </Field>
            <h4 className={styles.fieldTitle}>
              {t('registration.contact-information')}
            </h4>
            <Field
              validate={validationRules.required.bind('First name is required')}
              name="first_name"
            >
              {({ input }) => {
                return (
                  <TextInput
                    id="first_name"
                    {...input}
                    placeholder={t('form.first-name')}
                    className={`inputs step1inputs ${styles.input}`}
                    background={'grey'}
                    textColor={'black'}
                  />
                )
              }}
            </Field>
            <Field
              validate={validationRules.required.bind('Last name is required')}
              name="last_name"
            >
              {({ input }) => (
                <TextInput
                  id="last_name"
                  {...input}
                  placeholder={t('form.last-name')}
                  className={`inputs step1inputs ${styles.input}`}
                  background={'black'}
                  textColor={'black'}
                />
              )}
            </Field>
            {form.getState().values.country === 'Sweden' && (
              <Field
                validate={validationRules.required.bind(
                  'Personal no. is required'
                )}
                name="personal_no"
              >
                {({ input }) => (
                  <TextInput
                    id="personal_no"
                    {...input}
                    placeholder={t('form.personnumber')}
                    className={`inputs step1inputs ${styles.input}`}
                    background={'black'}
                    textColor={'black'}
                  />
                )}
              </Field>
            )}
            <Field validate={validationRules.requiredEmail} name="email">
              {({ input }) => (
                <TextInput
                  id="email"
                  {...input}
                  placeholder={t('form.email')}
                  className={`inputs step1inputs ${styles.input}`}
                  background={'black'}
                  textColor={'black'}
                />
              )}
            </Field>
            <Field validate={validationRules.requiredPassword} name="password">
              {({ input }) => (
                <PasswordField
                  {...input}
                  className={`inputs step1inputs inputs-pass ${styles.input}`}
                  style={{ width: '100%' }}
                  placeholder={t('form.password')}
                />
              )}
            </Field>
          </>
        )}
        {step === 2 && (
          <>
            <Field
              name="address"
              validate={validationRules.required.bind('Address is required')}
            >
              {({ input }) => {
                return (
                  <TextInput
                    id="address"
                    {...input}
                    placeholder={t('form.address')}
                    className={`inputs ${styles.input}`}
                    background={'black'}
                    textColor={'black'}
                  />
                )
              }}
            </Field>
            <Field
              name="city"
              validate={validationRules.required.bind('City is required')}
            >
              {({ input }) => {
                return (
                  <TextInput
                    id="city"
                    {...input}
                    placeholder={t('form.city')}
                    className={`inputs ${styles.input}`}
                    background={'black'}
                    textColor={'black'}
                  />
                )
              }}
            </Field>
            <Field
              name="postcode"
              validate={validationRules.required.bind('Postcode is required')}
            >
              {({ input }) => {
                return (
                  <TextInput
                    id="postcode"
                    {...input}
                    placeholder={t('form.postcode')}
                    className={`aside-select-field ${styles.input}`}
                    background={'black'}
                    textColor={'black'}
                  />
                )
              }}
            </Field>
            <div id="phone-input-container">
              <Field
                validate={validationRules.phoneRequired.bind(
                  'Phone number is required'
                )}
                name="phone_no"
              >
                {({ input, meta }) => {
                  return (
                    <PhoneInput
                      className={`${styles.input}`}
                      placeholder={t('form.phone')}
                      {...input}
                    />
                  )
                }}
              </Field>
            </div>
            <div className={styles.file}>
              <FileUpload
                handleState={(url: string) => setProfileImage(url)}
                imageUrl={profileImage}
                folderName="company-logo"
                fileName={t('form.customer.photo')}
                id="company-logo-details-1"
              />
            </div>
            {form.getState().values.country === 'Sweden' && (
              <Field
                validate={validationRules.required.bind(
                  'Fastighetsbeteckning is required'
                )}
                name="fastighetsbeteckning"
              >
                {({ input }) => (
                  <TextInput
                    id="fastighetsbeteckning"
                    {...input}
                    placeholder="Fastighetsbeteckning"
                    className={`inputs step1inputs ${styles.input}`}
                    background={'black'}
                    textColor={'black'}
                  />
                )}
              </Field>
            )}
          </>
        )}
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.buttonWrapper}>
          <label className={styles.terms}>
            <Field
              id="agree_to_terms"
              type="checkbox"
              component="input"
              name="agree_to_terms"
              initialValue={false}
              validate={validationRules.requiredToBeChecked.bind(
                'Please, accept the terms and conditions to continue'
              )}
            />
            <p className={styles.termsText}>
              {t('terms-and-conditions.part-1-user') + ' '}
              <a
                className={styles.termsLink}
                href="/fixer-terms-conditions"
                target="_blank"
                title="terms and conditions Fixer"
              >
                {t('terms-and-conditions.part-2-user')}
              </a>
              {' ' + t('terms-and-conditions.part-3') + ' '}
              <a
                className={styles.termsLink}
                href="/privacy-policy"
                target="_blank"
                title="privacy policy"
              >
                {t('terms-and-conditions.part-4')}
              </a>
            </p>
          </label>
          {step === 1 && (
            <Button
              name={t('button.continue')}
              handleClick={proceed}
              fullSize={true}
            />
          )}
          {step === 2 && (
            <Button
              name={t('button.signup')}
              handleClick={() => handleSubmit(form)}
              fullSize={true}
            />
          )}
        </div>
      </MobileModalContainer>
      <SignUpBackgroundMobile styles={{ background: 'white' }} />
    </>
  )
}

export default RegistrationCustomerMobile
