import React from 'react'

interface IProps {
  options: { disabled?: boolean; value: any; key?: string; label?: string }[]
}

function Options(props: IProps) {
  return (
    <>
      {props.options.map((el) => (
        <option key={el.value} disabled={el.disabled} value={el.value}>
          {el.label}
        </option>
      ))}
    </>
  )
}

export default Options
