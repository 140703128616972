import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from 'styled-components'
import { variables } from './styles/theme'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import options from './i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  detection: options,
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={variables}>
          {/* <Suspense fallback={<div>Loading..</div>}> */}
          <App />
          {/* </Suspense> */}
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

reportWebVitals()
