import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '../ui/styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Modal from './Modal'
import closeIcon from '../../assets/global/icons/cancel-button.svg'
import { setIsClosed, setIsOpen } from '../../store/slice/ModalSlice'
import axios from 'axios'
import { getAuth } from 'firebase/auth'
import {
  CloseButton,
  ModalBody,
  ModalParent,
  ModalTitle,
  ModalWindow,
  ModalWrapper,
} from './styled-components'
function ReactivationModal(props: {
  email: string
  isActive?: boolean
  handleActivation: Dispatch<SetStateAction<boolean>>
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation() // don't touch this line
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900)
  const updateMedia = () => {
    setIsMobile(window.innerWidth < 900)
  }
  if (currentModal === 'reactivationModal') {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  async function reactivateAccount(email: string) {
    axios
      .post(
        'https://us-central1-bokafix-4371c.cloudfunctions.net/HandleActivationByEmail',
        {
          isDisabled: false,
          email,
        }
      )
      .then(() => {
        dispatch(setIsOpen('logInModal'))
        props.handleActivation(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const closeModal = () => {
    window.location.href = '/'
  }

  return (
    <ModalParent style={{ position: 'relative' }}>
      <Modal className="modal-reactivation" option={true}>
        <ModalWrapper className="wrapper">
          <CloseButton onClick={closeModal} src={closeIcon} />
          <ModalWindow>
            <ModalTitle>{t('modals.reactivate-account')}</ModalTitle>
            <ModalBody>
              <Button
                style={!isMobile ? {} : { backgroundColor: '#F27649' }}
                type="button"
                onClick={() => {
                  reactivateAccount(props.email)
                }}
              >
                {t('button.reactivate-account')}
              </Button>
              {isMobile ? null : (
                <Button
                  type="button"
                  style={{ backgroundColor: '#F27649' }}
                  onClick={closeModal}
                >
                  {t('button.close')}
                </Button>
              )}
            </ModalBody>
          </ModalWindow>
        </ModalWrapper>
      </Modal>
    </ModalParent>
  )
}

export default ReactivationModal
