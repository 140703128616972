import React from 'react'
import styles from './BookingDetails.module.css'
import { IRequest } from '../../../components/utils/interfaces'
import getStatusColor from '../../../utils/getStatusColor'
import { useTranslation } from 'react-i18next'
import CloseRegistrationBtn from '../../../components/modals/Registration/CloseRegistrationBtn'

function CardInfo(props: { booking: IRequest }) {
  const { i18n } = useTranslation()
  const language = i18n.language
  const currencySymbol = language === 'en' ? '£' : 'SEK'
  const currency = (value: string) => {
    return { children: !!value ? currencySymbol + value : 'N/A' }
  }
  const defProp = (value: string) => {
    return { children: !!value ? value : 'N/A' }
  }
  const { t } = useTranslation()
  const data: {
    label: string
    key: keyof IRequest
    additionalProps: any
  }[] = [
    {
      label: t('customer-bookings.hours-worked'),
      key: 'hours_worked',
      additionalProps: currency,
    },
    {
      label: t('customer-bookings.price-of-materials'),
      key: 'price_of_materials',
      additionalProps: currency,
    },
    {
      label: t('customer-bookings.total-price'),
      key: 'total_price',
      additionalProps: currency,
    },
    {
      label: t('customer-bookings.payment-status'),
      key: 'payment_status',
      additionalProps: defProp,
    },
    {
      label: t('customer-bookings.job-status'),
      key: 'request_status',
      additionalProps: (status: string) => {
        return {
          style: {
            color: getStatusColor(status),
          },
          children: status,
        }
      },
    },
  ]
  return (
    <div className={styles.cardInfo}>
      {data.map((item) => {
        return (
          <div key={item.label} className={styles.cardInfoItem}>
            <p className={styles.cardInfoTitle}>{item.label}</p>
            <p
              className={styles.cardInfoText}
              {...item.additionalProps(props.booking[item.key] as string)}
            />
          </div>
        )
      })}
    </div>
  )
}
export default CardInfo
