import '../css/button.css';

interface IButtonProps {
  color?: string;
  fullSize: boolean;
  name: string;
  handleClick: () => void;
}

const Button = (props: IButtonProps): JSX.Element => {
  let color: string;

  switch (props.color) {
    case 'orange':
      color = '#F27649';
      break;
    case 'green':
      color = '#0F7173';
      break;
    case 'black':
      color = '#34344A';
      break;
    default:
      color = '#F27649';
      break;
  }

  const StyleObject: Record<string, boolean | string | undefined> = {
    width: props.fullSize ? '100%' : '130px',
    background: color
  };
  return (
    <button className="button" onClick={props.handleClick} style={StyleObject}>
      {props.name}
    </button>
  );
};

export default Button;
