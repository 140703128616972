import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '../components/component-library/index'
import phoneDashboard from '../assets/global/fixer-how-it-works/phone-dashboard.svg'
import phoneFixedOffer from '../assets/global/fixer-how-it-works/phone-fixed-offer.svg'
import phoneSchedule from '../assets/global/fixer-how-it-works/phone-schedule.svg'
import phoneEarnings from '../assets/global/fixer-how-it-works/phone-earnings.svg'
import phoneAcceptOffer from '../assets/global/fixer-how-it-works/phone-accept-offer.svg'
import phoneOnTheWay from '../assets/global/fixer-how-it-works/phone-on-the-way.svg'
import { HowItWorksBackgroundDesktop } from '../components/Background'
import { HowItWorksBackgroundMobile } from '../components/Background'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../store/slice/ModalSlice'
import arrow from '../assets/global/fixer-how-it-works/page-top-arrow.svg'

const HowItWorks: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  usePageParams('How it works', 'fixerPage', 'Howitworks', '', true)
  const dispatch = useDispatch()

  function openRegistrationModal() {
    dispatch(setIsOpen('registration-modal'))
  }
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <OuterContainer>
      {isDesktop ? (
        <HowItWorksBackgroundDesktop />
      ) : (
        <HowItWorksBackgroundMobile />
      )}
      <Container>
        <h1>{t('fixer-how-it-works.how-it-works.title')}</h1>
        <p className="bold bold2 narrow first">
          {t('fixer-how-it-works.how-it-works.subtitle')}
        </p>
        <div className="phones-container">
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.how-it-works.captions.one')}
            </p>
            <img src={phoneDashboard} alt="phone dashboard" />
          </div>
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.how-it-works.captions.two')}
            </p>
            <img src={phoneFixedOffer} alt="phone fixed offer" />
          </div>
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.how-it-works.captions.three')}
            </p>
            <img src={phoneSchedule} alt="phone schedule" />
          </div>
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.how-it-works.captions.four')}
            </p>
            <img src={phoneEarnings} alt="phone earnings" />
          </div>
        </div>
        {isDesktop ? (
          <p className="p">
            {t('fixer-how-it-works.how-it-works.paragraph-1')}
            {t('fixer-how-it-works.how-it-works.paragraph-2')}
          </p>
        ) : (
          <div>
            <p className="p b">
              {t('fixer-how-it-works.how-it-works.paragraph-1')}
            </p>
            <p className="p">
              {t('fixer-how-it-works.how-it-works.paragraph-2')}
            </p>
          </div>
        )}
        <p className="p">{t('fixer-how-it-works.how-it-works.paragraph-3')}</p>
        <p className="p">{t('fixer-how-it-works.how-it-works.paragraph-4')}</p>
        <p className="bold bold2 narrow margin">
          {t('fixer-how-it-works.how-it-works.paragraph-5')}
        </p>
        <div className="button-container">
          <Button
            fullSize={true}
            name={t('fixer-how-it-works.how-it-works.button')}
            handleClick={openRegistrationModal}
          />
        </div>
        <h1>{t('fixer-how-it-works.track-your-fixer.title')}</h1>
        <div className="phones-container">
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.track-your-fixer.captions.one')}
            </p>
            <img src={phoneAcceptOffer} alt="phone accept offer" />
          </div>
          <div className="phone-and-caption">
            <p className="bold numbered">
              {t('fixer-how-it-works.track-your-fixer.captions.two')}
            </p>
            <img src={phoneOnTheWay} alt="phone on the way" />
          </div>
        </div>
        <p className="p">
          {t('fixer-how-it-works.track-your-fixer.paragraph-1')}
        </p>
        <p className="p">
          {t('fixer-how-it-works.track-your-fixer.paragraph-2')}
        </p>
        <p className="p">
          {t('fixer-how-it-works.track-your-fixer.paragraph-3')}
        </p>
        <p className="bold bold2 narrow margin">
          {t('fixer-how-it-works.track-your-fixer.paragraph-4')}
        </p>
        <div className="button-container">
          <a href="/fixer-information/requirements">
            <Button
              handleClick={openRegistrationModal}
              fullSize={true}
              name={t('fixer-how-it-works.track-your-fixer.button')}
            />
          </a>
        </div>
      </Container>
      <FixerArmy />
      {isDesktop ? (
        <a className="arrow-container" href="#">
          <img className="arrow" src={arrow} />
        </a>
      ) : null}
    </OuterContainer>
  )
}
export default HowItWorks

const Container = styled.div`
  padding: 40px 20px;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 500;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  .bold {
    font-size: 16px;
    font-weight: 800;
  }

  .narrow {
    margin: 0 50px;
  }

  .first {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  .bold {
    font-size: 16px;
    font-weight: 800;
  }

  .narrow {
    margin: 0 50px;
  }

  .p {
    margin: 8px 0;
    font-family: Avenir;
    font-weight: 300;
    line-height: 20px;
  }
  .b {
    margin-bottom: 16px;
  }

  .margin {
    width: 215px;
    margin: 30px 50px;
  }

  .phones-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .phone-and-caption {
    margin: 20px 0;
    p {
      color: #3d3d3d;
    }
  }

  .button-container {
    width: 200px;
    margin-bottom: 75px;
  }

  h2 {
    font-size: 28px;
    font-weight: 800;
  }

  .first-blobs {
    margin: 20px 0;
  }

  .icon-and-text {
    display: flex;
    align-items: center;
    padding-left: 22%;
    margin: 20px 0;
    display: flex;
  }
  .meet-text p {
    color: #3d3d3d;
    margin-left: 20px;
  }

  .second-blobs {
    margin: 20px 0;
  }

  .blob1 {
    height: 93px;
  }
  .blob2 {
    height: 84px;
  }
  .blob3 {
    height: 140px;
  }
  .blob4 {
    height: 101px;
  }
  .blob5 {
    height: 72px;
  }
  .blob6 {
    height: 115px;
  }

  @media screen and (min-width: 900px) {
    font-size: 16px;
    font-family: Avenir-heavy;

    h1 {
      font-size: 63px;
    }

    .fixers {
      margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-rows: 100px 150px 200px;
      grid-template-areas:
        'firstblobs title'
        'firstblobs text'
        'secondblobs text';
      margin-bottom: 50px;
    }
    .blob1 {
      height: 145px;
    }
    .blob2 {
      height: 127px;
    }
    .blob3 {
      height: 240px;
    }
    .blob4 {
      height: 161px;
    }
    .blob5 {
      height: 103px;
    }
    .blob6 {
      height: 190px;
    }
    .phone-and-caption {
      margin: 0 20px;
      width: 25%;
      img {
        width: 100%;
      }
    }

    @media screen and (min-width: 1270px) {
      .blob1 {
        height: 215px;
      }
      .blob2 {
        height: 197px;
      }
      .blob3 {
        height: 310px;
      }
      .blob4 {
        height: 211px;
      }
      .blob5 {
        height: 153px;
      }
      .blob6 {
        height: 240px;
      }
    }

    .first-blobs {
      grid-area: firstblobs;
      justify-self: end;
      align-self: end;
      margin-bottom: -30px;
    }

    .second-blobs {
      grid-area: secondblobs;
      justify-self: end;
    }

    .numbered {
      font-family: Avenir-light;
      font-weight: 800;
      font-size: 1.7vw;
    }
    h2 {
      grid-area: title;
      justify-self: start;
      color: #3d3d3d;
      font-size: 36px;
      padding-left: 50px;
      align-self: end;
    }
    .meet-text {
      grid-area: text;
    }
    .icon-and-text {
      padding-left: 50px;
    }
    .icon-and-text p {
      font-weight: 300;
    }

    .p {
      width: 850px;
      margin: 20px 0;
      line-height: 24px;
    }
    .bold2 {
      width: 475px;
    }

    .phones-container {
      margin: 30px 0 50px;
      flex-direction: row;
      justify-content: center;
    }

    .button-container {
      margin-top: 30px;
    }

    .first {
      margin: 0 0 30px;
      width: 100%;
    }
  }
`
const OuterContainer = styled.div`
  position: relative;
  .arrow-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`
