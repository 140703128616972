import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import Modal from '.././Modal'
import { useTranslation } from 'react-i18next'
import LogInModalDesktop from './LogInModalDesktop'
import { useState, useEffect } from 'react'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import LogInModalMobile from './LogInModalMobile'
import ReactivationModal from '../ReactivationModal'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import doesUserExists from '../../../utils/doesUserExists'
import useHandleUserNotFound from '../../../hooks/useHandleUserNotFound'

const LogInModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation() // don't touch this line
  let { currentModal } = useSelector((state: any) => state.modalReducer)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900)
  const [isReactivationModalOpen, setIsReactivationModalOpen] = useState(false)

  const [isPassValid, setIsPassValid] = useState<boolean | null>(null)
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null)

  const [isRemember, setIsRemember] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleNotFound = useHandleUserNotFound()

  useEffect(() => {
    if (currentModal === 'logInModal') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [currentModal])
  function logIn() {
    const auth = getAuth()

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        dispatch(setIsClosed('logInModal'))
        document.body.style.overflow = 'auto'
        const uid = user?.uid
        if (!uid) {
          return
        }
        return doesUserExists(uid)
      })
      .then((userExists) => {
        // handle the case when user exists in firebase but not in our database.
        // User might have selected wrong account type (fixer/customer)
        if (!userExists) {
          handleNotFound()
          return
        }
        const isCustomer =
          window.localStorage.getItem('userType') === 'customer'
        if (isCustomer) {
          navigate('/customer-account')
        } else {
          navigate('/account')
        }
      })
      .catch((error) => {
        console.log(error.message)
        if (error.message === 'Firebase: Error (auth/user-disabled).') {
          dispatch(setIsClosed('logInModal'))
          setIsReactivationModalOpen(true)
        }
        if (error.message === 'Firebase: Error (auth/user-not-found).') {
        }
        setIsEmailValid(false)
        setIsPassValid(false)
      })
  }

  const updatePassword = (input: string) => {
    setPassword(input)
  }

  const updateEmail = (input: string) => {
    setEmail(input)
  }

  const updateRemember = () => {
    setIsRemember((prev) => !prev)
  }

  const updateMedia = () => {
    setIsMobile(window.innerWidth < 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  if (currentModal === 'logInModal') {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)
  }

  return (
    <div style={{ position: 'relative' }}>
      {isReactivationModalOpen ? (
        <ReactivationModal
          isActive={isReactivationModalOpen}
          handleActivation={setIsReactivationModalOpen}
          email={email}
        />
      ) : (
        <Modal
          className="modal"
          option={currentModal === 'logInModal' ? true : false}
        >
          {!isMobile ? (
            <LogInModalDesktop
              isEmailValid={isEmailValid}
              isPassValid={isPassValid}
              setIsPassValid={setIsPassValid}
              setIsEmailValid={setIsEmailValid}
              handleSubmit={logIn}
              handleRemember={updateRemember}
              handlePassword={updatePassword}
              handleMail={updateEmail}
            />
          ) : (
            <LogInModalMobile
              isEmailValid={isEmailValid}
              isPassValid={isPassValid}
              setIsPassValid={setIsPassValid}
              setIsEmailValid={setIsEmailValid}
              handleSubmit={logIn}
              handleRemember={updateRemember}
              handlePassword={updatePassword}
              handleMail={updateEmail}
            />
          )}
        </Modal>
      )}
    </div>
  )
}

export default LogInModal
