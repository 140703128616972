import { createSlice } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'

const initialState: {
  userData: {
    category_id?: string[]
  }
} = {
  userData: {},
}

const UserDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.userData = action.payload
    },
    updateUserData(state, action) {
      if (action.type === 'UPDATE_CATEGORIES') {
        const { category_id } = state.userData
        if (category_id) {
          if (category_id?.includes(action.payload)) {
            state.userData.category_id = category_id.filter(
              (item) => item !== action.payload
            )
          } else {
            state.userData.category_id = [...category_id, action.payload]
          }
        }
      } else {
        state.userData = { ...state.userData, ...action.payload }
      }
    },
    updateCategories(state, action) {
      const { category_id } = state.userData
      if (category_id) {
        if (category_id?.includes(action.payload)) {
          state.userData.category_id = category_id.filter(
            (item) => item !== action.payload
          )
        } else {
          state.userData.category_id = [...category_id, action.payload]
        }
      }
    },
  },
})

export const { setUserData, updateUserData, updateCategories } =
  UserDataSlice.actions

export default UserDataSlice.reducer
