import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentNav } from '../store/slice/NavSlice'
import { setCurrentTitle } from '../store/slice/TitleSlice'
import { setCurrentDot } from '../store/slice/DotSlice'

const usePageParams = (
  title: string,
  page: string,
  dot: string,
  logo?: string,
  noTextHeader?: boolean
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.document.title = title + ' - Bokafix'
    dispatch(setCurrentTitle(logo ? logo : noTextHeader ? '' : title))
    dispatch(setCurrentNav(page))
    dispatch(setCurrentDot(dot))
  }, [title, page, dot, logo])
}

export default usePageParams
