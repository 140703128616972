import { createContext, useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '../api/firebaseConfig'
import { useTranslation } from 'react-i18next'
export const FireBaseContext = createContext()

const FirebaseTranslationContextProvider = ({ children }) => {
  const { t } = useTranslation()

  const [category, setCategory] = useState()
  const [footerLinks, setFooterLinks] = useState()

  useEffect(() => {
    const categoryCollectionRef = collection(db, 'category')

    const getCategory = async () => {
      const data = await getDocs(
        query(categoryCollectionRef, orderBy('order_number'))
      )
      const categoryCollections = data.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })
      const filtredFooterLinkData = [
        ...categoryCollections.map((category) => {
          return {
            name:
              t(`footer-links.services.${category.category_name}`) != ''
                ? t(`footer-links.services.${category.category_name}`)
                : category.category_name,
            path: "https://bokafix.com/download-customer-app",//"category.category_name",
          }
        }),
      ]
      const filtredMenuData = [
        ...categoryCollections.map((category) => {
          return {
            name:
              t(`footer-links.services.${category.category_name}`) != ''
                ? t(`footer-links.services.${category.category_name}`)
                : category.category_name,
            backgroundColor: category.category_background_color,
            fontColor: category.category_font_color,
            image: category.category_image,
            id: category.category_id,
          }
        }),
      ]
      setFooterLinks(filtredFooterLinkData)
      setCategory(filtredMenuData)
    }
    getCategory()
  }, [t])

  return (
    <FireBaseContext.Provider value={{ category, footerLinks }}>
      {children}
    </FireBaseContext.Provider>
  )
}

export default FirebaseTranslationContextProvider
