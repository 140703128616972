import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import MainButton from '../components/common/MainButton'
import team from '../assets/global/team/team-photo.png'
import usePageParams from '../utils/usePageParams'
import {
  AboutBackgroundDesktop,
  FAQBackgroundMobile,
} from '../components/Background'
import { Link } from 'react-router-dom'
import FixerArmy from '../components/layout/FixerArmy'

interface CardProps {
  data: {
    founder: string
    image: string
  }
}

const FoundersCard: React.FC<CardProps> = ({ data }) => {
  return (
    <article className="article">
      <div>
        <img src={data.image} alt="founder card image" />
      </div>
      <div>
        <h5>{data.founder}</h5>
      </div>
    </article>
  )
}

const About: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  usePageParams('About Bokafix', 'customerPage', 'About')

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900)

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <div>
      {isDesktop ? <AboutBackgroundDesktop /> : <FAQBackgroundMobile />}
      <Container>
        <div className="content-wrapper">
          <div className="heading-container title">
            <h1>{t('about-page.about-us')}</h1>
          </div>
          <div className="paragraph-container">
            <p>{t('about-page.paragraph-1')}</p>
            <p>{t('about-page.paragraph-2')}</p>
            <p>{t('about-page.paragraph-3')}</p>
            <p>{t('about-page.paragraph-4')}</p>
          </div>
          <div className="team">
            <img src={team} alt="Members of Bokafix" />
          </div>
          <div>
            <div className="team-container">
              <p>{t('about-page.article-paragraph-1')}</p>
              <span className="strong">
                {t('about-page.article-paragraph-2')}
                {isDesktop ? `${t('about-page.extra-text')}.'` : ".'"}
              </span>
              <br />
              <i className="signature">{t('about-page.signature')}</i>
            </div>
          </div>
          <div className="btn-container">
            <div className="main-btn-wrap">
              <Link to="/meet-the-team">
                <MainButton
                  isLarge={true}
                  isLight={true}
                  buttonName={t('about-page.light-button')}
                />
              </Link>
            </div>
          </div>
        </div>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default About

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  .main-btn-wrap {
    width: 260px;
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.5rem;
    max-width: ${(props) => props.theme.contentWidth.max};
    width: ${(props) => props.theme.contentWidth.width};
  }

  .heading-container {
    text-align: center;
    padding: 3rem;

    h1 {
      font-size: ${(props) => props.theme.text.heading1.responsiveSize};
      font-family: Avenir-heavy;
    }
    h2 {
      font-family: Avenir-heavy;

      font-size: ${(props) => props.theme.text.heading2.responsiveSize};
    }
  }
  p {
    line-height: 28px;
  }
  .paragraph-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      max-width: 800px;
      width: 100%;
      margin: 20px 0;
      font-size: ${(props) => props.theme.text.paragraph.fontSize};
    }
  }

  .team {
    margin-top: 50px;
    height: 350px;
    width: 90%;
    overflow: hidden;
  }
  .team img {
    width: 100%;
  }

  .team-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      max-width: 800px;
      width: 100%;
      text-align: center;
      margin: 20px 0;
      font-size: ${(props) => props.theme.text.paragraph.fontSize};
    }
    .signature {
      margin-top: 20px;
      margin-top: 20px;
      font-weight: 600;
      font-size: 14px;
      text-align: end;
      margin-bottom: 30px;
      color: #444444;
      align-self: end;
      align-self: end;
      @media only screen and (min-width: 900px) {
        font-size: 16px;
      }
    }
  }

  .grid-wrapper {
    display: flex;
    justify-content: center;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(120px, 220px));

    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(2, minmax(120px, 220px));
    }
    @media screen and (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  }

  .article {
    text-align: center;
    margin: 10px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    padding: 5rem;
  }

  .heading-btn-container {
    width: 100%;
    min-height: 200px;
    padding: 1rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: 35px;
      font-family: Avenir;
      font-weight: 100;
      color: #3d3d3d;
    }
  }

  .captions {
    width: 380px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .captions p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: black;
  }

  .strong {
    max-width: 800px;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    margin: 15px 0 -30px;
    line-height: 20px;
    color: #444444;
    @media only screen and (min-width: 900px) {
      line-height: 24px;
      font-family: Avenir;
      font-style: italic;
      font-size: 16px;
      color: black;
    }
  }

  @media only screen and (max-width: 900px) {
    padding-top: 30px;
    .captions {
      margin-top: 10px;
      width: 160px;
      p {
        font-size: 10px;
      }
    }
    .title {
      display: none;
    }

    .paragraph-container p,
    .team-container p {
      margin: 10px 0;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.13125px;

      color: #444444;
    }

    .team {
      margin-top: 20px;
      height: 120px;
      width: 243px;
      object-fit: cover;
    }
    .heading-btn-container {
      display: none;
    }
    .btn-container {
      width: 100%;
      padding: 0;
    }
    .main-btn-wrap {
      width: 100%;
    }
    .content-wrapper {
      margin: 10px;
    }
    .team-title h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }
    .heading-container {
      padding: 31px 0 24px;
    }
    .team-container {
      margin-top: 10px;
    }
  }
`
