import React, { useEffect, useState } from 'react'
import { AccountWrapper, Header, Button, Content, Main } from '../ui'
import styled from 'styled-components'
import { ContactUsBackgroundDesktop } from '../../../components/Background'
import { useTranslation } from 'react-i18next'
import usePageParams from '../../../utils/usePageParams'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { doc, DocumentData, getDoc } from 'firebase/firestore'
import { getAuth, User, onAuthStateChanged } from 'firebase/auth'
import { db } from '../../../api/firebaseConfig'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import AccountNav from './AccountNav'
import styles from './Account.module.css'

function Account(props: {
  children?: React.ReactNode
  currentPage?: string
  contentStyle?: React.CSSProperties
}) {
  const navigate = useNavigate()
  const { userData } = useSelector((state: any) => state.userDataReducer)
  const { t } = useTranslation()

  usePageParams('Account', 'customerPage', '', t('nav-links.my-account'), false)

  function genitifyName(name: string) {
    if (name) {
      return name[name.length - 1] === 's' ? `${name}'` : `${name}'s`
    }
  }

  function redirectToWorkingPage() {
    navigate('/working-on-it')
  }

  function redirectToAppDownload() {
    navigate('/download-fixer-app')
  }

  return (
    <div>
      <ContactUsBackgroundDesktop />
      <AccountWrapper>
        <Header>
          {genitifyName(userData?.first_name)} {t('account.bokafix-account')}
        </Header>
        <Main className={styles.accountWrapper}>
          <AccountNav currentPage={props.currentPage || ''} />
          <Content style={{ minHeight: '561px', ...props.contentStyle }}>
            {props.children}
          </Content>
        </Main>
      </AccountWrapper>
    </div>
  )
}

export default Account
