import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import Modal from '../Modal'
import { useDispatch, useSelector } from 'react-redux'
import RegistrationCustomerDesktop from './RegistrationCustomerDesktop'
import RegistrationCustomerMobile from './RegistrationCustomerMobile'
import { Form } from 'react-final-form'
import { getToken } from 'firebase/messaging'
import { messaging, db, auth } from '../../../api/firebaseConfig'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { setDoc, doc } from 'firebase/firestore'
// import initialTradesmenDBData from '../Registration/initialTradesmanDBData'
import initialFirebaseData from './initialFirebaseData'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { useTranslation } from 'react-i18next'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import { FormType, IMixedCustomerData } from '../../utils/interfaces'

function RegistrationCustomer() {
  const isDesktop = useMediaQuery(900)
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const [profileImage, setProfileImage] = React.useState<string>('')
  const [error, setError] = React.useState('')
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const displayIssue = (errors: string[]) => {
    if (errors && errors.length > 0) {
      setError(errors[0])
      setTimeout(() => {
        setError('')
      }, 5000)
      return false
    }
    return true
  }

  const handleSuccess = () => {
    dispatch(setIsClosed('registrationCustomerModal'))
    window.scrollTo(0, 0)
    alert('You have successfully registered!')
  }
  // register customer
  const handleSubmit = async (form: FormType) => {
    try {
      const isValid = form.getState().valid
      if (!isValid) {
        const errors = form.getState().errors
        displayIssue(Object.values(errors as {}))
        return
      }

      const fcm_token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPIDKEY,
      })

      if (!fcm_token) {
        displayIssue(['Could not get token'])
        return
      }

      const formData = form.getState().values

      const user = await createUserWithEmailAndPassword(
        auth,
        formData?.email,
        formData?.password
      )

      const uid = user.user.uid

      const finalData: IMixedCustomerData = {
        ...initialFirebaseData,
        ...formData,
        fcm_token,
        profile_image: profileImage,
        customer_id: uid,
        email: formData?.email,
      }
      delete finalData.password

      await setDoc(doc(db, 'customers', uid), finalData)
      // await FirebaseAPI.customerSignupEmail(formData.email)
      handleSuccess()
    } catch (error: any) {
      console.log(error)
      displayIssue([error.message])
    }
  }

  const props = {
    profileImage,
    setProfileImage,
    error,
    displayIssue,
    handleSubmit,
  }
  return (
    <div style={{ position: 'relative' }}>
      <Modal
        style={{ marginBottom: '0px', paddingBottom: '200px' }}
        option={currentModal === 'registrationCustomerModal' ? true : false}
      >
        <Form
          onSubmit={() => {}}
          render={({ handleSubmit, form }) => {
            return isDesktop ? (
              <RegistrationCustomerDesktop form={form} {...props} />
            ) : (
              <RegistrationCustomerMobile form={form} {...props} />
            )
          }}
        />
      </Modal>
    </div>
  )
}

export default RegistrationCustomer

// TODO: merge from main and create PR after prev PR is merged
