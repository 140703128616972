import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import { validateEmail, validatePassword } from './validation'
import styled from 'styled-components'
import securityImage from '../../../assets/global/blobs/security-blob.png'
import cancelButtonImage from '../../../assets/global/icons/circle-grey-close-btn.svg'
import { useTranslation } from 'react-i18next'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorItem } from '../../ui/ModalUI'
import Checkbox from '../../ui/Checkbox'
import {
  ModalTitle,
  CloseButton,
  ModalOrangeBtn,
  ModalBoldSpan,
  ModalOrangeLink,
  ModalInput,
  GreyLink,
} from '../../ui/ModalUI'
import PasswordField from '../../ui/PasswordField'
import { useState } from 'react'
import SelectType from './SelectType'

interface IProps {
  isEmailValid: boolean | null
  isPassValid: boolean | null
  setIsEmailValid: (value: boolean) => void
  setIsPassValid: (value: boolean) => void
  handleSubmit: () => void
  handleMail: (text: string) => void
  handlePassword: (pass: string) => void
  handleRemember: () => void
}

function LogInModalDesktop(props: IProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { currentModal } = useSelector((state: any) => state.modalReducer)
  const [errors, setErrors] = useState<string[]>([])
  const [step, setStep] = useState('selectType')

  function goToForgotPassword() {
    dispatch(setIsOpen('forgotPasswordModal'))
  }

  function closeModal() {
    dispatch(setIsClosed('logInModal'))
    document.body.style.overflow = 'unset'
  }

  const handlePassword = (text: string) => {
    props.handlePassword(text)
    props.setIsPassValid(validatePassword(text))
  }

  const handleEmail = (text: string) => {
    props.handleMail(text)
    props.setIsEmailValid(validateEmail(text))
  }

  const submitPassword = () => {
    if (props.isPassValid === true && props.isEmailValid === true) {
      props.handleSubmit()
    } else {
      props.setIsPassValid(false)
      props.setIsEmailValid(false)
    }
  }

  const style = step === 'selectType' ? { paddingLeft: '92px' } : {}
  const navigate = useNavigate()
  return (
    <ModalWrapper>
      <ModalInnerWrapper style={style}>
        <CloseButton
          style={{ top: '32px', right: '32px' }}
          onClick={closeModal}
        >
          <img src={cancelButtonImage} alt="Close Modal Icon" />
        </CloseButton>
        <LogInContainer>
          <ModalTitle style={{ marginBottom: '20px' }}>
            {t('button.login')}
          </ModalTitle>
          <SuggestionText>
            <ModalBoldSpan>{t('modals.new-user')}</ModalBoldSpan>
            <ModalOrangeLink onClick={() => navigate('/sign-up')}>
              {t('registration.create')}
            </ModalOrangeLink>
          </SuggestionText>
          <FormWrapper>
            <InteractionsWrapper>
              {step === 'selectType' ? (
                <SelectType setStep={setStep} />
              ) : (
                <InputsWrapper>
                  <ModalInput
                    onChange={(e) => handleEmail(e.target.value)}
                    required
                    style={{ width: '100%' }}
                    type="email"
                    placeholder={t('form.email')}
                  />
                  {props.isEmailValid || props.isEmailValid === null ? null : (
                    <ErrorItem>{t('errors.invalid-email')}</ErrorItem>
                  )}
                  <PasswordField
                    onChange={(e) => handlePassword(e.target.value)}
                    style={{ width: '100%' }}
                    placeholder={t('form.password')}
                  />
                  {props.isPassValid || props.isPassValid === null ? null : (
                    <ErrorItem>{t('errors.invalid-password')}</ErrorItem>
                  )}
                  <GreyLink
                    onClick={goToForgotPassword}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    {t('modals.forgot-password.mobile-heading')}
                  </GreyLink>
                  <RememberWrapper>
                    <Checkbox onChange={props.handleRemember} />
                    <ModalBoldSpan>{t('form.remember-me')}</ModalBoldSpan>
                  </RememberWrapper>
                  <ModalOrangeBtn onClick={submitPassword}>
                    {t('menu.buttons.sign-in')}
                  </ModalOrangeBtn>
                </InputsWrapper>
              )}
            </InteractionsWrapper>
            <SecurityImg src={securityImage} alt="Security image" />
          </FormWrapper>
        </LogInContainer>
      </ModalInnerWrapper>
    </ModalWrapper>
  )
}

export default LogInModalDesktop

const LogInContainer = styled.div`
  width: 100%;
`

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 15px;
`

const RememberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const InteractionsWrapper = styled.div`
  flex-grow: 1;
  max-width: 352px;
  width: 100%;
  display: flex;
`

const InputsWrapper = styled.div`
  max-width: 352px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const ModalInnerWrapper = styled.div`
  box-shadow: 0px 0px 7px 0px #8b8b8b;
  max-width: 895px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #fff;
  border-radius: 8px;
  padding: 32px;
  padding-bottom: 140px;
`

const SuggestionText = styled.p`
  margin-bottom: 60px;
`

const FormWrapper = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  gap: 45px;
`

const SecurityImg = styled.img`
  max-width: 315px;
`
