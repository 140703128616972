import styled from 'styled-components'

export const AccountWrapper = styled.div`
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 3.5rem;
  max-width: 1082px;
  width: 100%;
`

export const Header = styled.h1`
  align-self: start;
  margin-bottom: 16px;
  font-size: 40px;
  font-family: 'Avenir', Arial, sans-serif;
  color: #3d3d3d;
`

export const Button = styled.button`
  cursor: pointer;
  font-size: 16px;
  &:disabled {
    opacity: 0.7;
  }
  outline: none;
  border: none;
  padding: 14px 38px;
  background-color: #34344a;
  border-radius: 4px;
  font-weight: 900;
  color: white;
  font-family: 'Avenir', Arial, sans-serif;
`
export const A = styled.a`
cursor: pointer;
font-size: 16px;
&:disabled {
  opacity: 0.7;
}
outline: none;
border: none;
padding: 14px 38px;
background-color: #34344a;
border-radius: 4px;
font-weight: 900;
color: white;
text-align: center;
text-decoration:none;
font-family: 'Avenir', Arial, sans-serif;
`

export const ContentWrapper = styled.div``

export const ErrorSpan = styled.div`
  color: red;
`

export const ErrorDissolve = styled.div`
  color: red;
  opacity: 0;
  transition: 1s;

  &.dissolve {
    opacity: 1;
  }
`

export const Content = styled.div`
  padding: 47px 22px 78px 22px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
`

export const Main = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: 20px;
`

export const Stats = styled.div`
  a {
    text-decoration: none;
    color: white;
    outline: none;
  }

  .dark-green {
    background: #0f7173;
  }

  .orange {
    background: #f27649;
  }
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  background-color: white;
  font-family: 'Avenir', Arial, sans-serif;
  color: white;
  margin-bottom: 42px;
`

export const StatsWrapper = styled.div`
  padding: 30px 45px;
  display: flex;
  gap: 25px;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    .job {
      width: 100%;
    }
    .interactions {
      flex-grow: 1;
    }
  }
`

export const Interactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Select = styled.select`
  border: none;
  background-color: #f27649;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px 14px;
  font-weight: 900;
  padding-right: 0;
  color: white;
  outline: none;
  border-right: 16px solid #f27649;
`

export const Tile = styled.div`
  cursor: pointer;
  padding: 22px 33px;
  border-radius: 4px;
`

export const TileNumber = styled.h1`
  font-size: 130px;
`

export const TileTitle = styled.h3`
  text-align: center;
  font-weight: 200;
  font-size: 20px;
`

export const Job = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 26px;
`

export const StatLink = styled.a`
  border-radius: 4px;
  padding: 36px 18px;
  display: flex;
  justify-content: space-between;
  gap: 45px;
  font-size: 20px;

  & .earnings {
    font-weight: 100;
  }
  & .price {
    font-weight: 900;
  }
`

export const NewJobs = styled.a`
  border-radius: 4px;
  text-align: center;
  padding: 36px 0;
  font-weight: 900;
  font-size: 20px;
`

export const SectionHeader = styled.h3`
  align-self: start;
  text-align: left;
  font-weight: 200;
  margin-bottom: 36px;
  font-family: 'Avenir', Arial, sans-serif;
  letter-spacing: 0.45px;
  color: #3d3d3d;
  font-size: 25px;
`

export const UpdateEmailWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  & .password-container {
    border-radius: 4px;
    background-color: #f6f4f6;
  }
  & .password-container > input,
  & .password-container > input::placeholder {
    color: #727272;
    font-size: 13.3px;
  }
`

export const ChangeEmailButton = styled.button`
  align-self: flex-end;
  margin-top: 5px;
  padding: 6px 24px;
  border-radius: 5px;
  background-color: #f27649;
  color: white;
  font-weight: 900;
  cursor: pointer;
  border: none;
  outline: none;
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`
