import React from 'react'
import { MobileModalContainer } from '../styled-components'
import { Switch } from '@mui/material'
import { ErrorItem } from '../../ui/ModalUI'
import styled from 'styled-components'
import MainButton from '../../common/MainButton'

import backgroundpassword from '../../../assets/global/modals/reg-bg.png'
import MyLogo from '../../../assets/global/modals/MainLogo.png'
import inputlogo from '../../../assets/global/modals/user-logo-black.svg'
import passlogo from '../../../assets/global/modals/lock.svg'
import i18n from 'i18next'
import i18next from 'i18next'

import { useDispatch } from 'react-redux'

import PasswordField from './../../ui/PasswordField'
import {
  MobileElement,
  CardMobile,
  OptionsContainer,
  RememberContainer,
  OrangeText,
  RegisterLink,
  WhiteBoldSpan,
} from '../LogIn/styled-components'
import styles from './SelectUserType.module.css'
import customerIcon from '../../../assets/global/icons/customer-icon.png'
import fixerIcon from '../../../assets/global/icons/fixer-icon.png'
import { useTranslation } from 'react-i18next'
import { ISelectTypeProps } from '../../utils/interfaces'
import { setIsClosed, setIsOpen } from '../../../store/slice/ModalSlice'
import { useNavigate } from 'react-router-dom'
import SelectBusiness from '../../ui/SelectBusiness'
import { Select } from '../../ui/styled-components'
function SelectUserTypeMobile({
  setUserType,
  userType,
  proceedToRegistration,
  openLogin,
}: ISelectTypeProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const goTo = (path: string) => {
    dispatch(setIsClosed('selectUserTypeModal'))
    document.body.style.overflow = 'unset'
    if (path === 'customer') {
      dispatch(setIsOpen('registrationCustomerModal'))
    } else if (path === 'fixer') {
      navigate('/sign-up')
    } else {
      navigate('#')
    }
  }

  document.body.style.overflow = 'hidden'
  window.scrollTo(0, 0)

  return (
    <MobileElement>
      <CardMobile style={{ backgroundImage: `url(${backgroundpassword})` }}>
        <div className="wrap-container-forgotm">
          <div className="logo-container-forgotm">
            <img src={MyLogo} alt="logo" />
          </div>
          <h3 className={styles.title}>{t('button.signup')}</h3>
          <div className={styles.typesWrapper}>
            <button onClick={() => goTo('customer')} className={styles.type}>
              <img src={customerIcon} />
              <h4 className={styles.typeTitle}>{t('button.customer')}</h4>
            </button>
            <button onClick={() => goTo('fixer')} className={styles.type}>
              <img src={fixerIcon} />
              <h4 className={styles.typeTitle}>{t('button.fixer')}</h4>
            </button>
          </div>
        </div>
        <div className={styles.select}>
          <h3 className={`${styles.title} ${styles.selectTitle}`}>
            {t('button.select-language')}
          </h3>
          <Select
            className={styles.selectInput}
            style={{ padding: '16px 20px' }}
            defaultValue={i18n.language}
            onChange={(e) => i18next.changeLanguage(e.target.value)}
          >
            <option value="en">English</option>
            <option value="sv">Swedish</option>
          </Select>
        </div>
        <div className={styles.bottomLink}>
          <RegisterLink className={styles.link} onClick={openLogin}>
            {t('registration.already-member')}
            <WhiteBoldSpan className={styles.loginLink}>
              {t('button.login')}
            </WhiteBoldSpan>
          </RegisterLink>
        </div>
      </CardMobile>
    </MobileElement>
  )
}

export default SelectUserTypeMobile
