import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Star from '../Star'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentData } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, updateEmail } from 'firebase/auth'
import userIcon from '../../../assets/global/icons/user-icon.png'
import styles from './Account.module.css'
import { Button } from '../../../components/component-library/index'
import useLogOut from '../../../hooks/useLogOut'

function AccountNav(props = { currentPage: 'my-account' }) {
  const { t } = useTranslation()
  const [user, setUser] = useState<DocumentData | null>()
  const { userData } = useSelector((state: any) => state.userDataReducer)

  useEffect(() => {
    const auth = getAuth()
    setUser(auth.currentUser)
  }, [])

  const logOut = useLogOut(true)

  const navLinks = [
    {
      value: 'my-account',
      name: t('account.nav.my-account'),
      link: '/customer-account',
    },
    {
      value: 'my-bookings',
      name: t('account.nav.my-bookings'),
      link: '/bookings',
    },
    {
      value: 'my-invoices',
      name: t('account.nav.invoices'),
      link: '/invoices',
    },
    {
      value: 'my-messages',
      name: t('account.nav.messages'),
      link: '/working-on-it',
    },
    {
      value: 'my-settings',
      name: t('account.nav.settings'),
      link: '/customer-account/settings',
    },
  ]

  return (
    <NavWrapper>
      <img className="user-icon" src={user?.photoURL || userIcon} alt="Name" />
      <h4 className={`${styles.navName} user-name`}>
        {userData.first_name + ' ' + userData.last_name}
      </h4>
      <div className="nav-links">
        {navLinks.map((link, index) => {
          return (
            <a
              href={link.link}
              className={`nav-link${
                props.currentPage === link.value ? ' nav-link-active' : ''
              }`}
              key={link.value}
            >
              {link.name}
            </a>
          )
        })}
      </div>
      <div className={styles.logoutButtonWrapper}>
        <Button
          color="green"
          handleClick={logOut}
          fullSize={true}
          name={t('account.nav.log-out')}
        />
      </div>
    </NavWrapper>
  )
}

const NavWrapper = styled.div`
  border-radius: 4px;
  max-height: 561px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.35);
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 35px;
  font-family: 'Avenir', Arial, sans-serif;

  .nav-reviews {
    color: #00000080;
    font-weight: 300;
    font-size: 10px;
    margin-bottom: 20px;
  }

  .user-icon {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
  }
  .user-name {
    font-size: 25px;
    color: #3d3d3d;
    font-weight: 400;
  }
  .nav-logout {
    cursor: pointer;
    font-size: 15px;
    color: #00000080;
    background: none;
    outline: none;
    border: none;
    margin-bottom: 10px;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
    color: #3d3d3d;
    padding: 12px;
    font-size: 20px;
    font-weight: 100;
    min-width: 160px;
  }
  .nav-link-active {
    border: 1px solid #f27649;
    border-radius: 4px;
  }
`

export default AccountNav
