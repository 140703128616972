import React from 'react'
import { IRequest } from '../../../components/utils/interfaces'
import styles from './Invoices.module.css'
import { Timestamp } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { useFormatTimestamp } from '../../../hooks/useFormatTimestamp'
interface IProps {
  invoices: IRequest[]
  fetchPdf: (request: IRequest) => void
}
function InvoicesTableMobile(props: IProps) {
  const { t } = useTranslation()
  const format = useFormatTimestamp()
  return (
    <div className={styles.invoices}>
      {props.invoices.map((invoice, index) => {
        const date = new Date(invoice.created_on._seconds * 1000)
        return (
          <div key={invoice.created_on._seconds} className={styles.invoice}>
            <div className={styles.invoiceRow}>
              <h5 className={styles.invoiceLabel}>{t('general.fixer-name')}</h5>
              <h5 className={styles.invoiceData}>{invoice.tradesman_name}</h5>
            </div>
            <div className={styles.invoiceRow}>
              <h5 className={styles.invoiceLabel}>{t('general.date')}</h5>
              <h5 className={styles.invoiceData}>{format(date)}</h5>
            </div>
            <div className={styles.invoiceRow}>
              <h5 className={styles.invoiceLabel}>{t('general.job')}</h5>
              <h5 className={styles.invoiceData}>
                {invoice.sub_category_details[0].sub_category_name}
              </h5>
            </div>
            <button className={styles.tableButton}>
              {t('button.download-invoice')}
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default InvoicesTableMobile
