import styled from 'styled-components'

export const CloseButton = styled.img`
position: absolute;
top: 6%;
right: 15px;
`

export const ModalBody = styled.div`
padding: 25px 20px;
background-color: white;
border-radius: 8px;
border-top-left-radius: 0;
border-top-right-radius: 0;
display: flex;
justify-content: center;
`

export const ModalParent = styled.div``

export const ModalTitle = styled.h3`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
padding: 14px 42px;
background: #34344a;
border: 1px solid #34344a;
box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
backdrop-filter: blur(13.5914px);
border-radius: 8px;
color: #ffffff;
`

export const ModalWindow = styled.div`
max-width: 345px;
width: 100%;
margin: 0 auto;
border-radius: 8px;
box-shadow: 0px 2px 10px rgba(96, 96, 96, 0.5);
backdrop-filter: blur(13.5914px);
`

export const ModalWrapper = styled.div`
display: flex;
justfiy-content: center;
align-items: center;
padding-left: 15px;
padding-right: 15px;
width: 100vw;
height: 100vh;
position: fixed;
background: rgba(209, 209, 209, 0.50967);
`