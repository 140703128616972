import { useEffect, useState } from 'react'
import styled from 'styled-components'
import usePageParams from '../utils/usePageParams'
import FixerArmy from '../components/layout/FixerArmy'

const TermsConditionsPage = () => {
  usePageParams(
    'Terms and Conditions',
    'customerPage',
    'Terms and Conditions',
    '',
    true
  )
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900)
  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 900)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  return (
    <div>
      <Container>
        <section className="intro">
          <p className="grey italic">
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE AGREEING TO
            PROVIDE YOUR SERVICES IN THE BOKAFIX APPLICATIONS AND/OR WEBSITE AS
            THEY INCLUDE IMPORTANT INFORMATION ABOUT THE SERVICES, YOUR RIGHTS
            AND OTHER IMPORTANT INFORMATION.
          </p>
          <h1>
            TERMS AND CONDITIONS <br />
            FOR <br />
            CUSTOMERS <br />
            IN <br />
            UNITED KINGDOM, SWEDEN AND IRELAND
          </h1>
          <p>Last updated: 22/03/2024</p>
          <div>
            <p className="hanging-indent">
              THESE TERMS APPLY TO BOKAFIX'S PLATFORM (AS DEFINED BELOW) IN
              SWEDEN, IRELAND AND THE UNITED KINGDOM AND ARE LEGALLY BINDING
              BETWEEN YOU AND BOKAFIX.
            </p>
            <p className="hanging-indent">
              THE BOKAFIX PLATFORM ENABLES A CUSTOMER TO REQUEST FIXER SERVICES
              FROM FIXERS’. WHEN YOU HAVE ACCEPTED A REQUEST FROM A FIXER FOR A
              FIXER SERVICE THROUGH THE BOKAFIX MOBILE APPLICATIONS AND
              WEBSITES, YOU ARE CONTRACTING DIRECTLY WITH THAT FIXER AND BOKAFIX
              IS NOT PART OF THAT CONTRACT.
            </p>
            <p className="hanging-indent">
              FIXERS ARE INDEPENDENT CONTRACTORS OF CUSTOMERS AND NOT EMPLOYEES,
              PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURES, INDEPENDENT
              CONTRACTORS OR FRANCHISEES OF BOKAFIX. BOKAFIX DOES NOT PERFORM
              FIXER SERVICES AND DOES NOT EMPLOY INDIVIDUALS TO PERFORM FIXER
              SERVICES. BY CONNECTING CUSTOMERS AND FIXERS SEEKING FIXER
              SERVICES, BOKAFIX OPERATES AS AN ONLINE MARKETPLACE THAT CONNECTS
              CUSTOMERS WITH FIXERS WHO WISH TO PERFORM A VARIETY OF FIXER
              SERVICES.
            </p>
            <p className="hanging-indent">
              YOUR CONSENT AT REGISTRATION AND CONTINUED USE OF THE BOKAFIX
              PLATFORM CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT TO ALL OF
              THE TERMS AND CONDITIONS IN THESE TERMS AND THE PRIVACY POLICY FOR
              THE COUNTRY IN WHICH THE FIXER SERVICES ARE PERFORMED, AS WELL AS
              ANY FUTURE AMENDMENTS AND ADDITIONS TO THESE TERMS AND/OR PRIVACY
              POLICY WE MAY PUBLISH FROM TIME TO TIME. IF ANY FUTURE CHANGES TO
              THESE TERMS AND/OR PRIVACY POLICY ARE UNACCEPTABLE TO YOU OR CAUSE
              YOU TO NO LONGER BE IN COMPLIANCE WITH THESE TERMS OR PRIVACY
              POLICY YOU MUST DEACTIVATE YOUR ACCOUNT AND STOP USING THE BOKAFIX
              PLATFORM.
            </p>
            <p className="hanging-indent">
              IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS AND/OR THE PRIVACY
              POLICY AND ABIDE BY ITS TERMS, YOU MAY NOT USE OR ACCESS THE
              BOKAFIX PLATFORM.
            </p>
            <p className="hanging-indent">
              THESE TERMS DO NOT CREATE AN EMPLOYMENT RELATIONSHIP WHATSOEVER
              BETWEEN YOU AND BOKAFIX.
            </p>
            <p className="hanging-indent">
              BY AGREEING TO THESE TERMS, YOU ALSO AGREE TO COMPLY WITH OUR
              PRIVACY POLICY.
            </p>
          </div>
        </section>
        <section className="about">
          <h2>1. ABOUT US</h2>
          <p>
            We are Bokafix AB a private limited liability company established in
            Sweden registered under the company number 559317-4278 and having
            its offices at Harrvägen 8a, 181 30 Lidingö. You can contact us at
            [info@bokafix.com].
          </p>
          <p>
            Bokafix operates as an online marketplace that connects Customers
            with Fixers who wish to perform a variety of Fixer Services. Bokafix
            does not perform Fixer Services and does not employ people to
            perform Fixer Services. Fixers operate as independent contractors
            and are customarily engaged in an independently established business
            of the same nature as that involved in the services performed for
            Customers through the Bokafix Platform. Bokafix does not control or
            direct the Fixers' performance of their services or set their work
            locations, work hours, or terms of work. Fixers provide services
            under their own name or business name, and not under Bokafix's name.
            Fixers provide their own tools and supplies to perform their
            services; Bokafix does not provide the tools or supplies. Fixers are
            free to maintain a clientele without any restrictions from Bokafix
            and are free to offer and provide their services elsewhere,
            including through competing platforms. Fixers are free to accept or
            reject Customers and contracts. Fixers are not penalized for
            rejecting Customers or contracts, though if Fixers accept a Customer
            or contract through the Bokafix Platform, they are expected to
            fulfill their contractual obligations to their Customer. Fixers set
            their own rates for services performed in the Bokafix general
            marketplace
          </p>
        </section>
        <h2>2. DEFINITIONS</h2>
        <section className="definitions">
          <div className="table">
            <div className="term-container">
              <p className="term">"Apps"</p>
              <p className="definition">
                means the Bokafix application(s) for Customer and for Fixers
                available to download on Google Play and App Store;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Affiliates"</p>
              <p className="definition">
                means Bokafix, its parents, affiliates or licensors, including
                their respective directors, officers, shareholders, agents,
                investors, subsidiaries, attorneys, representatives, insurers,
                employees successors and assigns;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Background Check"</p>
              <p className="definition">
                means the review process before a Fixer can register on the Apps
                and/or Website as set out in clause 6;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix Commission Fee"</p>
              <p className="definition">
                means the fee Bokafix charge the Fixer for each completed and
                paid Fixer Service which is currently set at 11% of the Fixer
                Payment and Out of Pocket Expenses and 5% of the Material
                Payment.
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix Platform"</p>
              <p className="definition">
                means the Bokafix Website, Bokafix Apps and related services,
                information and communications;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix"</p>
              <p className="definition">
                means Bokafix AB, a private limited liability company
                incorporated and registered in Sweden with company number
                559317-4278, whose registered office is at Harrvägen 8a, 181 30
                Lidingö (“<b>we</b>” or “<b>us</b>”);
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Bokafix Names, Marks or Works"</p>
              <p className="definition">
                means Bokafix's or its licensor's company names, logos, products
                or service names, trademarks, service marks, trade dress, other
                indicia of ownership, or copyrights;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Confidential Information"</p>
              <p className="definition">
                means any and all of Bokafix’s trade secrets, confidential and
                proprietary information, and all other information and data of
                Bokafix that is not generally known to the public or other third
                parties who could derive value, economic or otherwise, from its
                use or disclosure. Confidential Information shall be deemed to
                include technical data, know-how, research, product plans,
                products, services, customers, markets, software, developments,
                inventions, processes, formulas, technology, designs, drawings,
                engineering, hardware configuration information, marketing,
                finances, strategic and other Proprietary Materials and
                confidential information relating to Bokafix or Bokafix’s
                business, operations or properties, including information about
                Bokafix’s staff, Fixers, Customer, or partners, or other
                business information disclosed or obtained directly or
                indirectly in writing, orally or by drawings or observation;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Customer"</p>
              <p className="definition">
                means an individual or company seeking to obtain Fixer Services
                from Fixers using the Bokafix Platform (“<b>you</b>” or “
                <b>your</b>”);
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer"</p>
              <p className="definition">
                means an independent third party’s contractors and include, but
                are not limited to sole traders and companies seeking to perform
                Fixer Services for Customers and registered on the Bokafix
                Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer Payment"</p>
              <p className="definition">means as set out in clause 9.3;</p>
            </div>
            <div className="term-container">
              <p className="term">"Fixer Service(s)"</p>
              <p className="definition">
                means a short-term service of electrical, plumbing, decorating,
                carpentry, gas and boiler services, provided by a Fixer to a
                Customer. These are made available to you through your use of
                the Bokafix Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Landlord"</p>
              <p className="definition">
                means a person or company that either a&#41; owns a building,
                leasehold and/or freehold and is paid by a tenant to use it; or
                b&#41; a person or company managing a building, freehold and/or
                leasehold and are responsible for keeping the freehold,
                leasehold or building in good condition;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Notices"</p>
              <p className="definition">
                means agreements, notices, disclosures and other communications
                to which these Terms refers;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Material Payment"</p>
              <p className="definition">means as set out in clause 9.3 (c);</p>
            </div>
            <div className="term-container">
              <p className="term">"Out of Pocket Expense"</p>
              <p className="definition">
                means any out of pocket expenses agreed by the Fixer and the
                Customer in relation to the Fixer Services;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Privacy Policy"</p>
              <p className="definition">
                means Bokafix Privacy policy available here:{' '}
                <a href="/privacy-policy">Privacy Policy</a>;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"PSP"</p>
              <p className="definition">
                means a third-party service provider approved by Bokafix for
                making and receiving payments for Fixer Services and other
                payments related to the Bokafix Platform;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"PSP Services Agreement"</p>
              <p className="definition">means as set out in clause 9.8;</p>
            </div>
            <div className="term-container">
              <p className="term">"Public Areas"</p>
              <p className="definition">
                means profiles, email systems, blogs, message boards, reviews,
                ratings, postings, chat areas, news groups, forums, communities
                and/or other message or communication facilities on Bokafix
                Platform that allow users to communicate with other users.
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Rating"</p>
              <p className="definition">
                means reviews and/or rating of Customers and/or Fixers as
                described in clause 13.3;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Service Contract"</p>
              <p className="definition">
                means the Fixer Service Contract which is the agreement between
                you and a Fixer regarding Fixer Services as detailed in clause
                5;
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Terms"</p>
              <p className="definition">means these Terms and Conditions;</p>
            </div>
            <div className="term-container">
              <p className="term">"User Generated Content"</p>
              <p className="definition">
                means any information and materials you provide to Bokafix, its
                agents, Affiliates, and corporate partners, Customers or other
                use in connection with your registration for and use of the
                Bokafix Platform including without limitation the information
                and materials posted or transmitted for use in Public Areas.
              </p>
            </div>
            <div className="term-container">
              <p className="term">"Website"</p>
              <p className="definition">
                means Bokafix's website www.bokafix.com
              </p>
            </div>
          </div>
        </section>
        <section className="overview">
          <h2>3. OVERVIEW</h2>
          <ol>
            <li>
              These Terms cover your access and use of the Bokafix Platform.
            </li>
            <li>
              You must read carefully and agree to the Terms prior to accessing
              and using Bokafix Platform. These Terms expressly supersede prior
              agreements or arrangements about the Bokafix Platform between you
              and Bokafix.
            </li>
            <li>
              These Terms apply to the extent they do not conflict with any user
              and supplemental terms that specifically apply to Bokafix
              Platform. You will be asked to agree to any such terms separately.
            </li>
            <li>
              By using Bokafix Platform, you confirm that you accept these
              Terms. If you do not agree to these Terms, you may not access or
              use Bokafix Platform. We recommend that you keep a copy of these
              terms for future reference.
            </li>
            <li>
              If we have to contact you, we will do so by push notification from
              the App and/or Website, phone call, text message or email, using
              the details you provided to us on registration for the Apps and
              contain in the account details section of the Apps and/or Website.
              When we use the words "writing" or "written" in these terms, this
              includes emails, texts and push notifications.
            </li>
            <li>
              Unless otherwise agreed separately in writing, the Bokafix
              Platform are made available to you for your use in Sweden, Ireland
              and the United Kingdom only.
            </li>
          </ol>
        </section>
        <section className="fixer-services">
          <h2>4. FIXER SERVICES</h2>
          <ol>
            <li>
              Bokafix does not provide Fixer Services. Bokafix enables you to
              connect with Fixers’ who carry out Fixer Services. The Fixers are’
              independent third-party contractors, registered businesses or
              other legal entity who are not employed by or acting on behalf of
              Bokafix.
            </li>
            <li>
              Bokafix does not supervise, scope direct, control, or monitor the
              work and the Fixer Services performed.
            </li>
            <li>
              When you are logged in online and have requested a Fixer Service
              you will be given information in the Apps and/or Website about
              Fixers available in your service area before you decide to accept.
              The Fixer will also be given identifying information about you,
              including your first name, company information (if any), photo,
              location and other related information about the Fixer Service you
              have requested.
            </li>
            <li>
              You are free to decide to accept, reject or ignore a request from
              a Fixer. Acceptance is communicated to you through the Apps and/or
              Website. When you have accepted a request from a Fixer this will
              give rise to a direct contract between you and the Fixer.
            </li>
            <li>
              If you agree on the terms of a Fixer Service with a Fixer, you and
              such Fixer form a Service Contract directly between the two of you
              as set forth in more detail in clause 5 below.
            </li>
            <li>
              After a Fixer Service is completed, you will be asked to provide a
              Rating on the Fixer, who may also give you a Rating. Although your
              Rating by itself will not make you lose access to Bokafix
              Platform, Fixer will see your Rating in the Apps and/or Website
              and this Rating may affect whether or not they decide to offer you
              to carry out the Fixer Service requested.
            </li>
            <li>
              You are responsible for any obligations that may arise from the
              provision of the Fixer Services.
            </li>
            <li>
              If a job is more complicated than expected or the Fixer can't fix
              it due to inadequate information from you, the Fixer should stop
              all work and if he can, provide you with a new estimated price for
              the Fixer Service, if possible. If a new price is required, no
              work will continue until you agree to the additional work in
              writing and change is initialed by you. If you the Fixer can't
              complete the Fixer Service, the Fixer will inform you the reason
              he can't complete it. You will be charge a minimum one-hour fee.
            </li>
          </ol>
        </section>
        <section className="service-contract">
          <h2>5. SERVICE CONTRACT</h2>
          <ol>
            <li>
              You acknowledge and agree that a legally binding contract with a
              Fixer is formed when you and that Fixer have accepted the Service
              Contract on the Bokafix Platform.
            </li>
            <li>
              The Service Contract include:
              <ol className="letter-list" type="a">
                <li>
                  the Order Details proposed and accepted in the Apps and/or
                  Website;
                </li>
                <li>
                  the terms and conditions in the Service Contract, which you
                  must review and accept before you accept any Fixer Services in
                  the Bokafix Platform.; and
                </li>
                <li>
                  any other contractual terms accepted by you and the Fixer to
                  the extent such terms do not conflict with the terms in these
                  Terms or the Service Contract and do not expand Bokafix's
                  obligations or restrict Bokafix rights under these Terms.
                </li>
              </ol>
            </li>
            <li>
              Bokafix is not a party to any Service Contract and the formation
              of a Service Contract will not create an employment or other
              service relationship between Bokafix and you, nor will it create
              an employment relationship between Bokafix or the Fixer. Neither
              you or the Fixer have authority to enter into written or oral,
              whether implied or express, contracts on behalf of Bokafix.
            </li>
          </ol>
        </section>
        <section className="background-checks">
          <h2>6. BACKGROUND CHECKS</h2>
          <ol>
            <li>
              All Fixers' must provide certain documents before they can
              register on the Bokafix Platform which include, but is not limited
              to, qualification documents, using third-party services as
              appropriate <b>(“Background Checks”)</b>.
            </li>
            <li>
              The Background Checks are provided by third parties and Bokafix
              cannot and does not assume any responsibility for the accuracy or
              reliability of Background Check information.
            </li>
            <li>
              Please also note that the PSP may provide additional checks on you
              in order for you to register with them. If you do not pass the
              applicable checks asked by the PSP, you will not be able to
              register with Bokafix.
            </li>
          </ol>
        </section>
        <section className="insurance">
          <h2>7. INSURANCE</h2>
          <ol>
            <li>
              It is the Fixer's responsibility to have an adequate and valid
              insurance that covers the Fixer Service carried out by them at all
              times during their use of the Bokafix Platform. Any insurance
              claims should be communicated and handled with the Fixer directly.
            </li>
            <li>
              Bokafix is not liable for any acts or omissions of Fixers nor does
              Bokafix provide insurance against any losses sustained by Fixers
              or Customers.
            </li>
          </ol>
        </section>
        <section className="privacy-policy">
          <h2>8. PRIVACY POLICY</h2>
          <p>
            Bokafix collects, uses and discloses information from or about you
            as described in Bokafix Privacy Policy. You acknowledge that by
            accepting these Terms and by using the Apps and/or Website you have
            reviewed and accepted the Privacy Policy. Additional privacy notices
            may apply, including from third party controllers for Fixer
            Services.
          </p>
        </section>
        <section className="charges-and-payments">
          <h2>9. CHARGES AND PAYMENTS</h2>
          <h3>Charges</h3>
          <ol>
            <li>
              You enter into contract with Fixers' directly and Bokafix will not
              be a party to any contract for Fixer Services. You are responsible
              for payment for all Fixer Services you book through the Bokafix
              Platform. Such payment must be made via the PSP. Payments made or
              received outside of the Bokafix Platform is not allowed and these
              Term will not be applicable to Fixer Service paid partly or in
              full outside the Bokafix Platform. Bokafix is not obliged to
              compensate a Fixer for a Customers' failure to pay for the Fixer
              Services.
            </li>
            <li>Payment for Fixer Services must be made via the PSP.</li>
            <li>
              You will be responsible for paying the Invoice(s) for each Fixer
              Service detailed in the Service Contract (“Fixer Payment”).
            </li>
            <li>
              In some cases, if the Fixer Services cannot be performed due to
              your action or inaction (for example, if you are not present at
              the agreed location), all charges may apply.
            </li>
            <li>
              You may be charged supplementary charges, costs and/or fines, for
              your use or misuse of Fixer Services.
            </li>
            <li>
              The charges may be modified in the Apps and/or Website from time
              to time.
            </li>
            <li>
              Bokafix Commission fee is inclusive of applicable taxes including
              VAT unless specifically stated otherwise.
            </li>
            <h3 className="left50">Payment through PSP</h3>
            <li>
              You will be required to set up an account with a PSP, which may
              require any or all of the following: registration with the PSP,
              consent to the terms of service of the PSP, and completion of a
              vetting process and/or account validation at the request of the
              PSP. By accepting these Terms, you agree that they have downloaded
              or printed, and reviewed and agreed to, the PSP Services Agreement
              (the <b>“PSP Services Agreement”</b> ). Please note that Bokafix
              is not a party to the PSP Services Agreement and that you, the PSP
              and any other parties listed in the PSP Services Agreement are the
              parties to the PSP Services Agreement and that Bokafix has no
              obligations, responsibility or liability to any Fixer or other
              party under the PSP Services Agreement.
            </li>
            <li className="left10">
              When you receive confirmation through the Bokafix Platform or via
              email that a Fixer Service has been completed, you automatically
              authorizes the PSP to process the Invoice(s). You may be billed a
              one-hour cancellation charge as damages at the Fixer's hourly rate
              through the PSP if you book a Fixer Service but cancel it less
              than 24 hours from the scheduled start time of the Fixer Service.
            </li>
            <li className="left10">
              Bokafix reserves the right (but not the obligation) upon request
              from Customer or Fixer, or upon notice of any potential fraud,
              unauthorized charges or other misuse of the Bokafix Platform, to
              place on hold any Fixer Payment, Out of Pocket expenses, Material
              Payment or refund or provide credits, or arrange for the PSP to do
              so.
            </li>
            <li className="left10">
              For more information about charges and supplementary charges,
              fees, costs and/or fines specific to the relevant Fixer Service(s)
              please consult the support section in Bokafix's Apps and/or
              Website.
            </li>
          </ol>
          <h3>When you are charged</h3>
          <p>
            Unless otherwise agreed, all charges are due and invoices should be
            accurately sent to you immediately when a Fixer Service is
            completed. For the avoidance of doubt, if a Fixer Service takes
            three days to complete, invoices should be sent directly when the
            Fixer Service is completed in full, i.e. after the third day.
            Payment will be facilitated by a PSP using the preferred payment
            method associated with your account, after which Bokafix will send
            you a receipt by email. If your primary payment method is not able
            to be charged, PSP may use a secondary payment method if available.
            If the your payment method(s) are unavailable, PSP and Bokafix
            reserve the right to continue to try to take payment.Unless
            otherwise agreed, all charges are due and invoices should be
            accurately sent to you immediately when a Fixer Service is
            completed. For the avoidance of doubt, if a Fixer Service takes
            three days to complete, invoices should be sent directly when the
            Fixer Service is completed in full, i.e. after the third day.
            Payment will be facilitated by a PSP using the preferred payment
            method associated with your account, after which Bokafix will send
            you a receipt by email. If your primary payment method is not able
            to be charged, PSP may use a secondary payment method if available.
            If the your payment method(s) are unavailable, PSP and Bokafix
            reserve the right to continue to try to take payment.
          </p>
        </section>
        <section className="cancelations">
          <h2>10. CANCELLATIONS</h2>
          <ol>
            <li>
              Rules on cancellation of a Fixer Service can be found in the
              Service Contract.
            </li>
            <li>
              Bokafix may refuse requests and cancel the Fixer Services if there
              is reasonable doubt about the correctness or authenticity of the
              request and or approval or about the contact information. We may
              also end any contract in place between you and Bokafix.
            </li>
          </ol>
        </section>
        <section className="termination">
          <h2>11. TERMINATION</h2>
          <ol>
            <li>
              You are free at all times to use the Bokafix Platform (where and
              when available) and may terminate these Terms and the Bokafix
              Platform by closing your account, providing all outstanding
              balances are paid.
            </li>
            <li>
              We may terminate these Terms and your access to the Bokafix
              Platform with immediate effect if we conclude that there is a
              breach by you of these Terms or any other agreement between you
              and Bokafix
            </li>
            <li>
              Bokafix may, in its sole discretion, terminate these Terms or
              discontinue the Bokafix Platform at any time by giving you
              reasonable advance notice in writing.
            </li>
            <li>
              If we cannot charge the relevant charges to your preferred payment
              method, we may suspend or remove your access to the Bokafix
              Platform until payment is made.
            </li>
            <li>
              You must immediately pay any outstanding charges due to Bokafix by
              you upon termination. Any such charges will survive termination
              and we reserve all rights to collect payment after termination.
            </li>
          </ol>
        </section>
        <section className="plateform">
          <h2>12. BOKAFIX PLATFORM</h2>
          <h3>Your access to and use of the Bokafix Platform</h3>
          <ol>
            <li>
              By accessing and using the Bokafix Platform you accept these
              Terms.
            </li>
            <li>
              The Apps and/or Website can be downloaded or accessed on most
              modern mobile devices with an internet connection and equipped
              with popular operating systems such as Android or iOS.
            </li>
            <li>
              You are responsible for obtaining the network access necessary to
              use the Apps and/or Website and are responsible for any rates and
              fees from your mobile network provider, including from data
              consumption while using the Apps and/or Website. You are also
              responsible for obtaining compatible devices and operating systems
              necessary to use the Apps and/or Website, including any software
              or hardware updates.
            </li>
            <li>
              We provide the Bokafix Platform “as is” and “as available.” The
              Apps and/or Website, or any related digital content, may be
              subject to limitations, delays, and/or other problems inherent to
              the use of the internet and electronic communications and are not
              guaranteed to be available, uninterrupted or error free at all
              times. Bokafix may suspend or withdraw or restrict the
              availability of all or any part of the Bokafix Platform for
              business and operational reasons. Bokafix will try to give you
              reasonable notice of any suspension or withdrawal.
            </li>
            <li>
              To use the Bokafix Platform in Sweden, Ireland and the United
              Kingdom, you must register in the Apps and/or Website and set up
              an account and you must be eighteen (18) years old or older.
            </li>
            <li>
              You must provide us with certain personal information to register
              and set up your account, including your name, personal number (if
              applicable), phone number, address and email address.
            </li>
            <li>
              You are under no obligation to log in to, or use, Bokafix's Apps
              and/or Website. If you choose to stop using Bokafix's Apps and/or
              Website you may do so without giving us any notice. You further
              agree that Fixers' are under no obligation to log in to, or use,
              Bokafix's Apps and/or Website.
            </li>
            <li>
              Bokafix may temporarily restrict your access to and use of the
              Bokafix Platform if there is a suspected breach of your
              obligations (see clause 13 below) or otherwise of these Terms,
              including where we receive a complaint in relation to fraud. There
              may be circumstances in which we are unable to provide you with
              information about the complaint whilst an investigation is ongoing
              (by us and/or a third party such as the police).
            </li>
            <h3>Your Obligations when using the Bokafix Platform</h3>
            <li>
              When using the Bokafix Platform, you must comply with all
              applicable laws. You may only use the Bokafix Platform for lawful
              purposes and for the purposes for which they were intended as
              otherwise referenced in these Terms. You must not misuse Bokafix's
              Apps and/or Website or attempt to defraud either us or Fixers.
            </li>
            <li>
              You must provide accurate and complete information when you
              register an account and when using the Bokafix Platform.
            </li>
            <li>
              Your account cannot be licensed or shared unless expressly
              permitted by Bokafix. Once you have set up an account, you;
              <ol type="a" className="letter-list italic">
                <li>
                  may not register more than one account unless we agree
                  otherwise in writing;
                </li>
                <li>
                  may not allow third parties to use your account or transfer
                  your account to a third party;
                </li>
                <li>
                  must keep your account information accurate, complete and
                  up-to-date;
                </li>
                <li>
                  must keep your login details safe and confidential at all
                  times; and
                </li>
                <li>
                  must tell us immediately if you have any reason to believe
                  that someone else knows your username or password or if you
                  suspect that someone else is using your account.
                </li>
              </ol>
            </li>
            <li>
              You will be responsible for paying any fees or charges that may be
              incurred as a result of the provision of Fixer Services requested
              through your account.
            </li>
            <li>
              You must not, in your use of the Bokafix Platform, cause any
              nuisance, annoyance, inconvenience, or property damage, whether to
              the Fixers, Bokafix or any other party.
            </li>
            <li>
              We reserve the right to introduce a fee, and we will inform you,
              for specific features on the Apps and/or Website, in which case
              you can decide if you wish to proceed. These may be subject to
              separate terms, which Bokafix will make available to you at the
              time.
            </li>
            <h3>What if there's a problem with my Bokafix Platform?</h3>
            <li>
              If there is a problem with the Bokafix Platform, or if you have a
              complaint, please contact us on the details set out in the
              “Contact Us” section above.
            </li>
            <h3>
              Do not rely on information made available to you on the Bokafix
              Platform.
            </h3>
            <li>
              Although Bokafix makes reasonable efforts to update the
              information provided through the Bokafix Platform, Bokafix makes
              no representations, warranties, or guarantees, whether express or
              implied, that the content on the Apps and/or Website is accurate,
              complete, or up to date.
            </li>
            <h3>Ownership of and rights in the Bokafix Platform</h3>
            <li>
              Bokafix reserves all rights not expressly granted in these Terms.
              The Bokafix Platform, Bokafix's devices and all data gathered
              through the Apps and/or Website (including all intellectual
              property rights in all of the foregoing) are and remain Bokafix's
              property.
            </li>
            <li>
              You may not, and may not allow any other party to: (a) license,
              sublicense, copy, modify, distribute, create, sell, resell,
              transfer, or lease any part of Bokafix’s Apps and/or Website; (b)
              reverse engineer or attempt to extract the source code of the Apps
              and/or Website except as allowed under law; (c) launch or cause to
              launch any programs or scripts for the purpose of scraping,
              indexing, surveying, or otherwise data mining any part of the Apps
              and/or Website or data; (d) use, display, or manipulate any of
              Bokafix Names, Marks, or Works for any purpose other than to use
              the Bokafix Platform; (e) create or register any (i) businesses,
              (ii) URLs, (iii) domain names, (iv) software application names or
              titles, or (v) social media handles or profiles that include
              Bokafix Names, Marks, or Works or any confusingly or substantially
              similar mark, name, title, or work; (f) use Bokafix Names, Marks,
              or Works as your social media profile picture or wallpaper; (g)
              purchase keywords (including, but not limited to Google AdWords)
              that contain any Bokafix Names, Marks, or Works; or (h) apply to
              register, reference, use, copy, and/or claim ownership in
              Bokafix’s Names, Marks, or Works, or in any confusingly or
              substantially similar name, mark, title, or work, in any manner
              for any purposes, alone or in combination with other letters,
              punctuation, words, symbols, designs, and/or any creative works.
            </li>
            <li>
              Subject to your compliance with these Terms, we grant you a
              personal, non-exclusive, non-transferable, revocable,
              non-sublicensable license to install and/or make use of the Apps
              and/or Website on your device solely for your use and for you to
              access and use information made available through the Apps and/or
              Website. Any rights not expressly granted herein are reserved by
              Bokafix. This license will terminate when these Terms are
              terminated in accordance with clause 11.
            </li>
          </ol>
        </section>
        <section className="user-generated">
          <h2>13. USER GENERATED CONTENT</h2>
          <ol>
            <li>
              You are solely responsible for User Generated Content, and we act
              merely as a passive conduit for your online distribution and
              publication of your User Generated Content. You acknowledge and
              agree that Bokafix is not involved in the creation, development of
              User Generated Content, disclaims any responsibility for User
              Generated Content, and cannot be liable for claims arising out of
              or relating to User Generated Content. Further, you acknowledge
              and agree that Bokafix has no obligation to monitor or review User
              Generated Content but reserves the right to limit or remove User
              Generated Content if it is not compliant with the terms of these
              Terms.
            </li>
            <li>
              You hereby represent and warrant to Bokafix that your User
              Generated Content (a) will not be false, inaccurate, incomplete or
              misleading; (b) will not be fraudulent or involve the transfer or
              sale of illegal, counterfeit or stolen items; (c) will not
              infringe on any third party’s privacy, or copyright, patent,
              trademark, trade secret or other proprietary right or rights of
              publicity or personality; (d) will not violate any law, statute,
              ordinance, code, or regulation (including without limitation those
              governing export control, consumer protection, unfair competition,
              anti-discrimination, incitement of hatred or false or misleading
              advertising, anti-spam or privacy); (e) will not be defamatory,
              libellous, malicious, threatening, or harassing; (f) will not be
              obscene or contain pornography (including but not limited to child
              pornography) or be harmful to minors; (g) will not contain any
              viruses, scripts such as Trojan Horses, worms, time bombs, corrupt
              files, cancelbots or other computer programming routines that are
              intended to damage, detrimentally interfere with, surreptitiously
              intercept or expropriate any system, data or personal information;
              (h) will not claim or suggest in any way that you are employed or
              directly engaged by or affiliated with Bokafix or otherwise
              purport to act as a representative or agent of Bokafix; and (i)
              will not create liability for Bokafix or cause Bokafix to lose (in
              whole or in part) the services of its Internet Service Providers
              (ISPs) or other partners or suppliers.
            </li>
            <li>
              The Bokafix Platform hosts User Generated Content relating to
              ratings of specific Fixers and Customers ( “Rating” ). Rating is
              such Fixer’s or Customer’s opinion and not the opinion of Bokafix
              and has not been verified or approved by Bokafix. You agree that
              Bokafix is not responsible or liable for any Rating or other User
              Generated Content. Bokafix encourages Customer and/or Fixer to
              give objective, constructive and honest Ratings about the other
              party with whom they have transacted. Bokafix is not obligated to
              investigate any remarks posted by Fixers or Customers for accuracy
              or reliability or to consider any statements or materials posted
              or submitted by a Customer or Fixer about any Rating but may do so
              at its discretion. You agree that Rating enables Fixers and/or
              Customers to post and other Fixers and Customers to read about
              their experiences and that you will not complain or take any
              action merely because you happen to disagree with such Rating. You
              may request removal of a Rating that violates these Terms by
              contacting the Support team at [support@bokafix.com]. Each
              Customer should undertake their own research prior to booking to
              be satisfied that a specific Fixer has the right qualifications
              for a Fixer Service.
            </li>
            <li>
              Bokafix respects the personal and other rights of others, and
              expects you to do the same. Bokafix is entitled to identify a
              Fixer or Customer to other Fixer and/or Customers or to third
              parties who claim that their rights have been infringed by User
              Generated Content submitted by that Fixer or Customer, so that
              they may attempt to resolve the claim directly.
            </li>
            <li>
              If a User believes, in good faith, that any User Generated Content
              provided on or in connection with the Bokafix Platform is
              objectionable or infringes any of its rights or the rights of
              others (e.g. counterfeiting, insult, invasion of privacy), the
              Fixer or Customer is encouraged to notify Bokafix. If a Fixer or
              Customer discovers that User Generated Content promotes crimes
              against humanity, incites hatred and/or violence, or concerns
              child pornography, the Fixer or Customer must notify Bokafix. Such
              notification can be made at [support@bokafix.com].
            </li>
          </ol>
        </section>
        <section className="thrid-party">
          <h2>14. LINKS TO THIRD PARTY WEBSITES </h2>
          <ol>
            <li>
              The Bokafix Platform may contain links (such as hyperlinks) to
              third-party websites. Such links do not constitute endorsement by
              Bokafix or association with those websites, their content or their
              operators. Such links (including without limitation external
              websites that are framed by the Bokafix Platform as well as any
              advertisements displayed in connection therewith) are provided as
              an information service, for reference and convenience only.
              Bokafix does not control any such websites, and is not responsible
              for their availability, accuracy, content, advertising, products,
              or services. It is your responsibility to evaluate the content and
              usefulness of the information obtained from other websites. You
              acknowledge and agree that Bokafix is not involved in the creation
              or development of third-party websites and disclaims any
              responsibility for third-party websites, and cannot be liable for
              claims arising out of or relating to third-party websites.
              Further, you acknowledge and agree that Bokafix has no obligation
              to monitor, review, or remove links to third-party websites, but
              reserves the right to limit or remove links to third-party
              websites on the Bokafix Platform at its sole discretion.
            </li>
            <li>
              The use of any website controlled, owned or operated by a third
              party is governed by the terms and conditions of use and privacy
              policy for that website. You access such third-party websites at
              your own risk. Bokafix expressly disclaims any liability arising
              in connection with your use and/or viewing of any websites or
              other material associated with links that may appear on the
              Bokafix Platform. You hereby agree to hold Bokafix harmless from
              any liability that may result from the use of links that may
              appear on the Bokafix Platform.
            </li>
          </ol>
        </section>
        <section className="confidential">
          <h2>15. CONFIDENTIAL INFORMATION</h2>
          <p>
            You acknowledge that Confidential Information is a valuable, special
            and unique asset of Bokafix and agree that you will not, for the
            lifetime of your account on Bokafix plus 10 years thereafter,
            disclose, transfer, or use (or seek to induce others to disclose,
            transfer or use) any Confidential Information for any purpose other
            than use of the Bokafix Platform in accordance with these Terms. If
            relevant, you may disclose the Confidential Information to your
            authorized employees and agents provided that they are also bound to
            maintain the confidentiality of Confidential Information. You shall
            use best efforts to protect Confidential Information from
            unauthorized disclosure, transfer or use. You shall promptly notify
            Bokafix in writing of any circumstances that may constitute
            unauthorized disclosure, transfer, or use of Confidential
            Information. You shall return all originals and any copies of any
            and all materials containing Confidential Information to Bokafix
            promptly upon deactivation of your account or termination of these
            Terms for any reason whatsoever.
          </p>
        </section>
        <section className="liability">
          <h2>16. YOUR LIABILITY AND INDEMNITY</h2>
          <ol>
            <li>
              If you are a Landlord you are responsible for having the
              appropriate approval and permission for disclosing information on
              our Platform about your tenant(s) and/or the properties you manage
              and/or own.
            </li>
            <li>
              You are liable for any damage suffered by us as a result of your
              violation of these Terms, your misuse of the Bokafix Platform or
              your violation of any laws or third party rights. You are liable
              for all activities conducted through your account unless such
              activities are not authorised by you and you are not otherwise
              negligent.
            </li>
            <li>
              In order to have access to the Bokafix Platform, you agree to
              indemnify, defend (at our option) and hold us and our respective
              officers, directors, and employees harmless from and against all
              claims, liabilities, expenses, damages, penalties, fines, social
              security contributions and taxes arising out of or related to a
              breach of these Terms, breach of applicable law or third party
              claims directly or indirectly related to your use of the Fixer
              Services generally.
            </li>
          </ol>
        </section>
        <section className="limitation">
          <h2>17. LIMITATION OF LIABILITY</h2>
          <ol>
            <li>
              Nothing in these Terms limits or excludes any liability which
              cannot legally be limited or excluded, including liability for
              death or personal injury caused by negligence and liability for
              fraud or fraudulent misrepresentation or alters your rights as a
              consumer that cannot be excluded under applicable law.
            </li>
            <li>
              Bokafix and its Affiliates are not liable under or in relation to
              these Terms including, but not limited to, liability in contract,
              tort (including negligence, misrepresentation), breach of
              statutory duty, restitution or otherwise for any of the following
              arising out of or in connection with the use of the Bokafix
              Platform made available through the Bokafix Platform: (i) loss of
              profits; (ii) loss of sales or business; (iii) loss of agreements
              or contracts; (iv) loss of anticipated savings; (v) loss of use or
              corruption of software, data or information; (vi) loss of or
              damage to goodwill and (vii) indirect or consequential loss.
            </li>
            <li>
              Bokafix, its Affiliates and subcontractors not liable for:
              <ol className="letter-list" type="a">
                <li>
                  any Booking that is not accepted or is otherwise cancelled by
                  Bokafix.
                </li>
                <li>
                  damages or losses arising from any transaction between you and
                  a Customer;
                </li>
                <li>
                  the availability and accuracy of the content, products or
                  services requested from a Customer; or
                </li>
                <li>
                  delay or failure in performance resulting from causes beyond
                  our reasonable control.
                </li>
              </ol>
            </li>
            <li>
              Our total liability to you, arising out of these Terms or their
              subject matter, or in connection with the provision to you of the
              Bokafix in contract, tort (including negligence,
              misrepresentation), breach of statutory duty, restitution or
              otherwise, shall not exceed five hundred pounds sterling (£500),
              unless otherwise agreed.
            </li>
            <li>
              Bokafix, and its Affiliate are not liable for business losses. If
              you use Bokafix Platform for any commercial or business purpose
              Bokafix will have no liability to you for any loss of profit, loss
              of business, loss of revenue, business interruption, or loss of
              business opportunity (whether or not such loss could be reasonably
              expected to flow from our negligence or breach of these Terms).
            </li>
          </ol>
        </section>
        <section className="changes">
          <h2>18. CHANGES TO THESE TERMS AND THE BOKAFIX PLATFORM</h2>
          <ol>
            <li>
              Bokafix may, at its sole and absolute discretion, change, modify,
              add to, supplement, suspend, discontinue, or delete any of the
              terms and conditions of these Terms (Privacy Policy,) and review,
              improve, modify or discontinue, temporarily or permanently, the
              Bokafix Platform or any content or information through the Bokafix
              Platform at any time, effective with or without prior notice and
              without any liability to Bokafix. Bokafix may also impose limits
              on certain features or restrict your access to part or all of the
              Bokafix Platform without notice or liability.
            </li>
            <li>
              Bokafix will endeavor to notify you of material changes to these
              Terms by email, but will not be liable for any failure to do so.
              If any future changes to these Terms are unacceptable to you or
              cause you to no longer be in compliance with these Terms, you must
              deactivate your account, and immediately stop using the Bokafix
              Platform. Your continued use of the Bokafix Platform following any
              revision to these Terms constitutes your complete and irrevocable
              acceptance of any and all such changes, except where prohibited by
              any laws or regulations in your jurisdiction.
            </li>
          </ol>
        </section>
        <section className="general">
          <h2>19. GENERAL</h2>
          <ol>
            <li>
              We may assign, subcontract or transfer these Terms or any of our
              rights or obligations in them, in whole or in part, without your
              prior consent, provided this will not lead to a reduction of the
              rights you are entitled to by virtue of these Terms or by law. We
              will tell you if this happens. You may not assign, subcontract or
              transfer these Terms or any of our rights or obligations, in whole
              or in part, as your use of the Bokafix Platform. Bokafix is
              personal, unless agreed otherwise.
            </li>
            <li>
              These Terms replace all previous agreements relating to your
              access and use of the Bokafix Platform. In addition, in the event
              of any conflict between these Terms and any previous agreements
              relating to your use of Fixer Services, these Terms shall prevail.
            </li>
            <li>
              You may be required to accept additional terms to access or use
              the Bokafix Platform. If there is a conflict between these Terms
              and the separate agreement, the latter will have precedence unless
              specified otherwise in the separate agreement.
            </li>
            <li>
              There are no third party beneficiaries to these Terms except as
              provided for in these Terms.
            </li>
          </ol>
        </section>
        <section className="notices">
          <h2>20. NOTICES AND CONSENT TO ELECTRONIC SIGNATURES</h2>
          <ol>
            <li>
              By agreeing to these Term, you consent to receive any Notices
              electronically, including without limitation by email or by
              posting Notices on the Apps and/or Website. You agree that all
              Notices that we provide to you electronically satisfy any legal
              requirement that such communications be in writing. Unless
              otherwise specified in these Terms, all Notices under these Terms
              will be in writing and will be deemed to have been duly given when
              received, if personally delivered or sent by certified or
              registered mail, return receipt requested; when receipt is
              electronically confirmed, if transmitted by facsimile or email; or
              the day it is shown as delivered by the overnight delivery
              service's tracking information, if sent for next day delivery by a
              recognized overnight delivery service.
            </li>
            <li>
              By using the Bokafix Platform, you agree to transact
              electronically through the Bokafix Platform. You also agree to the
              following: (i) your electronic signature is the legal equivalent
              of your manual signature and has the same legal effect, validity
              and enforceability as a paper-based signature; (ii) your use of a
              keypad, mouse or other device to select an item, button, icon or
              similar act/action, constitutes your signature as if actually
              signed by you in writing; and (iii) no certification authority or
              other third party verification is necessary to validate your
              electronic signature, and the lack of such certification or third
              party verification will not in any way affect the enforceability
              of your electronic signature.
            </li>
          </ol>
        </section>
        <section className="dispute">
          <h2>21. DISPUTE</h2>
          <ol>
            <li>
              In the event you have a dispute with a Fixer that you cannot
              resolve with the Fixer directly, we may offer to mediate between
              you and the Fixer. If you would like our help, we would ask you to
              raise a complaint using the report an issue function in the Apps
              and/or Website. If we decide to mediate, we will let you know our
              decision within 4 weeks of the dispute being referred to us.
              Please note that Bokafix is not a legal mediator and any
              suggestions made by Bokafix how the dispute should be resolved are
              not legally binding. If you wish to have a legally binding
              decision you will have to bring legal proceedings in court.
              Bokafix does not commit to using an online dispute resolution
              platform to resolve consumer disputes.
            </li>
            <li>
              In the event of a dispute with Bokafix, we would ask you to raise
              a complaint using the report an issue function in the App and/or
              Website. In the unlikely event that we cannot resolve the dispute,
              we will work with you to discuss a way forward to resolve the
              dispute, which may include mediation. Bokafix's right to refer the
              dispute to a court is expressly reserved. Bokafix does not commit
              to using an online dispute resolution platform to resolve consumer
              disputes
            </li>
          </ol>
        </section>
        <section className="governing">
          <h2>22. GOVERNING LAW AND JURISDICTION</h2>
          <p>
            For Customers registered on our UK or Irish App and/or Website,
            these Terms shall be governed by and construed in accordance with
            the laws of England and any dispute regarding these Terms or the use
            of the Bokafix Platform will only be dealt with by the English
            courts.
          </p>
          <p>
            For Customers registered on our Swedish App and/or Website, these
            Terms shall be governed by and construed in accordance with the laws
            of Sweden and any dispute regarding these Terms or the use of the
            Bokafix Platform will only be dealt with by the Swedish courts.
          </p>
        </section>
      </Container>
      {isDesktop ? <FixerArmy /> : null}
    </div>
  )
}

export default TermsConditionsPage

const Container = styled.div`
  width: 100%;
  padding: 20px 10%;
  .italic {
    font-style: italic;
  }
  .grey {
    color: #707070;
  }
  /////////////////////////////////////
  font-family: Avenir;
  h2 {
    margin: 50px 0 20px;
  }
  p {
    margin-top: 20px;
  }
  ol {
    padding-left: 70px;
  }
  li {
    padding-left: 10px;
    position: relative;
  }
  li:before {
    position: absolute;
    left: -32px;
  }
  .letter-list *::before {
    display: none;
  }
  .intro {
    h1 {
      text-align: center;
      margin: 50px auto;
      line-height: 40px;
    }
    .hanging-indent {
      margin-top: 20px;
      padding-left: 25px;
      text-indent: -25px;
    }
  }
  .definitions {
    .table {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .term-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .term {
          width: 30%;
          font-weight: 600;
        }
        .definition {
          width: 60%;
        }
      }
    }
  }
  .overview {
    li:before {
      content: '3.';
    }
  }
  .fixer-services {
    li:before {
      content: '4.';
    }
  }
  .service-contract {
    li:before {
      content: '5.';
    }
  }
  .background-checks {
    li:before {
      content: '6.';
    }
  }
  .insurance {
    li:before {
      content: '7.';
    }
  }
  .charges-and-payments {
    li:before {
      content: '9.';
      left: -40px;
    }
    .left50 {
      margin-left: -70px;
    }
    .left10::before {
      left: -50px;
    }
    h3 {
      margin: 10px 0;
    }
    p {
      margin-top: 0px;
    }
  }
  .cancelations {
    li:before {
      content: '10.';
      left: -43px;
    }
  }
  .termination {
    li:before {
      content: '11.';
      left: -43px;
    }
  }

  .plateform {
    li:before {
      content: '12.';
      left: -50px;
    }
  }
  .ownership {
    li:before {
      content: '13.';
      left: -43px;
    }
  }
  .terminations {
    li:before {
      content: '14.';
      left: -43px;
    }
  }
  .user-generated {
    li:before {
      content: '13.';
      left: -48px;
    }
  }
  .links {
    li:before {
      content: '14.';
      left: -42px;
    }
  }
  .confidentiality {
    li:before {
      content: '15.';
      left: -42px;
    }
  }
  .liability {
    li:before {
      content: '16.';
      left: -42px;
    }
  }
  .limitation {
    li:before {
      content: '17.';
      left: -42px;
    }
  }
  .changes {
    li:before {
      content: '18.';
      left: -42px;
    }
  }
  .general {
    li:before {
      content: '19.';
      left: -42px;
    }
  }
  .notices {
    li:before {
      content: '20.';
      left: -42px;
    }
  }
  .dispute {
    li:before {
      content: '21.';
      left: -42px;
    }
  }
  .governing {
    li:before {
      content: '22.';
      left: -42px;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 5%;
    ol {
      padding-left: 40px;
    }
    .letter-list {
      padding-left: 20px;
    }
    .charges-and-payments,
    .bokafix-platform {
      .left50 {
        margin-left: -45px;
      }
    }
  }
`
