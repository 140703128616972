import React from 'react'
import styles from '../Settings/Settings.module.css'
import { Select } from '../../../components/component-library/index'
import { useTranslation } from 'react-i18next'
import { SettingsProps } from '../Settings/Settings'
import Switch from '../../../components/ui/Switch'
import { useDispatch } from 'react-redux'
import { setIsOpen } from '../../../store/slice/ModalSlice'
import { useNavigate } from 'react-router-dom'

function FixerSettingsMobile(props: SettingsProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const openModal = () => dispatch(setIsOpen('changePasswordModal'))
  const { t, i18n } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <div className={styles.customSelects}>
        <Select
          placeholder={t('settings.language')}
          options={[
            { value: 'sv', text: t('settings.swedish') },
            { value: 'en', text: t('settings.english') },
          ]}
          onChange={(e) => {
            if (e.target.value === 'sv' || e.target.value === 'en')
              props.selectLanguage(e.target.value)
          }}
        />
        <Select
          value={props.country}
          placeholder={t('settings.country')}
          options={[
            { value: 'United Kingdom', text: t('country.uk-short') },
            { value: 'Sweden', text: t('country.sweden') },
            { value: 'Ireland', text: t('country.ireland') },
          ]}
          onChange={(e: any) => {
            props.setCountry(e.target.value)
          }}
        />
      </div>
      <div className={styles.security}>
        <h5 className={styles.title}>{t('settings.security')}</h5>
        <div className={styles.box}>
          <div className={styles.row}>
            <span className={styles.label}>{t('form.password')}</span>
            <button className={styles.btn}>
              {t('settings.change-password')}
            </button>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>{t('form.face-id')}</span>
            <Switch onChange={() => {}} />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.row}>
            <span className={styles.label}>{t('form.profile-picture')}</span>
          </div>
          <div className={styles.row}>
            <button
              onClick={openModal}
              className={`${styles.btn} ${styles.centered}`}
            >
              {t('button.upload')}
            </button>
          </div>
        </div>
        <button
          onClick={() => navigate('/working-on-it')}
          className={styles.stripeBtn}
        >
          {t('button.add-stripe')}
        </button>
      </div>
    </div>
  )
}

export default FixerSettingsMobile
