import React from 'react'
import { FormApi } from 'final-form'
import styles from './MyAccount.module.css'
import { useTranslation } from 'react-i18next'
import useFormValidation from '../../../hooks/useFormValidation'

interface IProps {
  form: FormApi
  style?: React.CSSProperties
  color?: string
  submit: (form: FormApi) => Promise<void>
}

function SubmitBtn({ form, submit, style, color = 'orange' }: IProps) {
  const error = useFormValidation(form)
  const { t } = useTranslation()
  const onClick = async () => {
    await submit(form)
  }
  return (
    <button
      onClick={onClick}
      style={style}
      className={`${styles.interactButton} ${color} ${
        error ? styles.submitError : ''
      }`}
    >
      {t('button.save-changes')}
    </button>
  )
}

export default SubmitBtn
