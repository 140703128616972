import React, { useState, useEffect } from 'react'
import { IFixer, IRequest } from '../../../components/utils/interfaces'
import placeholder from '../../../assets/global/icons/user-icon.png'
import styles from './MyBookings.module.css'
import { Button } from '../../../components/component-library/index'
import FirebaseAPI from '../../../firebase-api/FirebaseAPI'
import { useFormatTimestamp } from '../../../hooks/useFormatTimestamp'
import { useTranslation } from 'react-i18next'
import NoBookings from './NoBookings'
import verified from '../../../assets/global/icons/verified.svg'
import { useNavigate } from 'react-router-dom'
import PayButton from './PayButton'
import normalizeTimestamp from '../../../utils/normalizeTimestamp'

function BookingsListDesktop(props: { data: IRequest[], fixers: IFixer[] }) {
  const navigate = useNavigate()
  const format = useFormatTimestamp()
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.bookings}>
      {props.data.length > 0 ? (
        props.data.map((el) => {
          const jobIsNotFinished = el.completed_on.toString() === 'NA'
          const date = normalizeTimestamp(el.created_on)
          const localizedDate = date ? date.toLocaleDateString(i18n.language, { day: 'numeric' , month: 'long', year: 'numeric'}) : 'Invalid';
          const hhMM = date ? date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit', hour12:false }) : 'Invalid'
          return (
            <div key={el.updated_on._seconds}>
              <div className={styles.item}>
                <div className={styles.date}>
                  <p className={styles.dateText}>{localizedDate}</p>
                  <p className={styles.dateText}>
                {hhMM}
                  </p>
                </div>
                <div className={styles.booking}>
                  <div className={styles.bookingProfile}>
                    <img
                      src={
                        props.fixers.find(
                          (fixer) => fixer.tradesman_id === el.tradesman_id
                        )?.company_logo || placeholder
                      }
                      className={styles.profileImage}
                    />
                    <img className={styles.verified} src={verified} />
                  </div>
                  <div className={styles.bookingInfo}>
                    <h6 className={styles.fixerName}>{el.tradesman_name}</h6>
                    <span className={styles.fixerTask}>
                      {el.sub_category_details[0].sub_category_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.buttons}>
                <div className={styles.buttonWrapper}>
                  <button
                    className={`${styles.payBtn} green`}
                    onClick={() => {
                      navigate('/bookings/' + el.customer_request_id)
                    }}
                  >
                    {t('customer-bookings.view-details')}
                  </button>
                </div>
                {jobIsNotFinished && (
                  <div className={styles.buttonWrapper}>
                    <PayButton status={el.payment_status} />
                  </div>
                )}
              </div>
            </div>
          )
        })
      ) : (
        <NoBookings />
      )}
    </div>
  )
}

export default BookingsListDesktop
