import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  links: {
    link: string
    path: string
  }
  className: string
}

const NavigationLink: React.FC<Props> = ({ links, className }): JSX.Element => {
  return (
    <li>
      <div
        className="dot"
        id={className}
        style={{ visibility: 'hidden' }}
      ></div>
      <NavLink
        className={className}
        to={links.path}
        // TODO  : Think about replacing the "dot" thingy by this
        // Only issue is that the Before attribute doens't seems to work out.
        // Will come back to it when a better solution comes up
        //
        // style={({ isActive }) => ({
        //   borderRadius: isActive ? '50%' : '',
        // })}
      >
        {links.link}
      </NavLink>
    </li>
  )
}

export default React.memo(NavigationLink)
