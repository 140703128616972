import React, { CSSProperties } from 'react'
import styles from './InsuranceWindow.module.css'
import { Button } from '../../../component-library/index'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '../../../../hooks/useMediaQuery'

function InsuranceWindow(props: {
  continue: () => void
  containerStyles?: CSSProperties
}) {
  const isMobile = !useMediaQuery(900)
  const { t } = useTranslation()
  return (
    <div style={props.containerStyles} className={styles.container}>
      <div className={styles.content}>
        {isMobile && (
          <h3 className={styles.subtitle}>
            {t('modals.registration-insurance-proceed.subtitle')}
          </h3>
        )}
        <h3 className={styles.title}>
          {t('modals.registration-insurance-proceed.title')}
        </h3>
        <div className={styles.buttonWrapper}>
          <a href="https://bit.ly/Bokafix-Towergate" target="_blank">
            <Button
              handleClick={props.continue}
              color="green"
              fullSize={true}
              name={t('modals.registration-insurance-proceed.button')}
            />
          </a>
        </div>
        {isMobile && (
          <p className={styles.note}>
            {t('modals.registration-insurance-proceed.note')}
          </p>
        )}
      </div>
    </div>
  )
}

export default InsuranceWindow
